import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnChanges, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@klickdata/core/auth';
import { FormHelper } from '@klickdata/core/form';
import { MobileService } from '@klickdata/core/mobile';
import { Resource, ResourceService, ResourceTag } from '@klickdata/core/resource';
import { ResourceItem, ResourceItemService } from '@klickdata/core/resource-item';
import { Observable, tap } from 'rxjs';
import { ResourceItemDirective } from '../resource-item.directive';

@Component({
    selector: 'app-resource-builder-material',
    templateUrl: './material.component.html',
    styleUrls: ['./material.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialComponent extends ResourceItemDirective implements OnChanges {
    public resourceItems$: Observable<ResourceItem[]>;
    currentIndex = 0;

    constructor(
        protected resourceService: ResourceService,
        protected fb: FormBuilder,
        protected changeRef: ChangeDetectorRef,
        protected itemService: ResourceItemService,
        protected dialog: MatDialog,
        protected authService: AuthService,
        protected mobileService: MobileService
    ) {
        super(resourceService, changeRef, dialog, authService, mobileService);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.childGroup && !changes.childGroup.previousValue && changes.childGroup.currentValue) {
            this.childGroup.addControl('child_items', new FormArray([]), { emitEvent: false });
            this.resourceItems$ = this.itemService
                .getResourceItemsByParentItemId(this.childGroup.value.child_resource_id, this.childGroup.value.id)
                .pipe(tap((items) => this.initChildItemsForm(items)));
        }
    }

    selectResource(resource: Resource) {
        super.selectResource(resource);
    }

    /**
     * @override
     * @param resource
     * @param patch
     */
    protected afterResourceSelect() {
        const instructions = this.selected.instructions;
        if (!this.childGroup.value.instructions && instructions) {
            this.childGroup.patchValue({ instructions: instructions });
        }
    }

    initChildItemsForm(items: ResourceItem[]) {
        const itemsArray = this.childGroup.get('child_items') as FormArray;
        itemsArray.clear();
        items.forEach((item) => {
            itemsArray.push(this.createItemGroup(item), { emitEvent: false });
        });
        FormHelper.resetForm(this.childGroup);
    }

    private createItemGroup(item: ResourceItem): FormGroup {
        return this.fb.group({
            id: item.id,
            can_download: item.can_download,
        });
    }

    toggleDownload(itemId: number, checked: boolean) {
        const itemsArray = this.childGroup.get('child_items') as FormArray;
        const index = itemsArray.controls.findIndex((control: FormGroup) => control.get('id').value === itemId);

        if (index !== -1) {
            const group = itemsArray.at(index) as FormGroup;
            group.patchValue({
                can_download: checked,
            });
            FormHelper.markForm(this.childGroup);
        }
    }

    previousItem() {
        this.currentIndex--;
    }

    nextItem() {
        this.currentIndex++;
    }
}
