import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { AuthService } from '@klickdata/core/auth';
import { Customer } from '@klickdata/core/customer';
import { GroupData, GroupService } from '@klickdata/core/group';
import { MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { SideNaveData } from '@klickdata/core/mobile';
import { SideNaveActionsTypes, SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { AppScope } from '@klickdata/core/resource';
import { User, UserService } from '@klickdata/core/user';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    EMPTY,
    filter,
    first,
    Observable,
    of,
    share,
    switchMap,
    takeUntil,
} from 'rxjs';

@Component({
    selector: 'app-menu-side-create-group',
    templateUrl: './menu-side-create-group.component.html',
    styleUrls: ['./menu-side-create-group.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideCreateGroupComponent extends OnDestroyHandler implements AfterViewInit, OnInit {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    public groupForm: FormGroup;
    public loading$: Observable<boolean>;
    public userSearch$: Observable<User[]>;
    public userControl = new FormControl();
    public teamManagers: User[] = [];
    public groupAdmins: User[] = [];
    public customer: Customer;
    AppScope = AppScope;

    constructor(
        protected fb: FormBuilder,
        protected groupService: GroupService,
        protected userService: UserService,
        protected authService: AuthService,
        protected messageService: MessageService,
        protected cdr: ChangeDetectorRef
    ) {
        super();
        this.buildForm();
    }
    ngOnInit(): void {
        this.navData.data.customer$.pipe(takeUntil(this.destroy)).subscribe((cus) => {
            this.customer = cus;
            this.cdr.markForCheck();
        });

        const teamId = this.navData.data?.team;
        if ((teamId || this.customer.division_mode) && !this.isTeam) {
            this.groupForm.addControl('parent_id', new FormControl(teamId ?? null));
            if (teamId) {
                this.groupForm.get('parent_id').disable();
            }
        }
    }
    get customer$(): Observable<Customer> {
        return this.navData.data.customer$ as Observable<Customer>;
    }
    ngAfterViewInit(): void {
        this.userSearch$ = this.userControl.valueChanges.pipe(
            filter((term: string) => typeof term === 'string' && term != ''),
            debounceTime(300),
            distinctUntilChanged(),
            share(),
            switchMap((term) =>
                term.trim()
                    ? this.userService.getCustomerUsers({
                          term: term,
                          limit: 20,
                          customerId: this.customer.id,
                      })
                    : of(<User[]>[])
            )
        );
    }
    buildForm() {
        this.groupForm = this.fb.group({
            name: ['', Validators.required],
            expiration: [null],
            // sorting_code: [0],
            // user_ids: [[]],
            // admin_id: [],
        });
    }
    public get isTeam(): boolean {
        return this.navData?.data?.isTeam;
    }
    getSearchStationPlaceholder(customer: Customer) {
        const stationScope = customer.scope_names[AppScope.TEAM] ?? AppScope.label(AppScope.TEAM);
        return $localize`Search and select` + ' ' + stationScope;
    }
    getSelectStationLable(customer: Customer) {
        const stationScope = customer.scope_names[AppScope.TEAM] ?? AppScope.label(AppScope.TEAM);
        const groupScope = customer.scope_names[AppScope.GROUPS] ?? AppScope.label(AppScope.GROUPS);
        return groupScope + ' ' + stationScope;
    }
    public selected(auto: MatAutocomplete, event: MatAutocompleteSelectedEvent) {
        this.userControl.patchValue(event.option.value.name);
        this.userControl.disable();
        this.teamManagers = [event.option.value];
        auto.options.reset([]);
        this.cdr.markForCheck();
    }
    onRemoveManager() {
        this.userControl.patchValue('');
        this.userControl.enable();
        this.teamManagers = [];
        this.cdr.markForCheck();
    }
    public submit(editGroup?: boolean): void {
        this.loading$ = of(true);
        this.groupService
            .create(this.prepareGroupData(), 'parent')
            .pipe(
                takeUntil(this.destroy),
                catchError((err) => {
                    this.loading$ = of(false);
                    this.groupForm.reset();
                    if (err && err.error && err.error.error) {
                        this.messageService.openMessage(MessageErrorComponent, err.error.error.messages.join('/n'));
                    } else {
                        this.messageService.openMessage(MessageErrorComponent, $localize`An unknown error occurred.`);
                    }
                    return EMPTY;
                })
            )
            .subscribe((group) => {
                this.loading$ = of(false);
                this.groupForm.reset();
                if (group?.id) {
                    this.onClose.emit({
                        action: SideNaveActionsTypes.POSITIVE,
                        data: {
                            group: group,
                            editGroup: editGroup,
                            type: this.navData.data.type,
                        },
                    });
                }
            });
    }
    private prepareGroupData(): GroupData {
        const data = this.groupForm.value;
        data.customer_id = this.customer.id;
        if (!!this.navData.data.userIds?.length) {
            data.users_attach = this.navData.data.userIds;
        }
        if (this.isTeam) {
            data.team = true;
            if (this.teamManagers?.length) {
                data.admin_ids = this.teamManagers.map((manager) => manager.id);
            }
        }
        if (this.navData.data.team?.id) {
            data.parent_id = this.navData.data.team.id;
        }
        return data;
    }
    getScopeName(customer: Customer, scope: number) {
        return customer.scope_names[scope] ?? AppScope.label(scope);
    }
}
