import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Group } from '@klickdata/core/group';
import { MobileService } from '@klickdata/core/mobile';
import { filter, Observable, shareReplay, takeUntil } from 'rxjs';
import { GroupsSelectComponent } from '../groups-select/groups-select.component';
import { GroupsService } from '../groups-select/groups.service';
import { TeamGroupsUsersSelectSheetComponent } from './team-groups-users-select-sheet/team-groups-users-select-sheet.component';
import { MessageSavedComponent, MessageService } from '@klickdata/core/message';
import { AppScope } from '@klickdata/core/resource';
import { AuthService } from '@klickdata/core/auth';
import { Customer } from '@klickdata/core/customer';

@Component({
    selector: 'app-team-groups-select',
    templateUrl: './team-groups-select.component.html',
    styleUrls: ['./team-groups-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamGroupsSelectComponent extends GroupsSelectComponent {
    public columns = ['select', 'name', 'users_count', 'created_at', 'sorting_code', 'updated_at', 'tools'];
    AppScope = AppScope;
    public customer$: Observable<Customer>;
    constructor(
        protected groupsService: GroupsService,
        protected mobile: MobileService,
        protected bottomSheet: MatBottomSheet,
        protected cdr: ChangeDetectorRef,
        protected auth: AuthService,
        protected message: MessageService
    ) {
        super(groupsService, mobile);
        this.customer$ = auth.getCustomer().pipe(shareReplay());
    }
    public onAddUser(group: Group) {
        if (!this.modelChecked(group)) {
            this.selectToggle(group, true);
        }
        this.addUsers(group);
    }
    public addUsers(group: Group) {
        this.bottomSheet
            .open(TeamGroupsUsersSelectSheetComponent, {
                data: {
                    width: !this.isMobile ? '70%' : '100%',
                    id: `${this.accessForm.value.id},${group.id}`,
                    context: $localize`Add learners into group`,
                    contextIcon: 'person_add_alt_1',
                    title: group.name,
                    referenceFilter: 'reference_team_group',
                },
                panelClass: 'sheet-wrapper',
            })
            .afterDismissed()
            .pipe(
                takeUntil(this.destroy),
                filter((result) => !!result)
            )
            .subscribe((value) => {
                this.dataSource.refresh();
                this.message.openMessage(MessageSavedComponent);
            });
    }
    updateGroupSortingCode(group: Group, code: number) {
        group.checked = true;
        this.groupsService
            .update({ id: this.accessForm.value.id, metadata: { group_id: group.id, sorting_code: code } })
            .pipe(takeUntil(this.destroy))
            .subscribe((updatedGroup) => {
                group.sorting_code = updatedGroup.sorting_code;
                this.cdr.markForCheck();
            });
    }
}
