import { Validators } from '@angular/forms';
import { Cast, CastType, IDataModel, InitialValue, Model } from '@klickdata/core/application/src/model/model-interface';
import { Prop } from '@klickdata/core/form';
import { DateRange } from '@klickdata/core/table/src/table-filter/date-filter/date-range';
import { User } from '@klickdata/core/user';
import * as moment from 'moment';

export interface PublishMessageData extends IDataModel {
    id?: number;
    author_id?: number;
    customer_id?: number;
    resource_id?: number;
    assign_id?: number;
    start_date?: string;
    end_date?: string;
    subject?: string;
    body?: string;
    delay?: boolean;
    recipient_ids?: number[];
    users?: User[];
    date_range?: DateRange;
    user_level?: string;
    query?: string;
    user_status?: string;
    // recipients_ids?: number[];
    excluded_ids?: number[];
    sync_all_users?: [];
    users_attach?: [];
    users_detach?: [];
    sync_all_groups?: [];
    groups_attach?: [];
    groups_detach?: [];
}

export class PublishMessage extends Model<PublishMessageData> {
    @Prop({})
    public id: number;
    public customer_id: number;
    public author_id: number;
    public assign_id: number;
    @Prop({})
    public resource_id: number;
    @Prop({})
    @Cast(CastType.MOMENT, 'YYYY-MM-DD')
    public start_date: moment.Moment;
    @Prop({
        validation: Validators.required,
    })
    @Cast(CastType.MOMENT, 'YYYY-MM-DD')
    public end_date: moment.Moment;

    @Prop({
        // validation: Validators.required,
    })
    public subject: string;

    @Prop({
        // validation: Validators.required,
    })
    public body: string;

    @Prop({
        // validation: Validators.required,
    })
    // @Ignore()
    // public date_range: DateRange;
    @Prop({
        // validation: Validators.required,
    })
    public delay = false;
    // @Prop({})
    // public user_level: string;
    // @Prop({})
    // public query: string;
    // @Prop({})
    // public user_status: string;
    // @Prop({})
    // @InitialValue([])
    // public recipients_ids: number[];
    // @Prop({})
    // @InitialValue([])
    // public excluded_ids: number[];
    @Prop({})
    @InitialValue([])
    public sync_all_users?: [];
    @Prop({})
    @InitialValue([])
    public users_attach?: [];
    @Prop({})
    @InitialValue([])
    public users_detach?: [];
    @Prop({})
    @InitialValue([])
    public sync_all_groups?: [];
    @Prop({})
    @InitialValue([])
    public groups_attach?: [];
    @Prop({})
    @InitialValue([])
    public groups_detach?: [];

    // /**
    //  * @override
    //  */
    // public afterModelInit(data: PublishMessageData) {
    //     // this.date_range = {
    //     //     begin: this.start_date ? moment(this.start_date, 'YYYY-MM-DD HH:mm:ss') : moment(),
    //     //     end: this.end_date ? moment(this.end_date, 'YYYY-MM-DD HH:mm:ss') : moment().add(1, 'M'),
    //     // };
    // }

    // beforeExport(data: PublishMessageData): void {}
    // afterExport(data: PublishMessageData) {
    //     // if (this.date_range?.begin && this.date_range?.end) {
    //     //     data.start_date = moment(this.date_range.begin).format('YYYY-MM-DD HH:mm:ss');
    //     //     data.end_date = moment(this.date_range.end).format('YYYY-MM-DD HH:mm:ss');
    //     // }
    // }
}
