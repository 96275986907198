import { BehaviorSubject } from 'rxjs';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SideNaveData } from '@klickdata/core/mobile';
import { SideNaveActionsTypes, SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { QuestionTypes } from '@klickdata/core/question';
import { AppScope, ResourceService } from '@klickdata/core/resource';
import { ResourceItemTypes } from '@klickdata/core/resource-item';
import { Resource } from '@klickdata/core/resource/src/resource.model';
import { ResourceBuildingItems } from '@klickdata/core/resource/src/types.enum';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-menu-side-add-course-items',
    templateUrl: './menu-side-add-course-items.component.html',
    styleUrls: ['./menu-side-add-course-items.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideAddCourseItemsComponent {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    ResourceBuildingItems = ResourceBuildingItems;
    AppScope = AppScope;

    onSelect(value: any, scope: AppScope) {
        this.onClose.emit({
            action: SideNaveActionsTypes.POSITIVE,
            data: {
                value: value,
                scope: scope,
                type: this.navData.data.type,
            },
        });
    }
    public get canCreateItem(): boolean {
        return this.navData.data.authUser.privileges?.publish == 'allowed';
    }
}
