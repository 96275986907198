import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Customer } from '@klickdata/core/customer';
import { Group, GroupService } from '@klickdata/core/group';
import { User } from '@klickdata/core/user';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    merge,
    Observable,
    share,
    startWith,
    Subject,
    switchMap,
    takeUntil,
} from 'rxjs';

@Component({
    selector: 'app-team-select-dropdown',
    templateUrl: './team-select-dropdown.component.html',
    styleUrls: ['./team-select-dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TeamSelectDropdownComponent),
            multi: true,
        },
    ],
})
export class TeamSelectDropdownComponent
    extends OnDestroyHandler
    implements ControlValueAccessor, AfterViewInit, OnChanges
{
    @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;
    @Input() user: User;
    @Input() customer: Customer;
    @Input() _groupId: number;
    @Input() filters: { [key: string]: any };
    @Input() label: string;
    @Input() searchPlacholder: string;
    public groupControl = new FormControl();
    public groups: Group[] = [];
    public groupsSearch$: Observable<Group[]>;
    private refresh: Subject<string> = new Subject();

    constructor(protected groupService: GroupService, protected cdr: ChangeDetectorRef) {
        super();
    }

    ngAfterViewInit(): void {
        this.groupsSearch$ = merge(
            this.groupControl.valueChanges.pipe(startWith(''), debounceTime(300), distinctUntilChanged()),
            this.refresh
        ).pipe(
            filter((term: string) => typeof term === 'string'),
            share(),
            switchMap((term) =>
                this.groupService.getGroupsByParams({
                    query: term,
                    limit: 20,
                    customer: this.customer?.id,
                    ...this.filters,
                })
            )
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.customer || changes.filters) {
            this.refresh.next('');
        }
    }

    public selectedGroup(group: Group, auto: MatAutocomplete) {
        this.groupControl.patchValue('');
        this.groups.push(group);
        this.groupId = group.id;
        this.cdr.markForCheck();
    }

    onRemoveGroup(id: number) {
        this.groups.splice(
            this.groups.findIndex((group) => group.id == id),
            1
        );
        this.groupId = null;
        this.cdr.markForCheck();
    }

    get groupId(): number {
        return this._groupId;
    }

    set groupId(groupId: number) {
        this._groupId = groupId;
        this.propagateChange(this._groupId);
    }

    public writeValue(groupId: number): void {
        this._groupId = groupId;
        if (groupId) {
            this.groupService
                .getGroup(groupId)
                .pipe(takeUntil(this.destroy))
                .subscribe((group) => {
                    this.groups = [group];
                    this.cdr.markForCheck();
                });
        } else {
            this.groups = [];
            this.cdr.markForCheck();
        }
    }

    public propagateChange = (_: any) => {};

    public registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }
    public setDisabledState(isDisabled: boolean) {
        if (isDisabled) {
            this.groupControl.disable();
        }
    }
    public registerOnTouched(fn: any): void {}

    toggleAutocompletePanel() {
        if (this.autocompleteTrigger.panelOpen) {
            this.autocompleteTrigger.closePanel();
        } else {
            this.autocompleteTrigger.openPanel();
        }
    }
}
