<div class="main-dialog-wrap w-100" fxLayout="column">
    <div
        [ngClass]="{ left: authorDir === 'left', right: authorDir === 'right' }"
        class="chat-row"
        *ngIf="dialog.author | async; let user; else: loading"
        [fxLayout]="authorDir === 'left' ? 'row' : 'row-reverse'"
        fxLayoutAlign="start start"
        fxLayoutGap="10px"
    >
        <app-image-view
            *ngIf="user.media?.url"
            [ngStyle]="{ minWidth: '55px' }"
            [isCircularImg]="true"
            [ignoreThumbnail]="true"
            [media_id]="user.media"
            paddingBottom="55px"
            class="chat-img"
        ></app-image-view>
        <div
            *ngIf="!user.media?.url"
            class="name-sign"
            [ngClass]="{
                'is-user': !user.isAdmin(),
                'is-admin': user.isAdmin() && !user.isMasterAdmin(),
                'is-master': user.isMasterAdmin()
            }"
        >
            {{ user.name | nameSign }}
        </div>
        <div
            fxLayout="column"
            class="mt-1 w-100 chat-bulk"
            [fxLayoutAlign]="authorDir === 'left' ? 'start start' : 'start end'"
            fxLayoutGap="5px"
        >
            <span fxLayout="row" class="time-right">
                <span *ngIf="authorDir === 'left'">{{ user.name }}</span>
                <span *ngIf="authorDir === 'right'" i18n>Me</span> &nbsp;
                <span>({{ dialog.created_at | kdDateFormat : 'YYYY-MM-DD HH:mm' }})</span>
            </span>
            <!-- <div *ngIf="dialog.body" class="body-wrapper">{{ dialog.body }}</div> -->
            <div *ngIf="dialog.body" class="body-wrapper" fxLayout="column">
                <!-- Main content -->
                <div [innerHtml]="dialog.body | innerHtml : 'inner-HTML-course'"></div>

                <!-- Bottom-right aligned button -->
                <div fxLayout="row" fxLayoutAlign="end center">
                    <button
                        *ngIf="dialog.downloads"
                        mat-icon-button
                        matTooltip="Click to download"
                        (click)="$event.stopPropagation(); download()"
                        i18n-matTooltip="@@clickToMakeDownload"
                    >
                        <mat-icon>save_alt</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <app-media-attachment
        [fxLayoutAlign]="authorDir === 'left' ? 'start start' : 'start end'"
        [mediaIds]="dialog.media_ids"
        fxLayout="column"
        [isCreateForm]="false"
        [ngClass]="{ 'left-media-dialog': authorDir === 'left', 'right-media-dialog': authorDir === 'right' }"
        class="mt-1"
    ></app-media-attachment>
</div>
<ng-template #loading>
    <app-kd-logo-loading></app-kd-logo-loading>
</ng-template>
