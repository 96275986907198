import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Group } from '@klickdata/core/group';
import { MobileService, SideNaveDataTypes } from '@klickdata/core/mobile';
import { Filter, TableFilterComponent, TableSource } from '@klickdata/core/table';
import { User } from '@klickdata/core/user';
import { UsersGroupsService } from './users-groups.service';
import { Customer } from '@klickdata/core/customer';

@Component({
    selector: 'app-shared-log-users-tabs-groups',
    templateUrl: './shared-log-users-tabs-groups.component.html',
    styleUrls: ['./shared-log-users-tabs-groups.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [UsersGroupsService],
})
export class SharedLogUsersTabsGroupsComponent implements OnInit, AfterViewInit {
    @Input() user: User;
    @Input() customer: Customer;
    @Input() isMobile: boolean;
    public dataSource = new TableSource<Group>();
    public columns = ['name', 'users_count', 'admin_name', 'tools'];
    @ViewChild(TableFilterComponent) filter: TableFilterComponent;

    constructor(protected groupService: UsersGroupsService, protected mobile: MobileService) {}

    ngOnInit(): void {
        this.dataSource.service = this.groupService;
    }
    ngAfterViewInit(): void {
        if (this.filter) {
            if (this.customer) {
                this.filter.createOrUpdateWithoutEmitFilter([
                    new Filter('customer', [this.customer.id]),
                    new Filter('eager', ['admins']),
                ]);
            } else if (this.user) {
                this.filter.createOrUpdateWithoutEmitFilter([
                    new Filter('user', [this.user.id]),
                    new Filter('customer', [this.user.customer_id]),
                    new Filter('eager', ['admins']),
                ]);
            }
        }

        this.dataSource.filter = this.filter;
    }
    public sendEmail(group: Group) {
        if (group) {
            this.mobile.updateSideNavSub({
                dataType: SideNaveDataTypes.SEND_MESSAGE,
                data: { model: group, isGroup: true },
            });
        }
    }
    noteGroup(group: Group) {
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.USER_NOTE,
            data: {
                privacy: 'group',
                group: group,
                status: 'create',
            },
        });
    }
}
