import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { Group, GroupService } from '@klickdata/core/group';

import { ENTER } from '@angular/cdk/keycodes';
import { ViewChild } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { SideNaveData } from '@klickdata/core/mobile';
import { SideNaveActionsTypes, SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { AppScope } from '@klickdata/core/resource';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    EMPTY,
    filter,
    Observable,
    of,
    share,
    Subject,
    switchMap,
    takeUntil,
} from 'rxjs';

@Component({
    selector: 'app-menu-side-add-group-into-team',
    templateUrl: './menu-side-add-group-into-team.component.html',
    styleUrls: ['./menu-side-add-group-into-team.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideAddGroupIntoTeamComponent extends OnDestroyHandler implements AfterViewInit {
    @Input() public navData: SideNaveData;
    AppScope = AppScope;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    @ViewChild(MatAutocompleteTrigger) autoTrigger!: MatAutocompleteTrigger;
    public onLoading: Subject<boolean> = new Subject<boolean>();
    public onSavingTeam: Subject<boolean> = new Subject<boolean>();
    public selectedGroups: Group[] = [];
    public teamControl = new FormControl();
    public teamsSearch$: Observable<Group[]>;
    separatorKeyCodes: number[] = [ENTER];
    constructor(private groupService: GroupService, private messageService: MessageService) {
        super();
    }

    ngAfterViewInit(): void {
        this.teamsSearch$ = this.teamControl.valueChanges.pipe(
            filter((term: string) => typeof term === 'string' && term != ''),
            debounceTime(300),
            distinctUntilChanged(),
            share(),
            switchMap((term) =>
                term.trim()
                    ? this.groupService.getCustomerGroupsByParams({
                          query: term,
                          limit: 20,
                          orphanFor: this.navData.data.team.id,
                      })
                    : of(<Group[]>[])
            )
        );
    }
    get groupsLabel(): string {
        return this.navData.data.customer?.scope_names[AppScope.GROUPS] ?? AppScope.label(AppScope.GROUPS);
    }
    public selectedTeam(team: Group, auto: MatAutocomplete) {
        this.teamControl.patchValue('');
        this.selectedGroups.push(team);
        auto.options.reset([]);
    }
    onRemoveTeam(id: number) {
        this.selectedGroups.splice(
            this.selectedGroups.findIndex((manag) => manag.id == id),
            1
        );
    }
    onAdd(value: string): void {
        this.onSavingTeam.next(true);
        this.groupService
            .create({ name: value, customer_id: this.navData.data.customer.id })
            .pipe(
                takeUntil(this.destroy),
                catchError((err) => {
                    if (err && err.error && err.error.error) {
                        this.messageService.openMessage(MessageErrorComponent, err.error.error.messages);
                    }
                    this.resetState();
                    return EMPTY;
                })
            )
            .subscribe({
                next: (group) => {
                    this.resetState();
                    if (group) {
                        this.selectedGroups.push(group);
                    }
                },
                error: (err) => {
                    if (err && err.error && err.error.error) {
                        this.messageService.openMessage(MessageErrorComponent, err.error.error.messages);
                    }
                    this.resetState();
                },
            });
    }
    private resetState() {
        this.onSavingTeam.next(false);
        this.teamControl.reset();
        this.closeAutocomplete();
    }
    closeAutocomplete() {
        if (this.autoTrigger) {
            this.autoTrigger.closePanel();
        }
    }
    submit() {
        this.onLoading.next(true);
        this.groupService
            .update({
                id: this.navData.data.team.id,
                children_ids: this.selectedGroups.map((group) => group.id),
            })
            .pipe(takeUntil(this.destroy))
            .subscribe((team) => {
                this.onLoading.next(false);
                this.onClose.emit({
                    action: SideNaveActionsTypes.POSITIVE,
                    data: { type: this.navData.data.type, team: team },
                });
            });
    }
}
