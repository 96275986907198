import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
    selector: 'app-guest-mode-mobile-login',
    templateUrl: './guest-mode-mobile-login.component.html',
    styleUrls: ['./guest-mode-mobile-login.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GuestModeMobileLoginComponent {
    constructor(
        protected bottomSheetRef: MatBottomSheetRef<GuestModeMobileLoginComponent>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
    ) {}
}
