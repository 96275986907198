import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormHelper } from '@klickdata/core/form';
import { MessageSavedComponent, MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { SideNaveData } from '@klickdata/core/mobile';
import { SideNaveResponseData, SideNaveActionsTypes } from '@klickdata/core/mobile/src/mobile.service';
import { User, UserService } from '@klickdata/core/user';
import { UserNotesService } from '@klickdata/core/user-notes';
import { UserData } from '@klickdata/core/user/src/user.model';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarRef,
    MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { AuthService } from '@klickdata/core/auth';
import { Utils } from '@klickdata/core/util';

@Component({
    selector: 'app-menu-side-add-user',
    templateUrl: './menu-side-add-user.component.html',
    styleUrls: ['./menu-side-add-user.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideAddUserComponent implements OnDestroy, OnInit {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    public userForm: FormGroup;
    public formats: { [key: string]: string };
    public destroy: Subject<boolean> = new Subject<boolean>();
    public loading$: Observable<boolean>;
    public error: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public userRoles: { [key: string]: string }[];
    protected position: {
        verticalPosition: MatSnackBarVerticalPosition;
        horizontalPosition: MatSnackBarHorizontalPosition;
    } = {
        verticalPosition: 'bottom',
        horizontalPosition: 'center',
    };
    private messageConfig = {
        duration: 6000,
        verticalPosition: this.position.verticalPosition,
        horizontalPosition: this.position.horizontalPosition,
        panelClass: ['right-direction', 'red_container_bg'],
    };

    constructor(
        protected fb: FormBuilder,
        protected userService: UserService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected notesService: UserNotesService,
        protected message: MessageService
    ) {
        this.buildForm();
        this.formats = {
            fname: $localize`First name`,
            lname: $localize`Last name`,
            username: $localize`User name`,
            email: $localize`Email`,
            role_value: $localize`Learner role`,
        };
    }
    ngOnInit() {
        this.userRoles = Utils.getUserRoles(
            this.navData.data.user.role_value,
            this.navData.data.customer?.division_mode
        );
    }
    protected buildForm(): void {
        this.userForm = this.fb.group(
            {
                fname: ['', Validators.required],
                lname: ['', Validators.required],
                email: ['', [Validators.email, Validators.required]],
                // username: ['', Validators.required],
                role_value: ['user', Validators.required],
                notes: [''],
                attached_groups: [[]],
            }
            // { validators: [this.userValidator()] }
        );
    }
    userValidator(): ValidatorFn {
        return (ctrl: AbstractControl): ValidationErrors | null => {
            return ctrl.value.role_value === 'groupadmin' && !ctrl.value.attached_groups?.length
                ? { forbidden: { message: $localize`Managed groups is required!` } }
                : null;
        };
    }
    public submit(sendActivation?: boolean): void {
        this.checkSubmitValid()
            .pipe(
                filter((isValid) => !!isValid),
                switchMap(() => this.prepareSubmit()),
                filter((user) => user && !!user.id),
                switchMap((user) =>
                    this.userForm.value.notes && !!this.userForm.value.notes.length
                        ? this.notesService
                              .addHrNote({
                                  body: this.userForm.value.notes,
                                  user_id: user.id,
                              })
                              .pipe(map(() => user.id))
                        : of(user.id)
                ),
                filter((id) => !!id),
                switchMap((userId) => {
                    const msgSendReciever = this.userService.send({ users_attach: [userId] }).pipe(
                        filter((msg) => msg.sent),
                        map(() => userId)
                    );
                    return sendActivation ? msgSendReciever : of(userId);
                }),
                takeUntil(this.destroy),
                catchError((err) => this.handleCatchedError(err))
            )
            .subscribe(
                (fetchedUserId) => this.onSuccess(fetchedUserId, sendActivation),
                (err) => {
                    if (err && err.error && err.error.error) {
                        this.error.next(err.error.error.messages);
                    }
                    this.loading$ = of(false);
                }
            );
    }
    handleCatchedError(err: any) {
        if (err && err.error && err.error.error) {
            this.error.next(err.error.error.messages);
            this.message.openMessage(MessageErrorComponent, err.error.error.messages.join('/n'), this.messageConfig);
        }
        this.loading$ = of(false);
        return of(null);
    }
    private checkSubmitValid(): Observable<boolean> {
        if (this.userForm.invalid && this.userForm.errors && this.userForm.errors?.forbidden) {
            this.message.openMessage(MessageErrorComponent, this.userForm.errors.forbidden.message, this.messageConfig);
            return of(false);
        }
        return of(true);
    }
    private prepareSubmit(): Observable<User> {
        this.loading$ = of(true);
        const user = this.prepareForm();
        return this.userService.create(user);
    }

    protected prepareForm(): UserData {
        const data: UserData = {
            fname: this.userForm.value.fname,
            lname: this.userForm.value.lname,
            role_value: this.userForm.value.role_value,
            email: this.userForm.value.email.trim(),
            username: this.userForm.value.email.trim(),
        };
        if (this.userForm.value.attached_groups && !!this.userForm.value.attached_groups.length) {
            data.managed_groups = this.userForm.value.attached_groups.map((group) => group.id);
        }
        const user = new User(data).getPayload();
        user.customer_id = this.navData.data.user.customer_id;
        return user;
    }
    protected onSuccess(userId: number, sendActivation?: boolean): void {
        if (!userId) {
            this.loading$ = of(false);
            return;
        }
        this.message.openMessage(
            MessageSavedComponent,
            sendActivation
                ? $localize`A new learner created with an activation mail has been sent`
                : $localize`A new learner has been created`,
            this.messageConfig
        );
        this.loading$ = of(false);
        if (!sendActivation && userId) {
            this.router.navigate(
                this.navData.data.context === 'customer'
                    ? ['/master/customers/k3-connections/connection', userId]
                    : ['/admin/accounts/users/users', userId]
            );
        }
        this.userForm.reset({ role_value: 'user' });
        this.error.next(null);
        this.onClose.emit({ action: SideNaveActionsTypes.POSITIVE, data: userId });
    }
    public ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
