<form #formDirective="ngForm" fxLayout="row wrap" [formGroup]="testForm" class="course-items-switch-wrapper">
    <div fxLayout="row" fxLayoutAlign="end center" class="tools-wrapper">
        <button
            *ngIf="!(loadingUploader | async)"
            [ngClass]="{
                'no-media': !testForm.value.media_id,
                'blue-ice': (isAIUploadedImg | async),
                green: !(isAIUploadedImg | async)
            }"
            mat-icon-button
            type="button"
            (click)="addMedia()"
            i18n-matTooltip
            [matTooltip]="!testForm.value.media_id ? 'Add cover image' : 'Show cover image'"
        >
            <mat-icon>add_photo_alternate</mat-icon>
        </button>
        <app-kd-logo-loading *ngIf="loadingUploader | async" size="small"></app-kd-logo-loading>
        <app-language-selector
            formControlName="language_id"
            class="icon-resource-builder"
            fxFlex="0 0 auto"
        ></app-language-selector>
    </div>

    <mat-tab-group #mainTabs fxFlex="grow" class="main-creator-tabs">
        <mat-tab #mainTab label="Content" i18n-label style="max-height: 100%">
            <div class="wrapper-parent column-third-layout" fxFlex="100" fxLayout="row wrap" fxLayout.lt-md="column">
                <div class="main-row res-display w-100" fxLayout="column" fxLayoutAlign="start center">
                    <mat-form-field class="w-100 text-bold clear-consider" fxFlex="grow">
                        <textarea
                            #title
                            matInput
                            type="text"
                            formControlName="title"
                            placeholder="Title"
                            i18n-placeholder
                            cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="1"
                            cdkAutosizeMaxRows="10"
                            required
                        ></textarea>

                        <mat-error i18n>Title field is required!</mat-error>
                    </mat-form-field>

                    <div class="w-100" fxLayout="column" fxLayoutAlign="start start">
                        <mat-form-field
                            [floatLabel]="'never'"
                            class="summary-field limit-drag clear-consider"
                            fxFlex="0 1 100%"
                        >
                            <textarea
                                #summary
                                required
                                matInput
                                placeholder="Summary"
                                i18n-placeholder
                                minRows="2"
                                cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize"
                                cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="10"
                                formControlName="bullets"
                            ></textarea>
                        </mat-form-field>
                    </div>
                    <app-resource-builder
                        #builderForm
                        [resource]="resource"
                        [language_id]="testForm.get('language_id').value"
                        [questionTypes]="questionTypes"
                        [resource_type_id]="ResourceTypes.GeneralTest"
                        [resourceType]="ResourceTypes.TEST"
                        (saved)="$event && savingQuestions && submitWithQuestion()"
                        [active]="bottomToolSheet"
                    >
                    </app-resource-builder>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Extra" i18n-label style="max-height: 100%">
            <div *ngIf="testForm.value.media_id" class="image-wrapper">
                <mat-expansion-panel
                    [expanded]="true"
                    #coverImagePanel
                    class="user-manage-course-expantion res-creat-manag"
                >
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <mat-icon>add_photo_alternate</mat-icon>
                            <div i18n="@@coverImg">Cover image</div>
                            <mat-icon
                                class="icon-explain"
                                matTooltip="It's the image to be displayed in the cover"
                                i18n-matTooltip
                                >help_outline</mat-icon
                            >
                        </mat-panel-title>
                        <mat-panel-description fxLayoutAlign="end center">
                            <span
                                matTooltip="Indicator of field change"
                                i18n-matTooltip
                                class="cell-indicator"
                                [ngClass]="{ green: testForm.get('media_id').dirty }"
                            ></span>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="w-100" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="10px">
                        <ng-container [ngTemplateOutlet]="coverImg"></ng-container>
                        <div class="ai-prompt-text" *ngIf="mediaAIPrompterText.value">
                            <span i18n>Prompt:</span>
                            <span>{{ mediaAIPrompterText.value }}</span>
                        </div>
                    </div>
                </mat-expansion-panel>
            </div>
            <div class="editor-wrapper" fxLayout="column" fxLayoutAlign="start start">
                <div class="third-colmn-editor-wrapper mb-1" fxFlex="0 1 100%" fxLayout="column">
                    <mat-expansion-panel [expanded]="true" class="user-manage-course-expantion res-creat-manag">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>tag</mat-icon>
                                <div i18n>Tags</div>
                                <mat-icon class="icon-explain" matTooltip="Tag" i18n-matTooltip>help_outline</mat-icon>
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: testForm.get('tag_ids').dirty }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <ng-template matExpansionPanelContent>
                            <app-tag-chip-select
                                class="mt-1"
                                placeholder="Add Tag"
                                i18n-placeholder
                                [sortedAlph]="true"
                                [language_id]="testForm.value.language_id"
                                (onTagClick)="showTagResources($event)"
                                formControlName="tag_ids"
                            ></app-tag-chip-select>
                        </ng-template>
                    </mat-expansion-panel>
                </div>
                <div class="third-colmn-editor-wrapper mb-1" fxFlex="0 1 100%" fxLayout="column">
                    <mat-expansion-panel [expanded]="true" class="user-manage-course-expantion res-creat-manag">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>folder</mat-icon>
                                <div i18n>Categories</div>
                                <mat-icon class="icon-explain" matTooltip="Tag" i18n-matTooltip>help_outline</mat-icon>
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: testForm.get('tag_ids').dirty }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <ng-template matExpansionPanelContent>
                            <app-category-chip-select
                                fxFlex="0 1 100%"
                                [resource_id]="testForm?.value?.id"
                                [language_id]="testForm.get('language_id').value"
                                formControlName="category_ids"
                                [type_id]="ResourceTypes.TEST"
                                (onCatClick)="showCatgeoryResources($event)"
                            ></app-category-chip-select>
                        </ng-template>
                    </mat-expansion-panel>
                </div>
                <div class="third-colmn-editor-wrapper mb-1" fxFlex="0 1 100%" fxLayout="column">
                    <mat-expansion-panel [expanded]="true" class="user-manage-course-expantion res-creat-manag">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>description</mat-icon>
                                <div i18n>Description</div>
                                <mat-icon
                                    class="icon-explain"
                                    matTooltip="Description that will be visiable before learners take the test"
                                    i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: testForm.get('description').dirty }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <ng-template matExpansionPanelContent>
                            <mat-form-field class="summary-field" fxFlex="0 1 100%">
                                <textarea
                                    #description
                                    matInput
                                    placeholder="Enter text"
                                    i18n-placeholder
                                    minRows="3"
                                    cdkAutosizeMinRows="1"
                                    cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMaxRows="150"
                                    formControlName="description"
                                ></textarea>
                                <button
                                    *ngIf="description.value.length"
                                    mat-icon-button
                                    class="clear-btn"
                                    matSuffix
                                    type="button"
                                    (click)="
                                        $event.stopPropagation();
                                        testForm.get('description').setValue('');
                                        description.focus()
                                    "
                                >
                                    <mat-icon>close</mat-icon>
                                </button>
                                <div class="bullets-info-wrapper">
                                    <span *ngIf="description.value" class="char-count">
                                        {{ description.value.length }}
                                        <span>(</span>
                                        {{ defaultResourceGoalCount - description.value.length }}
                                        <span i18n="@@left">left</span>
                                        <span>)</span>
                                    </span>
                                </div>
                            </mat-form-field>
                        </ng-template>
                    </mat-expansion-panel>
                </div>
                <div class="third-colmn-editor-wrapper mb-1" fxFlex="0 1 100%" fxLayout="column">
                    <mat-expansion-panel [expanded]="true" class="user-manage-course-expantion res-creat-manag">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>subject</mat-icon>
                                <div>Other information</div>
                                <mat-icon
                                    class="icon-explain"
                                    matTooltip="Other informations relevent to this test"
                                    i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: testForm.get('instructions').dirty }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <ng-template matExpansionPanelContent>
                            <mat-form-field class="summary-field" fxFlex="0 1 100%">
                                <textarea
                                    #instructions
                                    matInput
                                    placeholder="Enter text"
                                    i18n-placeholder
                                    minRows="3"
                                    formControlName="instructions"
                                    cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMinRows="1"
                                    cdkAutosizeMaxRows="10"
                                ></textarea>
                                <button
                                    *ngIf="instructions.value.length"
                                    mat-icon-button
                                    class="clear-btn"
                                    matSuffix
                                    type="button"
                                    (click)="
                                        $event.stopPropagation();
                                        testForm.get('instructions').setValue('');
                                        instructions.focus()
                                    "
                                >
                                    <mat-icon>close</mat-icon>
                                </button>
                                <div class="bullets-info-wrapper">
                                    <span *ngIf="instructions.value" class="char-count">
                                        {{ instructions.value.length }}
                                        <span>(</span>
                                        {{ defaultResourceGoalCount - instructions.value.length }}
                                        <span i18n="@@left">left</span>
                                        <span>)</span>
                                    </span>
                                </div>
                            </mat-form-field>
                        </ng-template>
                    </mat-expansion-panel>
                </div>
                <div class="third-colmn-editor-wrapper mb-1" fxFlex="0 1 100%" fxLayout="column">
                    <mat-expansion-panel [expanded]="true" class="user-manage-course-expantion res-creat-manag">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>subject</mat-icon>
                                <div>Priority</div>
                                <mat-icon
                                    class="icon-explain"
                                    matTooltip="Priority relevent to this test"
                                    i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: testForm.get('priority').dirty }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <ng-template matExpansionPanelContent>
                            <mat-form-field fxFlex="1 1 95%">
                                <input
                                    matInput
                                    fxLayoutAlign="end center"
                                    type="number"
                                    placeholder="Please set priority"
                                    i18n-placeholder="@@plsSetPriority"
                                    formControlName="priority"
                                />
                            </mat-form-field>
                        </ng-template>
                    </mat-expansion-panel>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>

    <div class="mt-1" fxFlex="0 0 100%" fxLayout="row" fxLayoutAlign="end" fxLayoutGap=".5em">
        <div fxLayoutGap=".5em">
            <button
                *ngIf="!isLoading && (isAdmin$ | async)"
                mat-raised-button
                [disabled]="!testForm.valid"
                (click)="onSubmit(true)"
                color="primary"
                i18n
            >
                Add to course
            </button>
        </div>
        <app-saving-button *ngIf="isLoading"></app-saving-button>
    </div>
</form>
<ng-template #coverImg>
    <app-image-view
        (click)="showImgModal(testForm.get('media_id')?.value)"
        class="mt-1 mb-1"
        *ngIf="testForm.get('media_id')?.value"
        [media_id]="testForm.get('media_id')?.value"
        [proportional]="false"
    >
        <button
            class="remove-image"
            mat-icon-button
            color="warn"
            type="button"
            *ngIf="testForm.get('media_id').value as mediaId"
            matTooltip="Remove image"
            i18n-matTooltip
            (click)="testForm.get('media_id').setValue(null); testForm.get('media_id').markAsDirty()"
        >
            <mat-icon class="md-24">delete_outline</mat-icon>
        </button>
    </app-image-view>
</ng-template>
