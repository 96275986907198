import { Injectable } from '@angular/core';
import { ConfigService } from '@klickdata/core/config';
import { RequestBuilderService } from '@klickdata/core/http';
import { Filter } from '@klickdata/core/table';
import { User } from '@klickdata/core/user/src/user.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivityLog, ActivityLogData } from './activity-log.model';
import { Utils } from '@klickdata/core/util';

@Injectable({
    providedIn: 'root',
})
export class ActivityLogService {
    protected activityUrl: string;

    constructor(protected config: ConfigService, protected builder: RequestBuilderService) {
        this.activityUrl = `${config.config.apiUrl}actions-log`;
    }

    public getAllActivities(customerId?: number): Observable<ActivityLog[]> {
        const request = this.builder.get<ActivityLogData[]>(`${this.activityUrl}`);
        if (customerId) {
            request.param('customer', customerId);
        }
        return request.request().pipe(map((res) => res.data.map((activityLog) => this.mapActivityLog(activityLog))));
    }

    public downloadActions(filters: Filter<string | number>[]): Observable<Blob> {
        return this.builder.get<Blob>(`${this.activityUrl}/download`).filters(filters).download();
    }

    public getUserRelatedResourceActivities(params: { [key: string]: any }): Observable<ActivityLog[]> {
        const request = this.builder.get<ActivityLogData[]>(`${this.activityUrl}`);
        request.putParam(params);
        return request.request().pipe(map((res) => res.data.map((activityLog) => this.mapActivityLog(activityLog))));
    }

    protected mapActivityLog(activityLogData: ActivityLogData): ActivityLog {
        const activity = new ActivityLog(activityLogData);
        activity.user = new User(activityLogData.author);
        return activity;
    }
}
