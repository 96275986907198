<div *ngIf="inGlobalFilter" class="chip-container" fxLayoutGap="0px" fxLayoutGap.gt-sm="10px" fxLayout="column">
    <mat-form-field class="search-box" appearance="fill">
        <mat-label>{{ AppScope.getScopeLabel(customer$, AppScope.TEAM) | async }}</mat-label>
        <input
            matInput
            #search
            placeholder="Filter by name..."
            i18n-placeholder="@@filterByName"
            [formControl]="control"
        />
    </mat-form-field>
    <h4 *ngIf="source?.length" class="filter-header">
        <span i18n="@@choose">Choose</span> {{ AppScope.getScopeLabel(customer$, AppScope.TEAM) | async }}
    </h4>
    <mat-chip-list
        fxFlex="0 0 100%"
        [class.top-align]="source?.length"
        [ngClass]="{ 'mobile-view': isMobile | async }"
        [multiple]="multiple"
    >
        <ng-container *ngFor="let item of source">
            <mat-chip class="chip-point" [value]="item.id" [selected]="item.selected">
                {{ item.label }}
            </mat-chip>
        </ng-container>
        <div *ngIf="source?.length" class="seperater"></div>
    </mat-chip-list>
</div>

<ng-template #activeFilterTemplate let-filter="filter">
    <mat-icon>{{ filter.icon }}</mat-icon>
    <span *ngFor="let item of filter.chips; let last = last">{{ item.label }}<span *ngIf="!last">,&nbsp;</span> </span>
</ng-template>
