import { Injectable, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { LoggerService } from '@klickdata/core/application';
import { QuestionType, QuestionTypeService, QuestionTypes } from '@klickdata/core/question';
import { Resource, ResourceTypes } from '@klickdata/core/resource';
import { ResourceItem, ResourceItemService, ResourceItemTypes } from '@klickdata/core/resource-item';
import { PromptStatus } from '@klickdata/core/util';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, first, map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';

export interface ResourceBuilderSubmitEvent {
    emitSaved: boolean;
    extraPayload?: {};
    prompt_status?: PromptStatus;
}

@Injectable()
export class ResourceBuilderService implements OnDestroy {
    public resource_type_id: number;
    public resourceType: ResourceTypes;
    public published: boolean;
    protected formSubject: BehaviorSubject<FormGroup> = new BehaviorSubject(null);
    protected resource$: BehaviorSubject<Resource> = new BehaviorSubject(null);
    protected items: BehaviorSubject<ResourceItem[]> = new BehaviorSubject([]);
    protected itemsCollapsingStatus: BehaviorSubject<boolean> = new BehaviorSubject(true);
    public selectedWOKQuestionType: BehaviorSubject<QuestionTypes> = new BehaviorSubject(null);
    public showWOKGenerator: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    protected sortSubject: BehaviorSubject<boolean> = new BehaviorSubject(null);
    protected questionTypes: Observable<QuestionType[]>;
    protected createSubject: Subject<{
        item_type_value?: ResourceItemTypes;
        questionForm?: FormGroup;
        name?: string;
        description?: string;
        instructions?: string;
        learning_field?: string;
        child_resource_published?: string;
        child_resource_id?: number;
        child_resource_language_id?: number;
        collapse?: boolean;
        img_url?: string;
        child_resource_customer_id?: number;
        child_resource_author_id?: number;
    }> = new Subject();
    protected submitSubject: Subject<ResourceBuilderSubmitEvent> = new Subject();
    protected questionTypesFilter: string[] = [QuestionTypes.CHECKBOX, QuestionTypes.RADIO];
    protected loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    protected languageId: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    protected destroy: Subject<boolean> = new Subject<boolean>();

    constructor(
        protected fb: FormBuilder,
        protected itemService: ResourceItemService,
        protected questionTypeService: QuestionTypeService,
        protected logger: LoggerService
    ) {
        this.formSubject.next(
            this.fb.group({
                items: this.fb.array([]),
            })
        );

        this.questionTypes = this.questionTypeService.getTypes().pipe(shareReplay());

        this.getForm()
            .pipe(
                takeUntil(this.destroy),
                switchMap((formGroup: FormGroup) => formGroup.valueChanges.pipe(map(() => formGroup))),
                debounceTime(10000)
            )
            .subscribe((form: FormGroup) => {
                if (form.dirty && form.valid) {
                    this.submitSubject.next({
                        emitSaved: false,
                    });

                    this.setLoading(true);
                }
            });
    }

    public ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }

    public init(resource: Resource): void {
        if (resource?.id) {
            this.setResourceItems(resource);
            this.updateResource(resource);
        }

        if (resource?.type_id) {
            this.resource_type_id = resource.type_id;
        }

        this.published = resource?.isPublished();
    }

    public setResourceItems(resource: Resource) {
        this.updateResource(resource);
        /**
         * Get items only if there are no items
         * This is to avoid to get items when the resource is updated
         * and the items are already loaded
         * @TODO: Check if this is need to listen items updated from socket
         */
        if (!this.items.getValue().length) {
            this.itemService
                .getResourceItems(resource.id)
                .pipe(first())
                .subscribe((items) => this.items.next(items));
        }
    }

    public updateForm(form: FormGroup): void {
        // this.formSubject.next(form);
    }

    public reset() {
        this.resource$.next(null);
        this.items.next([]);
        this.formSubject.next(
            this.fb.group({
                items: this.fb.array([]),
            })
        );
    }

    public getForm(): Observable<FormGroup> {
        return this.formSubject.asObservable();
    }

    public getItemsFoldingState(): Observable<boolean> {
        return this.itemsCollapsingStatus.asObservable();
    }

    public updateItemsFoldingState(state: boolean) {
        return this.itemsCollapsingStatus.next(state);
    }

    public readyForSubmit(minItemsNumber = 0): boolean {
        const form = this.formSubject.value;
        const items = form.get('items') as FormArray;
        return form.valid && items.valid && !!items.length && items.length > minItemsNumber;
    }

    public getCurrentForm(): FormGroup {
        return this.formSubject.getValue();
    }

    public getResource(): Observable<Resource> {
        return this.resource$.asObservable();
    }

    public getCurrentResource(): Resource {
        return this.resource$.getValue();
    }

    public updateResource(resource: Resource) {
        if (resource) {
            this.resource$.next(resource);
        }
    }

    public updateItems(items: ResourceItem[]): void {
        return this.items.next(items);
    }

    public getItems(): Observable<ResourceItem[]> {
        return this.items.asObservable();
    }

    public getQuestionTypes(): Observable<QuestionType[]> {
        return this.questionTypes.pipe(
            map((types) => {
                return types.filter((type) => this.questionTypesFilter.indexOf(type.value) !== -1);
            })
        );
    }

    public setQuestionsTypesFilter(types: QuestionTypes[]): void {
        this.questionTypesFilter = types;
    }

    public onCreateItem(): Observable<{
        item_type_value?: ResourceItemTypes;
        question?: FormGroup;
        name?: string;
        description?: string;
        instructions?: string;
        learning_field?: string;
        child_resource_published?: string;
        child_resource_id?: number;
        child_resource_language_id?: number;
        collapse?: boolean;
        mandatory?: boolean;
        percentage?: number;
        repeats?: number;
        show_correct_alts?: string;
        img_url?: string;
        child_resource_customer_id?: number;
        child_resource_author_id?: number;
    }> {
        return this.createSubject.asObservable();
    }

    public createItem(data: {
        item_type_value?: ResourceItemTypes;
        questionForm?: FormGroup;
        name?: string;
        description?: string;
        instructions?: string;
        learning_field?: string;
        child_resource_id?: number;
        child_resource_published?: string;
        child_resource_language_id?: number;
        collapse?: boolean;
        mandatory?: boolean;
        show_correct_alts?: string;
        repeats?: number;
        percentage?: number;
        img_url?: string;
        child_resource_customer_id?: number;
        child_resource_author_id?: number;
        isEmpty?: boolean;
    }): void {
        this.createSubject.next(data);
    }

    public onSort(): Observable<boolean> {
        return this.sortSubject.asObservable();
    }

    public sort(value: boolean): void {
        this.sortSubject.next(value);
    }

    public onSubmit(): Observable<ResourceBuilderSubmitEvent> {
        return this.submitSubject.asObservable();
    }

    public submit(event: ResourceBuilderSubmitEvent = { emitSaved: true, extraPayload: {} }): void {
        this.submitSubject.next(event);
    }

    public forceSubmit(
        event: ResourceBuilderSubmitEvent = { emitSaved: true, extraPayload: {} }
    ): Observable<Resource> {
        this.submit(event);
        return this.getResource();
    }

    public setLoading(isLoading: boolean): void {
        this.loading.next(isLoading);
    }

    public setLanguageId(lngId: number): void {
        this.languageId.next(lngId);
    }

    public getLanguageId(): Observable<number> {
        return this.languageId.asObservable();
    }

    public isLoading(): Observable<boolean> {
        return this.loading.asObservable();
    }

    public getLoading(): BehaviorSubject<boolean> {
        return this.loading;
    }
}
