<div fxLayout="column" fxLayoutGap="1em">
    <div fxLayoutGap="4px" class="assignee input-wrapper default-select" fxLayout="column">
        <span class="label">{{ label }}</span>
        <div
            *ngIf="!groups?.length || (!!groups?.length && groups.length < 1)"
            fxLayout="row"
            fxLayoutGap="10px"
            fxLayoutGap.lt-md="10px"
            class="input-parent"
        >
            <mat-form-field class="small-field users-rec-input" appearance="fill">
                <input
                    type="text"
                    matInput
                    #assigneeRecieverInput
                    [placeholder]="searchPlacholder"
                    [formControl]="groupControl"
                    [matAutocomplete]="autoGroup"
                />
                <mat-autocomplete
                    #autoGroup="matAutocomplete"
                    (optionSelected)="selectedGroup($event.option.value, autoGroup)"
                >
                    <mat-option *ngFor="let group of groupsSearch$ | async" [value]="group">
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <span *ngIf="group.name">{{ group.name }}</span>
                        </div>
                    </mat-option>
                </mat-autocomplete>
                <button
                    *ngIf="!!groupControl.value"
                    mat-icon-button
                    class="cancel-contact"
                    matSuffix
                    (click)="groupControl.reset()"
                >
                    <mat-icon>cancel</mat-icon>
                </button>
                <button
                    *ngIf="!groupControl.disabled"
                    mat-icon-button
                    matSuffix
                    (click)="$event.stopPropagation(); toggleAutocompletePanel()"
                >
                    <mat-icon>arrow_drop_down</mat-icon>
                </button>
            </mat-form-field>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="5px" *ngIf="!!groups?.length">
            <div
                class="admin-chip"
                fxLayout="row wrap"
                fxLayoutAlign="start center"
                fxLayoutGap="10px"
                *ngFor="let group of groups"
            >
                <span>{{ group.name }}</span>
                <button *ngIf="!groupControl.disabled" mat-icon-button matSuffix (click)="onRemoveGroup(group.id)">
                    <mat-icon>cancel</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>
