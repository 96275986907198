import { DatePipe } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@klickdata/core/auth';
import { CustomerService } from '@klickdata/core/customer';
import { FormHelper } from '@klickdata/core/form';
import { Language, LanguageService } from '@klickdata/core/localization';
import { MessageService } from '@klickdata/core/message';
import { SideNaveData } from '@klickdata/core/mobile';
import { SideNaveActionsTypes, SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { Task, TaskData, TaskService } from '@klickdata/core/task';
import { UserService } from '@klickdata/core/user';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-menu-side-add-task',
    templateUrl: './menu-side-add-task.component.html',
    styleUrls: ['./menu-side-add-task.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideAddTaskComponent implements OnInit, OnDestroy {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    public taskForm: FormGroup;
    public formats: { [key: string]: string };
    public destroy: Subject<boolean> = new Subject<boolean>();
    public onLoading: Subject<boolean> = new Subject<boolean>();
    public minDate = new Date();
    public currentLang: Language;
    public currentLaguageId$: Observable<number>;
    public task: Task;
    public isContactTask = false;

    constructor(
        protected fb: FormBuilder,
        protected userService: UserService,
        protected changeRef: ChangeDetectorRef,
        protected customerService: CustomerService,
        protected taskService: TaskService,
        protected auth: AuthService,
        protected langService: LanguageService,
        protected messageService: MessageService
    ) {
        this.buildForm();
    }

    ngOnInit(): void {
        this.currentLaguageId$ = this.langService
            .getLanguageByKey(this.langService.getCurrentLanguage().value)
            .pipe(map((lang) => lang.id));
        if (this.navData.data.type === 'updateTask') {
            this.task = this.navData.data.task;
            this.updateTask();
        }
        if (this.navData.data.user) {
            this.taskForm.get('staff').patchValue({ assignee: [this.navData.data.user?.id] });
        }
        if (this.navData.data.customer) {
            this.taskForm.patchValue({ customer_ids: [this.navData.data.customer.id] });
        }
    }

    protected buildForm(): void {
        this.taskForm = this.fb.group({
            body: ['', Validators.required],
            type: ['other'],
            action: ['other'],
            deadline: [new DatePipe('en').transform(Date.now(), 'yyyy-MM-dd').toString()],
            note: [''],
            priority: ['b1'],
            private: [false],
            staff: this.fb.group({
                assignee: [[]],
                manager: [[]],
            }),
            customer_ids: [[]],
            tag_ids: [[]],
        });
    }
    private updateTask() {
        this.taskForm.reset(this.task);
        FormHelper.resetForm(this.taskForm);
        this.changeRef.markForCheck();
    }

    private resetTask() {
        this.taskForm.reset({
            type: 'other',
            action: 'other',
            priority: 'b1',
            private: true,
            deadline: new DatePipe('en').transform(Date.now(), 'yyyy-MM-dd').toString(),
        });
        this.changeRef.markForCheck();
    }
    submit() {
        this.onLoading.next(true);
        return this.createOrUpdate()
            .pipe(takeUntil(this.destroy))
            .subscribe((task) => {
                this.onLoading.next(false);
                this.onClose.emit({
                    action: SideNaveActionsTypes.POSITIVE,
                    data: { task: task, type: this.navData.data.type },
                });
                this.resetTask();
            });
    }
    private createOrUpdate(): Observable<Task> {
        return this.task?.id && this.navData.data.type === 'updateTask'
            ? this.taskService.update(this.prepareData(), ['tags', 'customers', 'managers'])
            : this.taskService.create(this.prepareData(), ['tags', 'customers', 'managers']);
    }

    protected prepareData(): TaskData {
        const data = this.task?.id ? this.task.getPayload(this.taskForm.value) : this.taskForm.value;

        if (this.navData.data.parentId) {
            data.parent_id = this.navData.data.parentId;
        }
        if (this.task?.id && this.navData.data.type === 'updateTask') {
            delete data.note;
        }

        return data;
    }
    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
