import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SideNaveActionsTypes, SideNaveData } from '@klickdata/core/mobile';
import { SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { DownloadData } from '@klickdata/core/resource/src/download.model';
import { DownloadService } from 'apps/klickdata/src/app/shared/dialog/download-pdf-dialog/download.service';
import * as moment from 'moment';

@Component({
    selector: 'app-menu-side-download',
    templateUrl: './menu-side-download.component.html',
    styleUrls: ['./menu-side-download.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideDownloadComponent implements OnInit {
    @Input() set navData(value: SideNaveData) {
        this._navData = value;
        this.form = this.fb.group(
            this.navData.data.data.reduce((acc, data: DownloadData) => {
                acc[data.value] = { value: false, disabled: !data.canSee };
                return acc;
            }, {})
        );
    }
    get navData(): SideNaveData {
        return this._navData;
    }
    private _navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    SideNaveActionsTypes = SideNaveActionsTypes;
    public form: FormGroup;
    // public selectAll = new FormControl(false);
    downloading: boolean;
    constructor(protected fb: FormBuilder, protected downloadService: DownloadService) {}

    ngOnInit(): void {}
    toggleAll(checked: boolean) {
        // this.form.controls.forEach(control => control.patchValue(checked));
        Object.keys(this.form.controls).forEach((key) => this.form.controls[key].patchValue(checked));
    }

    public download() {
        if (this.navData.data.returnValue) {
            this.onClose.emit({
                action: SideNaveActionsTypes.POSITIVE,
                data: {
                    value: Object.keys(this.form.value).filter((key) => this.form.value[key]),
                    returnValue: true,
                },
            });
            return;
        }
        const checkedOptionKeys = Object.keys(this.form.value).filter((key) => this.form.value[key]);
        if (checkedOptionKeys.length === 1) {
            const url = this.navData.data.data.find(
                (option: DownloadData) => option.value === checkedOptionKeys[0]
            ).url;
            window.open(url, '_blank');
            this.onClose.emit();
        } else if (this.navData.data.archive_link && checkedOptionKeys.length > 1) {
            this.downloading = true;
            this.downloadService
                .download(this.navData.data.archive_link, { types: checkedOptionKeys })
                .subscribe((data) => {
                    const blob = new Blob([data], {
                        type: 'application/zip',
                    });
                    const url = window.URL.createObjectURL(blob);
                    const anchor = document.createElement('a');
                    anchor.download = `KLMS_package_${moment().format('yMD_hms')}`;
                    anchor.href = url;
                    anchor.click();
                    this.downloading = false;
                    this.onClose.emit();
                });
        }
    }
    isSubmitDisabled(): boolean {
        return Object.values(this.form.value).every((value) => !value);
    }
}
