<div class="builder-header">
    <ng-content select="[appBuilderTitle]"></ng-content>

    <ng-content select="[appBuilderEmpty]"></ng-content>
    <ng-container *ngIf="form | async; let form">
        <ng-container *ngIf="getItems() | async; let items">
            <button
                *ngIf="!(isMobile$ | async) && items.length"
                class="unFold-all expander"
                [ngClass]="{ unfold: !foldAll }"
                mat-icon-button
                type="button"
                (click)="$event.stopPropagation(); foldAll = !foldAll; onItemCollapsingChange(foldAll)"
            >
                <mat-icon>last_page</mat-icon>
            </button>
        </ng-container>

        <app-resource-builder-form
            #builderForm
            fxFlex="0 1 1000px"
            fxFlex.lt-lg="80%"
            fxFlex.lt-md="100%"
            [usePoints]="usePoints"
            [form]="form"
            [resourceForm]="resourceForm"
            (saving)="onSaving($event)"
            (removal)="removal.emit($event)"
            (saved)="onSaved($event)"
        >
        </app-resource-builder-form>
    </ng-container>
    <div
        *ngIf="
            !hideTools &&
            (resource_type_id === ResourceTypes.GeneralSurvey || resource_type_id === ResourceTypes.GeneralTest)
        "
        fxFlex="grow"
        fxLayoutAlign="start center"
        class="mt-1"
    >
        <button *ngIf="!(getLoading() | async)" mat-button type="button" class="add-qs" (click)="addQuestions()">
            <mat-icon>add_circle</mat-icon>
            <span i18n>Add Question</span>
        </button>
        <ng-container *ngIf="getLoading() | async">
            <ng-container *ngIf="aiMetaData | async; let aiData; else: smallLoading">
                <app-resource-builder-ai-loader-panel [aiData]="aiData"></app-resource-builder-ai-loader-panel>
            </ng-container>
        </ng-container>
    </div>
</div>
<ng-template #smallLoading>
    <app-saving-button></app-saving-button>
</ng-template>
