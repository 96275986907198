import {
    Cast,
    CastType,
    IDataModel,
    Ignore,
    InitialValue,
    MethodType,
    Nullable,
} from '@klickdata/core/application/src/model/model-interface';
import { Language } from '@klickdata/core/localization';
import { Observable } from 'rxjs';
import { TranslatableModel } from './../../resource/translatable.model';

export interface SectionOrder {
    setOrder(data: Section[], currentIndex: number, previousIndex: number): void;
}

export class SectionCustomerOrder implements SectionOrder {
    setOrder(data: Section[], currentIndex: number, previousIndex: number) {
        const order =
            previousIndex > currentIndex ? data[currentIndex + 1].order + 1 : data[currentIndex - 1].order - 1;
        data[currentIndex].order = order;
    }
}

export class SectionPublicOrder implements SectionOrder {
    setOrder(data: Section[], currentIndex: number, previousIndex: number) {
        const order =
            previousIndex > currentIndex
                ? data[currentIndex + 1].public_order + 1
                : data[currentIndex - 1].public_order - 1;
        data[currentIndex].public_order = order;
    }

    public static getInstance(): SectionPublicOrder {
        return new this();
    }
}

export interface SectionQuery {
    tags?: number[];
    categories?: number[];
    folders?: number[];
    scope?: number;
    genre?: 'started' | 'completed' | 'assigned' | 'favorite' | 'recommended';
    sort?: 'alphabetically' | 'random' | 'newely' | 'rated' | 'popularity';
}

export interface SectionData extends IDataModel {
    id?: number;
    order?: number;
    public_order?: number;
    active?: boolean;
    guest_mode?: boolean;
    created_by?: number;
    public?: boolean;
    open?: boolean;
    query?: SectionQuery;
    customer_id?: number;
    resources_count?: number;
    folders_detach?: number[];
    folders_attached?: boolean;
    detail?: { customer_id: number; section_id: number; order: number };
    title?: string;
    description?: string;
    title_translation?: { [key: string]: string };
    description_translation?: { [key: string]: string };
    customer_stream?: boolean;
    pref_lang_ids?: number[];
    pref_lang_values?: string[];

    excluded_resource_ids?: number[];

    // update order
    section_id?: number;
    // Resource priority
    resource_priority?: number;
    resource_id?: number;
    divisions?: any;
    groups?: any;
    group_id?: number;
}

export class Section extends TranslatableModel<SectionData> {
    order: number;
    public_order: number;
    active: boolean;
    guest_mode: boolean;
    created_by: number;
    customer_id: number;
    resources_count: number;
    @Nullable(MethodType.PUT)
    customer_stream: boolean;
    @Cast(CastType.OBJECT)
    query: SectionQuery;

    public: boolean;
    open: boolean;
    folders_attached: boolean;
    pref_lang_ids: number[];

    title: string;
    description: string;
    @InitialValue([])
    public excluded_resource_ids: number[];
    /**
     * Ignore from adding to payload.
     */
    @Ignore()
    checked: boolean;
    @Ignore()
    type_stream: boolean;
    @Ignore()
    pref_langs: Observable<Language[]>;
    @Ignore()
    deleted_at: any;
    @Ignore()
    loading: boolean;

    resourceCount$: Observable<number>;

    get streamType(): boolean {
        return this.public || !!this.query;
    }
    get author_id(): number {
        return this.created_by;
    }

    public updateOrder(
        data: Section[],
        currentIndex: number,
        previousIndex: number,
        sorter: SectionOrder = new SectionCustomerOrder()
    ) {
        if (previousIndex === currentIndex) {
            return false; // no change
        }
        sorter.setOrder(data, currentIndex, previousIndex);
        return true;
    }
}
