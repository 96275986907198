import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SideNaveData, SideNaveActionsTypes } from '@klickdata/core/mobile';
import { SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { TakeResActionValue } from '@klickdata/player/src/next-step.service';

@Component({
    selector: 'app-menu-side-cancel-course-play',
    templateUrl: './menu-side-cancel-course-play.component.html',
    styleUrls: ['./menu-side-cancel-course-play.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideCancelCoursePlayComponent {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    SideNaveActionsTypes = SideNaveActionsTypes;
    TakeResActionValue = TakeResActionValue;
    public feedback = new FormControl();
    public showWarning: boolean;

    public get requiredSignOff(): boolean {
        return this.navData?.data?.resource?.collaboration?.signoff_required;
    }
}
