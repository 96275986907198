<ng-container>
    <mat-sidenav-container [ngClass]="{ isAu: userIsAU$ | async }" class="mobile-side-container">
        <mat-sidenav
            class="mobile-side"
            [ngClass]="{ 'side-nav-data': isSideNavDataMenu$ | async }"
            [opened]="showingMenu | async"
            fxLayout="column"
            fxLayoutAlign="start"
            [position]="position"
            [mode]="mode"
            (closedStart)="clearDrawer()"
            [fixedInViewport]="true"
        >
            <ng-container *ngIf="sideNavData$ | async; let data">
                <ng-container [ngSwitch]="data.dataType">
                    <ng-container *ngSwitchCase="SideNaveDataTypes.MESSAGE_ACTIONS">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-actions-msg
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-actions-msg>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.ALERT">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-alert-msg (onClose)="clearDrawer()" [alertData]="data"></app-menu-side-alert-msg>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.SIGNOFF_FEEDBACK">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-signoff-feedback
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-signoff-feedback>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.RESOURCE_ENROLLMENT_APPROVAL">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-resource-enrollment-approve
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-resource-enrollment-approve>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.RESOURCE_ENROLLMENT_REQUEST">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-resource-enrollment-request
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-resource-enrollment-request>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.EVENT_JOIN">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-event-join
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-event-join>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.PROMPT_REGENERATION">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-material-prompt-regeneration
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-material-prompt-regeneration>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.SELECT_PUBLISHER_LOGO">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-select-publisher-logo
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-select-publisher-logo>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.RESOURCE_DONE_ACTIVITY">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-resource-done-au-activity
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-resource-done-au-activity>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.ADD_ROLE">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-add-role (onClose)="onClose($event)" [navData]="data"></app-menu-side-add-role>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.ADD_QUOTE">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-add-quote (onClose)="onClose($event)" [navData]="data"></app-menu-side-add-quote>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.AI_GENERATE">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-generate-ai
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-generate-ai>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.SELECT_PERMISSION_LEVEL">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-select-permission-level
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-select-permission-level>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.USER_NOTE">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-add-note (onClose)="onClose($event)" [navData]="data"></app-menu-side-add-note>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.MANAGE_ASK_AI_PREDEFINED_QS">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-manage-ask-ai-predefined-qs
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-manage-ask-ai-predefined-qs>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.EMAIL_MULTIPLE_USERS">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-email-multiple-users
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-email-multiple-users>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.USER_PROGRESS">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-log-user-resource-events
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-log-user-resource-events>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.RELATED_RESOURCES">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-related-resoures
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-related-resoures>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.LOGIN_REGISTER">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-login-register
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-login-register>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.FORM_BUILDER">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-form-builder
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-form-builder>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.ADD_GROUP_INTO_TEAM">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-add-group-into-team
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-add-group-into-team>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.VALIDATE_SIGNOFF">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-validate-signoff
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-validate-signoff>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.EVALUATION_FEEDBACK">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-validation-feedback
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-validation-feedback>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.UPDATE_RECURRING">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-update-recurring
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-update-recurring>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.UPDATE_COMPETENCE">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-update-comp
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-update-comp>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.TOGGLE_COMPETENCE">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-toggle-competence
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-toggle-competence>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.FORM_BUILDER_LISTING">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-custom-fields-listing
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-custom-fields-listing>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.GENERAL_NOTIFIER">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-notifier (onClose)="onClose($event)" [navData]="data"></app-menu-side-notifier>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.CREATE_GROUP">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-create-group
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-create-group>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.AI_PROMPTER">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-ai-prompter
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-ai-prompter>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.QUICK_ADD_COMPETENCE">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-quick-add-competence
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-quick-add-competence>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.VIDEO_TO_QIUZ">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-video-to-quiz
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-video-to-quiz>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.DATE_RANGE">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-date-range
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-date-range>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.CREATE_RESOURCE">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-create-resource
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-create-resource>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.ADD_RESOURCE_MEDIA">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-add-media-with-options
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-add-media-with-options>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.ADD_FILE">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-add-media-file
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-add-media-file>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.ALL_QUOTES">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-show-all-quotes
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-show-all-quotes>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.SHOW_QUOTES_OF_QUOTEE">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-show-quotee-quotes
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-show-quotee-quotes>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.ADD_TASK">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-add-task (onClose)="onClose($event)" [navData]="data"></app-menu-side-add-task>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.ADD_LINK_MATERIAL">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-create-link-material
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-create-link-material>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.DOWNLOAD">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-download (onClose)="onClose($event)" [navData]="data"></app-menu-side-download>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.ADD_USER">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-add-user (onClose)="onClose($event)" [navData]="data"></app-menu-side-add-user>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.ADD_ADDRESS">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-add-address
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-add-address>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.MANAGE_WIDGETS">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-manage-widgets
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-manage-widgets>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.DASHBOARD_COLUMN_SETTINGS">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-dashboard-column-settings
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-dashboard-column-settings>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.GENERAL_TEXT_AREA">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-text-area-field
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-text-area-field>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.GENERAL_TEXT_AREA_WITH_ACTIONS">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-textarea-with-multiple-actions
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-textarea-with-multiple-actions>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.ADD_HR_NOTE">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-add-hr-note
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-add-hr-note>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.QUESTION_EXTRA_INFO">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-question-extra-info
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-question-extra-info>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.ADD_RESOURCE_QUESTIONS">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-add-resource-questions
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-add-resource-questions>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.ADD_COURSE_ITEMS">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-add-course-items
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-add-course-items>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.COURSE_REGISTER">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-course-register
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-course-register>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.EVENT_REGISTER">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-event-reg (onClose)="onClose($event)" [navData]="data"></app-menu-side-event-reg>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.ADD_CUSTOMER">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-add-customer
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-add-customer>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.RESOURCE_MANAGERS">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-resource-managers
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-resource-managers>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.MEDIA_ATTACHMENT">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-display-media
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-display-media>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.USERS_CASE_STATUS">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-users-status
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-users-status>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.CHECK_EFFICIENCY">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-res-efficiency
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-res-efficiency>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.SEND_MESSAGE">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-send-message
                            (onClose)="clearDrawer()"
                            [navData]="data"
                        ></app-menu-side-send-message>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.ADD_EDUCATOR">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-add-educator
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-add-educator>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.SEND_GROUP_MAIL">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-send-group-email
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-send-group-email>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.ADD_NEXT_CONTACT">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-add-next-contact
                            (onClose)="onClose($event)"
                            [navData]="data"
                        ></app-menu-side-add-next-contact>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.SEND_MAIL">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-send-mail (onClose)="onClose($event)" [navData]="data"></app-menu-side-send-mail>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.SEND_TEXT_BLOCK_MAIL">
                        <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
                        <app-menu-side-mail-text-block
                            (onClose)="clearDrawer()"
                            [navData]="data"
                        ></app-menu-side-mail-text-block>
                    </ng-container>
                    <ng-container *ngSwitchCase="SideNaveDataTypes.MOBILE_MENU">
                        <app-mobile-menu></app-mobile-menu>
                        <div class="mobile-menu-wrapper" fxLayout="row wrap" fxLayoutAlign="start">
                            <ng-container [ngTemplateOutlet]="template"></ng-container>
                        </div>
                        <p [routerLink]="['/changelog']" class="version" *ngIf="version$ | async; let ver">
                            KLMS {{ ver.version }}
                        </p>
                    </ng-container>
                </ng-container>
            </ng-container>
        </mat-sidenav>

        <app-header id="scoller-head" class="scrolling-header" *ngIf="!(isMobile | async)">
            <app-module-menu>
                <ng-container [ngTemplateOutlet]="template"></ng-container>
            </app-module-menu>
        </app-header>

        <app-mobile-header class="scrolling-header" *ngIf="isMobile | async">
            <ng-content select="appMenuTitle"></ng-content>
        </app-mobile-header>

        <ng-content></ng-content>
    </mat-sidenav-container>
</ng-container>
<ng-template #closeBtn>
    <div fxLayout="row" fxLayoutAlign="end start">
        <button fxFlex="0 0 auto" class="close-button" (click)="clearDrawer()" mat-icon-button>
            <mat-icon>cancel</mat-icon>
        </button>
    </div>
</ng-template>
