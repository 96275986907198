<div class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutGap="5px" fxLayout="row wrap" fxLayoutAlign="start center">
        <mat-icon>mail</mat-icon><span i18n>Email to</span> <span>{{ navData.data.name }}</span
        >*
    </div>
    <div class="email-field" *ngIf="navData.data.email"><span i18n>Address: </span>{{ navData.data.email }}</div>

    <div fxLayoutGap="10px" fxLayout="row wrap" fxLayoutAlign="start center" *ngIf="!showMailFields">
        <button *ngIf="!(saving | async)" fxFlex="0 0 auto" class="activation" (click)="submit(true)" mat-raised-button>
            <span i18n>Send activation email</span>
        </button>
        <button
            *ngIf="!(saving | async)"
            fxFlex="0 0 auto"
            class="edit-email"
            (click)="showMailFields = !showMailFields"
            mat-raised-button
        >
            <span i18n>Edit email</span>
        </button>
        <ng-container *ngIf="!showMailFields">
            <button
                class="hover-tools-btn"
                *ngIf="!(saving | async)"
                mat-icon-button
                matTooltip="Share"
                i18n-matTooltip="@@share"
                (click)="openShareDialog()"
            >
                <mat-icon>share</mat-icon>
            </button>
        </ng-container>
        <ng-container *ngIf="saving | async">
            <app-saving-button></app-saving-button>
        </ng-container>
    </div>
    <ng-container *ngIf="!showMailFields" [ngTemplateOutlet]="notifiers"></ng-container>

    <form *ngIf="showMailFields" fxLayoutGap="20px" fxLayout="column" [formGroup]="mailForm">
        <mat-form-field appearance="fill" class="templates-select" fxFlex="0 0 100%">
            <input matInput placeholder="Type in the subject*" i18n-placeholder formControlName="subject" />
        </mat-form-field>
        <app-text-editor
            [subject]="mailForm.value.subject"
            (onSubject)="mailForm.controls['subject'].patchValue($event)"
            [fitTemplatesInSmallScreen]="true"
            formControlName="body"
            fieldName="userWelcomeMessage"
            title="Content"
            i18n-title
            fxFlex="1 0 100%"
            class="mail-body-menu"
        >
            <mat-error i18n="@@messageBodyError">Message body is required!</mat-error>
        </app-text-editor>
        <ng-container [ngTemplateOutlet]="notifiers"></ng-container>
        <div class="action">
            <button
                *ngIf="!(saving | async)"
                [disabled]="mailForm.invalid && mailForm.touched"
                fxFlex="0 0 auto"
                class="mobile-button"
                (click)="submit()"
                mat-raised-button
            >
                <span i18n>Send Email</span>
            </button>
            <ng-container *ngIf="saving | async">
                <app-saving-button></app-saving-button>
            </ng-container>
        </div>
        <div class="info" i18n>
            <span>*</span>Your email will be sent but you can't read the reply. Copy the adress and use your email
            client if you like to be able to have a conversation.
        </div>
    </form>
</div>
<ng-template #smallLoading>
    <app-kd-logo-loading size="small"></app-kd-logo-loading>
</ng-template>
<ng-template #notifiers>
    <div *ngIf="!navData.data.activated" fxLayout="column">
        <mat-checkbox
            matTooltip="KAM is Key Account Manager: the person who is in charge"
            i18n-matTooltip
            [formControl]="kam"
            i18n
            >Notify KAM when learner login first time.</mat-checkbox
        >
        <mat-checkbox matTooltip="MA is the Main admin in the Academy" i18n-matTooltip [formControl]="ma" i18n
            >Notify MA when learner login first time.</mat-checkbox
        >
    </div>
</ng-template>
