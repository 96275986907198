import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { SubMessage } from '@klickdata/core/notification';
import { UserService } from '@klickdata/core/user';
import { DownloadHelperService } from 'apps/klickdata/src/app/shared/dialog/download-pdf-dialog/download-helper.service';

@Component({
    selector: 'app-chat-dialog',
    templateUrl: './chat-dialog.component.html',
    styleUrls: ['./chat-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubMessageComponent implements OnInit {
    @Input() dialog: SubMessage;
    @Input() authorDir: 'left' | 'right';
    constructor(protected userService: UserService, protected downloadHelper: DownloadHelperService) {}

    ngOnInit(): void {}

    public download() {
        this.downloadHelper.download(this.dialog.downloads);
    }
}
