import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, FormArray, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { LoggerService } from '@klickdata/core/application';
import { AuthService } from '@klickdata/core/auth';
import { FormHelper } from '@klickdata/core/form';
import { MessageSavedComponent, MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { MobileService, SideNaveActionsTypes, SideNaveDataTypes } from '@klickdata/core/mobile';
import {
    Resource,
    ResourceCategoryService,
    ResourceData,
    ResourceService,
    ResourceTypes,
} from '@klickdata/core/resource';
import { ResourceItemData, ResourceItemTypes } from '@klickdata/core/resource-item';
import { ResourceBuilderComposerDirective } from '@klickdata/core/resource/src/resource-builder-composer-directive';
import { User } from '@klickdata/core/user';
import moment from 'moment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, first, map, takeUntil } from 'rxjs/operators';
import { ResourceBuilderComponent } from '../resource-builder/resource-builder.component';
import { CourseManagerCoreAddMaterialComponent } from './course-manager-core-items/course-manager-core-add-material/course-manager-core-add-material.component';
import { CourseManagerCoreItemsComponent } from './course-manager-core-items/course-manager-core-items.component';
import { CourseManagerCoreSecodaryControlsComponent } from './course-manager-core-secodary-controls/course-manager-core-secodary-controls.component';
import { Customer } from '@klickdata/core/customer';
@Component({
    selector: 'app-course-manager-core',
    templateUrl: './course-manager-core.component.html',
    styleUrls: ['./course-manager-core.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class CourseManagerCoreComponent extends ResourceBuilderComposerDirective implements OnInit, AfterViewInit {
    typeId = ResourceTypes.COURSE;
    ResourceTypes = ResourceTypes;
    public showAddingItems: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public selection: number[] = [];
    private _resourceBuilder: ResourceBuilderComponent;
    public itemsData: ResourceItemData[] = [];
    public authUser$: Observable<User>;
    public authCustomer$: Observable<Customer>;
    @ViewChild('secondaryControls') secondaryControls: CourseManagerCoreSecodaryControlsComponent;
    @ViewChild('courseItemsCore') courseItemsCore: CourseManagerCoreItemsComponent;
    @ViewChild(ResourceBuilderComponent)
    public set resourceBuilder(resourceBuilder: ResourceBuilderComponent) {
        if (resourceBuilder) {
            this._resourceBuilder = resourceBuilder;
            this.resourceBuilder
                .getItems()
                .pipe(takeUntil(this.destroy))
                .subscribe((items) => {
                    this.selection = items.map((item) => item.child_resource_id);
                    items.map((item) => {
                        if (this.itemsData.map((i) => i.id).indexOf(item.id) === -1) {
                            this.itemsData.push(item.getData());
                        }
                    });
                    this.resourceForm.patchValue({ items: this.itemsData });
                });
            this.loading.emit(this.resourceBuilder.getService().getLoading().value);
        }
    }

    public get resourceBuilder(): ResourceBuilderComponent {
        return this._resourceBuilder;
    }

    public savingItems: boolean;

    constructor(
        protected parentFormDirective: FormGroupDirective,
        protected auth: AuthService,
        protected categoryService: ResourceCategoryService,
        protected messageService: MessageService,
        protected resourceService: ResourceService,
        protected logger: LoggerService,
        protected mobileService: MobileService,
        protected router: Router,
        protected bottomSheet: MatBottomSheet
    ) {
        super(resourceService, categoryService, auth, parentFormDirective, messageService, router, bottomSheet);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.resourceForm.addControl('items', new FormControl(''));
        this.authUser$ = this.auth.getUser().pipe(first());
        this.authCustomer$ = this.auth.getCustomer().pipe(first());
    }
    ngAfterViewInit(): void {
        this.checkCanAddItems();
    }

    onResourceChanged(created: boolean) {
        this.checkCanAddItems();
    }
    public onTabSelectionChange(activeIndex: number) {
        this.secondaryControls.onTabSelectionChange(activeIndex);
    }
    checkCanAddItems() {
        if (!this.resource) {
            this.showAddingItems.next(true);
        } else {
            this.showAddingItems.next(!this.resource.isPublished());
        }
    }
    public onSubmit() {
        this.loading.emit(true);
        if (this.courseItemsCore && this.courseItemsCore.compRef instanceof CourseManagerCoreAddMaterialComponent) {
            this.courseItemsCore.submitItems();
        } else {
            this.checkSubmitValid();
        }
    }
    enableEdit() {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.GENERAL_NOTIFIER,
            data: {
                icon: 'lock_open',
                title: $localize`Unlock adding course items`,
                contentBody: $localize`You will unpublish a course and this might affect current students progress and statistics.`,
                positiveBtn: $localize`Unlock`,
                negativeBtn: $localize`Cancel`,
            },
        });
        this.mobileService
            .getSideNavAction()
            .pipe(filter((action) => action === SideNaveActionsTypes.POSITIVE))
            .subscribe(() => {
                this.resourceForm.patchValue({ publish: false, public: false });
                this.resourceForm.get('publish').markAsTouched();
                this.resourceForm.get('publish').markAsDirty();
                this.showAddingItems.next(true);
            });
    }
    checkSubmitValid() {
        const itemsReady = this.resourceBuilder.getService().readyForSubmit();
        if (this.resourceForm.invalid) {
            FormHelper.markForm(this.resourceForm);
            this.openMessage(
                MessageErrorComponent,
                $localize`${FormHelper.formatInvalidForm(this.resourceForm, this.formats)} is missing`
            );
            this.loading.emit(false);
            return of(false);
        }
        if (
            this.publish &&
            !this.resourceForm.value.always_available &&
            !this.resourceForm.value.occasion_dates?.length
        ) {
            FormHelper.markForm(this.resourceForm);
            this.openMessage(MessageErrorComponent, $localize`You need to add at least one occasion dates.`);
            this.loading.emit(false);
            return of(false);
        }
        if (!itemsReady) {
            if (this.publish) {
                this.openMessage(
                    MessageErrorComponent,
                    $localize`You can not publish a Course without any Material, Test or Survey.`
                );
                this.loading.emit(false);
                return of(false);
            } else {
                this.submitCourseAfterItemsSubmitted();
                return of(true);
            }
        } else {
            this.savingItems = true;
            this.resourceBuilder.getService().submit();
            return of(true);
        }
    }

    public submitCourseAfterItemsSubmitted() {
        this.savingItems = false;
        super.performResSubmit();
    }

    public prepareForm(): Observable<ResourceData> {
        return of(this.resourceBuilder.getCurrentResource()).pipe(
            map((resource) => {
                const extras = {
                    examination: this.resourceForm.get('examination').value,
                    duration: moment.duration(this.resourceForm.value.duration).asSeconds(),
                    time_limit: moment.duration(this.resourceForm.value.time_limit).asSeconds(),
                };
                const data = resource
                    ? resource.getData({ ...this.resourceForm.value, ...extras })
                    : new Resource({ ...this.resourceForm.value, ...extras }).getData();
                if (!data.id) {
                    data.type_id = ResourceTypes.GeneralCoursePlan;
                }
                if (data.passing_levels?.length) {
                    const percentages = data.passing_levels.map(({ enabled, ...rest }) => {
                        if (!enabled) {
                            return { ...rest, percentage: null, repeats: null };
                        }
                        return rest;
                    });
                    data.passing_levels = percentages;
                }

                if (data.always_available) {
                    data.start_date = null;
                    data.end_date = null;
                    data.occasion_dates = null;
                }
                if (data.occasion_dates) {
                    data.occasion_dates = data.occasion_dates.map((occasion) => {
                        return {
                            start_date: occasion.start_date,
                            end_date: occasion.end_date,
                            id: occasion.id,
                        };
                    });
                }
                data.collaboration = this.handleCollaboration();
                return data;
            })
        );
    }
    handleCollaboration() {
        const collabForm = this.resourceForm.get('collaboration') as FormGroup;
        const collabValues = (this.resourceForm.get('collaboration') as FormGroup).value;
        Object.keys(collabForm.controls).forEach((key) => {
            const control = collabForm.get(key);
            if (control.pristine) {
                delete collabValues[key];
            }
        });
        return collabValues;
    }
    public updateRemovedItems(removal: number[]) {
        this.selection = this.selection.filter((item) => removal.indexOf(item) === -1);
    }
    addItem(response: { res: Resource; isParentSubmission?: boolean }) {
        const resource = response.res;
        const resourceType: ResourceTypes = ResourceTypes.parentType(resource.type_id);
        // Check if resource already add just update
        const itemForm = (<FormArray>this.resourceBuilder.getService().getCurrentForm().get('items')).controls.find(
            (item) => item.get('child_resource_id').value === resource.id
        );

        if (!itemForm) {
            // Add resource to selection to handle single item selected from resource libraries.
            this.selection.push(resource.id);
            // code for showing the message of adding to CP
            this.showResourceAddedMsg(resource, resourceType);
            // Add item to resource builder.
            this.resourceBuilder.getService().createItem({
                item_type_value: this.getItemType(resourceType),
                name: resource.title,
                description: resource.description,
                instructions: resource.instructions,
                child_resource_id: resource.id,
                child_resource_published: resource.published,
                child_resource_language_id: resource.language_id,
                collapse: true,
                child_resource_customer_id: resource.customer_id,
                child_resource_author_id: resource.author_id,
            });
        } else {
            itemForm.patchValue({
                item_type_value: this.getItemType(resourceType),
                name: resource.title,
                description: resource.description,
                instructions: resource.instructions,
                child_resource_id: resource.id,
                child_resource_published: resource.published,
                child_resource_language_id: resource.language_id,
            });
        }
        // send submit action.
        // this.saveParts();
        this.resourceBuilder.getService().submit({ emitSaved: false });
        const resourceItem: ResourceItemData = {
            child_resource_id: resource.id,
            name: resource.title,
            item_type_value: this.getItemType(resourceType),
            mandatory: true, // Make mandatory true as default when new item added
        };
        const accumulatedTags = (this.resourceForm.value.tag_ids as number[]).concat(response.res?.tag_ids);
        this.itemsData.push(resourceItem);
        this.resourceForm.patchValue({ items: this.itemsData, tag_ids: accumulatedTags });
        this.updateCourseAfterSavingItems(this.resourceBuilder.getResource(), response.isParentSubmission);
    }
    private updateCourseAfterSavingItems(resource: Observable<Resource>, isParentSubmission: boolean) {
        resource.pipe(takeUntil(this.destroy)).subscribe((res) => {
            if (res) {
                const data = res.getData();
                this.resourceForm.patchValue({
                    title: this.resourceForm.value.title || data.title,
                });
                if (isParentSubmission) {
                    this.checkSubmitValid();
                }
            }
        });
    }
    private getItemType(resourceType: ResourceTypes): ResourceItemTypes {
        switch (resourceType) {
            case ResourceTypes.TEST:
                return ResourceItemTypes.TEST;
            case ResourceTypes.SURVEY:
                return ResourceItemTypes.SURVEY;
            case ResourceTypes.MATERIAL:
                return ResourceItemTypes.MATERIAL;
            case ResourceTypes.E_COURSE:
                return ResourceItemTypes.E_COURSE;
            case ResourceTypes.EVENT:
                return ResourceItemTypes.EVENT;
        }
    }
    private showResourceAddedMsg(resource: Resource, resourceType: ResourceTypes) {
        this.openMessage(
            MessageSavedComponent,
            `${resource.title}` +
                $localize` added as ` +
                `${resource.type_id ? this.getItemType(resourceType) : 'resource'}` +
                $localize` to ` +
                `${ResourceTypes.label(ResourceTypes.COURSE, { capitalize: true })}`
        );
    }

    public isEmpty(form: FormGroup) {
        return !(<FormArray>form?.get('items'))?.controls.length;
    }
    public getResKeysValidaty(): { [key: string]: string | boolean }[] {
        return [
            {
                key: 'title',
                title: $localize`Course title`,
                hasValidValue: this.resourceForm.get('title').valid,
                mandatory: true,
            },
            {
                key: 'language_id',
                title: $localize`Course language`,
                hasValidValue: this.resourceForm.get('language_id').valid,
                mandatory: true,
            },
            {
                key: 'category_ids',
                title: $localize`Course categories`,
                hasValidValue: this.resourceForm.get('category_ids').valid,
                mandatory: true,
            },
            {
                key: 'items',
                title: $localize`Course structure`,
                hasValidValue: this.itemsData.length > 0,
                mandatory: true,
            },
            {
                key: 'media_id',
                title: $localize`Course image`,
                hasValidValue: this.resourceForm.value.media_id,
                mandatory: false,
            },
            {
                key: 'tag_ids',
                title: $localize`Course tags`,
                hasValidValue: this.resourceForm.value.tag_ids?.length,
                mandatory: false,
            },
            {
                key: 'bullets',
                title: $localize`Course Summary`,
                hasValidValue: this.resourceForm.value.bullets,
                mandatory: true,
                info: $localize`The short summary of this course that will be displayed udner the title (max 256 characters).`,
            },
            // {
            //     key: 'practice_document',
            //     title: $localize`Exercise Documents`,
            //     hasValidValue: this.resourceForm.value.practice_document,
            //     mandatory: false,
            //     info: $localize`Upload exercise document that belongs to the course for practice. It can be a documents pre-course, during course or post the course.`,
            // },
            {
                key: 'description',
                title: $localize`Course description`,
                info: $localize`A description of the course that will be visible befor learners take the course.`,
                hasValidValue: this.resourceForm.value.description,
                mandatory: false,
            },
            {
                key: 'instructions',
                title: $localize`Course instructions`,
                hasValidValue: this.resourceForm.value.instructions,
                mandatory: false,
                info: $localize`Other information that is relevant to this test.`,
            },
            {
                key: 'audience',
                title: $localize`Course audience`,
                hasValidValue: this.resourceForm.value.audience,
                mandatory: false,
                info: $localize`Who is the target for the Course? The person or group who are the learners who will benefit from the knowledge covered in this course.`,
            },
            {
                key: 'availability',
                title: $localize`Course availaility`,
                hasValidValue:
                    this.resourceForm.value.always_available ||
                    this.resourceForm.value.start_date ||
                    this.resourceForm.value.end_date,
                mandatory: false,
                info: $localize`The time period where course will be available for the authorized learners..`,
            },
            {
                key: 'goal',
                title: $localize`Course goal`,
                hasValidValue: this.resourceForm.value.goal,
                mandatory: false,
                info: $localize`The goal of this course.`,
            },
            {
                key: 'agenda',
                title: $localize`Course agenda`,
                hasValidValue: this.resourceForm.value.agenda,
                mandatory: false,
                info: $localize`List the subjects covered in the course. The content in bullets for the lessons, modules, and classes. Suitable for onsite teaching, classroom, or webinar audience. In short, a list of what you will learn.`,
            },
            {
                key: 'type',
                title: $localize`End reward`,
                hasValidValue: this.resourceForm.get('examination')?.value.type,
                mandatory: false,
                info: $localize`Set the reward for this Course. When you have a Final Test in the Course; the learner will receive either a Participation Certificate, a Diploma or a Certification.`,
            },
            {
                key: 'time_limit',
                title: $localize`Play time`,
                hasValidValue: this.resourceForm.value.time_limit,
                mandatory: false,
                info: $localize`Course play time`,
            },
            {
                key: 'pre_skills',
                title: $localize`Pre skills`,
                hasValidValue: this.resourceForm.value.pre_skills,
                mandatory: false,
                info: $localize`The prerequisite knowledge that would be required from the participant prior to the course or the event.`,
            },
            {
                key: 'episode',
                title: $localize`Occasions`,
                hasValidValue: this.resourceForm.value.episode,
                mandatory: false,
                info: $localize`The course time on a calendar when onsite, webinar, classroom, or synchronous teaching.`,
            },
            {
                key: 'duration',
                title: $localize`Duration`,
                hasValidValue: this.resourceForm.value.duration,
                mandatory: false,
                info: $localize`Course duration`,
            },
            {
                key: 'other_info',
                title: $localize`Other information`,
                hasValidValue: this.resourceForm.value.other_info,
                mandatory: false,
                info: $localize`Here you can add other information needed for the users who will take this course`,
            },
            // {
            //     key: 'bg_img',
            //     title: $localize`Background image`,
            //     hasValidValue: this.resourceForm.get('screenshots')?.value.bg_img,
            //     mandatory: false,
            //     info: $localize`Background for the course in the course details page.`,
            // },
            // {
            //     key: 'screenshot',
            //     title: $localize`Screenshot image`,
            //     hasValidValue: this.resourceForm.get('screenshots')?.value.screenshot,
            //     mandatory: false,
            //     info: $localize`A thumbnail from the video (or a homepage) This image is a Screenshot from the creator to be uploaded.`,
            // },
            // {
            //     key: 'images',
            //     title: $localize`Instructor`,
            //     hasValidValue:
            //         (this.resourceForm.get('instructor')?.value.images &&
            //             this.resourceForm.get('instructor')?.value.images.length > 0) ||
            //         this.resourceForm.get('instructor')?.value.name,
            //     mandatory: false,
            //     info: $localize`The one who explain and teach in the Course. Can be the narrator in the video on screen or voice over. In the film industry: This will be equal to Actor `,
            // },
            // {
            //     key: 'educators_ids',
            //     title: $localize`Educators`,
            //     hasValidValue: this.resourceForm.value.educators_ids,
            //     mandatory: false,
            //     info: $localize`Enter the educators of this course.`,
            // },
            // {
            //     key: 'publisher',
            //     title: $localize`Publisher`,
            //     hasValidValue: this.resourceForm.get('publisher')?.value.id,
            //     mandatory: false,
            //     info: $localize`The one who publish the Course.`,
            // },
            {
                key: 'promo_code',
                title: $localize`Promo code`,
                hasValidValue: this.resourceForm.value.promo_code,
                mandatory: false,
                info: $localize`The course promo code`,
            },
            {
                key: 'article_code',
                title: $localize`SKU/ Article number`,
                hasValidValue: this.resourceForm.value.article_code && this.resourceForm.get('article_code').valid,
                mandatory: false,
                info: $localize`Course code is a unique field, autogenerated when empty.`,
            },
            {
                key: 'subtitles',
                title: $localize`Subtitle languages`,
                hasValidValue: this.resourceForm.value.subtitles,
                mandatory: false,
                info: $localize`If Subtitles are availible in the video for exampel ; Info Copied from TED or YouTube`,
            },
            {
                key: 'total_seats',
                title: $localize`Course seats`,
                hasValidValue: true,
                mandatory: false,
                info: $localize`Total seats can be unlimited or specified`,
            },
            {
                key: 'extra_seats',
                title: $localize`Extra seats`,
                hasValidValue: this.resourceForm.value.extra_seats,
                mandatory: false,
                info: $localize`Extra seats activated when seats are full.`,
            },
            {
                key: 'copyrights',
                title: $localize`Copyrights`,
                hasValidValue: this.resourceForm.value.copyrights,
                mandatory: false,
                info: $localize`Set course copyrights`,
            },
            {
                key: 'priority',
                title: $localize`Priority`,
                hasValidValue: this.resourceForm.value.priority,
                mandatory: false,
                info: $localize`Set course priority`,
            },
        ];
    }
    public canDeactivate(): boolean {
        return super.canDeactivate() && this.resourceBuilder.getService().getCurrentForm().pristine;
    }
}
