import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '@klickdata/core/auth';
import { Utils } from '@klickdata/core/util';
import { map, Observable } from 'rxjs';

@Component({
    selector: 'app-ai-model-selector',
    templateUrl: './ai-model-selector.component.html',
    styleUrls: ['./ai-model-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiModelSelectorComponent implements OnInit {
    model = null; // default model
    @Output() modelChange = new EventEmitter<string>();
    public allLLmsList$: Observable<
        {
            name: string;
            value: string;
            enabled?: boolean;
            default?: boolean;
            divider?: boolean;
        }[]
    >;
    constructor(protected auth: AuthService) {}

    ngOnInit(): void {
        this.model = Utils.getLLmsList().find((item) => item.default).value;
        // Emit the initial value on component initialization
        this.modelChange.emit(this.model);
        this.allLLmsList$ = this.auth.getCustomer().pipe(
            map((customer) => customer.name),
            map((name) => Utils.getLLmsList(name))
        );
    }

    selectModel(value: string): void {
        this.model = value;
        this.modelChange.emit(value);
    }
}
