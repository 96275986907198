import { Location } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertDialogComponent } from '@klickdata/core/application/src/alert-dialog/alert-dialog.component';
import { AuthService } from '@klickdata/core/auth';
import { ConfigService } from '@klickdata/core/config';
import { Customer, CustomerLanding } from '@klickdata/core/customer';
import { GloalSearchService, GlobalSearchData } from '@klickdata/core/global-search';
import { MessageService } from '@klickdata/core/message';
import { MobileService, SideNaveDataTypes } from '@klickdata/core/mobile';
import {
    AppScope,
    Resource,
    ResourceCategory,
    ResourceCategoryService,
    ResourceService,
    ResourceTag,
    ResourceTypeService,
    ResourceTypes,
} from '@klickdata/core/resource';
import { ResourceItem, ResourceItemService } from '@klickdata/core/resource-item';
import { Educator } from '@klickdata/core/resource/src/resource.model';
import { StartResourceService } from '@klickdata/core/resource/src/start-resource.service';
import { TagService } from '@klickdata/core/resource/src/tag/tag.service';
import { User } from '@klickdata/core/user';
import { Utils } from '@klickdata/core/util';
import { UserStatisticsService } from '@klickdata/home/src/core/user-statistics.service';
import { ResourceItemOpportunityService } from '@klickdata/player/src/core/resource-item/resource-item.service';
import { DialogDisplayImgComponent } from 'apps/klickdata/src/app/shared/dialog/dialog-display-img/dialog-display-img.component';
import { DownloadHelperService } from 'apps/klickdata/src/app/shared/dialog/download-pdf-dialog/download-helper.service';
import * as moment from 'moment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, first, map, shareReplay, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ResourcesAssignSheetComponent } from '../resource-assignment/resource-assign-sheet/resource-assign-sheet.component';
import { ResourceInviteSheetComponent } from '../resource-assignment/resource-invite-sheet/resource-invite-sheet.component';
import { ResourceEditDialogComponent } from '../resource-edit-dialog/resource-edit-dialog.component';
import { ResourceListingSheetComponent } from '../resource-listing-sheet/resource-listing-sheet.component';
import { ShareService } from '../share/share.service';
import { GuestService } from '@klickdata/guest/src/guest.service';
import { GuestModeMobileLoginComponent } from '@klickdata/guest/src/onboarding-guest-mode/guest-mode-mobile-login/guest-mode-mobile-login.component';

@Component({
    selector: 'app-resource-course-details',
    templateUrl: './resource-course-details.component.html',
    styleUrls: ['./resource-course-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceCourseDetailsComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('specsExpansionPanel') expansionPanel: MatExpansionPanel;
    @Input() resource: Resource;
    @Input() resourceItems$: Observable<ResourceItem[]>;
    @Input() resourceCategories$: Observable<ResourceCategory[]>;
    @Input() resourceTags$: Observable<ResourceTag[]>;
    @Input() resourceType: ResourceTypes;
    @Input() resourceLabel: string;
    private destroy: Subject<boolean> = new Subject<boolean>();
    public shareLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public resourceId: number;

    public materialItem: ResourceItem;
    public canEdit = false;
    public fullScreenMode = false;
    AppScope = AppScope;
    ResourceTypes = ResourceTypes;
    public mediaKeyValue: string;
    public resourceauthorId: string;
    public user$: Observable<User>;
    public customer: Customer;
    public mediaUrl: string;

    public isMobile: Observable<boolean>;
    public moment = moment();
    public hideImg = false;
    public showAuthorName = false;
    private platform: string;
    public defaultResourceOnlineEventId: number;
    public registrationId: number;
    public isActionBtnDisabled$: Observable<boolean>;
    public customerLanding: CustomerLanding;
    public scrolledItemWidth = 355;
    public showQuotesItems: boolean;
    customOptions = Utils.getOwlOptions({ loop: true });

    public quotes = [
        {
            id: 1,
            body: 'Excellent content and assignments that build on your knowledge, reinforce, and then expand. I recently secured new employment using XSL and XML and couldn’t have done so without the Professional XML and XSL courses. Thanks!',
            authorId: 132,
            authorName: 'Edgar Hoover',
            title: 'Excellent content!',
        },
        {
            id: 2,
            body: 'You prepare people to carry out skills in the real world. Keep up the good work. What I have learned in this course, I will be able to apply in the real world.',
            authorId: 6244,
            authorName: 'Hernández Marío',
            title: 'Fantastic Experience!',
        },
        {
            id: 3,
            body: 'The teacher (Kitty) was always there if you needed her. She was great! Looking into to signing up some of my staff. Looking forward to taking another class.',
            authorId: 8494,
            authorName: 'Mary Elizabeth',
            title: 'Very well organized and conceived.',
        },
        {
            id: 4,
            body: 'I wrapped a real life experience around the assignments and it was a tremendous learning experience!',
            authorId: 8487,
            authorName: 'Elizabeth Smith',
            title: 'The course exceeded my expectations!',
        },
        {
            id: 5,
            body: 'Very interactive course, perfect for people with limited/no experience-I was surprised how much I learnt in 3 hours!',
            authorId: 8486,
            authorName: 'Meena López',
            title: 'Fantastic Experience!',
        },
        {
            id: 6,
            body: 'One thing that impressed me about Online-Learning is if I have a question (even before enrolling), there is always a quick response. This is critical when distance is involved. Susan is very good about getting me through difficult issues and keeping in touch with the class.',
            authorId: 8485,
            authorName: 'Suresh Hernández',
            title: 'Fantastic Experience!',
        },
    ];

    constructor(
        protected route: ActivatedRoute,
        protected resourceService: ResourceService,
        protected resourceItemService: ResourceItemService,
        protected resourceTypeService: ResourceTypeService,
        protected router: Router,
        protected location: Location,
        protected userStatisticsService: UserStatisticsService,
        protected cd: ChangeDetectorRef,
        protected auth: AuthService,
        protected fb: FormBuilder,
        protected tagService: TagService,
        protected mobile: MobileService,
        protected categoriesService: ResourceCategoryService,
        protected bottomSheet: MatBottomSheet,
        public shareService: ShareService,
        protected dialog: MatDialog,
        protected downloadHelper: DownloadHelperService,
        protected itemService: ResourceItemOpportunityService,
        protected searchService: GloalSearchService,
        protected startResourceService: StartResourceService,
        protected configService: ConfigService,
        protected guestService: GuestService,
        protected message: MessageService,
        protected elementRef: ElementRef
    ) {
        this.user$ = this.auth.getUser().pipe(startWith(new User({ role_value: 'guest' })));
        this.isMobile = this.mobile.isMobile().pipe(shareReplay());
        this.platform = this.auth.getNK3PlatformValue();
        this.defaultResourceOnlineEventId = this.configService.config.defaultResourceOnlineEventId;
    }

    ngOnInit() {
        this.registrationId = +localStorage.getItem('registrationId');
        this.auth
            .getCustomerLanding()
            .pipe(takeUntil(this.destroy))
            .subscribe((cl) => (this.customerLanding = cl));
        if (ResourceTypes.parentType(this.resource.type_id) === ResourceTypes.EVENT && this.registrationId) {
            this.isActionBtnDisabled$ = this.resourceService.checkResBooking(this.resource.id, this.registrationId);
        }
        this.auth
            .getCustomer()
            .pipe(first(), takeUntil(this.destroy))
            .subscribe((customer) => (this.customer = customer));
        this.route.queryParams
            .pipe(
                filter((queryParams) => queryParams['start'] === 'true'),
                switchMap(() => this.auth.getUser()),
                switchMap((user) => this.startResourceService.takeResource(this.resource, user)),
                takeUntil(this.destroy)
            )
            .subscribe();
    }
    // Handle case of the header not displayed in CD
    ngAfterViewInit() {
        document.getElementById('scoller-head')?.classList.remove('slide-down');
        this.searchService.activateSearchBox(false);
    }
    getSliderWidth() {
        this.scrolledItemWidth =
            this.elementRef.nativeElement.getElementsByClassName('quotes-slider-wrapper')[0]?.offsetWidth / 2;
        this.cd.markForCheck();
    }
    displayCorrectIcon(type) {
        if (type.indexOf('test') !== -1) {
            return 'kd-icon-test';
        } else if (type.indexOf('course_plan') !== -1) {
            return 'kd-icon-task';
        } else if (type.indexOf('survey') !== -1) {
            return 'kd-icon-survey';
        } else if (type.indexOf('course') !== -1) {
            return 'kd-icon-player';
        } else if (type.indexOf('material') !== -1) {
            return 'kd-icon-document';
        }
    }

    public takeResource(resource: Resource, user: User, item?: ResourceItem) {
        if (this.auth.getNK3PlatformValue() === 'guest') {
            this.bottomSheet
                .open(GuestModeMobileLoginComponent, {
                    data: {
                        customerLanding: this.customerLanding,
                        accessMode: this.guestService.getAccessMode(),
                    },
                    panelClass: 'sheet-wrapper',
                })
                .afterDismissed()
                .pipe(takeUntil(this.destroy))
                .subscribe(() =>
                    this.router.navigate(['/home/dashboard/resource-details/' + resource.id], {
                        queryParams: { start: true },
                    })
                );

            return;
        }
        this.startResourceService.takeResource(resource, user, item)?.subscribe();
    }
    protected startResource(resource: Resource, item?: ResourceItem) {
        this.router.navigate(['/player/resource', resource.id, ResourceTypes.playerRoute(resource.type_id)], {
            queryParams: {
                queryParams: item ? { position: item.play_position } : {},
                referrer: this.location.path(),
            },
        });
    }

    public assign(resource: Resource) {
        this.bottomSheet.open(ResourcesAssignSheetComponent, {
            data: {
                width: !this.isMobile ? '70%' : '100%',
                id: resource.id,
                context: $localize`Assign`,
                contextIcon: 'assignment_ind',
                title: resource.title,
                referenceIdKey: 'resource_id',
                referenceFilter: 'assignedResource',
            },
            panelClass: 'sheet-wrapper',
        });
    }

    public invite(resource: Resource) {
        this.bottomSheet.open(ResourceInviteSheetComponent, {
            data: {
                width: !this.isMobile ? '70%' : '100%',
                id: resource.id,
                context: $localize`Invite`,
                contextIcon: 'outgoing_mail',
                title: resource.title,
            },
            panelClass: 'sheet-wrapper',
        });
    }
    showQuotes() {
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.ALL_QUOTES,
        });
    }
    public editResource(resource: Resource, isAdmin: boolean) {
        if (resource.type_id === ResourceTypes.General_E_Course) {
            const dialogRef = this.dialog.open(ResourceEditDialogComponent, {
                width: !this.isMobile ? '80%' : '100%',
                data: { ...resource, scope_id: AppScope.E_COURSE },
                disableClose: true,
                panelClass: 'sheet-wrapper',
            });
            dialogRef.afterClosed().subscribe((result) => {
                // TODO after update.
            });
        } else if (resource.type_id === ResourceTypes.GeneralCoursePlan) {
            isAdmin
                ? this.router.navigate(['/admin/content/courses/', resource.id])
                : this.router.navigate(['/home/manage/course-plan/', resource.id]);
        } else {
            isAdmin
                ? this.router.navigate([`/admin/content/${ResourceTypes.playerRoute(resource.type_id)}s/`, resource.id])
                : this.router.navigate([`/home/manage/${ResourceTypes.playerRoute(resource.type_id)}/`, resource.id]);
        }
    }

    public goToLink(url) {
        if (Utils.isUrl(url)) {
            window.open(url, '_blank');
        }
    }

    public styleInnerHTML(description: string) {
        if (description) {
            return '<div class="inner-HTML-course">' + description + '</div>';
        } else {
            return;
        }
    }

    togglePanel() {
        this.expansionPanel.toggle();
    }

    styleBullets(bullets) {
        return bullets.replace(/↵/g, '<br/>');
    }

    public showImgModal(id: number) {
        this.dialog.open(DialogDisplayImgComponent, {
            maxWidth: '60%',
            data: id,
        });
    }

    public download(resource: Resource) {
        this.downloadHelper.download(resource.downloads);
    }

    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }

    public startCoursePopUp(resource: Resource, user: User, item?: ResourceItem) {
        this.takeResource(resource, user, item);
        return;
        this.dialog
            .open(AlertDialogComponent, {
                disableClose: true,
                panelClass: 'confirm-wrapper',
                data: {
                    icon: 'play_circle',
                    title: $localize`Do you want to enroll in the ` + ResourceTypes.label(resource.type_id) + ' ?',
                    positiveBtn: $localize`:@@start:Start`,
                    negativeBtn: $localize`:@@cancel:Cancel`,
                },
            })
            .afterClosed()
            .pipe(
                takeUntil(this.destroy),
                filter((result) => !!result)
            )
            .subscribe(() => this.takeResource(resource, user, item));
    }

    public likeResource(resource: Resource) {
        resource.like = !resource.like;
        this.resourceService
            .update(
                {
                    id: resource.id,
                    like: resource.like,
                },
                true
            )
            .subscribe();
    }

    public favoriteResource(resource: Resource) {
        resource.favorite = !resource.favorite;
        this.resourceService
            .update(
                {
                    id: resource.id,
                    favorite: resource.favorite,
                },
                true
            )
            .subscribe();
    }

    public showTagResources(tag: ResourceTag) {
        this.showResources({ id: tag.id, label: tag.name, scope_id: AppScope.TAGS });
    }
    public showCatgeoryResources(category: ResourceCategory) {
        this.showResources({ id: category.id, label: category.title, scope_id: AppScope.CATEGORIES });
    }

    private showResources(item: GlobalSearchData) {
        this.bottomSheet.open(ResourceListingSheetComponent, {
            data: {
                id: item.id,
                label: item.label,
                scope_id: item.scope_id,
            },
            panelClass: 'sheet-wrapper',
        });
    }
    showManagers(managers: Educator[], resource: Resource) {
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.RESOURCE_MANAGERS,
            data: {
                title: resource.title,
                managers: managers,
            },
        });
    }
    registerCourse(res: Resource, user: User) {
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.COURSE_REGISTER,
            data:
                user.role_value === 'guest'
                    ? {
                          resource: res,
                      }
                    : {
                          resource: res,
                          user: user,
                      },
        });
    }
    downloadPractise() {}
}
