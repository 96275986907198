import { HttpClient } from '@angular/common/http';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Host,
    OnInit,
    ViewChild,
} from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { AuthService } from '@klickdata/core/auth';
import { ConfigService } from '@klickdata/core/config';
import { Media } from '@klickdata/core/media/src/media.model';
import { TagService } from '@klickdata/core/resource/src/tag/tag.service';
import { Utils } from '@klickdata/core/util';
import { VTQ, VTQService } from '@klickdata/core/vtq';
import moment from 'moment';
import { Observable, combineLatest, of } from 'rxjs';
import { debounceTime, filter, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { MaterialItemComposerDirective } from '../material-item-composer-directive';
import { MaterialsManagerCoreComponent } from '../materials-manager-core.component';

export interface YouTubeVideoData {
    title: string;
    duration: number;
}

@Component({
    selector: 'app-materials-manager-link',
    templateUrl: './materials-manager-link.component.html',
    styleUrls: ['./materials-manager-link.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialsManagerLinkComponent extends MaterialItemComposerDirective implements AfterViewInit {
    @ViewChild('link_input') linkInput: ElementRef;

    public youTubeVideoInfo$: Observable<YouTubeVideoData>;
    isLoadingVideoInfo: boolean;
    public media: Media;
    Utils = Utils;

    constructor(
        @Host() protected readonly host: MaterialsManagerCoreComponent,
        protected cdRef: ChangeDetectorRef,
        protected auth: AuthService,
        protected parentFormDirective: FormGroupDirective,
        protected vTQService: VTQService,
        protected config: ConfigService,
        protected http: HttpClient,
        protected tagsService: TagService
    ) {
        super(host, cdRef, parentFormDirective);
    }

    ngOnInit(): void {
        super.ngOnInit();
        if (this.resourceForm.value.id) {
            this.itemForm
                .get('name')
                .valueChanges.pipe(takeUntil(this.destroy), startWith(this.itemForm.value.name), debounceTime(300))
                .subscribe((link) => {
                    this.getLinkInfo(link);
                    if (link && !Utils.isEmpty(link) && (Utils.isYoutubeURL(link) || Utils.isVimeoURL(link))) {
                        this.vTQService.setActiveVTQ(
                            new VTQ({
                                material_type: 'link',
                                material_name: link,
                                language_id: this.itemForm.value.language_id,
                                material_id: this.resourceForm.value.id,
                            })
                        );
                    } else {
                        this.vTQService.setActiveVTQ(null);
                    }
                });
        }
    }
    ngAfterViewInit(): void {
        setTimeout(() => {
            this.linkInput?.nativeElement?.focus();
        }, 1000);
    }

    get mediaKey() {
        const link = this.itemForm.value.name;
        if (link && Utils.isVideoUrl(link)) {
            this.media = Utils.getMedia(link);
            return link;
        }
        this.media = null;
    }
    goToLink(url: string) {
        if (Utils.isUrl(url)) {
            window.open(url, '_blank');
        }
    }
    private getLinkInfo(link: string) {
        if (Utils.isYoutubeURL(link)) {
            this.isLoadingVideoInfo = true;
            const apiUrl = `https://www.googleapis.com/youtube/v3/videos?id=${Utils.getYoutubeMedia(link).src}&key=${
                this.config.config.YOUTUBE_DATA_API_KEY
            }&part=snippet,contentDetails`;
            this.youTubeVideoInfo$ = this.http.get<any>(apiUrl).pipe(
                map((res) => {
                    if (res?.items?.length) {
                        return res.items[0];
                    } else {
                        this.isLoadingVideoInfo = false;
                        return null;
                    }
                }),
                filter((res) => !!res),
                switchMap((item) => combineLatest([of(item), this.auth.getCustomer()])),
                switchMap(([item, customer]) =>
                    combineLatest([
                        of(item),
                        item.snippet?.tags && !!item.snippet?.tags?.length
                            ? this.tagsService
                                  .createManyTags(item.snippet?.tags, this.resourceForm.value.language_id, customer.id)
                                  .pipe(map((tags) => tags.map((tag) => tag.id)))
                            : of([]),
                    ])
                ),
                map(([item, tagIds]) => {
                    this.isLoadingVideoInfo = false;
                    (<FormGroup>this.resourceForm).patchValue({
                        title: Utils.isDefaultTitle(this.resourceForm.value.title)
                            ? item.snippet.title
                            : this.resourceForm.value.title,

                        bullets:
                            this.resourceForm.value.bullets ??
                            Utils.extractBullets(this.resourceForm.value.descriptionƒ),

                        description: this.resourceForm.value.description ?? item.snippet?.description,

                        media_id: this.resourceForm.value.media_id
                            ? this.resourceForm.value.media_id
                            : item.snippet?.thumbnails?.medium?.url,

                        tag_ids: !!this.resourceForm.value.tag_ids?.length ? this.resourceForm.value.tag_ids : tagIds,
                    });
                    return <YouTubeVideoData>{
                        title: item.snippet.title,
                        duration: moment.duration(item.contentDetails.duration).asSeconds(),
                    };
                })
            );
        } else {
            this.youTubeVideoInfo$ = of(null);
        }
    }
}
