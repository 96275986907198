import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ResourceDetailsBaseComponent } from '../resource-details-base/resource-details-base.component';
import { ResourceItem } from '@klickdata/core/resource-item';

@Component({
    selector: 'app-resource-details-course',
    templateUrl: './resource-details-course.component.html',
    styleUrls: ['./resource-details-course.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceDetailsCourseComponent extends ResourceDetailsBaseComponent {
    public showDates: boolean;
    constructor() {
        super();
    }
    checkIfResourceOfType(type) {
        if (type.indexOf('test') !== -1) {
            return 'test';
        } else if (type.indexOf('survey') !== -1) {
            return 'survey';
        } else if (type.indexOf('course') !== -1) {
            return 'course';
        } else if (type.indexOf('material') !== -1) {
            return 'material';
        } else if (type.indexOf('event') !== -1) {
            return 'event';
        }
    }
    public getPassingLevelLabel(passingLevel: any) {
        if (passingLevel.percentage > 0) {
            return $localize`Passing percentage: ${passingLevel.percentage} %`;
        } else {
            return $localize`No passing percentage`;
        }
    }
    public getRepeatsLabel(passingLevel: any) {
        if (passingLevel.repeats == 0) {
            return $localize`No retries allowed`;
        } else if (passingLevel.repeats > 0) {
            return passingLevel.user_repeats > 0
                ? $localize`Retry limits: ${passingLevel.user_repeats} / ${passingLevel.user_repeats}`
                : $localize`Retry limits: ${passingLevel.repeats}`;
        } else {
            return $localize`Infinte retries`;
        }
    }
}
