import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'messagesSignoffApproval',
})
export class MessagesSignoffApprovalPipe implements PipeTransform {
    transform(sigoff: { opportunity_id: number; approved: boolean }): { tooltip: string; color: string; icon: string } {
        if (sigoff.approved) {
            return { tooltip: $localize`Signoff Approved`, color: '#bfd8d0', icon: 'assignment_turned_in' };
        } else if (sigoff.approved == false) {
            return { tooltip: $localize`Signoff Rejected`, color: '#e44a66', icon: 'cancel' };
        } else {
            return { tooltip: $localize`Signoff iswaiting approval`, color: '#3e5365', icon: 'assignment_turned_in' };
        }
    }
}
