import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PlatformType } from '@klickdata/core/auth';
import { Group, GroupService } from '@klickdata/core/group';
import { LanguageService } from '@klickdata/core/localization';
import { MessageService } from '@klickdata/core/message';
import { AppScope } from '@klickdata/core/resource';
import { User } from '@klickdata/core/user';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';
import { Observable, Subject } from 'rxjs';

@Component({
    selector: 'app-menu-side-edit-group-right-column',
    templateUrl: './menu-side-edit-group-right-column.component.html',
    styleUrls: ['./menu-side-edit-group-right-column.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideEditGroupRightColumnComponent extends OnDestroyHandler implements OnInit {
    groupForm: FormGroup;
    @Input() public loading$: Observable<boolean>;
    @Input() group: Group;
    @Input() nk3Platform: PlatformType;
    @Input() authUser: User;
    public showMobExtraInfo: boolean;
    // public valuesChanged$: Observable<boolean>;
    AppScope = AppScope;
    public destroy: Subject<boolean> = new Subject<boolean>();

    constructor(
        @Optional() protected formDirective: FormGroupDirective,
        protected cd: ChangeDetectorRef,
        protected fb: FormBuilder,
        protected router: Router,
        protected groupService: GroupService,
        protected message: MessageService,
        protected langService: LanguageService,
        protected dialog: MatDialog
    ) {
        super();
        this.groupForm = this.fb.group({
            name: [''],
            sorting_code: [],
            expiration: [],
        });
    }

    ngOnInit(): void {
        // this.isMobile$ = this.mobile.isMobile();
    }
    onSubmit() {}
    delete(group: Group) {}
}
