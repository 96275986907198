import {
    AfterContentInit,
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AuthService, CanComponentDeactivate } from '@klickdata/core/auth';
import { MobileService } from '@klickdata/core/mobile';
import { Filter, GlobalFilterProperty, GlobalFilterPropertyType, SelectFilterOption } from '@klickdata/core/table';
import { User } from '@klickdata/core/user';
import { Utils } from '@klickdata/core/util';
import { SelectModelBaseDirective } from '../select-model-base/select-model-base.component';
import { UsersService } from './users.service';
import { Observable } from 'rxjs';
import { AppScope } from '@klickdata/core/resource';
import { Customer } from '@klickdata/core/customer';

@Component({
    selector: 'app-admin-users-select',
    templateUrl: './users-select.component.html',
    styleUrls: ['./users-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [UsersService],
})
export class UsersSelectComponent
    extends SelectModelBaseDirective<User>
    implements OnInit, AfterViewInit, CanComponentDeactivate, OnDestroy, AfterContentInit
{
    @Input() accessForm: FormGroup;
    @Input() referenceFilter: string;
    @Input() referenceIdKey = 'id';
    @Input() selectionLabel: string;
    @Input() customer_id: number;
    @Input() sync_all_key = 'sync_all_users';
    @Input() attachKey = 'users_attach';
    @Input() detachKey = 'users_detach';
    @Input() public columns = ['select', 'name', 'username', 'email'];
    @Input() assignId: number;
    @Input() filtersToBeApplied: Filter<string | number>[];
    @ContentChild('customTools') customTools: TemplateRef<any>;
    public itemSelectedOptions: SelectFilterOption[];
    GlobalFilterProperty = GlobalFilterProperty;
    GlobalFilterPropertyType = GlobalFilterPropertyType;
    public userStatusOptions: SelectFilterOption[];
    public userRoleOptions: SelectFilterOption[];
    authUser$: Observable<User>;
    AppScope = AppScope;
    public customer$: Observable<Customer>;

    constructor(
        protected datatableService: UsersService,
        protected mobileService: MobileService,
        protected auth: AuthService
    ) {
        super(datatableService, mobileService);
        this.itemSelectedOptions = Utils.getItemSelectedOptions();
        this.userRoleOptions = Utils.getUserRoleOptions();
        this.userStatusOptions = Utils.getUserStatusOptions();
        this.authUser$ = this.auth.getUser();
        this.customer$ = this.auth.getCustomer();
    }

    /**
     * @override
     * @param id
     */
    protected refresh(id?: any): void {
        const filters = [];
        if (this.referenceFilter) {
            filters.push({ property: this.referenceFilter, items: [id] });
        }
        if (this.customer_id) {
            filters.push({ property: 'customer', items: [this.customer_id] });
        }
        if (!!this.filtersToBeApplied?.length) {
            this.filtersToBeApplied
                .filter((fltr) => !!fltr.property && !!fltr.items?.length)
                .forEach((fltr) => filters.push(fltr));
        }
        this.filter.createOrUpdateFilter(filters);
    }

    detectIdKeyValueChange() {
        if (this.referenceFilter) {
            super.detectIdKeyValueChange();
        } else {
            this.refresh();
        }
    }
    ngAfterContentInit(): void {
        if (this.customTools) {
            this.columns.push('customTools');
        }
    }
}
