import { Injectable } from '@angular/core';
import { LoginData } from '@klickdata/core/auth/src/auth/login.model';
import { AuthService } from '@klickdata/core/auth/src/token/auth.service';
import { ConfigService } from '@klickdata/core/config/src/config.service';
import { HttpErrorService } from '@klickdata/core/http/src/error/http-error.service';
import { RequestBuilderService } from '@klickdata/core/http/src/request/request-builder.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class GuestService {
    private _drawerStatus: boolean;
    private _accessMode: 'login' | 'signup' = 'login';
    private drawerStatusSub: Subject<boolean> = new Subject<boolean>();
    private afterDismissedSubject = new Subject<any>();

    constructor(
        protected builder: RequestBuilderService,
        protected auth: AuthService,
        protected config: ConfigService,
        protected error: HttpErrorService
    ) {}
    public updateAccessMode(mode: 'login' | 'signup') {
        this._accessMode = mode;
    }
    public getAccessMode(): 'login' | 'signup' {
        return this._accessMode;
    }
    public updateDrawer(status: boolean) {
        this.drawerStatusSub.next(status);
    }
    getDrawerStatus(): Observable<boolean> {
        return this.drawerStatusSub.asObservable();
    }
    afterDismissed() {
        return this.afterDismissedSubject.asObservable();
    }

    dismiss(result?: any) {
        this.afterDismissedSubject.next(result);
    }
}
