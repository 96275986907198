import { Location } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivityLogService } from '@klickdata/core/activity-log';
import { AuthService } from '@klickdata/core/auth';
import { Customer } from '@klickdata/core/customer';
import { GloalSearchService } from '@klickdata/core/global-search';
import { LanguageService } from '@klickdata/core/localization';
import { MessageSavedComponent, MessageService } from '@klickdata/core/message';
import { MobileService } from '@klickdata/core/mobile';
import { QuoteService } from '@klickdata/core/quote';
import {
    Resource,
    ResourceCategory,
    ResourceCategoryService,
    ResourceService,
    ResourceTag,
    ResourceTypes,
    ResourceTypeService,
} from '@klickdata/core/resource';
import { ResourceItem, ResourceItemService } from '@klickdata/core/resource-item';
import { EventCheckKeys, EventJoin, StartEventService } from '@klickdata/core/resource/src/start-event.service';
import { StartResourceService } from '@klickdata/core/resource/src/start-resource.service';
import { TagService } from '@klickdata/core/resource/src/tag/tag.service';
import { User } from '@klickdata/core/user';
import { UserStatisticsService } from '@klickdata/home/src/core/user-statistics.service';
import { ResourceItemOpportunityService } from '@klickdata/player/src/core/resource-item/resource-item.service';
import { DownloadHelperService } from 'apps/klickdata/src/app/shared/dialog/download-pdf-dialog/download-helper.service';
import * as moment from 'moment';
import { Observable, shareReplay } from 'rxjs';
import { ShareService } from '../../share/share.service';
import { ResourceDetailsInfoComponent } from '../resource-details-info/resource-details-info.component';
import { GuestService } from '@klickdata/guest/src/guest.service';

@Component({
    selector: 'app-resource-details-event',
    templateUrl: './resource-details-event.component.html',
    styleUrls: ['./resource-details-event.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceDetailsEventComponent extends ResourceDetailsInfoComponent {
    @Input() resource: Resource;
    @Input() user: User;
    @Input() customer: Customer;
    @Input() resourceItems: ResourceItem[];
    @Input() resourceCategories$: Observable<ResourceCategory[]>;
    @Input() resourceTags$: Observable<ResourceTag[]>;
    @Input() resourceType: ResourceTypes;
    @ViewChild(ResourceDetailsInfoComponent) infoComp: ResourceDetailsInfoComponent;
    public availableToJoin: EventJoin = {
        status: 'allowed',
        label: $localize`Register`,
        icon: 'event_seat',
        checkType: EventCheckKeys.DATE,
    };
    ResourceTypes = ResourceTypes;
    public mainEventType: { title: string; icon: string; color: string };
    public subEventType: { title: string; icon: string; color: string };
    public moment = moment();

    constructor(
        protected startEventService: StartEventService,
        protected router: Router,
        protected location: Location,
        protected mobile: MobileService,
        protected route: ActivatedRoute,
        protected resourceService: ResourceService,
        protected resourceItemService: ResourceItemService,
        protected resourceTypeService: ResourceTypeService,
        protected userStatisticsService: UserStatisticsService,
        protected cd: ChangeDetectorRef,
        protected auth: AuthService,
        protected fb: FormBuilder,
        protected tagService: TagService,
        protected categoriesService: ResourceCategoryService,
        protected bottomSheet: MatBottomSheet,
        public shareService: ShareService,
        protected dialog: MatDialog,
        protected guestService: GuestService,
        protected downloadHelper: DownloadHelperService,
        protected itemService: ResourceItemOpportunityService,
        protected searchService: GloalSearchService,
        protected message: MessageService,
        protected elementRef: ElementRef,
        protected localizationService: LanguageService,
        protected quoteService: QuoteService,
        protected activityLogService: ActivityLogService,
        protected startResourceService: StartResourceService
    ) {
        super(
            router,
            location,
            mobile,
            route,
            resourceService,
            resourceItemService,
            resourceTypeService,
            userStatisticsService,
            cd,
            auth,
            fb,
            tagService,
            categoriesService,
            bottomSheet,
            shareService,
            dialog,
            downloadHelper,
            itemService,
            searchService,
            message,
            elementRef,
            guestService,
            localizationService,
            quoteService,
            activityLogService,
            startResourceService
        );
    }
    ngOnInit(): void {
        super.ngOnInit();
        this.mainEventType = ResourceTypes.getEventTypeByMainId(
            ResourceTypes.getEventTypes().find((type) => type.value == this.resource?.type_id)?.eventTypeId
        );

        this.subEventType = ResourceTypes.getEventTypeByTypeId(this.resource.type_id);
        this.cd.markForCheck();
    }
    public styleInnerHTML(description: string) {
        if (description) {
            return '<div class="inner-HTML-course">' + description + '</div>';
        } else {
            return;
        }
    }
    public getResourceAvailablity(resource: Resource): Observable<EventJoin> {
        return this.startEventService.checkEventJoin(resource).pipe(shareReplay());
    }
    joinEventDireclty() {
        this.startEventService.requestJoinEvent(this.resource, this.user).subscribe((res) => {
            this.checkCanJoiEvent();
            this.resource.opportunity_id = res.opportunity_id;
            this.cd.markForCheck();
        });
    }
    public checkCanJoiEvent(): boolean {
        if (!this.resource.occasion_dates?.length) {
            return false;
        }
        this.resource.occasion_dates = this.resource.occasion_dates.map((item) => {
            return {
                ...item,
                isValid: new Date(item.end_date) > new Date(),
            };
        });
        return this.resource.occasion_dates.length > 1;
    }
    public takeEvent(resource: Resource, user: User, eventJoin: EventJoin) {
        this.startEventService.takeEvent(resource, user, eventJoin).subscribe((res) => {
            switch (eventJoin.status) {
                case 'Payment_needed':
                case 'allowed':
                    this.resource.purchased = res.purchased;
                    this.resource.available_seats = res.available_seats;
                    this.message.openMessage(
                        MessageSavedComponent,
                        $localize`Congratulations, you have registered successfully.`
                    );
                case 'approval_required':
                    this.resource.enrollment = res.enrollment;
            }
            this.checkCanJoiEvent();
            this.resource.opportunity_id = res.opportunity_id;
            this.cd.markForCheck();
        });
    }
}
