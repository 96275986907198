import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Question, QuestionService, QuestionType } from '@klickdata/core/question';
import { ResourceItem } from '@klickdata/core/resource-item';
import { Observable, of } from 'rxjs';
import { ResourceBuilderService } from '../../resource-builder.service';

@Component({
    selector: 'app-resource-builder-question',
    templateUrl: './question.component.html',
    styleUrls: ['./question.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionComponent implements OnInit, OnChanges {
    @Input()
    public item: ResourceItem;

    @Input()
    public group: FormGroup;

    @Input()
    public usePoints = true;

    @Input()
    public editable: boolean;

    public question: Observable<Question>;

    constructor(protected builderService: ResourceBuilderService, protected questionService: QuestionService) {}

    ngOnInit() {
        if (this.item) {
            this.loadQuestion();
        }
    }

    ngOnChanges(): void {
        if (this.item) {
            this.loadQuestion();
        }
    }

    loadQuestion(): void {
        if (this.item.id) {
            this.question = this.questionService.getQuestionByItem(this.item.id);
        } else {
            this.question = of(new Question());
        }
    }
}
