<div *ngIf="navData" class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutAlign="start start" fxLayoutGap="5px">
        <mat-icon>exit_to_app</mat-icon>
        <div i18n>Leave course</div>
    </div>

    <div class="action" fxLayout="row-reverse" fxLayoutAlign="start center" fxLayoutGap="10px">
        <button
            fxFlex="0 0 auto"
            class="mobile-button positive pos-btn"
            (click)="
                onClose.emit({
                    data: {
                        type: navData.data.type,
                        response: TakeResActionValue.CONTINUE_LATER
                    }
                });
                showWarning = false
            "
            i18n
            mat-button
        >
            Continue later
        </button>
        <button
            fxFlex="0 0 auto"
            class="mobile-button positive neg-btn"
            (click)="
                onClose.emit({
                    data: {
                        type: navData.data.type,
                        response: TakeResActionValue.CANCEL
                    }
                });
                showWarning = false
            "
            i18n
            mat-button
        >
            Go back
        </button>
        <button
            *ngIf="!showWarning"
            fxFlex="0 0 auto"
            class="mobile-button positive null-btn"
            (click)="showWarning = true"
            i18n
            mat-button
        >
            Cancel course
        </button>
    </div>
    <div fxLayoutGap="20px" fxLayout="column" class="mt-1 order-last" *ngIf="navData.data.canSignOff">
        <ng-container [ngTemplateOutlet]="desc"></ng-container>
        <div *ngIf="requiredSignOff" class="input-field-parent" fxLayout="column" fxLayoutGap="5px">
            <mat-form-field cdkFocusInitial appearance="outline" floatLabel="never" class="w-100" fxFlex="grow">
                <textarea
                    #taskInput
                    matInput
                    type="text"
                    [formControl]="feedback"
                    placeholder="Write feedback to admin"
                    i18n-placeholder
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="4"
                    cdkAutosizeMaxRows="10"
                ></textarea>
            </mat-form-field>
        </div>
        <div class="action" fxLayout="row-reverse" fxLayoutAlign="start center" fxLayoutGap="10px">
            <button
                fxFlex="0 0 auto"
                class="mobile-button positive sign-btn"
                (click)="
                    onClose.emit({
                        data: {
                            type: navData.data.type,
                            response: TakeResActionValue.SIGNOFF,
                            signoff: requiredSignOff ? { body: feedback.value } : null
                        }
                    })
                "
                mat-button
                i18n
            >
                Signoff
            </button>
        </div>
    </div>
    <ng-container *ngIf="showWarning">
        <div class="desc mt-1">
            Are you sure you like to cancel the Course ? This means you will not get any statistics or award for your
            efforts.
        </div>
        <div class="action" fxLayout="row-reverse" fxLayoutAlign="start center" fxLayoutGap="10px">
            <button
                fxFlex="0 0 auto"
                class="mobile-button positive neg-btn"
                (click)="
                    onClose.emit({
                        data: { type: navData.data.type, response: TakeResActionValue.CANCEL_COURSE }
                    });
                    showWarning = false
                "
                i18n
                mat-button
            >
                Cancel course
            </button>
        </div>
    </ng-container>
</div>
<ng-template #desc>
    <div class="desc" *ngIf="requiredSignOff">
        <span i18n>
            Now you have completed all parts. You can signoff now and the admin will need to approve in order for you to
            download your PDF of
        </span>
        {{ (navData.data.resource | resourceCertificate).certificate }}.
        <span i18n> Check status in the Activity overview. You can send a message to the admin below. </span>
    </div>
    <div class="desc" *ngIf="!requiredSignOff">
        <span i18n="@@youCompletedAllPartsSignOffNow">
            Now you have completed all parts. You can sign off now and you will be able to download your PDF of
        </span>
        {{ (navData.data.resource | resourceCertificate).certificate }}.
    </div>
</ng-template>
