import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    Input,
    LOCALE_ID,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { FormArray } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Group } from '@klickdata/core/group';
import { MobileService } from '@klickdata/core/mobile';
import { Resource } from '@klickdata/core/resource';
import {
    Filter,
    GlobalFilterProperty,
    GlobalFilterPropertyType,
    SelectFilterOption,
    TableFilterComponent,
    TableSource,
} from '@klickdata/core/table';
import { Utils } from '@klickdata/core/util';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';
import { BehaviorSubject, shareReplay, takeUntil } from 'rxjs';
import { UserResourcesPermissionsHandlerComponent } from '../resource-access-control-sheet/user-resources-permissions-handler/user-resources-permissions-handler.component';
import { ResourceListingService } from './resource-listing.service';
import { AppScope } from '@klickdata/core/resource';
@Component({
    selector: 'app-group-resources-access-control',
    templateUrl: './group-resources-access-control.component.html',
    styleUrls: ['./group-resources-access-control.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ResourceListingService],
})
export class GroupResourcesAccessControlComponent extends OnDestroyHandler implements OnInit, AfterViewInit {
    @Input() group: Group;
    @Input() permissionsFormArray: FormArray;
    @Input() referenceKey = 'referenceGroup';
    @Input() permissionsKey = 'group_permissions';
    @Input() scope: AppScope;
    public resourcePermissions: {
        index: number;
        value: string;
        label: string;
        tooltip?: string;
        disableUser?: boolean;
    }[];
    @ViewChildren(UserResourcesPermissionsHandlerComponent)
    resourcePermHandlerchilldren: QueryList<UserResourcesPermissionsHandlerComponent>;
    public resourceTypeOptions: SelectFilterOption[];
    public dataSource = new TableSource<Resource>();
    public columns = ['select', 'title', 'permissions'];
    public isMobile: boolean;
    GlobalFilterProperty = GlobalFilterProperty;
    GlobalFilterPropertyType = GlobalFilterPropertyType;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(TableFilterComponent) filter: TableFilterComponent;
    public disableTable: BehaviorSubject<boolean> = new BehaviorSubject(false);
    constructor(
        protected mobile: MobileService,
        protected cdRef: ChangeDetectorRef,
        protected resourceListingService: ResourceListingService,
        @Inject(LOCALE_ID) public localeId: string
    ) {
        super();
        this.resourcePermissions = Utils.getResourcePermissions();
        this.resourceTypeOptions = Utils.getResourceTypeOptions();
    }

    ngOnInit(): void {
        this.mobile
            .isMobile()
            .pipe(takeUntil(this.destroy), shareReplay())
            .subscribe((mobile) => {
                this.isMobile = mobile;
                this.cdRef.markForCheck();
            });
        this.dataSource.service = this.resourceListingService;
    }
    public ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filter = this.filter;
        const filters = [
            new Filter(this.referenceKey, [this.group.id]),
            new Filter('customer', [this.group.customer_id]),
        ];
        if (this.scope) {
            filters.push(new Filter('scope', [this.scope]));
        }
        this.filter.createOrUpdateWithoutEmitFilter(filters);
    }
    selectToggle(resource: Resource, status: boolean) {
        this.resourcePermHandlerchilldren.forEach((child) => child.toggleAll(status, resource.id));
    }
    public refresh() {
        this.disableTable.next(true);
        this.dataSource.refresh();
        this.dataSource.latestData.pipe(takeUntil(this.destroy)).subscribe(() => {
            this.resourcePermHandlerchilldren.forEach((child) => child.ngOnInit());
            this.disableTable.next(false);
        });
    }
}
