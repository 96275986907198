import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AuthService } from '@klickdata/core/auth';
import { SideNaveActionsTypes, SideNaveData } from '@klickdata/core/mobile';
import { SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { User } from '@klickdata/core/user';
import { Observable, forkJoin, of, switchMap, take, takeUntil } from 'rxjs';
import { AddFormativeEvaluationComponent } from '../formative-evaluation/add-formative-evaluation/add-formative-evaluation.component';
import { ResourceOpportunityService } from '@klickdata/core/opportunity';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';
import { EditorTemplateService } from '../text-editor/editor-template.service';
import { EditorTemplate } from '../text-editor/editor-template.model';
import { MenuSideSignoffFeedbackComponent } from '../menu-side-signoff-feedback/menu-side-signoff-feedback.component';

@Component({
    selector: 'app-menu-side-validation-feedback',
    templateUrl: './menu-side-validation-feedback.component.html',
    styleUrls: ['./menu-side-validation-feedback.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideValidationFeedbackComponent extends MenuSideSignoffFeedbackComponent {
    constructor(
        protected auth: AuthService,
        protected resourceOpportunityService: ResourceOpportunityService,
        protected templateService: EditorTemplateService
    ) {
        super(auth, resourceOpportunityService, templateService);
    }

    submitEvaluation() {
        const evaluationFormValues = this.addFormEval.evaluationForm.value;
        const feedbackTemplateData = {
            title: this.addFormEval.feedbackTemplateForm.value.name,
            visibility: this.addFormEval.feedbackTemplateForm.value.visibility,
            subject: this.addFormEval.evaluationForm.value.title,
            body: this.addFormEval.evaluationForm.value.body,
            field_name: 'occasionFeedback',
        };
        const assessTemplateData = {
            title: this.addFormEval.assessmentTemplateForm.value.name,
            visibility: this.addFormEval.assessmentTemplateForm.value.visibility,
            criteria: this.addFormEval.evaluationForm.value.criteria,
        };
        assessTemplateData.criteria.forEach((cri) => delete cri.editable);

        const saveAssessTemplate$ =
            this.addFormEval.saveAssessTemplate && !!this.addFormEval.assessmentTemplateForm.value.name
                ? this.templateService.createAssessmentTemplate(new EditorTemplate(assessTemplateData))
                : of(null);

        const saveFeedbackTemplate$ =
            this.addFormEval.saveFeedbackTemplate && this.addFormEval.feedbackTemplateForm.value.name
                ? this.templateService.create(new EditorTemplate(feedbackTemplateData))
                : of(null);

        if (!!evaluationFormValues?.criteria?.length) {
            evaluationFormValues?.criteria.forEach((cri) => delete cri.editable);
        }
        forkJoin([saveAssessTemplate$, saveFeedbackTemplate$])
            .pipe(
                switchMap(() =>
                    this.resourceOpportunityService.update(
                        {
                            id: this.navData.data.occasion.id,
                            evaluation: { ...evaluationFormValues },
                        },
                        ['resource', 'user']
                    )
                ),
                takeUntil(this.destroy)
            )
            .subscribe((oppo) =>
                this.onClose.emit({
                    data: {
                        oppo: oppo,
                        type: this.navData.data.type,
                    },
                })
            );
    }
}
