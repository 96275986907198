<div fxFlex="100" fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="1em">
    <div class="img-wrapper mt-1" fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="15px">
        <div class="group-name" fxFlex="1 1 100%" fxLayout="column" fxLayoutAlign="center center" fxFill>
            <div class="group-img">{{ groupForm.value.name | nameSign }}</div>
        </div>
    </div>
    <app-user-setting-input
        type="text"
        i18n-placeholder
        placeholder="Enter group name"
        [control]="groupForm.get('name') | cast"
    >
        <label role="input-label" i18n="@@groupName">Group Name*</label>
    </app-user-setting-input>
    <app-user-setting-input
        type="number"
        i18n-placeholder
        placeholder="Enter group sorting code"
        [control]="groupForm.get('sorting_code') | cast"
    >
        <label role="input-label" i18n="@@sortingCode">Sorting code</label>
    </app-user-setting-input>
    <div fxLayoutGap="4px" fxLayout="column" class="w-100 input-field-parent deadline">
        <span i18n>Deadline</span>
        <mat-form-field
            appearance="outline"
            floatLabel="never"
            class="w-100"
            fxFlex="grow"
            fxFlex="1 1 100%"
            fxFlex.lt-md="1 1 100%"
        >
            <mat-label i18n>Enter expiration date</mat-label>
            <input
                matInput
                [matDatepicker]="deadlineDatepicker"
                (focus)="deadlineDatepicker.open()"
                formControlName="expiration"
            />
            <mat-datepicker-toggle matIconSuffix [for]="deadlineDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #deadlineDatepicker></mat-datepicker>
        </mat-form-field>
    </div>
    <div *ngIf="group.id" class="info-created mt-1">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
            <span class="info-title" i18n>Group Created:</span>
            <span class="info-data">{{ group.created_at | kdDateFormat : 'YYYY-MM-DD HH:mm' }}</span>
        </div>
        <div fxLayout="row" *ngIf="group.created_by" fxLayoutAlign="start center" fxLayoutGap="4px">
            <span class="info-title" i18n>Added By:</span>
            <span class="info-data">{{ (group.creator | async)?.name }}</span>
        </div>
    </div>

    <div *ngIf="group && group.id" fxFlex="grow" fxLayoutAlign="end center">
        <button class="tools-btn" mat-icon-button (click)="delete(group)" matTooltip="Delete group" i18n-matTooltip>
            <mat-icon class="material-icons-outlined">delete</mat-icon>
        </button>
    </div>
</div>
