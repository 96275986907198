import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { GroupsSelectComponent } from '../groups-select/groups-select.component';
import { MobileService } from '@klickdata/core/mobile';
import { Utils } from '@klickdata/core/util';
import { GroupsService } from '../groups-select/groups.service';
import { Group } from '@klickdata/core/group';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Customer } from '@klickdata/core/customer';
import { Observable } from 'rxjs';
import { AuthService } from '@klickdata/core/auth';

@Component({
    selector: 'app-groups-select-with-stations',
    templateUrl: './groups-select-with-stations.component.html',
    styleUrls: ['./groups-select-with-stations.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class GroupsSelectWithStationsComponent extends GroupsSelectComponent {
    public columns = ['select', 'name', 'team_groups_count', 'created_at', 'updated_at', 'team_users_count', 'tools'];
    public authCustomer$: Observable<Customer>;
    expandedElement: Group | null;
    constructor(protected groupsService: GroupsService, protected mobile: MobileService, private auth: AuthService) {
        super(groupsService, mobile);
        this.itemSelectedOptions = Utils.getItemSelectedOptions();
        this.authCustomer$ = this.auth.getCustomer();
    }
}
