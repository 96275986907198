import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ResourceOpportunity } from '@klickdata/core/opportunity';
import { Resource } from '@klickdata/core/resource';
import { User } from '@klickdata/core/user';

@Component({
    selector: 'app-course-player-results-result',
    templateUrl: './course-player-results-result.component.html',
    styleUrls: ['./course-player-results-result.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoursePlayerResultsResultComponent {
    @Input() opportunity: ResourceOpportunity;
    @Input() user: User;
    @Input() resource: Resource;
    @Input() rewardType: 'Participation' | 'Diploma' | 'Certification' = 'Participation';
    @Output() repeatCourse: EventEmitter<boolean> = new EventEmitter<boolean>();
    constructor() {}
}
