<div class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutGap="5px" fxLayout="row wrap" fxLayoutAlign="start center">
        <mat-icon>add_circle_outline</mat-icon><span i18n>Add to course</span>
    </div>
    <mat-accordion class="mt-1" #accordion="matAccordion">
        <mat-expansion-panel>
            <mat-expansion-panel-header class="main">
                <mat-panel-title>
                    <mat-icon
                        matListIcon
                        class="header-icon kd-icon-36"
                        fontSet="klickdata"
                        fontIcon="kd-icon-document"
                    ></mat-icon>
                    <span i18n>Add Material</span>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <div
                    *ngIf="canCreateItem"
                    (click)="onSelect(ResourceBuildingItems.CREATE_NEW_LINK_MATERIAL, AppScope.MATERIAL)"
                    class="tool-button-inner"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxLayoutGap="5px"
                >
                    <mat-icon>add_link</mat-icon>
                    <span fxFlex="grow" class="tool-button-text" i18n>Link</span>
                    <mat-icon class="qs-m" matTooltip="Add link and use it directly" i18n-matTooltip
                        >question_mark</mat-icon
                    >
                </div>
                <div
                    *ngIf="canCreateItem"
                    (click)="onSelect(ResourceBuildingItems.CREATE_NEW_FILE_MATERIAL, AppScope.MATERIAL)"
                    class="tool-button-inner"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxLayoutGap="5px"
                >
                    <mat-icon>cloud_upload</mat-icon>
                    <span fxFlex="grow" class="tool-button-text" i18n>File</span>
                    <mat-icon class="qs-m" matTooltip="Upload file and use it directly" i18n-matTooltip
                        >question_mark</mat-icon
                    >
                </div>
                <div
                    *ngIf="canCreateItem"
                    (click)="onSelect(ResourceBuildingItems.CREATE_NEW_TEXT_MATERIAL, AppScope.MATERIAL)"
                    class="tool-button-inner"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxLayoutGap="5px"
                >
                    <mat-icon>post_add</mat-icon>
                    <span fxFlex="grow" class="tool-button-text" i18n>Text</span>
                    <mat-icon class="qs-m" matTooltip="Add text as material and use it directly" i18n-matTooltip
                        >question_mark</mat-icon
                    >
                </div>
                <div
                    (click)="onSelect(ResourceBuildingItems.MATERIAL_LIBRARY, AppScope.MATERIAL)"
                    class="tool-button-inner"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxLayoutGap="5px"
                >
                    <mat-icon>library_books</mat-icon>
                    <span fxFlex="grow" class="tool-button-text" i18n>Library</span>
                    <mat-icon class="qs-m" matTooltip="All the tests that exist in KOL" i18n-matTooltip
                        >question_mark</mat-icon
                    >
                </div>
                <div
                    *ngIf="canCreateItem"
                    (click)="onSelect(ResourceBuildingItems.CREATE_AI_PROMPTER_MATERIAL, AppScope.MATERIAL)"
                    class="tool-button-inner ai"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxLayoutGap="5px"
                >
                    <mat-icon>smart_toy</mat-icon>
                    <span fxFlex="grow" class="tool-button-text" i18n>AI Prompter</span>
                    <mat-icon class="qs-m" matTooltip="Create AI Prompter" i18n-matTooltip>question_mark</mat-icon>
                </div>
            </ng-template>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header class="main">
                <mat-panel-title>
                    <mat-icon
                        matListIcon
                        fontSet="klickdata"
                        class="header-icon kd-icon-36"
                        fontIcon="kd-icon-test"
                    ></mat-icon>
                    <span i18n>Add Test</span>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <div
                    *ngIf="canCreateItem"
                    (click)="onSelect(ResourceBuildingItems.CREATE_NEW_TEST, AppScope.TEST)"
                    class="tool-button-inner"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxLayoutGap="5px"
                >
                    <mat-icon>edit_document</mat-icon>
                    <span fxFlex="grow" class="tool-button-text" i18n>Create a new test</span>
                    <mat-icon class="qs-m" matTooltip="Create a new test and use it directly" i18n-matTooltip
                        >question_mark</mat-icon
                    >
                </div>
                <div
                    (click)="onSelect(ResourceBuildingItems.TEST_LIBRARY, AppScope.TEST)"
                    class="tool-button-inner"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxLayoutGap="5px"
                >
                    <mat-icon>library_books</mat-icon>
                    <span fxFlex="grow" class="tool-button-text" i18n>Library</span>
                    <mat-icon class="qs-m" matTooltip="All the tests that exist in KOL" i18n-matTooltip
                        >question_mark</mat-icon
                    >
                </div>
                <div
                    *ngIf="canCreateItem"
                    (click)="onSelect(ResourceBuildingItems.CREATE_NEW_TEST_WITH_AI, AppScope.TEST)"
                    class="tool-button-inner ai"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxLayoutGap="5px"
                >
                    <mat-icon>smart_toy</mat-icon>
                    <span fxFlex="grow" class="tool-button-text" i18n>Create a new test with AI</span>
                    <mat-icon
                        class="qs-m"
                        matTooltip="Create a new test with AI prompt and use it directly"
                        i18n-matTooltip
                        >question_mark</mat-icon
                    >
                </div>
            </ng-template>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header class="main">
                <mat-panel-title>
                    <mat-icon
                        matListIcon
                        fontSet="klickdata"
                        class="header-icon kd-icon-36"
                        fontIcon="kd-icon-survey"
                    ></mat-icon>
                    <span i18n>Add Survey</span>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <div
                    *ngIf="canCreateItem"
                    (click)="onSelect(ResourceBuildingItems.CREATE_NEW_SURVEY, AppScope.SURVEY)"
                    class="tool-button-inner"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxLayoutGap="5px"
                >
                    <mat-icon>edit_document</mat-icon>
                    <span fxFlex="grow" class="tool-button-text" i18n>Create a new survey</span>
                    <mat-icon class="qs-m" matTooltip="Create a new survey and use it directly" i18n-matTooltip
                        >question_mark</mat-icon
                    >
                </div>
                <div
                    (click)="onSelect(ResourceBuildingItems.SURVEY_LIBRARY, AppScope.SURVEY)"
                    class="tool-button-inner"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxLayoutGap="5px"
                >
                    <mat-icon>library_books</mat-icon>
                    <span fxFlex="grow" class="tool-button-text" i18n>Library</span>
                    <mat-icon class="qs-m" matTooltip="All the surveys that exist in KOL" i18n-matTooltip
                        >question_mark</mat-icon
                    >
                </div>
            </ng-template>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header class="main">
                <mat-panel-title>
                    <mat-icon matListIcon class="header-icon kd-icon-36">event</mat-icon>
                    <span i18n>Add Event</span>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <div
                    *ngIf="canCreateItem"
                    (click)="onSelect(ResourceBuildingItems.CREATE_NEW_EVENT, AppScope.EVENT)"
                    class="tool-button-inner"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxLayoutGap="5px"
                >
                    <mat-icon>edit_document</mat-icon>
                    <span fxFlex="grow" class="tool-button-text" i18n>Create a new event</span>
                    <mat-icon class="qs-m" matTooltip="Create a new event and use it directly" i18n-matTooltip
                        >question_mark</mat-icon
                    >
                </div>
                <div
                    (click)="onSelect(ResourceBuildingItems.EVENT_LIBRARY, AppScope.EVENT)"
                    class="tool-button-inner"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxLayoutGap="5px"
                >
                    <mat-icon>library_books</mat-icon>
                    <span fxFlex="grow" class="tool-button-text" i18n>Library</span>
                    <mat-icon class="qs-m" matTooltip="All the events that exist in KOL" i18n-matTooltip
                        >question_mark</mat-icon
                    >
                </div>
            </ng-template>
        </mat-expansion-panel>
    </mat-accordion>
</div>
