<div fxLayout="column" class="w-100" fxLayoutGap="1em" *ngIf="testOccasions$ | async; let occasions">
    <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="5px">
            <div fxLayout="row" class="title-wrap" fxLayoutAlign="start center" fxLayoutGap="5px">
                <span i18n>Your results on</span>
                <span>{{ test.title }}</span>
            </div>
            <div class="grey">{{ getTestTooltip() }}</div>
        </div>
        <div class="actions-wrap" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
            <button
                [matTooltip]="getRemainigRepeats()"
                *ngIf="showRepeatBtn"
                class="repeat"
                mat-raised-button
                type="button"
                [ngClass]="{ lastRepeat: isLastRepeat }"
                i18n
                (click)="repeat()"
            >
                Repeat
            </button>
            <button
                *ngIf="showNextBtn"
                class="next"
                color="primary"
                i18n
                mat-raised-button
                type="button"
                (click)="done()"
            >
                Next
            </button>
        </div>
    </div>
    <ng-container
        *ngIf="occasions.length == 1"
        [ngTemplateOutlet]="results"
        [ngTemplateOutletContext]="{ occ: occasions[0] }"
    ></ng-container>
    <ng-container *ngIf="occasions.length > 1">
        <mat-accordion [multi]="true" #accordion="matAccordion">
            <mat-expansion-panel *ngFor="let occasion of occasions" [expanded]="false">
                <mat-expansion-panel-header class="main">
                    <div class="w-100 p-y-1 pl-0" fxLayout="row" fxLayoutAlign="space-between center">
                        <div fxLayout="row" class="title-wrap f-s-15" fxLayoutAlign="start center" fxLayoutGap="5px">
                            <span i18n>Completed on:</span>
                            <span>{{ occasion.done | kdDateFormat : 'YYYY-MM-DD HH:mm' }}</span>
                            <span
                                >(<span
                                    [class.red]="!occasion.passed"
                                    [class.perfect]="occasion.passed && perfectScore(occasion.user_percentage)"
                                    [class.green]="occasion.passed && !perfectScore(occasion.user_percentage)"
                                    >{{ roundPercentage(occasion.user_percentage) }}% </span
                                ><span i18n>Correct</span>)</span
                            >
                        </div>
                        <div
                            fxLayout="row"
                            fxLayoutAlign="start center"
                            fxLayoutGap="5px"
                            class="perfect"
                            *ngIf="occasion.passed && perfectScore(occasion.user_percentage)"
                            matTooltip="Perfect"
                            i18n-matTooltip
                        >
                            <span i18n>Excellent</span>
                            <mat-icon>star</mat-icon>
                        </div>
                        <div
                            fxLayout="row"
                            fxLayoutAlign="start center"
                            fxLayoutGap="5px"
                            class="passed"
                            *ngIf="occasion.passed && !perfectScore(occasion.user_percentage)"
                            matTooltip="Passed"
                            i18n-matTooltip
                        >
                            <span i18n>Passed</span>
                            <mat-icon>verified</mat-icon>
                        </div>
                        <div
                            fxLayout="row"
                            fxLayoutAlign="start center"
                            fxLayoutGap="5px"
                            class="failed"
                            *ngIf="!occasion.passed"
                            matTooltip="Not Passed"
                            i18n-matTooltip
                        >
                            <!-- <span i18n>Not Passed</span> -->
                            <mat-icon>dangerous</mat-icon>
                        </div>
                    </div>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <ng-container
                        *ngIf="occasions.length > 1"
                        [ngTemplateOutlet]="results"
                        [ngTemplateOutletContext]="{ occ: occasion }"
                    ></ng-container>
                </ng-template>
            </mat-expansion-panel>
        </mat-accordion>
    </ng-container>
</div>
<ng-template let-occ="occ" #results>
    <app-general-test-results
        [user]="user"
        [isCourseItem]="true"
        [resource]="test"
        [occasion]="occ"
        (close)="close.emit($event)"
        class="true"
    ></app-general-test-results>
</ng-template>
