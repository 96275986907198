import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@klickdata/core/auth';
import { ConfigService } from '@klickdata/core/config';
import { RequestBuilderService } from '@klickdata/core/http';
import { LanguageService } from '@klickdata/core/localization';
import { Resource } from '@klickdata/core/resource';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { first } from 'rxjs/operators';
import { ShareDialogComponent } from './share-dialog/share-dialog.component';

@Injectable()
export class ShareService {
    authUrl: string;
    constructor(
        protected builder: RequestBuilderService,
        protected config: ConfigService,
        protected auth: AuthService,
        protected dialog: MatDialog,
        protected languageService: LanguageService
    ) {
        this.authUrl = `${this.config.config.apiUrl}auth`;
    }

    public getSharableLink(resource: Resource) {
        /**
         * In issue #1872 EB asked
         * Share: Share shall share CD, not the AU link to started Course.
         * He asked this to be overall share btn
         */
        // return this.auth.getUser().pipe(
        //     switchMap(user =>
        //         this.builder
        //             .post<any>(`${this.authUrl}/sso/checkuser`, {
        //                 email:
        //                     user.email.substring(0, user.email.indexOf('@')) +
        //                     '+' +
        //                     (resource.article_code || resource.id) +
        //                     user.email.substring(user.email.indexOf('@')),
        //                 fname: user.fname,
        //                 lname: user.lname,
        //                 customer_id: 28, // Demo account
        //                 course_id: resource.id,
        //             })
        //             .request()
        //     ),
        //     map(res => res.data.access_link)
        // );
        return of(
            `${this.config.config.demoAccountBaseURL}${this.languageService
                .getCurrentLanguage()
                .value.split('-')[0]
                .toLowerCase()}/guest/resource-details/${resource.id}`
        );
    }

    openShareDialog(resource: Resource, shareLoading: BehaviorSubject<boolean>): void {
        shareLoading.next(true);
        combineLatest([this.getSharableLink(resource), resource.media$])
            .pipe(first())
            .subscribe(([link, media]) => {
                this.dialog.open(ShareDialogComponent, {
                    maxWidth: '450px',
                    backdropClass: 'backdropBackground',
                    panelClass: 'login-dialog-wrapper',
                    data: { ...resource, url: link, image: media.url },
                });
                shareLoading.next(false);
            });
    }
}
