<h1 mat-dialog-title class="title" i18n="@@share">Share</h1>
<div mat-dialog-content>
    <share-buttons
        theme="circles-dark"
        [showText]="true"
        [include]="
            data.include ??
            [
                'facebook',
                'twitter',
                'linkedin',
                'email',
                'copy',
                'pinterest',
                'reddit',
                'tumblr',
                'mix',
                'viber',
                'vk',
                'telegram',
                'messenger',
                'whatsapp',
                'xing',
                'line',
                'sms',
                'print'
            ]
        "
        [show]="data.show ?? 5"
        [url]="data.url"
        [title]="data.title"
        [description]="data.description"
        [image]="data.image"
    ></share-buttons>
</div>
