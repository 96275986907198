import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { AuthModule } from '@klickdata/core/auth';
import { FormModule } from '@klickdata/core/form';
import { MediaModule } from '@klickdata/core/media';
import { TableModule } from '@klickdata/core/table';
import { UserModule } from '@klickdata/core/user';
import { KdLoaderModule } from '@klickdata/kd-loader';
import { MaterialModule } from '@klickdata/material';
import { SharedComponentsModule } from '@klickdata/shared-components';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FileUploadModule } from 'ng2-file-upload';
import { MarkdownModule } from 'ngx-markdown';
import { MomentModule } from 'ngx-moment';
import { CoreModule } from '../core/core.module';
import { AccountsResourceAssignComponent } from './account/accounts-resource-assign/accounts-resource-assign.component';
import { AccountsResourceRecommendComponent } from './account/accounts-resource-recommend/accounts-resource-recommend.component';
import { GroupAdminChipSelectComponent } from './account/group-chip-select/group-chip-select.component';
import { GroupListComponent } from './account/group-list/group-list.component';
import { GroupResourcesAssignComponent } from './account/group-resources-assign/group-resources-assign.component';
import { GroupSettingsDialogComponent } from './account/group-settings-dialog/group-settings-dialog.component';
import { GroupSettingsFormComponent } from './account/group-settings-form/group-settings-form.component';
import { TeamsListComponent } from './account/teams-list/teams-list.component';
import { UserGroupsComponent } from './account/user-groups/user-groups.component';
import { UserListComponent } from './account/user-list/user-list.component';
import { UserSettingsDialogComponent } from './account/user-settings-dialog/user-settings-dialog.component';
import { UserSettingsComponent } from './account/user-settings/user-settings.component';
import { AlertSuccessComponent } from './alert/success/alert-success.component';
import { CardAvatarComponent } from './card-avatar/card-avatar.component';
import { CategoryChildsListingSheetComponent } from './category/category-childs-listing-sheet/category-childs-listing-sheet.component';
import { CategoryControlComponent } from './category/category-control/category-control.component';
import { CategoryCreateDialogComponent } from './category/category-create-dialog/category-create-dialog.component';
import { CategoryCreateLightComponent } from './category/category-create-light/category-create-light.component';
import { CategoryCreateSheetComponent } from './category/category-create-sheet/category-create-sheet.component';
import { CategoryDetailComponent } from './category/category-detail/category-detail.component';
import { CategoryLightControlComponent } from './category/category-light-control/category-light-control.component';
import { CategoryListComponent } from './category/category-list/category-list.component';
import { CategoryRootControlComponent } from './category/category-root-control/category-root-control.component';
import { ChangelogComponent } from './changelog/changelog.component';
import { ColorPickerBodyComponent } from './color-picker/color-picker-body.component';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { SelectedColorComponent } from './color-picker/selected-color.component';
import { DatatableColumnComponent } from './datatable/datatable-column/datatable-column.component';
import { DatatableLinkDirective } from './datatable/datatable-toolbar-link.directive';
import { DatatableComponent } from './datatable/datatable.component';
import { DatatableOptionsComponent } from './datatable/options/datatable-options.component';
import { DatatableSearchComponent } from './datatable/search/datatable-search.component';
import { DefaultImgItemComponent } from './default-img/default-img-item/default-img-item.component';
import { DefaultImgComponent } from './default-img/default-img.component';
import { DialogDiscardChangesComponent } from './dialog/dialog-discard-changes/dialog-discard-changes.component';
import { DialogDisplayImgComponent } from './dialog/dialog-display-img/dialog-display-img.component';
import { DialogPaytabsPaymentPageComponent } from './dialog/dialog-paytabs-payment-page/dialog-paytabs-payment-page.component';
import { DialogResponseUsersComponent } from './dialog/dialog-response-users/dialog-response-users.component';
import { DialogSentResultComponent } from './dialog/dialog-sent-result/dialog-sent-result.component';
import { DownloadPdfDialogComponent } from './dialog/download-pdf-dialog/download-pdf-dialog.component';
import { InnerHtmlDisplayDialogComponent } from './dialog/inner-html-display-dialog/inner-html-display-dialog.component';
import { EmailComponent } from './email/email.component';
import { WelcomeMessageDialogComponent } from './email/welcome-message-dialog/welcome-message-dialog.component';
import { WelcomeMessageComponent } from './email/welcome-message/welcome-message.component';
import { FlashSavedComponent } from './flash/flash-saved/flash-saved.component';
import { FlashSavingComponent } from './flash/flash-saving/flash-saving.component';
import { GeneralSettingSwitchComponent } from './general-setting-switch/general-setting-switch.component';
import { AcademySettingsComponent } from './general-settings-management/academy-settings/academy-settings.component';
import { AuthorSettingsComponent } from './general-settings-management/author-settings/author-settings.component';
import { GeneralSettingsManagementComponent } from './general-settings-management/general-settings-management.component';
import { MainSettingsComponent } from './general-settings-management/main-settings/main-settings.component';
import { PaymentOptionsSettingsComponent } from './general-settings-management/payment-options-settings/payment-options-settings.component';
import { RoleItemComponent } from './general-settings-management/roles-settings/role-item/role-item.component';
import { AccessPermissionRowComponent } from './general-settings-management/roles-settings/roles-access-tab/roles-access-row/access-permission-row/access-permission-row.component';
import { RolesAccessRowComponent } from './general-settings-management/roles-settings/roles-access-tab/roles-access-row/roles-access-row.component';
import { RolesAccessTabComponent } from './general-settings-management/roles-settings/roles-access-tab/roles-access-tab.component';
import { FunctionPolicyRowComponent } from './general-settings-management/roles-settings/roles-function-tab/roles-function-row/function-policy-row/function-policy-row.component';
import { RolesFunctionRowComponent } from './general-settings-management/roles-settings/roles-function-tab/roles-function-row/roles-function-row.component';
import { RolesFunctionTabComponent } from './general-settings-management/roles-settings/roles-function-tab/roles-function-tab.component';
import { RolesSettingsComponent } from './general-settings-management/roles-settings/roles-settings.component';
import { TriggersSettingsComponent } from './general-settings-management/triggers-settings/triggers-settings.component';
import { GradeSystemDetailComponent } from './grade-system/grade-system-detail/grade-system-detail.component';
import { GradeSystemDialogComponent } from './grade-system/grade-system-dialog/grade-system-dialog.component';
import { HrNotesCommentsComponent } from './hr-notes/hr-notes-comments/hr-notes-comments.component';
import { HrNotesComponent } from './hr-notes/hr-notes.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PipesModule } from './pipes/pipes.module';
import { PowerbiReportComponent } from './powerbi/powerbi-report/powerbi-report.component';
import { CollectionItemsListViewComponent } from './section-collections/collection-items-list-view/collection-items-list-view.component';
import { SectionCollectionListItemComponent } from './section-collections/section-collection-list-item/section-collection-list-item.component';
import { SectionCollectionListComponent } from './section-collections/section-collection-list/section-collection-list.component';
import { SectionCollectionSettingsDialogComponent } from './section-collections/section-collection-settings-dialog/section-collection-settings-dialog.component';
import { SectionCollectionsComponent } from './section-collections/section-collections.component';
import { StepActionsDirective } from './stepper/step-actions.directive';
import { StepBodyComponent } from './stepper/step-body/step-body.component';
import { StepTitleComponent } from './stepper/step-title/step-title.component';
import { StepComponent } from './stepper/step.component';
import { StepperComponent } from './stepper/stepper.component';
import { StickyDirective } from './sticky/sticky.directive';
import { TagCreateComponent } from './tag/tag-create/tag-create.component';
import { TagListingComponent } from './tag/tag-listing/tag-listing.component';
import { UnauthorisedComponent } from './unauthorised/unauthorised.component';
import { DialogTokenExpirationCountdownComponent } from './dialog/dialog-token-expiration-countdown/dialog-token-expiration-countdown.component';
import { HumanResourcesSettingsComponent } from './general-settings-management/human-resources-settings/human-resources-settings.component';
import { TeamExpandDisplayGroupsComponent } from './account/teams-list/team-expand-display-groups/team-expand-display-groups.component';
import { MainSettingsDiplomaComponent } from './general-settings-management/main-settings/main-settings-diploma/main-settings-diploma.component';
import { ExtraImagesUploaderComponent } from './default-img/extra-images-uploader/extra-images-uploader.component';

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        RouterModule,
        MomentModule,
        MaterialModule,
        PortalModule,
        NgxDatatableModule,
        CoreModule,
        AuthModule,
        UserModule,
        SharedComponentsModule,
        FormModule,
        MomentModule,
        FlexLayoutModule,
        PipesModule,
        FileUploadModule,
        MediaModule,
        TableModule,
        MarkdownModule.forRoot(),
        KdLoaderModule,
        // AccountsManagerModule,
    ],
    declarations: [
        PageNotFoundComponent,
        DatatableSearchComponent,
        DatatableOptionsComponent,
        AlertSuccessComponent,
        DialogSentResultComponent,
        DialogDiscardChangesComponent,
        UnauthorisedComponent,
        StepComponent,
        StepperComponent,
        StepTitleComponent,
        StepBodyComponent,
        StepActionsDirective,
        DatatableComponent,
        DatatableColumnComponent,
        DatatableLinkDirective,
        FlashSavingComponent,
        FlashSavedComponent,
        StickyDirective,
        ColorPickerComponent,
        ColorPickerBodyComponent,
        SelectedColorComponent,
        CardAvatarComponent,
        DialogResponseUsersComponent,
        ChangelogComponent,
        WelcomeMessageComponent,
        EmailComponent,
        UserListComponent,
        UserGroupsComponent,
        UserSettingsComponent,
        AccountsResourceAssignComponent,
        AccountsResourceRecommendComponent,
        GroupSettingsFormComponent,
        GroupListComponent,
        UserSettingsDialogComponent,
        GroupSettingsDialogComponent,
        WelcomeMessageDialogComponent,
        GroupResourcesAssignComponent,

        CategoryListComponent,
        CategoryControlComponent,
        CategoryDetailComponent,
        CategoryCreateDialogComponent,
        CategoryRootControlComponent,
        GradeSystemDialogComponent,
        GradeSystemDetailComponent,
        CategoryLightControlComponent,
        CategoryCreateLightComponent,
        CategoryCreateSheetComponent,
        DialogDisplayImgComponent,
        TagListingComponent,
        TagCreateComponent,
        PowerbiReportComponent,
        GroupAdminChipSelectComponent,
        DownloadPdfDialogComponent,
        InnerHtmlDisplayDialogComponent,
        GeneralSettingSwitchComponent,
        CategoryChildsListingSheetComponent,
        HrNotesComponent,
        HrNotesCommentsComponent,
        SectionCollectionsComponent,
        SectionCollectionListComponent,
        SectionCollectionListItemComponent,
        SectionCollectionSettingsDialogComponent,
        CollectionItemsListViewComponent,
        DialogPaytabsPaymentPageComponent,
        GeneralSettingsManagementComponent,
        TriggersSettingsComponent,
        PaymentOptionsSettingsComponent,
        AuthorSettingsComponent,
        RolesSettingsComponent,
        MainSettingsComponent,
        AcademySettingsComponent,
        RoleItemComponent,
        RolesAccessTabComponent,
        RolesFunctionTabComponent,
        RolesAccessRowComponent,
        RolesFunctionRowComponent,
        FunctionPolicyRowComponent,
        AccessPermissionRowComponent,
        DefaultImgItemComponent,
        DefaultImgComponent,
        TeamsListComponent,
        DialogTokenExpirationCountdownComponent,
        HumanResourcesSettingsComponent,
        TeamExpandDisplayGroupsComponent,
        MainSettingsDiplomaComponent,
        ExtraImagesUploaderComponent,
    ],
    exports: [
        // Export dependencies
        MatIconModule,
        MatToolbarModule,
        MatButtonModule,
        NgxDatatableModule,
        MomentModule,
        SharedComponentsModule,

        // Exports
        PageNotFoundComponent,
        DatatableSearchComponent,
        DatatableOptionsComponent,
        AlertSuccessComponent,
        StepComponent,
        StepperComponent,
        StepActionsDirective,
        DatatableComponent,
        DatatableColumnComponent,
        DatatableLinkDirective,
        FlashSavingComponent,
        FlashSavedComponent,
        StickyDirective,
        ColorPickerComponent,
        ColorPickerBodyComponent,
        SelectedColorComponent,
        CardAvatarComponent,
        WelcomeMessageComponent,

        /** Export account shared component */
        UserListComponent,
        UserGroupsComponent,
        UserSettingsComponent,
        AccountsResourceAssignComponent,
        AccountsResourceRecommendComponent,
        GroupSettingsFormComponent,
        GroupListComponent,
        UserSettingsDialogComponent,
        GroupSettingsDialogComponent,
        WelcomeMessageDialogComponent,

        CategoryListComponent,
        CategoryControlComponent,
        CategoryDetailComponent,
        CategoryCreateDialogComponent,
        CategoryRootControlComponent,
        GradeSystemDialogComponent,
        GradeSystemDetailComponent,
        CategoryLightControlComponent,
        CategoryCreateLightComponent,
        CategoryCreateSheetComponent,

        TagListingComponent,
        PowerbiReportComponent,
        InnerHtmlDisplayDialogComponent,

        GeneralSettingSwitchComponent,
        HrNotesComponent,
        SectionCollectionsComponent,
        SectionCollectionListComponent,
        SectionCollectionListItemComponent,
        CollectionItemsListViewComponent,

        GeneralSettingsManagementComponent,
        TriggersSettingsComponent,
        PaymentOptionsSettingsComponent,
        AuthorSettingsComponent,
        RolesSettingsComponent,
        MainSettingsComponent,
        AcademySettingsComponent,
        RoleItemComponent,
        RolesAccessTabComponent,
        RolesFunctionTabComponent,
        RolesAccessRowComponent,
        RolesFunctionRowComponent,
        FunctionPolicyRowComponent,
        AccessPermissionRowComponent,
        DefaultImgItemComponent,
        DefaultImgComponent,
        TeamsListComponent,
        DialogTokenExpirationCountdownComponent,
        TeamExpandDisplayGroupsComponent,
        ExtraImagesUploaderComponent,
    ],
})
export class SharedModule {}
