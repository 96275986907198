<div *ngIf="navData" class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutAlign="start center" fxLayoutGap="5px">
        <mat-icon>diversity_2</mat-icon>
        <div>
            <span i18n>Add </span>
            <span>{{ groupsLabel }}</span>
            <span i18n> to </span>
            <span>{{ navData.data.team?.name }}</span>
        </div>
    </div>
    <div>
        <span i18n>Search and select or click 'enter' to add </span>
        <span>{{ groupsLabel }}</span>
    </div>
    <div fxLayoutGap="4px" class="assignee input-wrapper default-select" fxLayout="column">
        <div fxLayout="row" fxLayoutGap="10px" fxLayoutGap.lt-md="10px" class="input-parent">
            <mat-form-field class="small-field users-rec-input" appearance="fill">
                <input
                    type="text"
                    matInput
                    #teamRecieverInput
                    i18n-placeholder
                    placeholder="Search and select"
                    [formControl]="teamControl"
                    [matAutocomplete]="autoTeam"
                    (keyup.enter)="onAdd(teamControl.value)"
                />
                <mat-autocomplete
                    #autoTeam="matAutocomplete"
                    (optionSelected)="selectedTeam($event.option.value, autoTeam)"
                >
                    <mat-option *ngFor="let team of teamsSearch$ | async" [value]="team">
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <span *ngIf="team.name">{{ team.name }}</span>
                        </div>
                    </mat-option>
                </mat-autocomplete>
                <app-kd-logo-loading *ngIf="onSavingTeam | async" size="small"></app-kd-logo-loading>

                <button
                    [fxHide]="onSavingTeam | async"
                    *ngIf="!!teamControl.value"
                    mat-icon-button
                    class="cancel-contact"
                    matSuffix
                    (click)="teamControl.reset()"
                >
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-form-field>
        </div>
        <div
            class="mt-1"
            fxLayout="row wrap"
            fxLayoutAlign="start center"
            fxLayoutGap="5px"
            *ngIf="!!selectedGroups?.length"
        >
            <div
                class="admin-chip"
                fxLayout="row wrap"
                fxLayoutAlign="start center"
                fxLayoutGap="10px"
                *ngFor="let selectedTeam of selectedGroups"
            >
                <span>{{ selectedTeam.name }}</span>
                <button mat-icon-button matSuffix (click)="onRemoveTeam(selectedTeam.id)">
                    <mat-icon>cancel</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="action" fxLayout="row-reverse wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
        <ng-container *ngIf="!(onLoading | async)">
            <button
                [disabled]="!selectedGroups.length"
                type="button"
                fxFlex="0 0 auto"
                class="mobile-button custom"
                (click)="submit()"
                mat-raised-button
            >
                <span i18n>Save</span>
            </button>
        </ng-container>
        <app-kd-logo-loading *ngIf="onLoading | async" size="small"></app-kd-logo-loading>
        <button fxFlex="0 0 auto" class="mobile-button negative" (click)="onClose.emit()" mat-button>
            <span i18n>Cancel</span>
        </button>
    </div>
</div>
