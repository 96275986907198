import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { GroupService } from '@klickdata/core/group';
import { MobileService } from '@klickdata/core/mobile';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { GlobalFilterColor, GlobalFilterProperty } from '../../global-filters-types.enum';
import { ChipFilterComponent } from '../chip-filter/chip-filter.component';
import { Filter } from '../filter';
import { FilterBase } from '../filter-base';
import { Customer } from '@klickdata/core/customer';
import { AuthService } from '@klickdata/core/auth';
import { AppScope } from '@klickdata/core/resource';

@Component({
    selector: 'app-team-chip-search-filter',
    templateUrl: './team-chip-search-filter.component.html',
    styleUrls: ['./team-chip-search-filter.component.scss'],
    providers: [
        { provide: ChipFilterComponent, useExisting: forwardRef(() => TeamChipSearchFilterComponent) },
        { provide: FilterBase, useExisting: TeamChipSearchFilterComponent },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamChipSearchFilterComponent extends ChipFilterComponent implements OnInit, OnDestroy {
    private destroy: Subject<boolean> = new Subject<boolean>();
    @Input() inGlobalFilter: boolean;
    AppScope = AppScope;
    public customer$: Observable<Customer>;
    public control: FormControl = new FormControl();
    protected _filter: Filter<string> = {
        property: GlobalFilterProperty.TEAMS,
        items: [],
        label: $localize`:@@teams:Teams`,
        icon: 'diversity_2',
        color: { bg: GlobalFilterColor.C2, fg: GlobalFilterColor.WHITE },
        styleClass: 'global-teams-filter-selector',
    };
    constructor(
        protected changeRef: ChangeDetectorRef,
        protected mobile: MobileService,
        protected auth: AuthService,
        protected groupService: GroupService
    ) {
        super(changeRef, mobile);
    }

    ngOnInit() {
        this.customer$ = this.auth.getCustomer().pipe(
            shareReplay(),
            tap(
                (customer) =>
                    (this._filter.label = customer.scope_names[AppScope.TEAM] ?? AppScope.label(AppScope.TEAM))
            )
        );
        this.control.valueChanges
            .pipe(
                takeUntil(this.destroy),
                debounceTime(300),
                distinctUntilChanged(),
                switchMap((query) =>
                    query
                        ? this.groupService
                              .getCustomerGroupsByParams({
                                  query: query,
                                  limit: 20,
                                  team: 1,
                              })
                              .pipe(
                                  map((groups) =>
                                      groups.map((group, index) => {
                                          return {
                                              id: group.id,
                                              label: group.name,
                                              selected: index === 0, // make 1st item selected.
                                          };
                                      })
                                  )
                              )
                        : of([])
                )
            )
            .subscribe((items) => {
                this.source = items;
                if (items?.length) {
                    this.updateSelection();
                } else {
                    this.removeFilter();
                }
                this.changeRef.markForCheck();
            });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
