<div [formGroup]="itemGroup" fxLayout="row wrap" (keydown.enter)="$event.preventDefault()">
    <mat-tab-group #mainTabs fxFlex="grow" class="main-item-tabs">
        <mat-tab #mainTab label="Content" i18n-label>
            <div fxLayout="row wrap" fxFlex="1 1 100%" fxLayoutAlign="start start">
                <mat-form-field fxFlex="0 1 100%" *ngIf="itemGroup.get('item_type_value').value !== ItemTypes.QUESTION">
                    <input matInput type="text" #title formControlName="title" placeholder="Title" i18n-placeholder />
                </mat-form-field>

                <mat-form-field fxFlex="0 1 100%" *ngIf="itemGroup.get('item_type_value').value !== ItemTypes.QUESTION">
                    <textarea
                        #summary
                        matInput
                        minRows="2"
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="10"
                        placeholder="Summary"
                        i18n-placeholder="@@summary"
                        formControlName="description"
                    ></textarea>
                </mat-form-field>
            </div>

            <ng-container [ngSwitch]="itemGroup.value.item_type_value">
                <app-resource-builder-course
                    *ngSwitchCase="ItemTypes.COURSE"
                    fxFlex="1 1 100%"
                    [item]="item"
                    [selection]="selection"
                    [childGroup]="itemGroup"
                ></app-resource-builder-course>

                <app-resource-builder-test
                    #itemDirective
                    *ngSwitchCase="ItemTypes.TEST"
                    fxFlex="1 1 100%"
                    [item]="item"
                    [selection]="selection"
                    [childGroup]="itemGroup"
                    (onResourceExist)="onResourceExist($event)"
                ></app-resource-builder-test>

                <app-resource-builder-survey
                    #itemDirective
                    *ngSwitchCase="ItemTypes.SURVEY"
                    fxFlex="1 1 100%"
                    [item]="item"
                    [selection]="selection"
                    [childGroup]="itemGroup"
                    (onResourceExist)="onResourceExist($event)"
                ></app-resource-builder-survey>

                <app-resource-builder-material
                    #itemDirective
                    *ngSwitchCase="ItemTypes.MATERIAL"
                    fxFlex="1 1 100%"
                    [item]="item"
                    [selection]="selection"
                    [childGroup]="itemGroup"
                    (onResourceExist)="onResourceExist($event)"
                ></app-resource-builder-material>
                <app-resource-builder-event
                    #itemDirective
                    *ngSwitchCase="ItemTypes.EVENT"
                    fxFlex="1 1 100%"
                    [item]="item"
                    [selection]="selection"
                    [childGroup]="itemGroup"
                    (onResourceExist)="onResourceExist($event)"
                ></app-resource-builder-event>
            </ng-container>
        </mat-tab>
        <mat-tab #mainTab label="Extra" i18n-label>
            <ng-template matTabContent>
                <div fxLayout="column" fxFlex="1 1 100%" fxLayoutAlign="start start" fxLayoutGap="1em">
                    <ng-container *ngIf="selectedResource$ | async; let selectedResource">
                        <app-image-view
                            *ngIf="selectedResource?.media$"
                            [media_id]="selectedResource.media$"
                            [proportional]="true"
                        ></app-image-view>
                        <div
                            class="mt-1 mb-1"
                            *ngIf="resourceTags$ | async; let tags"
                            fxFlex="0 1 100%"
                            fxLayoutAlign="start center"
                            fxLayoutGap="10px"
                        >
                            <mat-chip-list>
                                <mat-chip class="tags" (click)="showTagResources(tag)" *ngFor="let tag of tags"
                                    >{{ tag.name }}
                                    <app-language-selector
                                        class="flag tag-chip-lang-select"
                                        fxFlex="0 0 auto"
                                        [language_id]="tag.language_id"
                                    >
                                    </app-language-selector
                                ></mat-chip>
                            </mat-chip-list>
                        </div>
                    </ng-container>
                    <mat-accordion
                        fxFlex="0 1 100%"
                        displayMode="flat"
                        class="w-100"
                        *ngIf="itemGroup.value.item_type_value === ItemTypes.MATERIAL"
                    >
                        <mat-expansion-panel #expansionPanelDesc>
                            <mat-expansion-panel-header>
                                <div *ngIf="!expansionPanelDesc.expanded" i18n="@@contentInformation">
                                    Content information
                                </div>
                            </mat-expansion-panel-header>
                            <ng-template matExpansionPanelContent>
                                <app-text-editor
                                    fxFlex="1 1 100%"
                                    title="Content information"
                                    i18n-title
                                    [showTemplates]="false"
                                    formControlName="instructions"
                                ></app-text-editor>
                            </ng-template>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>
