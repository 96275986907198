<div class="loader-expansion-wrapper">
    <mat-expansion-panel [disabled]="true" [expanded]="false">
        <mat-expansion-panel-header class="main">
            <div fxFlex="grow" fxLayout="row" fxLayoutAlign="start center">
                <div fxFlex="grow" fxLayout="row" fxLayoutAlign="start center">
                    <div class="qs-num-label">{{ aiData.question_count }}</div>
                    <div fxLayout="column" fxLayoutAlign="center start">
                        <div class="qs-type-icon-wrap" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">
                            <mat-icon *ngIf="aiData.question_type_value.includes(QuestionTypes.INPUT)"
                                >edit_note</mat-icon
                            >
                            <mat-icon *ngIf="aiData.question_type_value.includes(QuestionTypes.RADIO)"
                                >radio_button_checked</mat-icon
                            >
                            <mat-icon *ngIf="aiData.question_type_value.includes(QuestionTypes.CHECKBOX)"
                                >check_box</mat-icon
                            >
                            <span>Questions</span>
                        </div>
                        <div>{{ aiData.text }}</div>
                    </div>
                </div>
                <mat-icon class="loadingg-icon-ai">smart_toy</mat-icon>
            </div>
        </mat-expansion-panel-header>
    </mat-expansion-panel>
</div>
