<div class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutGap="5px" fxLayout="row wrap" fxLayoutAlign="start center">
        <mat-icon>fitbit</mat-icon>
        <span i18n>Publisher logo</span>
        <button
            fxLayout="row"
            mat-button
            color="primary"
            class="upload-logo-btn"
            matTooltip="Upload a new logo"
            i18n-matTooltip
            (click)="
                onClose.emit({
                    action: SideNaveActionsTypes.POSITIVE,
                    data: { type: 'selectPublisherLogo', uploadLogo: true }
                })
            "
        >
            <mat-icon class="upload-icon">cloud_upload</mat-icon>
        </button>
    </div>
    <div class="desc" i18n>Upload or select a publisher logo that you want to use for your course</div>

    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
        <app-image-view
            *ngFor="let publisherLogo of navData.data.publisherLogos"
            [proportional]="false"
            [media_id]="publisherLogo"
            class="landing-bg-customer"
            (click)="
                onClose.emit({
                    action: SideNaveActionsTypes.POSITIVE,
                    data: { type: 'selectPublisherLogo', publisherLogo: publisherLogo }
                })
            "
        ></app-image-view>
        <ng-container *ngIf="!!navData.data.kd_publisher?.length">
            <app-image-view
                *ngFor="let kdLogo of navData.data.kd_publisher"
                [proportional]="false"
                [media_id]="kdLogo"
                class="landing-bg-customer"
                (click)="
                    onClose.emit({
                        action: SideNaveActionsTypes.POSITIVE,
                        data: { type: 'selectPublisherLogo', publisherLogo: kdLogo }
                    })
                "
            ></app-image-view>
        </ng-container>
    </div>
</div>
