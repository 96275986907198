import { Cast, CastType, IDataModel, Ignore, Model } from '@klickdata/core/application';
import { User } from '@klickdata/core/user';
import { Observable } from 'rxjs';

export interface SubMessageData extends IDataModel {
    id?: number;
    created_at?: string;
    updated_at?: string;
    end_date?: string;
    message_id?: number;
    author_id?: number;
    parent_id?: number;
    body?: string;
    media_ids?: number[];
    author?: Observable<User>;
    recipients?: User[];
}
export class SubMessage extends Model<SubMessageData> {
    id: number;
    @Cast(CastType.MOMENT)
    created_at: string;
    author_id: number;
    message_id: string;
    @Cast(CastType.MOMENT)
    updated_at: string;
    @Cast(CastType.MOMENT)
    end_date: string;
    body: string;
    media_ids: number[];
    author: Observable<User>;
    recipients: User[];
    @Ignore()
    downloads?: any;
}
