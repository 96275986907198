<div
    [matTooltipDisabled]="!showTimeHover"
    [matTooltip]="dateTime.value | kdDateFormat : (mode === 'dateTime' ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD')"
    class="dates-time-picker"
    fxLayout="column"
>
    <!-- Seperated date and time picker due to conflicting mat-datepicker-toggle which makes bugs -->
    <ng-container *ngIf="mode === 'dateTime'">
        <mat-form-field class="small-field mt-1" appearance="fill">
            <input
                #input
                matInput
                [formControl]="dateTime"
                [ngxMatDatetimePicker]="datetimePicker"
                (focus)="datetimePicker.open()"
            />

            <mat-icon *ngIf="showTimeHover" class="hover-time">watch_later</mat-icon>
            <mat-icon
                *ngIf="enableClear && dateTime.value"
                matDatepickerToggleIcon
                class="reset-picker"
                (click)="dateTime.setValue(null)"
                >clear</mat-icon
            >
            <mat-datepicker-toggle matSuffix [for]="$any(datetimePicker)"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker color="primary" #datetimePicker>
                <ng-template>
                    <span>Done</span>
                </ng-template>
            </ngx-mat-datetime-picker>
        </mat-form-field>
    </ng-container>
    <ng-container *ngIf="mode === 'date'">
        <mat-form-field class="small-field mt-1" appearance="fill">
            <input #input matInput [formControl]="dateTime" [matDatepicker]="datePicker" (focus)="datePicker.open()" />

            <mat-icon *ngIf="showTimeHover" class="hover-time">watch_later</mat-icon>
            <mat-icon
                *ngIf="enableClear && dateTime.value"
                matDatepickerToggleIcon
                class="reset-picker"
                (click)="dateTime.setValue(null)"
                >clear</mat-icon
            >
            <mat-datepicker-toggle matSuffix [for]="$any(datePicker)"></mat-datepicker-toggle>

            <mat-datepicker #datePicker></mat-datepicker>
        </mat-form-field>
    </ng-container>
</div>
