import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Group } from '@klickdata/core/group';
import { AccessControlPermission, Resource } from '@klickdata/core/resource/src/resource.model';
import { User } from '@klickdata/core/user';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';
import { filter, takeUntil } from 'rxjs';

@Component({
    selector: 'app-user-resources-permissions-handler',
    templateUrl: './user-resources-permissions-handler.component.html',
    styleUrls: ['./user-resources-permissions-handler.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserResourcesPermissionsHandlerComponent extends OnDestroyHandler implements OnInit {
    @Input() resource: Resource;
    @Input() permissionsFormArray: FormArray;
    @Input() permissions: AccessControlPermission[];
    @Input() resourcePermissions: {
        index: number;
        value: string;
        label: string;
        tooltip?: string;
        disableUser?: boolean;
    }[];
    public permForm: FormGroup;
    public toolTipsMapping: { value: true | false | null; label: string }[] = [
        { value: true, label: $localize`:@@permissionGranted:Permission granted` },
        { value: false, label: $localize`:@@permissionDenied:Permission denied` },
        { value: null, label: $localize`:@@permissionInherited:Permission inherited from higher level` },
    ];
    constructor(protected fb: FormBuilder) {
        super();
    }

    ngOnInit(): void {
        this.permForm = this.fb.group({
            id: [this.resource.id],
            permissions: this.fb.array([]),
        });
        this.handlePermissions();
    }
    handlePermissions() {
        if (!!this.permissions?.length) {
            this.permissions.forEach((perm) =>
                (this.permForm.get('permissions') as FormArray).push(this.addNewPerm(perm))
            );
        }

        this.permForm.markAsPristine();
        this.permForm.valueChanges
            .pipe(
                takeUntil(this.destroy),
                filter(() => this.permForm.dirty || this.permItemsFormArray.dirty)
            )
            .subscribe(() => this.pushIntoPermForm());
    }

    private pushIntoPermForm() {
        const existingIndex = this.permissionsFormArray.controls.findIndex(
            (group: FormGroup) => group.getRawValue().id === this.permForm.value.id
        );

        const dirtyForm = this.getDirtyPermForm();
        // Only proceed if there are dirty permissions
        if (dirtyForm.get('permissions').value.length > 0) {
            if (existingIndex !== -1) {
                const control = this.permissionsFormArray.at(existingIndex);
                control.patchValue(dirtyForm.value);
                control.markAsDirty();
            } else {
                const newControl = this.fb.group(dirtyForm.value);
                newControl.markAsDirty();
                this.permissionsFormArray.push(newControl);
            }
        }
    }

    getDirtyPermForm(): FormGroup {
        const dirtyPermissions = this.permForm.get('permissions') as FormArray;
        const newPermissionsArray = this.fb.array([] as FormGroup[]);

        dirtyPermissions.controls.forEach((control) => {
            if (control.dirty) {
                newPermissionsArray.push(
                    this.fb.group({
                        permission_type: [control.get('permission_type').value],
                        permission_value: [control.get('permission_value').value],
                    })
                );
            }
        });
        return this.fb.group({
            id: [this.permForm.get('id').value],
            permissions: newPermissionsArray,
        });
    }

    addNewPerm(permission: AccessControlPermission) {
        const group = this.fb.group({
            permission_type: [permission.permission_type],
            permission_value: [permission.permission_value.value],
        });
        if (permission.permission_value.disabled) {
            group.get('permission_value').disable();
        }
        return group;
    }
    public toggleAll(status: boolean, modelId: number) {
        if (this.permForm.value.id == modelId) {
            this.permItemsFormArray.controls
                .filter((control) => control.get('permission_value').enabled)
                .forEach((ctr) => {
                    ctr.get('permission_value').patchValue(status);
                    ctr.markAsDirty();
                });
            this.pushIntoPermForm();
        }
    }
    public checkAllValues() {
        const controls = this.permItemsFormArray.controls.filter((control) => control.get('permission_value').enabled);
        const allTrue = controls.every((group) => group.get('permission_value')?.value === true);
        const allFalse = controls.every((group) => group.get('permission_value')?.value === false);
        if (allFalse) {
            this.handleModelMasterToggle(false);
        }
        if (allTrue) {
            this.handleModelMasterToggle(true);
        }
        if (!allFalse && !allTrue) {
            this.handleModelMasterToggle();
        }
    }
    get permItemsFormArray(): FormArray {
        return this.permForm?.get('permissions') as FormArray;
    }
    handleModelMasterToggle(status?: boolean) {
        if (status) {
            this.resource.all_resource_perm_checked = status;
            this.resource.some_resource_perm_checked = false;
        } else {
            this.resource.some_resource_perm_checked = true;
        }
    }
}
