import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@klickdata/core/auth';
import { SideNaveData } from '@klickdata/core/mobile';
import { SideNaveActionsTypes, SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { Observable, Subject, map } from 'rxjs';
import { Utils } from '@klickdata/core/util';
import { ResourceItem } from '@klickdata/core/resource-item';

@Component({
    selector: 'app-menu-side-material-prompt-regeneration',
    templateUrl: './menu-side-material-prompt-regeneration.component.html',
    styleUrls: ['./menu-side-material-prompt-regeneration.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideMaterialPromptRegenerationComponent implements OnInit {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    public form: FormGroup;
    public saving: Subject<boolean> = new Subject<boolean>();
    SideNaveActionsTypes = SideNaveActionsTypes;
    public promptOptionControl = new FormControl(false);
    public allLLmsList$: Observable<
        {
            name: string;
            value: string;
            enabled?: boolean;
            default?: boolean;
            divider?: boolean;
        }[]
    >;

    constructor(protected fb: FormBuilder, protected cd: ChangeDetectorRef, private auth: AuthService) {
        this.form = this.fb.group({
            llm: [''],
            prompt: [''],
            name: [''],
        });
    }
    ngOnInit(): void {
        this.allLLmsList$ = this.auth.getCustomer().pipe(
            map((customer) => customer.name),
            map((name) => Utils.getLLmsList(name))
        );
        if (this.item) {
            this.updateForm();
        } else {
            this.updateLLM();
        }
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (this.item) {
            this.updateForm();
        } else {
            this.updateLLM();
        }
    }
    public get item(): ResourceItem {
        return this.navData?.data?.resourceItem as ResourceItem;
    }
    private updateForm() {
        this.form.patchValue({
            llm: this.item.model_name ?? Utils.getLLmsList().find((llm) => llm.default)?.value,
            prompt: this.item.prompt,
            name: this.item.name,
        });
        this.cd.markForCheck();
    }
    private updateLLM() {
        this.form.patchValue({
            llm: Utils.getLLmsList().find((llm) => llm.default)?.value,
        });
    }
    public submit() {
        const prompt = this.form.get('prompt').dirty ? this.form.value.prompt : null;
        const name = this.form.get('name').dirty ? this.form.value.name : null;

        this.onClose.emit({
            action: SideNaveActionsTypes.POSITIVE,
            data: {
                llm: this.form.value.llm,
                prompt: prompt ?? name,
                type: this.navData.data.type,
            },
        });
        this.clear();
    }
    public clear() {
        this.form.patchValue({ value: '' });
        this.cd.markForCheck();
    }
}
