<div class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutGap="5px" fxLayout="row wrap" fxLayoutAlign="start center">
        <mat-icon class="{{ navData.data.comp.competence_status }}">circle</mat-icon><span>Update</span
        ><span>"{{ navData.data.comp.article_code }}"</span>
    </div>
    <div
        class="label"
        *ngIf="navData.data.comp.title"
        fxLayoutGap="5px"
        fxLayout="row wrap"
        fxLayoutAlign="start center"
    >
        <span i18n>Title:</span>
        <span>{{ navData.data.comp.title }}</span>
    </div>

    <form [formGroup]="form" fxLayoutGap="1em" fxFlex="0 0 100%" fxLayout="column">
        <div fxLayoutGap="4px" fxLayout="column" class="w-100 input-field-parent deadline">
            <span i18n>Update competence status</span>

            <mat-radio-group
                fxFlex="grow"
                formControlName="status"
                fxLayoutAlign="center start"
                fxLayout="column"
                fxLayoutGap="10px"
                class="mt-1"
            >
                <mat-radio-button [value]="false">
                    <div fxLayoutGap="5px" fxLayout="row" fxLayoutAlign="start center">
                        <mat-icon class="red">circle</mat-icon>
                        <span>
                            <span>{{ AppScope.USERS | modelDynamicLabel | async }}</span>
                            <span i18n> not completed</span></span
                        >
                    </div>
                </mat-radio-button>
                <mat-radio-button [value]="true">
                    <div fxLayoutGap="5px" fxLayout="row" fxLayoutAlign="start center">
                        <mat-icon class="green">circle</mat-icon>
                        <span
                            ><span>{{ AppScope.USERS | modelDynamicLabel | async }}</span>
                            <span i18n> completed with date</span>
                        </span>
                    </div>
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <div
            *ngIf="form.value.status"
            fxLayoutGap="4px"
            fxLayout="column"
            class="w-100 input-field-parent deadline mb-0"
        >
            <span i18n>Update completion date</span>
            <mat-form-field
                appearance="outline"
                floatLabel="never"
                class="w-100"
                fxFlex="grow"
                fxFlex="1 1 100%"
                fxFlex.lt-md="1 1 100%"
            >
                <mat-label i18n>Enter completion date</mat-label>
                <input
                    matInput
                    [matDatepicker]="deadlineDatepicker"
                    (focus)="deadlineDatepicker.open()"
                    [max]="maxDate"
                    formControlName="start_date"
                />
                <mat-datepicker-toggle matIconSuffix [for]="deadlineDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #deadlineDatepicker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="mb-0 date" *ngIf="hasValidDoneDate" fxLayoutGap="5px" fxLayout="row" fxLayoutAlign="start center">
            <span i18n>Date of completion:</span>
            <span>{{ navData.data.comp.done_at | kdDateFormat : 'YYYY-MM-DD' }}</span>
        </div>
        <div
            class="date"
            *ngIf="!!navData.data.comp.occasion_expiry"
            fxLayoutGap="5px"
            fxLayout="row"
            fxLayoutAlign="start center"
        >
            <span i18n>Date of expiration:</span>
            <span>{{ navData.data.comp.occasion_expiry | kdDateFormat : 'YYYY-MM-DD' }}</span>
        </div>
        <div fxFlex="1 0 100%" fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutGap=".5em" class="mt-1">
            <button class="cancelBtn" mat-raised-button (click)="onClose.emit()" i18n>Cancel</button>
            <button
                *ngIf="navData.data.canAssign"
                [disabled]="!!navData.data.comp.assign_id"
                [matTooltip]="getAssignTooltip(!!navData.data.comp.assign_id)"
                class="assignBtn"
                mat-icon-button
                (click)="assign()"
            >
                <mat-icon>assignment_ind</mat-icon>
            </button>
            <button mat-raised-button color="primary" [disabled]="!form.dirty" (click)="submit()" i18n>Done</button>
        </div>
    </form>
</div>
