<form [formGroup]="diplomaLogosForm" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="10px">
    <!-- Iterate through logo form groups -->
    <ng-container *ngFor="let i of [1, 2, 3]">
        <div class="w-100" [formGroupName]="'logo_' + i">
            <!-- Logo Display Controls -->
            <div class="w-100" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                <span i18n>Diploma logo {{ i }}</span>
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                    <span i18n>Align in diploma</span>
                    <div fxLayoutGap="4px" fxLayout="column" class="input-field-parent select">
                        <mat-form-field class="role-field">
                            <mat-select formControlName="alignment">
                                <mat-option
                                    *ngFor="let option of alignmentOptions"
                                    [disabled]="option.disabled"
                                    [value]="option.value"
                                >
                                    {{ option.label }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <!-- Logo Preview -->
            <div
                *ngIf="getLogoForm(i).value.media_id"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="mt-1 mb-1 w-100"
            >
                <app-image-view
                    [proportional]="false"
                    [media_id]="getLogoForm(i).value.media_id"
                    class="landing-bg-customer"
                >
                    <button
                        class="remove-file"
                        color="warn"
                        fxFlex="0 1 auto"
                        mat-icon-button
                        matTooltip="Remove image"
                        i18n-matTooltip
                        (click)="removeLogo(i)"
                    >
                        <mat-icon class="md-24">delete_outline</mat-icon>
                    </button>
                </app-image-view>
            </div>

            <!-- Logo Upload -->
            <div
                *ngIf="!getLogoForm(i).value.media_id"
                fxLayout="row"
                fxLayoutAlign="start center"
                fxLayoutGap="10px"
                class="logog-wrapper"
            >
                <button class="add-academy-logo" mat-button color="primary" (click)="addAcademyLogo(i)">
                    <mat-icon>add_circle</mat-icon>
                    <span i18n>Add logo</span>
                </button>
            </div>
            <input
                #logoInput
                class="media-upload-button"
                type="file"
                (change)="fileHandler($event, i)"
                accept="image/png, image/jpeg"
            />
            <!-- Progress Bar -->
            <ng-container *ngIf="logoProgress[i] | async; let progressBar">
                <div class="progress-wrapper" *ngIf="progressBar > 0 && progressBar < 100">
                    <mat-progress-bar class="progress" mode="determinate" [value]="progressBar"></mat-progress-bar>
                    <span class="progress-value">{{ progressBar }}%</span>
                </div>
            </ng-container>
        </div>
    </ng-container>
</form>
