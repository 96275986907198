import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Inject,
    Injector,
    Input,
    LOCALE_ID,
    NgZone,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormArray,
    FormBuilder,
    FormGroup,
    NG_VALUE_ACCESSOR,
    Validators,
} from '@angular/forms';
import { FormHelper } from '@klickdata/core/form';
import { LanguageService } from '@klickdata/core/localization';
import { Utils } from '@klickdata/core/util';
import { Subject, Subscription } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
@Component({
    selector: 'app-title-translation',
    templateUrl: './title-translation.component.html',
    styleUrls: ['./title-translation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TitleTranslationComponent),
            multi: true,
        },
    ],
})
export class TitleTranslationComponent implements OnInit, ControlValueAccessor, OnDestroy {
    @Input() placeholder: string;
    @Input() type: 'title' | 'description' = 'title';
    private _value: { [key: string]: string } = {};
    public translationsFormArray: FormArray;
    private destroy: Subject<boolean> = new Subject<boolean>();
    subscription: Subscription;
    noMoreLanguages: boolean;

    get value(): { [key: string]: string } {
        return this._value;
    }

    set value(value) {
        this._value = value ? value : {};
        this.propagateChange(this._value);
    }
    constructor(
        protected fb: FormBuilder,
        protected languageService: LanguageService,
        protected injector: Injector,
        protected cdRef: ChangeDetectorRef,
        protected zone: NgZone,
        @Inject(LOCALE_ID) protected localeId: string
    ) {
        this.localeId = localeId === 'en' ? 'en-US' : localeId;
    }

    public propagateChange = (_: any) => {};

    ngOnInit(): void {
        this.buildFormArray([this.makeTranslationGroup({ key: this.localeId, value: '' })]);
    }

    private buildFormArray(translationGroups: FormGroup[]) {
        this.translationsFormArray = this.fb.array(translationGroups);
        this.cdRef.markForCheck();
        this.destroySubscription();

        this.subscription = this.translationsFormArray.valueChanges
            .pipe(switchMap((formValues) => this.mapFormValuesToLanguageObject(formValues)))
            .subscribe((translationValues) => {
                this.zone.run(() => this.updateValueIfChanged(translationValues));
            });
    }

    /**
     * Maps form values to a translation object using language data.
     */
    private mapFormValuesToLanguageObject(formValues: any[]) {
        const languageIds = formValues.map(({ language_id }) => language_id);

        return this.languageService.getLanguagesByKeysWithNonExisting(languageIds).pipe(
            map(({ languages, nonExistingKeys }) => {
                this.removeByLanguageIds(nonExistingKeys as string[]);
                return formValues.reduce((translationObject, { language_id, value }) => {
                    const language = languages.find(({ lang }) => lang === language_id);
                    if (language) {
                        translationObject[language.lang] = value;
                    }
                    return translationObject;
                }, {} as Record<string, string>);
            })
        );
    }

    /**
     * Updates the component value if it has changed and is valid
     */
    private updateValueIfChanged(newValues: { [key: string]: string }) {
        const isUserChange = !this.translationsFormArray.pristine || !Utils.isEqual(this.value, newValues);
        const isValid = this.translationsFormArray.controls.length === Object.keys(newValues).length;

        if (isUserChange && isValid) {
            this.value = newValues;
        }
    }

    private destroySubscription() {
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = null;
        }
    }

    makeTranslationGroup({ key, value }): FormGroup {
        return this.fb.group({
            language_id: [key],
            value: [value],
        });
    }

    public addLang() {
        if (this.noMoreLanguages) {
            return;
        }
        this.languageService
            .getLanguageNotIn(this.translationsFormArray.value.map((obj) => obj.language_id))
            .pipe(takeUntil(this.destroy))
            .subscribe((language) => {
                if (language) {
                    this.translationsFormArray.push(this.makeTranslationGroup({ key: language.lang, value: '' }));
                } else {
                    this.noMoreLanguages = true;
                }
            });
    }

    public writeValue(value): void {
        this._value = value || {};
        if (Object.keys(this.value).length !== 0) {
            const translations = Object.entries(this.value)
                .sort((a, _) => (a[0] === this.localeId ? -1 : 1))
                .map((translation) => this.makeTranslationGroup({ key: translation[0], value: translation[1] }));
            this.buildFormArray(translations);
        }
    }

    /**
     * Removes a translation entry from the form array by language ID
     * @param translationForm The form control to remove
     */
    removeTranslation(translationForm: AbstractControl): void {
        this.removeByLanguage(translationForm.value.language_id);
    }

    /**
     * Removes a translation entry from the form array by language ID
     * @param languageId The language ID to remove
     * @returns boolean indicating whether removal was successful
     */
    removeByLanguage(languageId: string): boolean {
        const index = this.translationsFormArray.value.findIndex((values) => values.language_id === languageId);

        if (index !== -1) {
            this.translationsFormArray.removeAt(index);
            this.noMoreLanguages = false;
            return true;
        }

        return false;
    }

    removeByLanguageIds(languageIds: string[]) {
        for (const languageId of languageIds) {
            this.removeByLanguage(languageId);
        }
    }

    public registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: any): void {}

    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
        this.destroySubscription();
    }

    public markForm() {
        FormHelper.markForm(this.translationsFormArray);
        this.cdRef.markForCheck();
    }
}
