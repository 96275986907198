<div fxLayout="column" class="main-wrapper-core" [ngClass]="{ borderless: resourceForm?.value?.scorm_uri }">
    <div class="w-100">
        <div fxFlex="0 0 100%" fxLayout="column wrap" fxLayoutAlign="start center" class="user-manage-tests">
            <app-scorm-upload
                *ngIf="(status$ | async) == 'empty'"
                (onScormUploaded)="scormMedia = $event; onScormUploaded($event)"
            ></app-scorm-upload>
            <div class="scorm-block-wrap mt-1" *ngIf="(status$ | async) != 'empty'">
                <button
                    class="remove-file"
                    color="warn"
                    type="button"
                    fxFlex="0 1 auto"
                    mat-icon-button
                    matTooltip="Remove SCORM"
                    i18n-matTooltip
                    (click)="onRemove()"
                >
                    <mat-icon class="md-24">delete_outline</mat-icon>
                </button>
                <img src="assets/images/Default_scorm_img.png" alt="SCORM" />
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="(status$ | async) == 'parsing'">
                <span>Parsing SCORM package</span>
                <app-kd-logo-loading size="small"></app-kd-logo-loading>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="(status$ | async) == 'error'">
                <span>{{ resource?.prompt_error }}</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="(status$ | async) == 'parsed'">
                <span>SCORM package</span>
                <button
                    color="primary"
                    type="button"
                    fxFlex="0 1 auto"
                    mat-icon-button
                    matTooltip="Play SCORM"
                    i18n-matTooltip
                    (click)="onPlay()"
                >
                    <mat-icon>play_circle</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>
