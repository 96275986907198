import { DatePipe } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivityLogService } from '@klickdata/core/activity-log';
import { MessageSavedComponent, MessageService } from '@klickdata/core/message';
import { MobileService, SideNaveDataTypes } from '@klickdata/core/mobile';
import { Resource, ResourceService } from '@klickdata/core/resource';
import { ResourceItemService } from '@klickdata/core/resource-item';
import { TextCountableDirective } from '@klickdata/core/util';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators';

export interface ValidationColorBtn {
    value: string;
    label: string;
}

@Component({
    selector: 'app-material-manager-text-item-header',
    templateUrl: './material-manager-text-item-header.component.html',
    styleUrls: ['./material-manager-text-item-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: TextCountableDirective, useExisting: MaterialManagerTextItemHeaderComponent }],
})
export class MaterialManagerTextItemHeaderComponent extends TextCountableDirective implements OnInit, OnDestroy {
    @Input() disabled: boolean;
    @Input() index: number;
    @Input() item: FormGroup;
    @Input() expanded: boolean;
    @Input() resource: Resource;
    @Output() onDelete: EventEmitter<number> = new EventEmitter<number>();
    @Output() onTranslate: EventEmitter<number> = new EventEmitter<number>();
    @Output() onRegenerate: EventEmitter<number> = new EventEmitter<number>();
    @Output() onCopy: EventEmitter<any> = new EventEmitter<any>();
    @Output() onDuplicate: EventEmitter<number> = new EventEmitter<number>();
    public editMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public loadingTitle: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public destroy: Subject<boolean> = new Subject<boolean>();
    public isMobile$: Observable<boolean>;
    public validationColorBtns: ValidationColorBtn[];
    public loadingMaterialsCreation: boolean;
    public itemLastEdit: string;

    constructor(
        protected resourceItemService: ResourceItemService,
        protected cdr: ChangeDetectorRef,
        protected mobileService: MobileService,
        protected element: ElementRef,
        protected messageService: MessageService,
        protected resourceService: ResourceService,
        protected activityLogService: ActivityLogService
    ) {
        super();
        this.validationColorBtns = [
            { value: 'fiction', label: $localize`Fiction` },
            { value: 'toxic', label: $localize`Toxic` },
            { value: 'uncertain', label: $localize`Uncertain` },
            { value: 'accepted', label: $localize`Accepted` },
            { value: 'none', label: $localize`Not Reviewed` },
        ];
    }

    ngOnInit(): void {
        this.isMobile$ = this.mobileService.isMobile();
        this.item
            .get('name')
            .valueChanges.pipe(
                startWith(this.item.value.name),
                takeUntil(this.destroy),
                distinctUntilChanged(),
                map(this.convertToPlain)
            )
            .subscribe((text) => {
                // Case when a new added text block and AU start typing,
                // It shouldn't change to 'edited'.
                // if (this.item.get('name').dirty) {
                //     this.item.patchValue({ text_validation: 'edited' });
                // }
                this.buildTextCount(text);
                this.cdr.markForCheck();
            });
        this.itemLastEdit = this.item?.value?.updated_at
            ? new DatePipe('en').transform(this.item.value.updated_at, 'YYYY-MM-dd HH:mm')
            : '';
    }
    getToolTipValue(state: string): string {
        switch (state) {
            case 'ai':
                return $localize`Created by pure AI`;
            case 'edited':
                return $localize`Created from AI and edited by human`;
            case 'translated':
                return $localize`Translated by AI`;
            case 'empty':
                return $localize`Created by human but not saved`;
            case 'human':
                return $localize`Created by human and saved`;
            case 'merged':
                return $localize`Merged from multiple items by AI`;
        }
    }

    buildTextCount(text: string) {
        const characters = text.length;
        const words = text.split(/\s+/).length;
        this.setCounts(characters, words);
    }
    showReasoning() {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.GENERAL_NOTIFIER,
            data: {
                icon: 'psychology',
                title: $localize`Reasoning for ${this.item.value.title}`,
                contentBody: $localize`This is reasoning .....`,
                positiveBtn: $localize`OK`,
            },
        });
    }
    convertToPlain(html: string) {
        const tempDivElement = document.createElement('div');
        tempDivElement.innerHTML = html;
        return tempDivElement.textContent || tempDivElement.innerText || '';
    }

    get tooltipInfo() {
        return [
            this.characters ? $localize`Characters: ${this.characters}` : '',
            this.words ? $localize`Words: ${this.words}` : '',
            this.itemLastEdit ? $localize`Last edit: ${this.itemLastEdit}` : '',
            $localize`Tokens: ${this.item.value.total_tokens ? this.item.value.total_tokens : 0}`,
            this.item.value.model_name ? $localize`LLM: ${this.item.value.model_name}` : '',
        ].join('\n');
    }
    sendEmail() {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.SEND_TEXT_BLOCK_MAIL,
            data: this.item.value,
        });
    }
    viewItemLog() {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.USER_PROGRESS,
            data: {
                resource: this.resource,
                logs: this.activityLogService.getUserRelatedResourceActivities({
                    item: this.item.value.id,
                    eager: ['user_prompt'],
                    sort: 'activity_id',
                }),
            },
        });
    }

    redirectToMail() {
        window.open(
            `mailto:?subject=${this.item.value.title}&body=${this.convertToPlain(this.item.value.name)}`,
            '_blank'
        );
    }

    changeColor(state: string) {
        this.item.patchValue({ text_validation: state });
        this.item.get('text_validation').markAsDirty();
    }
    makeMaterial() {
        this.resourceService
            .duplicate(new Resource({ id: this.resource.id }), { resource_items: [{ id: this.item.value.id }] })
            .pipe(takeUntil(this.destroy))
            .subscribe((newResource) => {
                if (newResource) {
                    this.messageService.openMessage(MessageSavedComponent, $localize`Materials created.`);
                    this.loadingMaterialsCreation = false;
                    this.cdr.markForCheck();
                }
            });
    }

    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
