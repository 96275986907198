<div fxLayout="column" class="rel" fxLayoutAlign="center center" fxLayoutGap="10px">
    <ng-container *ngIf="!!occasion?.evaluations?.length">
        <!-- <div *ngIf="evaluations.length > 1" class="slider-arrows">
                <button
                    mat-icon-button
                    [disabled]="currentIndex === 0"
                    (click)="previousItem()"
                    matTooltip="Previous"
                    aria-label="Previous"
                >
                    <mat-icon>arrow_circle_left</mat-icon>
                </button>
                <button
                    mat-icon-button
                    [disabled]="currentIndex === evaluations.length - 1"
                    (click)="nextItem()"
                    matTooltip="Next"
                    aria-label="Next"
                >
                    <mat-icon>arrow_circle_right</mat-icon>
                </button>
            </div> -->
        <app-formative-evaluation-item
            *ngFor="let eval of occasion.evaluations"
            [authUser]="authUser"
            [evaluation]="eval"
        ></app-formative-evaluation-item>
    </ng-container>
    <div
        *ngIf="authUser?.isAdmin()"
        class="mt-1 mb-2 w-100 admin-btns"
        fxLayout="row"
        fxLayoutGap="10px"
        fxLayoutAlign="start center"
    >
        <button *ngIf="canAddEval$ | async" (click)="addEvaluation()" class="add-eval" mat-button>
            Add Evaluation
        </button>
        <button
            *ngIf="!!occasion?.evaluations?.length && occasion?.evaluations[currentIndex].evaluator_id == authUser?.id"
            (click)="addEvaluation(occasion?.evaluations[currentIndex])"
            class="add-eval edit"
            mat-button
        >
            Edit Evaluation
        </button>
    </div>
</div>
