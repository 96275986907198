import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SideNaveData } from '@klickdata/core/mobile';
import { SideNaveActionsTypes, SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { AppScope } from '@klickdata/core/resource';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';
import * as moment from 'moment';
import { filter, startWith, takeUntil } from 'rxjs';

@Component({
    selector: 'app-menu-side-update-comp',
    templateUrl: './menu-side-update-comp.component.html',
    styleUrls: ['./menu-side-update-comp.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideUpdateCompComponent extends OnDestroyHandler implements OnInit {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    public form: FormGroup;
    maxDate = new Date();
    AppScope = AppScope;

    constructor(protected fb: FormBuilder) {
        super();
        this.form = this.fb.group({
            status: [],
            start_date: [],
        });
    }
    ngOnInit(): void {
        if (this.navData.data.comp) {
            this.form.get('status').patchValue(!!this.navData.data.comp.done_at);

            if (this.navData.data.comp.done_at && moment(this.navData.data.comp.done_at).format('YYYY') !== '1970') {
                this.form.get('start_date').patchValue(this.navData.data.comp.done_at);
            }
        }

        this.form
            .get('status')
            .valueChanges.pipe(
                startWith(this.form.value.status),
                takeUntil(this.destroy),
                filter((status) => !status)
            )
            .subscribe(() => this.form.get('start_date').patchValue(null));
    }

    public submit() {
        const data = this.form.value;
        if (data.start_date) {
            // https://github.com/SamProf/MatBlazor/issues/723
            data.start_date = data.start_date.toLocaleString();
        }

        if (data.status && !data.start_date) {
            data.start_date = new Date(0).toISOString();
        }

        this.onClose.emit({
            action: SideNaveActionsTypes.POSITIVE,
            data: {
                value: data,
                type: this.navData.data.type,
            },
        });
    }
    getAssignTooltip(assigned: boolean) {
        return assigned ? $localize`Already assigned` : $localize`Assign`;
    }
    public assign() {
        this.onClose.emit({
            action: SideNaveActionsTypes.CUSTOM,
            data: {
                type: this.navData.data.type,
            },
        });
    }

    get hasValidDoneDate(): boolean {
        const doneAt = this.navData?.data?.comp?.done_at;
        return !!doneAt && moment(doneAt).year() !== 1970;
    }
}
