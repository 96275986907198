import { Injectable } from '@angular/core';
import { PaginatorResponse } from '@klickdata/core/http/src/responce/paginator-responce';
import { Resource, ResourceData } from '@klickdata/core/resource/src/resource.model';
import { ResourceService } from '@klickdata/core/resource/src/resource.service';
import { TableSource } from '@klickdata/core/table';
import { TableHttpHelpers, TableHttpService } from '@klickdata/core/table/src/table-http-service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ResourceSelectDatatableService extends ResourceService implements TableHttpService<Resource> {
    public fetchData(source: TableSource<Resource>): Observable<PaginatorResponse<Resource[]>> {
        const req = this.builder.get<ResourceData[]>(this.resourceUrl);

        req.param('published', 1);

        if (source.filter?.filter?.filters?.findIndex((filter) => filter.property === 'assignedOrCustomer') === -1) {
            if (
                source.filter?.filter?.filters?.findIndex((filter) =>
                    ['referenceCustomer', 'referenceClient'].includes(filter.property)
                ) === -1
            ) {
                req.param('assignedOrCustomer', this.customer_id);
            } else {
                req.param('public', 1);
            }
        }

        if (source.filter?.filter?.filters?.findIndex((filter) => filter.property.indexOf('reference') !== -1) !== -1) {
            req.param('referenceCount', 1);
        }

        TableHttpHelpers.managePagination(source.paginator, req);
        TableHttpHelpers.manageSort(source.sort, req);
        TableHttpHelpers.mapFilter(source.filter, req);

        return req.request().pipe(
            map((res: PaginatorResponse<ResourceData[]>) => {
                return {
                    data: res.data.map((item) => this.createResource(item)),
                    notify: res.notify,
                    paginator: res.paginator,
                    more: res.more,
                };
            })
        );
    }
}
