<div fxFlex="0 0 100%" fxLayout="row wrap" class="access-select">
    <app-sticky-header
        class="sticky-header"
        [title]="data.title"
        [context]="data.contextTitle"
        [contextIcon]="data.contextIcon"
        (onSubmit)="submit()"
        (onCancel)="bottomSheetRef.dismiss()"
        [savingStatus]="saving"
    ></app-sticky-header>
    <div class="w-100 mt-1 p-1">
        <app-group-resources-access-control
            *ngIf="data.group"
            [permissionsFormArray]="groupControl"
            fxFlex="1 1 100%"
            [group]="data.group"
            class="w-100"
        ></app-group-resources-access-control>
        <app-user-resources-access-control
            *ngIf="data.user"
            [permissionsFormArray]="userControl"
            fxFlex="1 1 100%"
            [user]="data.user"
            class="w-100"
        ></app-user-resources-access-control>
    </div>
</div>
