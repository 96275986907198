import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@klickdata/core/user';
import { Utils } from '@klickdata/core/util';

@Pipe({
    name: 'connectionStatus',
})
// https://git.klickportalen.se/klickdata2016/nk3-application/issues/3558#note_69160
export class ConnectionStatusPipe implements PipeTransform {
    grey = '#b6b6b6';
    orange = '#ff9961';
    white = '#fff';
    darkblue = '#3e5365';
    green = '#bfd8d0';
    iceblue = '#93cbd1';
    transform(user?: User): any {
        if (user) {
            switch (user.role_value) {
                case 'masteradmin':
                case 'superadmin':
                    return user.activated
                        ? {
                              fg: this.white,
                              bg: this.orange,
                              label: $localize`Master Admin`,
                          }
                        : {
                              fg: this.white,
                              bg: this.orange,
                              label: $localize`Master Admin (Not activated yet)`,
                              class: 'reversed',
                          };
                case 'customeradmin':
                    return user.activated
                        ? {
                              fg: this.white,
                              bg: this.orange,
                              label: $localize`Main admin`,
                          }
                        : {
                              fg: this.orange,
                              bg: this.white,
                              label: $localize`Main admin (Not activated yet)`,
                              class: 'reversed',
                          };
                case 'groupadmin':
                    return user.activated
                        ? {
                              fg: this.grey,
                              bg: this.green,
                              label: $localize`Admin`,
                          }
                        : {
                              fg: this.green,
                              bg: this.white,
                              label: $localize`Admin (Not activated yet)`,
                              class: 'reversed',
                          };
                case 'user':
                    return user.activated
                        ? {
                              fg: this.white,
                              bg: this.darkblue,
                              label: $localize`Learner`,
                          }
                        : {
                              fg: this.darkblue,
                              bg: this.white,
                              label: $localize`Learner (Not activated yet)`,
                              class: 'reversed',
                          };
                case 'staff':
                    return user.activated
                        ? {
                              fg: this.darkblue,
                              bg: this.green,
                              label: $localize`Contact`,
                          }
                        : {
                              fg: this.grey,
                              bg: this.white,
                              label: $localize`Contact (Not activated yet)`,
                          };
                case 'divisionadmin':
                    return user.activated
                        ? {
                              fg: this.darkblue,
                              bg: this.green,
                              label: $localize`Contact`,
                          }
                        : {
                              fg: this.iceblue,
                              bg: this.white,
                              label: $localize`Contact (Not activated yet)`,
                              class: 'reversed',
                          };
            }
            return {
                fg: this.darkblue,
                bg: this.white,
                label: user.role_value,
            };
        }
    }
}
