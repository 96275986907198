import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { ResourceOpportunity } from '@klickdata/core/opportunity';
import { Resource } from '@klickdata/core/resource';
import { ResourceItem, ResourceItemService, ResourceItemTypes } from '@klickdata/core/resource-item';
import { User } from '@klickdata/core/user';
import { OccasionService } from '@klickdata/player/src/core/occasion/occasion.service';
import { ResourceItemOpportunityService } from '@klickdata/player/src/core/resource-item/resource-item.service';
import { map, Observable, of, shareReplay, tap } from 'rxjs';

@Component({
    selector: 'app-course-player-results',
    templateUrl: './course-player-results.component.html',
    styleUrls: ['./course-player-results.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoursePlayerResultsComponent implements OnChanges {
    @Input() user: User;
    @Input() resource: Resource;
    @Input() occasionId: number;
    @Input() tabsIndex = 2;
    @Input() tabsVisisbility: boolean[] = [true, true, true, true];
    @Output() retakeCourse: EventEmitter<Resource> = new EventEmitter<Resource>();
    public courseHasTests: boolean;
    public rewardType: 'Participation' | 'Diploma' | 'Certification' = 'Participation';
    public items$: Observable<ResourceItem[]>;
    public opportunity$: Observable<ResourceOpportunity>;

    constructor(
        protected resourceItemService: ResourceItemOpportunityService,
        protected resourceItemsService: ResourceItemService,
        protected occasionService: OccasionService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        this.rewardType = this.resource?.examination?.type ?? 'Participation';
        if (this.resource) {
            const resourceItems$ = this.occasionId
                ? this.resourceItemService.getAllItems(this.resource.id, this.occasionId)
                : this.resourceItemsService.getResourceItems(this.resource.id);
            this.items$ = resourceItems$.pipe(
                map((items) => this.addStartStatus(items)),
                tap(
                    (items) =>
                        (this.courseHasTests =
                            items.filter((resourceItem) => resourceItem.item_type_value === ResourceItemTypes.TEST)
                                .length > 0)
                )
            );
            this.opportunity$ = this.occasionId
                ? this.occasionService.getOccasion(this.occasionId).pipe(shareReplay())
                : of(null);
        }
    }
    private addStartStatus(items: ResourceItem[]): ResourceItem[] {
        return items.map((item, index, items) => {
            if (item.child_opportunity_done) {
                item.startStatus = {
                    status: 'repeat',
                    tooltip: $localize`Study again. No records of your practice will be displayed in the course`,
                    color: '#c0d8cf',
                };
            } else if (item.child_opportunity_started_at && !item.child_opportunity_done) {
                item.startStatus = {
                    status: 'continue',
                    tooltip: $localize`Continue this item inside the course`,
                    color: 'rgb(255, 153, 97)',
                };
            } else if (!item.child_opportunity_started_at) {
                const prevItem = items[index - 1];
                if (this.checkDimmedStatus(items.slice(0, index))) {
                    item.startStatus = {
                        status: 'dimmed',
                        tooltip: $localize`Not yet availible`,
                        color: 'rgba(0, 0, 0, 0.3)',
                    };
                } else {
                    item.startStatus = {
                        status: 'start',
                        tooltip: $localize`Start this item inside the course`,
                        color: '#3e5365',
                    };
                }
            }

            return item;
        });
    }
    checkDimmedStatus(previousItems: ResourceItem[]): boolean {
        return !!previousItems.find((item) => item.mandatory && !item.child_opportunity_done);
    }
}
