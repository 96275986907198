import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    OnDestroy,
    Output,
    ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Media } from '@klickdata/core/media/src/media.model';
import { S3MediaService } from '@klickdata/core/media/src/s3-media.service';
import { MessageService } from '@klickdata/core/message';
import { MessageSavedComponent } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { ScormService } from '@klickdata/core/scorm';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';
import { BehaviorSubject, Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'app-scorm-upload',
    templateUrl: './scorm-upload.component.html',
    styleUrls: ['./scorm-upload.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScormUploadComponent extends OnDestroyHandler {
    public saving: boolean;
    public progress$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    @Output() onScormUploaded: EventEmitter<Media> = new EventEmitter<Media>();
    dropSize = '200px';
    public get dropStyle() {
        return {
            height: this.dropSize,
            width: this.dropSize,
            flex: `0 1 ${this.dropSize}`,
        };
    }
    @ViewChild('uploader') uploader: ElementRef;

    constructor(protected messageService: MessageService, protected s3Service: S3MediaService) {
        super();
    }

    public fileHandler(event) {
        const fileList: FileList = event.target.files;
        if (fileList.length === 1) {
            const file: File = fileList[0];
            this.saving = true;
            this.s3Service
                .uploadScormToS3(file, this.progress$)
                .pipe(takeUntil(this.destroy))
                .subscribe(
                    (media) => {
                        this.saving = false;
                        this.messageService.openMessage(MessageSavedComponent, $localize`SCORM uploaded!`);
                        this.onScormUploaded.emit(media);
                    },
                    (err) => this.onError(err)
                );
        }
    }

    private onError(err) {
        this.saving = false;
        this.uploader.nativeElement.value = '';
        if (err && err.error && err.error.error) {
            this.messageService.openMessage(MessageErrorComponent, err.error.error.messages.join('/n'));
        }
    }
}
