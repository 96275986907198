<ng-container *ngIf="!loading; else loadingTemp">
    <div fxFlex="0 0 100%" class="selected-wrapper" fxLayout="row wrap">
        <div
            class="mt-1 mb-1"
            *ngIf="selected.tags_attached"
            fxFlex="0 1 100%"
            fxLayoutAlign="start center"
            fxLayoutGap="10px"
        >
            <mat-chip-list *ngIf="resourceTags | async; let tags; else: loadingTemp">
                <mat-chip class="tags" (click)="showTagResources(tag)" *ngFor="let tag of tags"
                    >{{ tag.name }}
                    <app-language-selector
                        class="flag tag-chip-lang-select"
                        fxFlex="0 0 auto"
                        [language_id]="tag.language_id"
                    >
                    </app-language-selector
                ></mat-chip>
            </mat-chip-list>
        </div>
    </div>
</ng-container>

<ng-template #loadingTemp>
    <div fxLayout="row" fxLayoutAlign="left" class="spinner-container">
        <app-kd-logo-loading size="small"></app-kd-logo-loading>
    </div>
</ng-template>
