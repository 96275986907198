import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { File } from '@klickdata/core/file';
import { MessageDeletedComponent, MessageService } from '@klickdata/core/message';
import { MobileService, SideNaveActionsTypes, SideNaveDataTypes } from '@klickdata/core/mobile';
import { AppScope, ResourceTag, ResourceTypes } from '@klickdata/core/resource';
import { GlobalFilterProperty, SelectFilterOption } from '@klickdata/core/table';
import { Utils } from '@klickdata/core/util';
import { DownloadHelperService } from 'apps/klickdata/src/app/shared/dialog/download-pdf-dialog/download-helper.service';
import { MatTableBase } from 'apps/klickdata/src/app/shared/table/mat-table-base';
import { filter, switchMap, takeUntil, tap } from 'rxjs';
import { K3FilesListingService } from '../k3-files-listing.service';

@Component({
    selector: 'app-files-listing-general',
    templateUrl: './files-listing-general.component.html',
    styleUrls: ['./files-listing-general.component.scss'],
    providers: [K3FilesListingService],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class FilesListingGeneralComponent extends MatTableBase<File> implements AfterViewInit {
    @Input() public columns = [
        'id',
        'title',
        'description',
        'tags',
        'priority',
        'author',
        'updated_at',
        'created_at',
        'tools',
    ];
    @Input() visibility = 'private';
    AppScope = AppScope;
    public expandedRow: File | null;
    public filePrioOptions: SelectFilterOption[];
    GlobalFilterProperty = GlobalFilterProperty;
    public significantPrio = ['a1', 'a2', 'a3', 'b1', 'c3'];

    constructor(
        protected listService: K3FilesListingService,
        protected mobileService: MobileService,
        protected cdr: ChangeDetectorRef,
        protected message: MessageService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected downloadHelper: DownloadHelperService
    ) {
        super(cdr, mobileService, listService);
        this.filePrioOptions = Utils.getTaskPrioOptions();
    }
    ngAfterViewInit(): void {
        super.ngAfterViewInit();
        this.route.queryParams.pipe(filter((params) => params.action === 'addFile')).subscribe(() =>
            setTimeout(() => {
                this.addFile();
                this.router.navigate([this.router.url.split('?')[0]]);
            }, 500)
        );
        this.listenToNewFile();
    }
    public listenToNewFile() {
        this.listService
            .listenToNewFile()
            .pipe(takeUntil(this.destroy))
            .subscribe((file) => this.dataSource.replace(file));
    }

    public addFile() {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.ADD_FILE,
            data: {
                type: 'addFile',
                visibility: this.visibility,
            },
        });

        this.mobileService
            .getSideNavAction()
            .pipe(
                tap(() => this.router.navigate([], { queryParams: null })),
                filter((action) => action === SideNaveActionsTypes.POSITIVE),
                switchMap(() => this.mobileService.getSideNavResponseData()),
                filter((data) => data.type === 'addFile'),
                takeUntil(this.destroy)
            )
            .subscribe((data) => {
                if (data.visibility == this.visibility) {
                    this.dataSource.add(new File(data.value));
                }
                this.cdr.markForCheck();
            });
    }

    onTagClick(tag: ResourceTag) {
        this.filter.filterByModel(tag.getFilter(), true);
    }

    updateFileTags(file: File, tagsIds: number[]) {
        this.listService
            .update({ id: file.id, tag_ids: tagsIds }, ['author,tags'])
            .pipe(takeUntil(this.destroy))
            .subscribe();
    }
    onSelectPriority(file: File, prio: string) {
        this.listService
            .update({ id: file.id, priority: prio }, ['author,tags'])
            .pipe(takeUntil(this.destroy))
            .subscribe((file) => this.dataSource.replace(file));
    }
    public download(file: File) {
        if (file.downloads) {
            this.downloadHelper.download(file.downloads);
        }
    }
    public delete(file: File) {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.GENERAL_NOTIFIER,
            data: {
                icon: 'delete',
                title: $localize`Delete ${file.title}`,
                contentBody: $localize`You are about to delete ${file.title}. Please use caution and make sure that you really intend to delete before confirming delete below.`,
                positiveBtn: $localize`Delete`,
                negativeBtn: $localize`Cancel`,
            },
        });
        this.mobileService
            .getSideNavAction()
            .pipe(
                filter((action) => action === SideNaveActionsTypes.POSITIVE),
                switchMap((res) => {
                    if (res) {
                        return this.listService.destroy(file);
                    }
                })
            )
            .subscribe((action) => {
                if (action) {
                    this.dataSource.removeById(file.id);
                    this.message.openMessage(MessageDeletedComponent);
                }
            });
    }
    public createMaterial(file: File) {
        file.files$.pipe(takeUntil(this.destroy)).subscribe((mediaFiles) => {
            this.mobileService.updateSideNavSub({
                dataType: SideNaveDataTypes.CREATE_RESOURCE,
                data: {
                    typeId: ResourceTypes.DocMaterial,
                    items: mediaFiles.map((media) => ({ item_type_value: media.type, name: media.path })),
                },
            });
        });
    }
    public edit(file: File) {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.ADD_FILE,
            data: {
                file: file,
                type: 'editFile',
                visibility: this.visibility,
            },
        });

        this.mobileService
            .getSideNavAction()
            .pipe(
                filter((action) => action === SideNaveActionsTypes.POSITIVE),
                switchMap(() => this.mobileService.getSideNavResponseData()),
                filter((data) => data.type === 'editFile'),
                takeUntil(this.destroy)
            )
            .subscribe((data) => {
                if (data.visibility == this.visibility) {
                    this.dataSource.replace(new File(data.value));
                }
                this.cdr.markForCheck();
            });
    }
}
