<div class="creta-mat-column" fxLayout="column">
    <span *ngIf="!items.controls.length && !showPrompter.value" i18n class="extra">Material Type</span>
    <ng-container *ngIf="!items.controls?.length && !showPrompter.value">
        <div fxFlex="grow" fxLayoutAlign="start center" class="pt-1 material-accordion">
            <button mat-button type="button" class="add-mat" (click)="addMaterial()">
                <mat-icon>add_circle</mat-icon>
                <span i18n>Add material type</span>
            </button>
        </div></ng-container
    >

    <div *ngIf="items.controls.length" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="material-type-title" fxLayout="row" fxLayoutAlign="start center">
            <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
            >
            </mat-checkbox>
            <ng-container *ngIf="!selection.selected.length">
                <mat-icon fontSet="klickdata" fontIcon="kd-icon-document" class="kd-icon-16 no-shadow"></mat-icon>
                <span i18n>Material Items</span>
            </ng-container>
        </div>
        <div fxLayout="row" fxLayoutAlign="end center">
            <ng-container *ngIf="selectedIndices.length">
                <app-item-translator
                    (onTranslate)="translate({ language_id: $event, indices: selectedIndices })"
                ></app-item-translator>
                <button
                    class="tools-btn create-material"
                    *ngIf="!loadingMaterialsCreation && selectedIndices.length > 1"
                    type="button"
                    (click)="loadingMaterialsCreation = !loadingMaterialsCreation; makeMaterials()"
                    mat-icon-button
                    matTooltip="Split into new materials"
                    i18n-matTooltip
                >
                    <mat-icon>call_split</mat-icon>
                </button>
                <button
                    class="tools-btn"
                    *ngIf="selectedIndices.length > 1"
                    type="button"
                    (click)="merge(selectedIndices)"
                    mat-icon-button
                    matTooltip="Merge into one"
                    i18n-matTooltip
                >
                    <mat-icon>merge_type</mat-icon>
                </button>
                <button
                    class="tools-btn"
                    type="button"
                    (click)="download()"
                    mat-icon-button
                    matTooltip="Export all selected"
                    i18n-matTooltip
                >
                    <mat-icon>save_alt</mat-icon>
                </button>

                <app-kd-logo-loading *ngIf="loadingMaterialsCreation" size="small"></app-kd-logo-loading>
                <button
                    class="tools-btn"
                    type="button"
                    (click)="delete(selectedIndices)"
                    mat-icon-button
                    matTooltip="Delete multiple items at once"
                    i18n-matTooltip
                >
                    <mat-icon class="material-icons-outlined">delete</mat-icon>
                </button>
                <button
                    class="tools-btn"
                    type="button"
                    (click)="regenerate(selectedIndices)"
                    mat-icon-button
                    matTooltip="Regenerate response for multiple items at once"
                    i18n-matTooltip
                >
                    <mat-icon>autorenew</mat-icon>
                </button>
                <button
                    class="tools-btn"
                    type="button"
                    (click)="copyAll()"
                    mat-icon-button
                    matTooltip="Copy to clipboard"
                    i18n-matTooltip
                >
                    <mat-icon class="material-icons-outlined">copy_all</mat-icon>
                </button></ng-container
            >

            <button
                type="button"
                (click)="onFollowUp(true)"
                class="start-new-prompt"
                mat-icon-button
                matTooltip="Use AI to follow up Material"
                i18n-matTooltip
            >
                <mat-icon>smart_toy</mat-icon>
            </button>
            <button
                *ngIf="items.controls.length"
                mat-icon-button
                type="button"
                class="expand"
                color="primary"
                fxFlex="0 0 auto"
                matTooltip="Open All"
                i18n-matTooltip="@@openAll"
                (click)="expandAll = !expandAll; toggleAll(expandAll)"
            >
                <mat-icon class="expanding" [class.expanded-all]="expandAll">last_page</mat-icon>
            </button>
        </div>
    </div>

    <div class="third-colmn-editor-wrapper mt-1" fxFlex="0 1 100%" fxLayout="column" fxLayoutGap="20px">
        <ng-container *ngIf="items.controls.length">
            <mat-accordion
                formArrayName="resource_items"
                class="third-colmn-editor-wrapper"
                fxFlex="0 1 100%"
                fxLayout="column"
                [multi]="true"
                cdkDropList
                [cdkDropListData]="items.controls"
                [cdkDropListDisabled]="isItemsProcessing"
                cdkDropListLockAxis="y"
                (cdkDropListDropped)="drop($event)"
            >
                <div
                    cdkDrag
                    cdkDragBoundary=".creta-mat-column"
                    *ngFor="let item of items.controls; let i = index"
                    [formGroupName]="i"
                >
                    <ng-container [ngSwitch]="item.value.item_type_value">
                        <app-materials-manager-text
                            *ngSwitchCase="'text_material'"
                            [index]="i"
                            [disabled]="item.disabled"
                            [resource]="resource"
                            [opened]="opened"
                            [active]="active"
                            [itemForm]="item | cast"
                        >
                            <button class="drag-btn" cdkDragHandle mat-icon-button>
                                <mat-icon>drag_indicator</mat-icon>
                            </button>
                        </app-materials-manager-text>
                        <app-materials-manager-link
                            *ngSwitchCase="'url_material'"
                            [index]="i"
                            [disabled]="item.disabled"
                            [resource]="resource"
                            [opened]="opened"
                            [itemForm]="item | cast"
                        >
                            <button class="drag-btn" cdkDragHandle mat-icon-button>
                                <mat-icon>drag_indicator</mat-icon>
                            </button>
                        </app-materials-manager-link>

                        <ng-container
                            *ngSwitchCase="'doc_material'"
                            [ngTemplateOutlet]="docMaterial"
                            [ngTemplateOutletContext]="{ i: i, item: item }"
                        ></ng-container>

                        <ng-container
                            *ngSwitchDefault
                            [ngTemplateOutlet]="docMaterial"
                            [ngTemplateOutletContext]="{ i: i, item: item }"
                        ></ng-container>
                    </ng-container>
                </div>
            </mat-accordion>
        </ng-container>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="relative-wrapper">
            <div
                *ngIf="!showPrompter.value && items.controls.length"
                [ngClass]="{ 'mt--20': items.controls.length <= 0 }"
                class="follow-up-btn"
            >
                <button class="follow-btn" mat-button (click)="onFollowUp()">
                    <mat-icon class="add-ai-btn">smart_toy</mat-icon>
                    <span i18n>Ask AI</span>
                </button>
                <button class="add-btn" mat-button (click)="addText()">
                    <mat-icon class="add-ai-btn">post_add</mat-icon>
                    <span i18n>Text</span>
                </button>
                <button matTooltip="Add image" i18n-matTooltip class="add-btn-icon" mat-icon-button (click)="addImg()">
                    <mat-icon class="add-ai-btn">add_photo_alternate</mat-icon>
                </button>
                <button
                    matTooltip="Upload file"
                    i18n-matTooltip
                    class="add-btn-icon"
                    mat-icon-button
                    (click)="addFile()"
                >
                    <mat-icon class="add-ai-btn">cloud_upload</mat-icon>
                </button>
                <button matTooltip="Add link" i18n-matTooltip class="add-btn-icon" mat-icon-button (click)="addLink()">
                    <mat-icon class="add-ai-btn">add_link</mat-icon>
                </button>
                <button
                    matTooltip="Record a material"
                    i18n-matTooltip
                    fxHide.lt-md
                    class="add-btn-icon"
                    mat-icon-button
                    (click)="onRecordMaterial()"
                >
                    <mat-icon class="add-ai-btn">radio_button_checked</mat-icon>
                </button>
            </div>
            <div
                id="promptInput"
                fxLayout="column"
                fxFlex="0 1 100%"
                *ngIf="showPrompter.value"
                class="propmpter-wrapper"
            >
                <div class="ask-me" *ngIf="!prompt.value" i18n>
                    Write a prompter text to ask AI to assist you in this context
                </div>
                <mat-form-field fxFlex="0 1 100%">
                    <textarea
                        #input
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="15"
                        autofocus
                        matInput
                        type="text"
                        [formControl]="prompt"
                    >
                    </textarea>
                </mat-form-field>
                <div fxFlex="1 1 100%" fxLayoutAlign="start center" fxLayoutGap="10px">
                    <button
                        [disabled]="!prompt.value"
                        class="create"
                        mat-button
                        i18n-matTooltip
                        matTooltip="Send a request to AI and wait for a reply"
                        (click)="addFUQ(); showPrompter.next(false)"
                    >
                        <mat-icon>smart_toy</mat-icon>
                        <span i18n>Create</span>
                    </button>
                    <button
                        class="prompter-cancel-btn"
                        type="button"
                        (click)="showPrompter.next(false)"
                        mat-button
                        matTooltip="Cancel"
                        i18n-matTooltip
                    >
                        <span>Cancel</span>
                    </button>
                </div>
            </div>

            <div *ngIf="items.controls.length && !showPrompter.value" class="counters tooltTipIcon">
                <button
                    (click)="$event.stopPropagation(); tooltip.toggle()"
                    (mouseenter)="tooltip.show()"
                    class="add-btn-icon"
                    #tooltip="matTooltip"
                    mat-icon-button
                    matTooltipClass="info-tooltip"
                    [matTooltip]="tooltipInfo"
                >
                    <mat-icon class="material-icons-outlined">info</mat-icon>
                </button>
            </div>
            <div *ngIf="showPrompter.value" class="counters prompt-input-on">
                <app-ai-model-selector (modelChange)="model_name = $event"></app-ai-model-selector>
            </div>
        </div>
    </div>
</div>
<ng-template #docMaterial let-i="i" let-item="item">
    <app-materials-manager-file
        [index]="i"
        [disabled]="item.disabled"
        [opened]="opened"
        [resource]="resource"
        [itemForm]="item | cast"
        (onCreateNewItems)="addItemsFromChildren($event)"
    >
        <button class="drag-btn" cdkDragHandle mat-icon-button>
            <mat-icon>drag_indicator</mat-icon>
        </button>
    </app-materials-manager-file>
</ng-template>
