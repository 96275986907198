<div fxFlex="0 0 100%" fxLayout="row wrap" class="access-select">
    <app-sticky-header
        class="sticky-header"
        [title]="data.title"
        [context]="data.contextTitle"
        [contextIcon]="data.contextIcon"
        (onSubmit)="submit()"
        (onCancel)="bottomSheetRef.dismiss()"
        [savingStatus]="saving"
    ></app-sticky-header>
    <ng-container *ngIf="customer$ | async; let customer">
        <mat-tab-group class="user-manage-cp-access mt-1" fxFlex="0 0 90%" ngClass.xs="xs">
            <mat-tab>
                <ng-template mat-tab-label>
                    <span>{{ customer.scope_names[AppScope.USERS] ?? AppScope.label(AppScope.USERS) }}</span>
                </ng-template>
                <app-resource-access-control-users
                    [permissionsForm]="permissionsForm"
                    [resourcePermissions]="resourcePermissions"
                    class="mt-1"
                ></app-resource-access-control-users>
            </mat-tab>

            <mat-tab>
                <ng-template mat-tab-label>
                    <span>{{ customer.scope_names[AppScope.GROUPS] ?? AppScope.label(AppScope.GROUPS) }}</span>
                </ng-template>
                <app-resource-access-control-groups
                    [permissionsForm]="permissionsForm"
                    [resourcePermissions]="resourcePermissions"
                    [columns]="
                        customer.division_mode
                            ? ['select', 'name', 'parent', 'users_count', 'permissions']
                            : ['select', 'name', 'users_count', 'permissions']
                    "
                    [customer]="customer"
                    class="mt-1"
                ></app-resource-access-control-groups>
            </mat-tab>
            <mat-tab *ngIf="customer.division_mode">
                <ng-template mat-tab-label>
                    <span>{{ customer.scope_names[AppScope.TEAM] ?? AppScope.label(AppScope.TEAM) }}</span>
                </ng-template>
                <app-resource-access-control-groups
                    [permissionsForm]="permissionsForm"
                    [resourcePermissions]="resourcePermissions"
                    [filtersToBeApplied]="[{ property: 'team', items: [1] }]"
                    [isTeam]="true"
                    [columns]="['select', 'name', 'users_count', 'permissions']"
                    [customer]="customer"
                    class="mt-1"
                ></app-resource-access-control-groups>
            </mat-tab>
        </mat-tab-group>
    </ng-container>
</div>
