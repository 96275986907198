<form [formGroup]="educatorForm" *ngIf="navData" class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutAlign="start center" fxLayoutGap="5px">
        <mat-icon>engineering</mat-icon>
        <div fxLayoutGap="4px">
            <span i18n>Create new connection</span>
        </div>
    </div>

    <div class="role-name" fxLayoutAlign="start start" fxLayoutGap="15px" fxLayout="column">
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" class="role-name w-100">
            <label fxLayoutGap="4px"
                ><span>{{ navData.data.educatorLabel }}</span> <span i18n>photo</span>
            </label>

            <div class="user-img-uploader" fxLayoutAlign="center center">
                <app-media
                    [simple]="true"
                    *ngIf="!educatorForm.value.media_id"
                    formControlName="media_id"
                    dropLabel="Drop educator image"
                    i18n-dropLabel="@@dropEducatorImage"
                    uploadIcon="engineering"
                    [scope]="AppScope.USERS"
                ></app-media>
                <app-image-view
                    *ngIf="educatorForm.value.media_id"
                    [media_id]="educatorForm.value.media_id"
                    paddingBottom="100px"
                >
                    <button
                        *ngIf="educatorForm.value.media_id"
                        class="remove-image"
                        mat-icon-button
                        color="warn"
                        matTooltip="Remove image"
                        i18n-matTooltip
                        (click)="educatorForm.get('media_id').setValue(null)"
                    >
                        <mat-icon class="md-24">delete_outline</mat-icon>
                    </button>
                </app-image-view>
            </div>
            <small i18n>Photo size shouldn't exceed 1MB.</small>
        </div>

        <div fxLayoutGap="4px" class="assignee input-wrapper default-select" fxLayout="column">
            <span class="label" i18n>Email</span>
            <div fxLayout="row" fxLayoutGap.lt-md="10px" class="input-parent">
                <mat-form-field class="small-field users-rec-input" appearance="fill">
                    <input
                        type="text"
                        matInput
                        #assigneeRecieverInput
                        i18n-placeholder
                        placeholder="Email"
                        formControlName="email"
                    />
                    <button
                        *ngIf="nextContact && nextContact?.id"
                        mat-icon-button
                        class="cancel-contact"
                        matSuffix
                        (click)="onEditNameOrEmail()"
                    >
                        <mat-icon>cancel</mat-icon>
                    </button>
                </mat-form-field>
                <ng-container *ngIf="userSearch$ | async; let users">
                    <button *ngIf="users.length" mat-icon-button (click)="expandmail = !expandmail">
                        <mat-icon>{{ expandmail ? 'expand_less' : 'expand_more' }}</mat-icon>
                    </button>
                </ng-container>
            </div>
            <div class="collapse" [ngClass]="{ expand: expandmail }">
                <ng-container *ngIf="!!educatorForm.value.email" [ngTemplateOutlet]="usersChips"> </ng-container>
            </div>
        </div>

        <div fxLayoutGap="4px" class="assignee input-wrapper default-select" fxLayout="column">
            <span class="label" i18n>Name</span>
            <div fxLayout="row" fxLayoutGap.lt-md="10px" class="input-parent">
                <mat-form-field class="small-field users-rec-input" appearance="fill">
                    <input
                        type="text"
                        matInput
                        #assigneeRecieverInput
                        i18n-placeholder
                        placeholder="Name"
                        formControlName="name"
                    />
                    <button
                        *ngIf="nextContact && nextContact.id"
                        mat-icon-button
                        class="cancel-contact"
                        matSuffix
                        (click)="onEditNameOrEmail()"
                    >
                        <mat-icon>cancel</mat-icon>
                    </button>
                </mat-form-field>
                <ng-container *ngIf="userSearch$ | async; let users">
                    <button *ngIf="users.length" mat-icon-button (click)="expandname = !expandname">
                        <mat-icon>{{ expandname ? 'expand_less' : 'expand_more' }}</mat-icon>
                    </button>
                </ng-container>
            </div>

            <div class="collapse" [ngClass]="{ expand: expandname }">
                <ng-container *ngIf="!!educatorForm.value.email" [ngTemplateOutlet]="usersChips"> </ng-container>
            </div>
        </div>
        <app-user-setting-input
            type="text"
            i18n-placeholder
            [placeholder]="'Enter ' + navData.data.educatorLabel + ' phone number'"
            [control]="educatorForm.get('phone') | cast"
        >
            <label role="input-label" fxLayoutGap="4px">
                <span i18n>Phone number</span>
            </label>
        </app-user-setting-input>
        <app-user-setting-input
            [control]="educatorForm.get('note') | cast"
            type="textarea"
            class="textArea-field-user"
            [textAreaRows]="3"
            placeholder="Note"
            i18n-placeholder
        >
            <label role="textarea-label"><span i18n> Note</span> </label>
        </app-user-setting-input>
        <app-user-setting-input
            type="text"
            i18n-placeholder
            [placeholder]="'Enter position'"
            [control]="educatorForm.get('position') | cast"
        >
            <label role="input-label" fxLayoutGap="4px">
                <span i18n>Position</span>
            </label>
        </app-user-setting-input>
        <div fxLayoutGap="4px" class="input-wrapper default-select" fxLayout="column">
            <span class="label" i18n>Academy</span>
            <div fxLayout="row" *ngIf="!customerRecipient" fxLayoutGap="10px" fxLayoutGap.lt-md="10px">
                <mat-form-field class="small-field users-rec-input" appearance="fill">
                    <input
                        type="text"
                        matInput
                        #customerRecieverInput
                        i18n-placeholder
                        placeholder="Search academies"
                        [formControl]="customerCtrl"
                        [matAutocomplete]="autoCustomer"
                    />
                    <mat-autocomplete
                        #autoCustomer="matAutocomplete"
                        (optionSelected)="selectedAcademy(autoCustomer, $event)"
                    >
                        <mat-option *ngFor="let customer of customerSearch$ | async" [value]="customer">
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <span>{{ customer.name }}</span>
                            </div>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div fxLayout="column wrap" [ngClass]="{ 'mt-1': !!customerRecipient }">
                <mat-chip-list
                    *ngIf="customerRecipient"
                    class="reci-chips mb-1"
                    #chipList
                    aria-label="Recievers selection"
                >
                    <mat-chip
                        (removed)="removeCustomer()"
                        [selectable]="false"
                        [matTooltip]="(customerRecipient.type | clientStatus)?.label"
                        [ngStyle]="{
                            color: (customerRecipient.type | clientStatus)?.fg,
                            background: (customerRecipient.type | clientStatus)?.bg,
                            border: (customerRecipient.type | clientStatus)?.border
                        }"
                        class="users-wrap customer"
                    >
                        <div
                            [ngStyle]="{
                                color: (customerRecipient.type | clientStatus)?.fg
                            }"
                            fxLayout="row"
                            fxLayoutAlign="start center"
                            fxLayoutGap="5px"
                        >
                            <span class="cust-name">{{ customerRecipient?.name }}</span>
                        </div>
                        <button
                            [ngStyle]="{
                                color: (customerRecipient.type | clientStatus)?.fg
                            }"
                            *ngIf="!navData.data.customer"
                            matChipRemove
                        >
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip></mat-chip-list
                >
            </div>
        </div>

        <ng-container>
            <div fxLayoutGap="4px" fxLayout="column" class="w-100 input-field-parent deadline">
                <span i18n>Contact Date</span>
                <mat-form-field
                    appearance="outline"
                    floatLabel="never"
                    class="w-100"
                    fxFlex="grow"
                    fxFlex="1 1 100%"
                    fxFlex.lt-md="1 1 100%"
                >
                    <mat-label i18n>Enter next contact date</mat-label>
                    <input
                        matInput
                        [matDatepicker]="contactDatepicker"
                        (focus)="contactDatepicker.open()"
                        formControlName="deadline"
                    />
                    <mat-datepicker-toggle matIconSuffix [for]="contactDatepicker"></mat-datepicker-toggle>
                    <mat-datepicker #contactDatepicker></mat-datepicker>
                </mat-form-field>
            </div>
        </ng-container>
        <div fxLayoutGap="4px" fxLayout="column" class="w-100 input-field-parent select">
            <span i18n>State</span>
            <mat-form-field class="role-field">
                <mat-select fxFlex="1 1 100%" formControlName="type">
                    <mat-option value="client" i18n>Client</mat-option>
                    <mat-option value="partner" i18n>Partner</mat-option>
                    <mat-option value="offer" i18n>Offerers</mat-option>
                    <mat-option value="prospect" i18n>Prospect</mat-option>
                    <mat-option value="nothanker" i18n>Nothanker</mat-option>
                    <mat-option value="contact" i18n>Contact</mat-option>
                    <mat-option value="exclient" i18n>Ex-Client</mat-option>
                    <mat-option value="other" i18n>Other</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxLayoutGap="4px" fxLayout="column" class="w-100 input-field-parent k3-crm-tags">
            <span i18n>Tags</span>
            <app-tag-chip-select
                class="w-100"
                placeholder="Add Tag"
                [language_id]="currentLaguageId$ | async"
                i18n-placeholder
                formControlName="tag_ids"
            ></app-tag-chip-select>
        </div>
        <app-user-setting-input
            [control]="educatorForm.get('about') | cast"
            type="textarea"
            class="textArea-field-user"
            [textAreaRows]="3"
            [placeholder]="'Explain the role of the ' + navData.data.educatorLabel"
            i18n-placeholder
        >
            <label role="textarea-label"
                ><span i18n> About</span
                ><mat-icon
                    class="icon-explain"
                    matTooltip="Explain the role of the educator in the context of the resource like event, course etc so the attendees can understand the meaning of this person in the context of KLMS. Its an extract of a cv, not a full version."
                    i18n-matTooltip
                    >help_outline</mat-icon
                >
            </label>
        </app-user-setting-input>
        <div fxLayoutGap="4px" class="input-wrapper default-select" fxLayout="column">
            <app-user-chip-select
                titleLabel="Signature"
                i18n-titleLabel
                [searchUsersParams]="{ limit: 20, userRole: 'superadmin' }"
                [defaultMe]="true"
                formControlName="manager"
            ></app-user-chip-select>
        </div>
    </div>
    <div class="action" fxLayout="row-reverse" fxLayoutAlign="start center" fxLayoutGap="10px">
        <button
            [disabled]="
                !educatorForm.get('name').value && !educatorForm.get('email').value && !educatorForm.get('note').value
            "
            fxFlex="0 0 auto"
            class="mobile-button positive"
            (click)="submit()"
            mat-raised-button
        >
            <span *ngIf="!!nextContact?.id">Add connection</span>
            <span *ngIf="!nextContact?.id">Create connection</span>
        </button>
        <button
            fxFlex="0 0 auto"
            class="mobile-button negative"
            (click)="onClose.emit(); educatorForm.reset()"
            mat-button
        >
            <span i18n>Cancel</span>
        </button>
    </div>
</form>
<ng-template #usersChips>
    <mat-chip-list class="users-chip-select" *ngIf="!nextContact">
        <mat-chip
            *ngFor="let user of userSearch$ | async"
            (click)="onSelectUser(user)"
            [matTooltip]="user.id.toString()"
            [selectable]="false"
            [removable]="false"
            color="primary"
        >
            <div fxLayout="row wrap" fxLayoutAlign="start center">
                <span *ngIf="user.name">{{ user.name }}</span>
                <span *ngIf="!user.name">Anonymous</span> (<span>{{ user.email }}</span
                >)
            </div>
        </mat-chip>
    </mat-chip-list>
</ng-template>
