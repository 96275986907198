import { ChangeDetectionStrategy, Component, OnInit, Input } from '@angular/core';
import { QuestionTypes } from '@klickdata/core/question';

@Component({
    selector: 'app-resource-builder-ai-loader-panel',
    templateUrl: './resource-builder-ai-loader-panel.component.html',
    styleUrls: ['./resource-builder-ai-loader-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceBuilderAiLoaderPanelComponent implements OnInit {
    QuestionTypes = QuestionTypes;
    @Input() aiData: any;

    constructor() {}

    ngOnInit(): void {}
}
