<div class="dashboard-progress res-assign-block" fxFlex="0 1 100%">
    <app-sticky-header
        *ngIf="!hideSticky"
        class="sticky-header"
        [title]="data.name ? data.name : data.title"
        [context]="context ? context : data.context"
        [contextIcon]="contextIcon ? contextIcon : data.contextIcon"
        (onSubmit)="dirty() ? onSave.emit(resourceForm.value) : onCancel.emit()"
        (onCancel)="onCancel.emit()"
    ></app-sticky-header>
    <mat-tab-group class="dashboard-tabs" mat-stretch-tabs (selectedTabChange)="tabChanged($event)">
        <!-- <mat-card fxFlex="1 1 100%" fxFlex.lt-lg="0 1 90%" fxFlex.lt-md="0 1 100%" fxLayout="column wrap"> -->

        <mat-tab label="Courses" i18n-label="@@courses" style="max-height: 100%">
            <ng-container
                [ngTemplateOutlet]="selectTemp"
                [ngTemplateOutletContext]="{ label: 'Number of courses', scope: AppScope.COURSE }"
            ></ng-container>
        </mat-tab>
        <mat-tab label="Material" i18n-label="@@material" style="max-height: 100%">
            <ng-container
                *ngIf="openedTabIndices.includes(1)"
                [ngTemplateOutlet]="selectTemp"
                [ngTemplateOutletContext]="{ label: 'Number of materials', scope: AppScope.MATERIAL }"
            ></ng-container>
        </mat-tab>
        <mat-tab label="Tests" i18n-label="@@tests" style="max-height: 100%">
            <ng-container
                *ngIf="openedTabIndices.includes(2)"
                [ngTemplateOutlet]="selectTemp"
                [ngTemplateOutletContext]="{ label: 'Number of tests', scope: AppScope.TEST }"
            ></ng-container>
        </mat-tab>
        <mat-tab label="Surveys" i18n-label="@@surveys" style="max-height: 100%">
            <ng-container
                *ngIf="openedTabIndices.includes(3)"
                [ngTemplateOutlet]="selectTemp"
                [ngTemplateOutletContext]="{ label: 'Number of surveys', scope: AppScope.SURVEY }"
            ></ng-container>
        </mat-tab>
        <mat-tab label="E-courses" i18n-label="@@ecourses" style="max-height: 100%">
            <ng-container
                *ngIf="openedTabIndices.includes(4)"
                [ngTemplateOutlet]="selectTemp"
                [ngTemplateOutletContext]="{ label: 'Number of e-courses', scope: AppScope.E_COURSE }"
            ></ng-container>
        </mat-tab>
        <mat-tab label="Packages" i18n-label="@@packages" style="max-height: 100%">
            <ng-container
                *ngIf="openedTabIndices.includes(5)"
                [ngTemplateOutlet]="selectTemp"
                [ngTemplateOutletContext]="{ label: 'Number of packages', scope: AppScope.PACKAGE }"
            ></ng-container>
        </mat-tab>
    </mat-tab-group>
</div>

<ng-template #selectTemp let-label="label" let-scope="scope">
    <app-resource-select
        *ngIf="resourceForm.value.id"
        [selectionLabel]="label"
        [scope_id]="scope"
        [customer_id]="data.customer_id"
        [accessForm]="resourceForm"
        [referenceFilter]="referenceProperty"
        i18n-selectionLabel
        [sync_all_key]="sync_all_key"
        [attachKey]="attachKey"
        [detachKey]="detachKey"
    ></app-resource-select>
</ng-template>
