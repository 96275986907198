<div *ngIf="navData" class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutAlign="start center" fxLayoutGap="5px">
        <mat-icon>save_alt</mat-icon>
        <div i18n>Download</div>
    </div>
    <div class="desc" i18n>Please select following:</div>

    <mat-checkbox class="select-all" (change)="toggleAll($event.checked)"> <span i18n>Select all</span></mat-checkbox>

    <form class="download-menu-slide" [formGroup]="form">
        <div *ngFor="let option of navData.data.data">
            <p>
                <mat-checkbox [formControlName]="option.value"
                    ><mat-icon class="download-icon">save_alt</mat-icon>
                    <span class="btn-label">{{ option.label }}</span></mat-checkbox
                >
            </p>
        </div>
    </form>
    <div class="action" fxLayout="row-reverse" fxLayoutAlign="start center" fxLayoutGap="10px">
        <div>
            <button
                [disabled]="isSubmitDisabled()"
                *ngIf="!downloading"
                fxFlex="0 0 auto"
                class="mobile-button positive"
                (click)="download()"
                mat-raised-button
                i18n
            >
                Download
            </button>
            <app-saving-button *ngIf="downloading"></app-saving-button>
        </div>
        <button fxFlex="0 0 auto" class="mobile-button negative" (click)="onClose.emit()" mat-button i18n>
            Cancel
        </button>
    </div>
</div>
