import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '@klickdata/core/auth';
import { Resource } from '@klickdata/core/resource';
import { ResourceOpportunity } from '@klickdata/core/opportunity';
import { User } from '@klickdata/core/user';
import { ExitService } from '@klickdata/player/src/core/exit/exit.service';
import { OccasionService } from '@klickdata/player/src/core/occasion/occasion.service';
import { Observable } from 'rxjs';
import { DownloadHelperService } from 'apps/klickdata/src/app/shared/dialog/download-pdf-dialog/download-helper.service';

@Component({
    selector: 'app-course-player-results-diploma',
    templateUrl: './course-player-results-diploma.component.html',
    styleUrls: ['./course-player-results-diploma.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoursePlayerResultsDiplomaComponent implements OnInit {
    @Input() user: User;
    @Input() opportunity: ResourceOpportunity;
    @Input() resource: Resource;
    @Output() repeatCourse: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() rewardType: 'Participation' | 'Diploma' | 'Certification' = 'Participation';
    public authUser$: Observable<User>;
    constructor(
        protected exitService: ExitService,
        protected opportunityService: OccasionService,
        protected auth: AuthService,
        protected downloadHelper: DownloadHelperService
    ) {}
    public ngOnInit() {
        this.authUser$ = this.auth.getUser();
    }
    public onClose() {
        this.exitService.exit();
    }

    public viewDiploma() {
        if (this.resource.downloads) {
            this.downloadHelper.downloadWithOptions(this.resource.downloads);
        } else {
            window.open(this.opportunity.diploma, '_blank');
        }
    }
}
