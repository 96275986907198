<div class="resource-selector-wrapper" fxLayout="column" fxLayoutGap="10px" fxFlex="0 0 100%">
    <app-table-filter
        class="res-select-tables"
        [cacheScope]="AppScope.RESOURCES"
        [inGlobalFilter]="true"
        [filtersTogglesInOnBtn]="true"
        color="accent"
        #filter
    >
        <app-table-search [inGlobalFilter]="true" class="align-space" searchFilterRef></app-table-search>

        <app-language-filter
            [inGlobalFilter]="true"
            #langFilter
            fxFlex="0 0 auto"
            fxLayout="row"
            langFilterRef
        ></app-language-filter>
        <app-tag-chip-search-filter
            [inGlobalFilter]="true"
            [languageChange]="langFilter.filterChange"
            fxFlex="50%"
            fxFlex.lt-md="100%"
            fxLayoutAlign="start"
            tagsFilterRef
        ></app-tag-chip-search-filter>
        <app-category-chip-search-filter
            [inGlobalFilter]="true"
            fxFlex="50%"
            fxFlex.lt-md="100%"
            [scope_id]="type_scope_id"
            categoriesFilterRef
        ></app-category-chip-search-filter>
    </app-table-filter>
    <mat-progress-bar
        class="progress progress-table-loader"
        *ngIf="(dataSource.status | async) === 'loading'"
        mode="indeterminate"
    >
    </mat-progress-bar>
    <mat-table
        #table
        [dataSource]="dataSource"
        matSort
        matSortDirection="desc"
        [matSortActive]="context === 'author' ? 'created_at' : 'published'"
    >
        <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let resource" (click)="$event.stopPropagation()">
                <mat-checkbox
                    #radio
                    [disabled]="isResDefaultSelected(resource.id) || !resource.ready_publish"
                    [checked]="resource.selected"
                    (change)="!selectionSub.value.includes(resource.id) && onCheckboxClick($event, resource)"
                ></mat-checkbox>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@title"> Title </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <ng-container *ngIf="isMobile$ | async">
                    <div class="mobile-row-head" fxLayout="row">
                        <mat-checkbox
                            #radio
                            [disabled]="isResDefaultSelected(resource.id) || !resource.ready_publish"
                            [checked]="resource.selected"
                            (change)="!selectionSub.value.includes(resource.id) && onCheckboxClick($event, resource)"
                        ></mat-checkbox>
                        <span>{{ resource.title }}</span>
                        <button
                            (click)="expandedRow = expandedRow == resource ? null : resource"
                            mat-icon-button
                            class="expand-btn-mob"
                        >
                            <mat-icon>{{ expandedRow === resource ? 'expand_less' : 'expand_more' }}</mat-icon>
                        </button>
                    </div>
                </ng-container>
                <ng-container *ngIf="!(isMobile$ | async)">
                    {{ resource.title }}
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="question_number">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@Q#"> Q# </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span i18n="@@Q#" class="mobile-label"> Q# </span>
                {{ resource.number_of_items }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="author_name">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@createdBy"> Created by </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span i18n="@@createdBy" class="mobile-label"> Created by </span>
                <ng-container *ngIf="resource.author$ | async; let loadedAuthor">
                    {{ $any(loadedAuthor).name }}
                </ng-container>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="created_at">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@createdAt"> Created at </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span i18n="@@createdAt" class="mobile-label"> Created at </span>
                {{ resource.created_at | kdDateFormat : 'YYYY-MM-DD' }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="language">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@language"> Language </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span i18n="@@language" class="mobile-label"> Language </span>
                <ng-container *ngIf="resource.language_id">
                    <app-language-selector
                        [language_id]="resource.language_id"
                        class="icon-resource-builder"
                        fxFlex="0 0 auto"
                    ></app-language-selector>
                </ng-container>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="published">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@publishedAt"> Published at </mat-header-cell>

            <mat-cell *matCellDef="let resource">
                <span i18n="@@publishedAt" class="mobile-label"> Published at </span>
                <span *ngIf="resource.published">
                    {{ resource.published | kdDateFormat : 'YYYY-MM-DD' }}
                </span>
                <span *ngIf="!resource.published" i18n="@@notPublishedYet"> Not yet. </span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="tags">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@tags"> Tags </mat-header-cell>
            <mat-cell [class.no-display-cell]="!resource.tags_attached" class="inset-shadow" *matCellDef="let resource">
                <div class="shadow-maker"></div>
                <mat-chip-list *ngIf="!!resource.tags.length" class="middle-details">
                    <mat-chip
                        *ngFor="let tag of resource.tags"
                        (click)="$event.stopPropagation(); onTagClick(tag)"
                        class="tag-chip-item"
                        fxLayout="row"
                        fxLayoutAlign="start center"
                        ><span>{{ tag.name }}</span></mat-chip
                    >
                </mat-chip-list>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row
            #matRow
            [ngClass]="!selectionSub.value.includes(resource.id) ? 'link' : 'disableRow disableIt'"
            *matRowDef="let resource; columns: columns"
            (click)="resource.ready_publish && !selectionSub.value.includes(resource.id) && onRowSelected(resource)"
            class="item-row"
            [class.expand-item-row]="expandedRow == resource"
            [class.no-tags]="!resource.tags || (resource.tags && !resource.tags?.length)"
            [class.disable-row]="!resource.ready_publish"
            [matTooltip]="getRowTooltip(!resource.ready_publish)"
            matRipple
        >
        </mat-row>
    </mat-table>

    <mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>
</div>
<div *ngIf="!!selectedResources.length" fxLayout="row" fxLayoutAlign="end center" class="submitBtn">
    <button mat-button color="primary" (click)="onSubmitMultiple()"><span i18n>Done</span></button>
</div>
