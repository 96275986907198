import { Injectable } from '@angular/core';
import { AuthService } from '@klickdata/core/auth';
import { ConfigService } from '@klickdata/core/config';
import { RequestBuilderService, ResponseData } from '@klickdata/core/http';
import { ResourceOpportunity, ResourceOpportunityData } from '@klickdata/core/opportunity';
import { User } from '@klickdata/core/user';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { first, map, switchMap, take } from 'rxjs/operators';

@Injectable()
export class OccasionService {
    protected resourceUrl: string;
    private doneOpportunity$: Subject<ResourceOpportunity> = new Subject<ResourceOpportunity>();

    constructor(
        protected auth: AuthService,
        protected builder: RequestBuilderService,
        protected config: ConfigService
    ) {
        this.resourceUrl = `${this.config.config.resourceUrl}opportunities`;
    }
    public updateLastestDoneOpportunity(opportunity: ResourceOpportunity) {
        this.doneOpportunity$.next(opportunity);
    }
    public getLastestDoneOpportunity(): Observable<ResourceOpportunity> {
        return this.doneOpportunity$.asObservable();
    }
    public setOccasion(resourceId: number): Observable<ResourceOpportunity> {
        return this.auth.getUser().pipe(
            take(1),
            switchMap((user: User) => {
                return this.builder
                    .post<ResourceOpportunityData>(this.resourceUrl, {
                        resource_id: resourceId,
                        user_id: user.id,
                    })
                    .request()
                    .pipe(map((res) => this.createOccasion(res.data)));
            })
        );
    }

    public createChildOccasion(
        parentId: number,
        resourceId: number,
        eventOccId?: number
    ): Observable<ResourceOpportunity> {
        return this.auth.getUser().pipe(
            first(),
            switchMap((user: User) => {
                return this.builder
                    .post<ResourceOpportunityData>(
                        this.resourceUrl,
                        // eventOccId
                        //     ? {
                        //           resource_id: resourceId,
                        //           parent_id: parentId,
                        //           user_id: user.id,
                        //           event_occasion_id: eventOccId,
                        //       }
                        //     :
                        {
                            resource_id: resourceId,
                            parent_id: parentId,
                            user_id: user.id,
                        }
                    )
                    .request()
                    .pipe(map((res) => this.createOccasion(res.data)));
            })
        );
    }
    public updateOccasion(occId: number, data: ResourceOpportunityData): Observable<ResourceOpportunity> {
        return this.builder
            .put<ResourceOpportunityData>(`${this.resourceUrl}/${occId}`, data)
            .request()
            .pipe(map((res) => this.createOccasion(res.data)));
    }

    public listenToOccasionUpdate(occId: number, qsId: number): Observable<ResourceOpportunity> {
        return this.auth
            .listenPrivate(`occasion.${occId}`, 'OccasionVerifierEvent', this.getOccData(occId, qsId))
            .pipe(map((res) => this.createOccasion(res.data)));
    }

    public getOccData(occId: number, qsId: number): Observable<ResponseData<ResourceOpportunityData>> {
        return this.builder
            .get<ResourceOpportunityData>(`${this.resourceUrl}/${occId}`)
            .param('question', qsId)
            .request();
    }

    public setOccasionId(opportunity) {
        return new ResourceOpportunity(opportunity);
    }

    public done(opportunity: ResourceOpportunity, feedback?: string) {
        return this.builder
            .put(`${this.resourceUrl}/${opportunity.id}`, { done: moment() })
            .request()
            .pipe(map((res) => this.createOccasion(res.data)));
    }

    public unDone(opportunity: ResourceOpportunity, feedback?: string) {
        return this.builder
            .put(`${this.resourceUrl}/${opportunity.id}`, { done: null })
            .request()
            .pipe(map((res) => this.createOccasion(res.data)));
    }

    public signoff(opportunity: ResourceOpportunity, signoff: { body: string }) {
        return this.builder
            .put(`${this.resourceUrl}/${opportunity.id}`, { signoff: signoff })
            .request()
            .pipe(map((res) => this.createOccasion(res.data)));
    }

    public cancel(opportunity: ResourceOpportunity) {
        return this.builder
            .put(`${this.resourceUrl}/${opportunity.id}`, { cancelled: moment() })
            .request()
            .pipe(map((res) => this.createOccasion(res.data)));
    }

    public getOccasion(oppId: number): Observable<ResourceOpportunity> {
        return this.auth.getUser().pipe(
            take(1),
            switchMap((user) => {
                return this.builder
                    .get<ResourceOpportunityData>(`${this.resourceUrl}/${oppId}`)
                    .request()
                    .pipe(map((res) => this.createOccasion(res.data)));
            })
        );
    }

    public checkForOccasion(
        resourceId: number,
        user?: User,
        started = true,
        index = 0
    ): Observable<ResourceOpportunity> {
        if (user) {
            return this.getOccasionByUser(resourceId, user, started, index);
        }
        return this.auth.getUser().pipe(
            take(1),
            switchMap((me) => {
                return this.getOccasionByUser(resourceId, me, started, index);
            })
        );
    }

    private getOccasionByUser(resourceId: number, user: User, started: boolean, index: number) {
        return this.builder
            .get<ResourceOpportunityData[]>(this.resourceUrl)
            .param(started ? 'started' : 'user', user.id)
            .param('resource', resourceId)
            .param('sort', 'updated_at')
            .request()
            .pipe(map((res) => (res.data.length ? this.createOccasion(res.data[index]) : null)));
    }

    public checkForOccasionWithParent(param: { parent_id: number; resource_id: number; eager?: string[] | string }) {
        return (
            this.builder
                .get<ResourceOpportunityData[]>(this.resourceUrl)
                // .param('started', user.id)
                .param('resource', `${param.resource_id}`)
                .param('parent', `${param.parent_id}`)
                .param('sort', 'updated_at')
                .param('dir', 'desc')
                .putEager(param.eager)
                .limit(1)
                .request()
                .pipe(map((res) => (res.data.length ? this.createOccasion(res.data[0]) : null)))
        );
    }
    public getAllOccasionsWithParent(param: {
        parent_id: number;
        resource_id: number;
        sort?: string;
        eager?: string[] | string;
    }): Observable<ResourceOpportunity[]> {
        return this.builder
            .get<ResourceOpportunityData[]>(this.resourceUrl)
            .param('resource', `${param.resource_id}`)
            .param('parent', `${param.parent_id}`)
            .param('sort', param.sort ?? 'updated_at')
            .param('dir', 'desc')
            .putEager(param.eager)
            .request()
            .pipe(map((res) => (!!res.data.length ? res.data.map((opp) => this.createOccasion(opp)) : [])));
    }

    public deleteOccasion(opportunity: number) {
        return this.builder.delete(`${this.resourceUrl}/${opportunity}`).request();
    }

    protected createOccasion(data: ResourceOpportunityData) {
        return new ResourceOpportunity(data);
    }
}
