<form
    fxLayout="row wrap"
    fxLayout.lt-md="column-reverse"
    [ngClass]="{ 'edit-msg-mode': msg }"
    [formGroup]="messageForm"
    class="two-columns-layout"
    fxLayoutGap.lt-md="10px"
>
    <mat-card fxLayout="column" class="first-child-layout border-green-top">
        <div class="nav-msg-wrapper" fxLayout="row wrap" fxLayoutAlign="center center">
            <div fxLayout="row wrap" class="wrap">
                <!-- Message reciepients appear only in create -->
                <div *ngIf="!msg || msg.draft" class="input-wrapper" fxLayout="column">
                    <span class="label" i18n>To</span>
                    <div fxLayout="row" fxLayoutGap="20px" fxLayoutGap.lt-md="10px">
                        <mat-form-field
                            *ngIf="selectedRecepientFilter.value === 'users'"
                            class="small-field mt-1 users-rec-input"
                            appearance="fill"
                        >
                            <input
                                type="text"
                                matInput
                                #userRecieverInput
                                i18n-placeholder
                                placeholder="Search"
                                [formControl]="userCtrl"
                                [matAutocomplete]="autoUser"
                            />
                            <mat-autocomplete
                                #autoUser="matAutocomplete"
                                (optionSelected)="selected(autoUser, $event, true)"
                            >
                                <mat-option *ngFor="let user of userSearch$ | async" [value]="user">
                                    <div fxLayout="row" fxLayoutAlign="start center">
                                        <mat-icon> {{ user.role_value | userRoleIcon }}</mat-icon>
                                        <span>{{ user.name }}</span>
                                    </div>
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                        <mat-form-field
                            *ngIf="selectedRecepientFilter.value === 'groups'"
                            class="small-field mt-1 groups-rec-input"
                            appearance="fill"
                        >
                            <input
                                type="text"
                                matInput
                                #groupRecieverInput
                                i18n-placeholder
                                placeholder="Search"
                                [formControl]="groupCtrl"
                                [matAutocomplete]="autoGroup"
                            />
                            <mat-autocomplete
                                #autoGroup="matAutocomplete"
                                (optionSelected)="selected(autoGroup, $event, false)"
                            >
                                <mat-option *ngFor="let group of groupSearch$ | async" [value]="group">
                                    {{ group.name }}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                        <div fxLayoutAlign="center center" fxLayoutGap="15px" fxLayout="row">
                            <mat-button-toggle-group [formControl]="selectedRecepientFilter">
                                <mat-button-toggle
                                    matTooltip="Send to selected learners in the Academy of KLMS"
                                    i18n-matTooltip
                                    class="user-choice"
                                    value="users"
                                >
                                    <mat-icon>person</mat-icon>
                                </mat-button-toggle>
                                <mat-button-toggle
                                    matTooltip="Send to selected group/groups in the Academy of KLMS"
                                    i18n-matTooltip
                                    class="group-choice"
                                    value="groups"
                                >
                                    <mat-icon>groups</mat-icon>
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutAl>
                        <mat-chip-list
                            *ngIf="userRecipients.length"
                            class="reci-chips mb-1"
                            #chipList
                            aria-label="Recievers selection"
                        >
                            <mat-chip
                                *ngFor="let recipient of userRecipients"
                                (removed)="removeUser(recipient)"
                                [selectable]="false"
                                class="users-wrap"
                                [ngClass]="{
                                    'dark-blue-bg': recipient.role_value === 'user',
                                    'green-bg': recipient.role_value !== 'user'
                                }"
                            >
                                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                                    <mat-icon>{{ recipient.role_value | userRoleIcon }}</mat-icon>
                                    <span>{{ recipient.fname }}</span>
                                    <span> {{ recipient.lname }}</span>
                                </div>
                                <button matChipRemove>
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </mat-chip></mat-chip-list
                        >
                        <mat-chip-list
                            *ngIf="groupRecipients.length"
                            class="reci-chips mb-1"
                            #chipList
                            aria-label="Recievers selection"
                        >
                            <mat-chip
                                *ngFor="let recipient of groupRecipients"
                                (removed)="removeGroup(recipient)"
                                [selectable]="false"
                                class="groups-wrap"
                            >
                                <div fxLayout="row" class="groups" fxLayoutAlign="start center" fxLayoutGap="5px">
                                    <mat-icon>groups</mat-icon>
                                    <span>{{ recipient.name }}</span>
                                </div>

                                <button matChipRemove>
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </mat-chip></mat-chip-list
                        >
                    </div>
                    <span
                        *ngIf="
                            (messageForm.get('recipient_ids').dirty || messageForm.get('recipient_ids').touched) &&
                            messageForm.hasError('needAtLeastGroupOrUser')
                        "
                        class="error"
                        i18n
                        >You should choose reciever/s</span
                    >
                </div>

                <!-- Meessage subject -->
                <!-- EB updated on 220323, No subject if msg sent -->
                <div *ngIf="!msg || msg.draft" class="input-wrapper" fxLayout="column" fxLayoutGap="10px">
                    <div fxLayoutAlign="space-between center">
                        <span class="label" i18n>Subject</span>
                        <mat-hint *ngIf="!msg || msg.draft"
                            >{{ messageForm.value.subject?.length }} <span> (128)</span>
                            <mat-icon
                                *ngIf="!(isMobile$ | async)"
                                class="info"
                                i18n-matTooltip
                                matTooltip="Max input characters are 128 letter"
                                >question_mark</mat-icon
                            ></mat-hint
                        >
                    </div>

                    <mat-form-field appearance="fill">
                        <input
                            maxlength="128"
                            i18n-placeholder
                            placeholder="Type in the subject for your message"
                            #subject
                            matInput
                            formControlName="subject"
                        />
                    </mat-form-field>
                </div>

                <!-- Message Body -->
                <div *ngIf="!msg || msg.draft" class="input-wrapper" fxLayout="column" fxLayoutGap="10px">
                    <div fxLayoutAlign="space-between center">
                        <span class="label" i18n>Message body</span>
                        <mat-hint *ngIf="!msg || msg.draft"
                            >{{ messageForm.value.body?.length }} <span> (5000)</span>
                            <mat-icon
                                *ngIf="messageForm.get('scope_id').value === MessageScopes.CASE && !(isMobile$ | async)"
                                class="info"
                                i18n-matTooltip
                                matTooltip="Max number of characters for Case Description is 5000 characters."
                                >question_mark</mat-icon
                            >
                            <mat-icon
                                *ngIf="
                                    messageForm.get('scope_id').value === MessageScopes.DIALOGE && !(isMobile$ | async)
                                "
                                class="info"
                                i18n-matTooltip
                                matTooltip="Max number of characters for Message body is 5000 characters."
                                >question_mark</mat-icon
                            >
                        </mat-hint>
                    </div>

                    <ng-container *ngIf="!msg || msg.draft; else msgBodyData">
                        <mat-form-field floatLabel="never" appearance="fill">
                            <textarea
                                maxlength="5000"
                                rows="5"
                                i18n-placeholder
                                placeholder="Type in your message"
                                #message
                                matInput
                                formControlName="body"
                            ></textarea>
                        </mat-form-field>
                    </ng-container>
                </div>

                <!-- Meessage case when there is a msg -->
                <div
                    class="content-select-wrapper"
                    *ngIf="msg && !msg.draft && messageForm.get('scope_id').value === MessageScopes.CASE"
                >
                    <app-message-case
                        (caseDeadline)="deadline = $event"
                        #caseComponent
                        fxLayout="column"
                        [msg]="msg"
                        [msgCase$]="msgCase$"
                    ></app-message-case>
                </div>

                <!-- Meessage Ticket -->
                <div
                    class="content-select-wrapper"
                    *ngIf="msg && !msg.draft && messageForm.value.scope_id === MessageScopes.ALERT"
                >
                    <app-message-ticket fxLayout="column" [msg]="msg"></app-message-ticket>
                </div>

                <!-- Meessage media -->
                <div
                    *ngIf="msg?.media_ids || !msg || msg?.draft"
                    class="input-wrapper"
                    fxLayout="column"
                    fxLayoutGap="10px"
                >
                    <app-media-attachment
                        #uploader
                        [maxImgCount]="10"
                        formControlName="media_ids"
                        [scope]="messageForm.value.scope_id"
                        [mediaIds]="messageForm.get('media_ids').value"
                        [isCreateForm]="!msg || msg.draft"
                    ></app-media-attachment>
                    <div fxLayoutAlign="center center" *ngIf="uploader.saving | async">
                        <app-kd-logo-loading></app-kd-logo-loading>
                    </div>
                </div>
                <!-- <div *ngIf="msg && !msg.draft" class="seperator"></div> -->

                <!-- Message Dialog -->
                <app-message-dialogue
                    *ngIf="msg && !msg.draft"
                    #msgDialogComponent
                    [user]="user$ | async"
                    [msg]="msg"
                    [msgContextType]="msgContextType"
                ></app-message-dialogue>

                <!-- Message Case -->
                <div
                    class="content-select-wrapper"
                    *ngIf="messageForm.get('scope_id').value === MessageScopes.CASE && (!msg || msg.draft)"
                >
                    <div class="seperator"></div>
                    <ng-container *ngTemplateOutlet="msgCase"></ng-container>
                </div>
            </div>
        </div>
    </mat-card>
    <mat-card class="second-child-layout border-green-top">
        <!-- Message scope -->
        <ng-container *ngIf="user$ | async; let user; else: smallLoading">
            <!-- <ng-container *ngIf="user.isAdmin()"> -->
            <div *ngIf="!msg || msg.draft" class="input-wrapper mb-1" fxLayout="column" fxLayoutGap="10px">
                <div fxLayoutAlign="space-between center" fxLayout="row">
                    <span class="label" i18n>Message type</span>
                    <button class="alert-btn" mat-icon-button type="button" (click)="alert()">
                        <mat-icon>warning_amber</mat-icon>
                    </button>
                </div>
                <mat-radio-group
                    fxLayout="row wrap"
                    formControlName="scope_id"
                    [fxLayoutAlign]="user.isAdmin() ? 'space-between start' : 'start start'"
                    [fxLayoutGap]="user.isAdmin() ? '0' : '10px'"
                >
                    <ng-container *ngFor="let type of messageScopes">
                        <mat-radio-button
                            [color]="type.value === MessageScopes.DIALOGE ? 'primary' : 'accent'"
                            class="{{ type.class }}"
                            [disabled]="type.disabled || (type.value === MessageScopes.CASE && !user.isAdmin())"
                            *ngIf="user.isAdmin() || (!user.isAdmin() && type.availableForUser)"
                            (click)="onMsgTypeClick(type.value === MessageScopes.NOTIFICATION)"
                            [value]="type.value"
                            [matTooltip]="type.toolTip"
                        >
                            <div class="radio-msg-type {{ type.class }}" fxLayoutGap="5px" fxLayoutAlign="start center">
                                <mat-icon *ngIf="">{{ type.icon }}</mat-icon>
                                <span [ngStyle]="{ color: type.color }">{{ type.title }}</span>
                            </div>
                        </mat-radio-button>
                    </ng-container>
                </mat-radio-group>
            </div>
            <!-- </ng-container> -->
        </ng-container>
        <!-- Info of msg -->
        <div *ngIf="msg" class="info" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center start">
            <div *ngIf="!(isMobile$ | async)" class="info-row">
                <span i18n>Type:</span>
                <div fxLayoutGap="5px" fxLayout="row">
                    <mat-icon>{{ (msg.scope_id | notificationType).icon }}</mat-icon
                    ><span>{{ (msg.scope_id | notificationType).title }}</span>
                </div>
            </div>
            <div
                class="info-row date recipients"
                *ngIf="
                    (msg.recipients || msg.groups) && msgContextType === MessageContextTypes.SENT && (isMobile$ | async)
                "
            >
                <span i18n>To:</span>
                <div class="recipients-wrappr">
                    <div
                        class="user-select"
                        [ngClass]="{
                            'chip-dark-blue-bg': rec.role_value === 'user',
                            'chip-green-bg': rec.role_value !== 'user'
                        }"
                        fxLayoutGap="3px"
                        fxLayout="row"
                        *ngFor="let rec of msg.recipients"
                    >
                        <mat-icon class="lh-20"> {{ rec.role_value | userRoleIcon }}</mat-icon>
                        <span>{{ rec.fname }}</span> <span>{{ rec.lname }}</span>
                    </div>
                    <div class="group-select" fxLayoutGap="3px" fxLayout="row" *ngFor="let group of msg.groups">
                        <mat-icon>groups</mat-icon>
                        <span>{{ group.name }}</span>
                    </div>
                </div>
            </div>
            <div class="info-row date" *ngIf="msgContextType === MessageContextTypes.INBOX">
                <span i18n>From:</span>
                <div
                    class="user-select"
                    [ngClass]="{
                        'chip-dark-blue-bg': msg.author.role_value === 'user',
                        'chip-green-bg': msg.author.role_value !== 'user'
                    }"
                    fxLayoutGap="3px"
                    fxLayout="row"
                >
                    <mat-icon class="lh-20"> {{ msg.author.role_value | userRoleIcon }}</mat-icon>
                    <span>{{ msg.author.fname }}</span> <span>{{ msg.author.lname }}</span>
                </div>
            </div>
            <div class="info-row date" *ngIf="msgContextType !== MessageContextTypes.DRAFT">
                <span *ngIf="msgContextType !== MessageContextTypes.SENT" i18n>Recieved:</span>
                <span *ngIf="msgContextType === MessageContextTypes.SENT" i18n>Sent:</span>
                <div>
                    <span>{{ msg.created_at | kdDateFormat : 'YYYY-MM-DD HH:mm' }}</span>
                </div>
            </div>
            <div class="info-row date" *ngIf="deadline">
                <span i18n>Deadline:</span>
                <div>
                    <span>{{ deadline | kdDateFormat : 'YYYY-MM-DD HH:mm' }}</span>
                </div>
            </div>
            <ng-container *ngTemplateOutlet="msgsignoff"></ng-container>
        </div>

        <!-- Message Status Actions -->
        <div
            *ngIf="msg && !msg.draft && msg.scope_id === MessageScopes.CASE"
            fxLayout="row"
            class="btn-actions-wrapper mt-1"
        >
            <ng-container *ngIf="user$ | async; let user; else: smallLoading">
                <ng-container *ngIf="msgCase$ | async; let msgCase; else: smallLoading">
                    <app-message-case-status-action
                        *ngIf="messageForm.value.id && !messageForm.value.draft"
                        [message_id]="messageForm.value.id"
                        [ownUser]="user"
                        [msgCase]="msgCase"
                    ></app-message-case-status-action>
                </ng-container>
            </ng-container>
            <ng-container *ngTemplateOutlet="msgsignoff"></ng-container>
        </div>

        <!-- Message Actions -->
        <div
            *ngIf="!msg || msg.draft"
            fxLayout="column"
            fxLayoutGap="10px"
            class="btn-actions-wrapper mt-1"
            fxLayoutAlign="start start"
        >
            <ng-container *ngIf="!(saving | async)">
                <button
                    *ngIf="!messageForm.value.draft; else draftBtn"
                    mat-button
                    class="submit-btn"
                    [disabled]="!messageForm.valid"
                    (click)="submitMsg()"
                >
                    <span i18n>Send</span>&nbsp;
                    <span i18n *ngIf="messageForm.value.scope_id === MessageScopes.DIALOGE">Message</span>
                    <span i18n *ngIf="messageForm.value.scope_id === MessageScopes.CASE">Case</span>
                </button>
                <mat-checkbox formControlName="draft" i18n color="primary" class="draft-checkbox"
                    >Save in draft mode</mat-checkbox
                >
            </ng-container>
            <ng-container *ngIf="saving | async">
                <app-saving-button></app-saving-button>
            </ng-container>
        </div>
    </mat-card>
</form>

<ng-template #draftBtn>
    <button mat-button class="draft-btn" (click)="submitMsg()" i18n>Save Draft</button>
</ng-template>

<ng-template #smallLoading>
    <div fxLayout="row" fxLayoutAlign="center center" class="spinner-container">
        <app-kd-logo-loading size="small"></app-kd-logo-loading>
    </div>
</ng-template>

<ng-template #msgSubjectData>
    <div class="display-wrapper">
        <p>{{ msg.subject }}</p>
    </div>
</ng-template>
<ng-template #msgBodyData>
    <div class="display-wrapper">
        <p>{{ msg.body }}</p>
    </div>
</ng-template>
<ng-template #msgCase>
    <app-message-case #caseComponent fxLayout="column"></app-message-case>
</ng-template>
<ng-template #msgsignoff>
    <ng-container *ngIf="msg.signoff as signoff">
        <ng-container *ngIf="signoff.approved != null">
            <div fxLayout="row" fxLayoutAlign="start center">
                <span i18n>Signoff approval: </span>
                <button
                    mat-icon-button
                    class="light"
                    [ngStyle]="{ color: (signoff | messagesSignoffApproval).color }"
                    [matTooltip]="(signoff | messagesSignoffApproval).tooltip"
                    (click)="$event.stopPropagation()"
                >
                    <mat-icon>{{ (signoff | messagesSignoffApproval).icon }}</mat-icon>
                </button>
            </div>
        </ng-container>
        <div *ngIf="signoff.approved == null" fxLayout="row" fxLayoutAlign="start center">
            <span i18n fxGrow>Signoff approval: </span>
            <button
                mat-icon-button
                class="green"
                matTooltip="Mark as approved"
                i18n-matTooltip
                (click)="$event.stopPropagation(); onApprove(signoff.opportunity_id, true)"
            >
                <mat-icon>assignment_turned_in</mat-icon>
            </button>
            <button
                mat-icon-button
                class="red"
                matTooltip="Mark as rejected"
                i18n-matTooltip
                (click)="$event.stopPropagation(); onApprove(signoff.opportunity_id, false)"
            >
                <mat-icon>cancel</mat-icon>
            </button>
        </div>
    </ng-container>
</ng-template>
