<button mat-icon-button [matMenuTriggerFor]="lms">
    <mat-icon>keyboard_arrow_down</mat-icon>
</button>

<mat-menu #lms="matMenu">
    <ng-container *ngIf="allLLmsList$ | async; let list">
        <button
            mat-menu-item
            class="lms-item"
            *ngFor="let item of list"
            [disabled]="!item.enabled"
            (click)="selectModel(item.value)"
            [class.selected]="item.value === model"
            [class.llm-divider]="item.divider"
        >
            <span>{{ item.name }}</span>
        </button>
    </ng-container>
</mat-menu>
