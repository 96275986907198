<mat-toolbar
    fxFill="1 1 100%"
    fxLayout.lt-sm="column"
    fxLayoutGap.gt-xs="0.5em"
    fxLayoutAlign="center center"
    color="warn"
    *ngIf="version$ | async; let release"
>
    <div fxLayoutGap="0.25em" fxLayoutAlign="center center" fxLayout="row">
        <mat-icon class="changelog" routerLink="/changelog">error_outline</mat-icon>
        <span i18n>New version {{ release.version.slice(0, release.version.lastIndexOf('-')) }} available!</span>
    </div>
    <button class="update" mat-raised-button (click)="onClick()" i18n>Update</button>
</mat-toolbar>
