<div fxLayout="column" class="main-wrapper-core">
    <span i18n class="title-label">Course structure</span>
    <div class="w-100">
        <app-resource-builder
            [resource]="resource"
            [active]="false"
            [resource_type_id]="ResourceTypes.GeneralCoursePlan"
            [resourceType]="ResourceTypes.COURSE"
            [hideAddItems]="true"
            [resourceForm]="resourceForm"
            [language_id]="resourceForm?.get('language_id').value"
            (saved)="$event && savingItems && submitCourseAfterItemsSubmitted()"
            (removal)="updateRemovedItems($event)"
            [hideTools]="isEmpty(resourceBuilder?.getService().getForm() | async)"
            [ngClass]="{ 'rb-is-empty': isEmpty(resourceBuilder?.getService().getForm() | async) }"
            class="course-manage-rb w-100 m-10-0"
        >
        </app-resource-builder>
    </div>
    <div class="items-parent" [ngClass]="{ 'enable-adding-items': (showAddingItems | async) }" fxFlex.lt-sm="100%">
        <ng-container *ngTemplateOutlet="courseItems"></ng-container>
        <button
            [ngStyle]="{ display: (showAddingItems | async) ? 'none' : 'block' }"
            matTooltip="Unlock adding course items"
            i18n-matTooltip
            class="unlock-btn"
            type="button"
            mat-icon-button
            (click)="enableEdit()"
        >
            <mat-icon class="material-symbols-outlined"> lock </mat-icon>
        </button>
    </div>
</div>
<ng-template #courseItems>
    <app-course-manager-core-items
        #courseItemsCore
        [selection]="selection"
        [authUser]="authUser$ | async"
        (add)="addItem($event)"
        [canAddItem]="showAddingItems | async"
        (onError)="loading.emit(false)"
        (onUnlockAddingItems)="enableEdit()"
    ></app-course-manager-core-items>
</ng-template>
<ng-template #contentTemplateToBeInParent>
    <ng-container *ngIf="authCustomer$ | async as customer">
        <app-course-manager-core-secodary-controls
            #secondaryControls
            [resource]="resource"
            [customer]="customer"
        ></app-course-manager-core-secodary-controls>
    </ng-container>
</ng-template>
