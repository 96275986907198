<form class="data-wrap" fxLayout="column" [formGroup]="form">
    <div class="title" fxLayoutGap="5px" fxLayout="row" fxLayoutAlign="start start">
        <mat-icon>smart_toy</mat-icon><span>Regenerate AI response</span>
    </div>
    <ng-container *ngIf="item">
        <mat-form-field *ngIf="item.prompt" class="mb-0" floatLabel="never" appearance="fill" fxFlex="0 0 100%">
            <textarea
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="10"
                placeholder="AI response"
                #message
                matInput
                formControlName="prompt"
            ></textarea>
        </mat-form-field>

        <app-text-editor
            *ngIf="!item.prompt && item.name"
            class="regenerate-mat-editor"
            fxFlex="1 1 100%"
            title="Material Text"
            i18n-title
            [showTemplates]="false"
            formControlName="name"
        ></app-text-editor>
    </ng-container>
    <div fxLayoutGap="4px" fxLayout="column" class="w-100 input-field-parent select">
        <span i18n>AI model</span>
        <mat-form-field class="role-field">
            <mat-select fxFlex="1 1 100%" formControlName="llm">
                <mat-option *ngFor="let llm of allLLmsList$ | async" [value]="llm.value" [disabled]="!llm.enabled">{{
                    llm.name
                }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="action mt-1">
        <button fxFlex="0 0 auto" class="mobile-button negative" (click)="onClose.emit()" mat-button i18n>
            Cancel
        </button>
        <button fxFlex="0 0 auto" class="mobile-button" (click)="submit()" mat-raised-button type="button">
            <span>Ok</span>
        </button>
    </div>
</form>
