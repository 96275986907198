import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '@klickdata/core/util';

@Pipe({
    name: 'clientStatus',
})
export class ClientStatusPipe implements PipeTransform {
    transform(value?: string): any {
        if (value) {
            switch (value) {
                case 'client':
                    return {
                        fg: '#fff',
                        bg: '#ff9961', //orange
                        label: Utils.getTaskTypesOptions().find((type) => type.value === value)?.title,
                    };
                case 'offer':
                    return {
                        fg: '#fff',
                        bg: '#93cbd1', //light blue or ice-blue
                        label: Utils.getTaskTypesOptions().find((type) => type.value === value)?.title,
                    };
                case 'prospect':
                    return {
                        fg: '#fff',
                        bg: '#3e5365', //Dark blue
                        label: Utils.getTaskTypesOptions().find((type) => type.value === value)?.title,
                    };
                case 'nothanker':
                    return {
                        fg: '#e44a66',
                        bg: '#fff',
                        label: Utils.getTaskTypesOptions().find((type) => type.value === value)?.title,
                    };
                case 'contact':
                    return {
                        fg: '#eee', //Grey
                        bg: '#bfd8d0', //Green
                        label: Utils.getTaskTypesOptions().find((type) => type.value === value)?.title,
                    };
                case 'exclient':
                    return {
                        fg: '#fff',
                        bg: '#e44a66', //Red
                        label: Utils.getTaskTypesOptions().find((type) => type.value === value)?.title,
                    };
                case 'partner':
                    return {
                        fg: '#ff9961',
                        bg: '#fff',
                        border: '1px solid #ff9961',
                        label: Utils.getTaskTypesOptions().find((type) => type.value === value)?.title,
                    };
                case 'investor':
                    return {
                        fg: '#93cbd1',
                        bg: '#fff',
                        border: '1px solid #93cbd1',
                        label: Utils.getTaskTypesOptions().find((type) => type.value === value)?.title,
                    };
                case 'other':
                    return {
                        fg: '#3e5365',
                        bg: '#fff', //orange
                        label: Utils.getTaskTypesOptions().find((type) => type.value === value)?.title,
                    };
            }
            // });
        }
    }
}
