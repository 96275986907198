<button
    class="download-btn"
    mat-icon-button
    matTooltip="Export actions"
    (click)="$event.stopPropagation(); download()"
    i18n-matTooltip="@@exportActions"
>
    <mat-icon>save_alt</mat-icon>
</button>
<div fxLayout="column" fxFlex="0 0 100%" class="overall-wrapper">
    <mat-accordion displayMode="flat">
        <mat-expansion-panel expanded="true" #expansionPanel>
            <mat-expansion-panel-header> </mat-expansion-panel-header>
            <app-table-filter
                [filtersTogglesInOnBtn]="true"
                [cacheScope]="AppScope.RESOURCES"
                [inGlobalFilter]="true"
                color="accent"
                #filter
            >
                <app-user-chip-search-filter
                    *ngIf="!user"
                    [isMaster]="isMasterPlatform"
                    [inGlobalFilter]="true"
                    userSearchRef
                ></app-user-chip-search-filter>
                <app-select-filter
                    [inGlobalFilter]="true"
                    class="filter-space"
                    label="Resource Action"
                    i18n-label="@@resourceAction"
                    [property]="GlobalFilterProperty.LOG_EVENT"
                    [options]="resourceEventOptions"
                    logEventRef
                ></app-select-filter>
                <app-select-filter
                    [inGlobalFilter]="true"
                    class="filter-space"
                    label="All Time"
                    i18n-label
                    [property]="GlobalFilterProperty.TIME_SPENT"
                    [options]="predefinedTimeSpentOptions"
                    fixedTimeSpentFilterRef
                >
                </app-select-filter>
                <app-select-filter
                    *ngIf="!user"
                    [inGlobalFilter]="true"
                    class="filter-space"
                    label="Academy Roles"
                    i18n-label="@@academyRoles"
                    [property]="GlobalFilterProperty.USER_ROLE"
                    [options]="userRoleOptions"
                    userRolesRef
                >
                </app-select-filter>
                <app-select-filter
                    [inGlobalFilter]="true"
                    class="filter-space"
                    label="Action Functions"
                    i18n-label="@@actionFunction"
                    [property]="GlobalFilterProperty.SCOPE"
                    [options]="functionOptions"
                    scopesFilterRef
                >
                </app-select-filter>

                <app-customer-filter
                    *ngIf="isMasterPlatform && !customer"
                    [inGlobalFilter]="true"
                    class="customer-select"
                    fxFlex="1 1 auto"
                    customerSearchRef
                ></app-customer-filter>
            </app-table-filter>
        </mat-expansion-panel>
    </mat-accordion>
    <mat-progress-bar
        class="progress-table-loader"
        *ngIf="(dataSource.status | async) === 'loading'"
        mode="indeterminate"
    ></mat-progress-bar>
    <mat-table #table [dataSource]="dataSource" matSortDirection="desc" matSortActive="created_at" matSort>
        <ng-container matColumnDef="created_at">
            <mat-header-cell mat-sort-header *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n="@@time">Time</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let activity">
                <span class="mobile-label" i18n="@@time">Time:</span>
                <span class="time-label">
                    {{ activity.created_at | kdDateFormat : 'YYYY-MM-DD HH:mm' }}
                </span>
                <button
                    *ngIf="isMobile"
                    class="expand-btn"
                    mat-icon-button
                    (click)="$event.stopPropagation(); expandedRow = expandedRow === activity ? null : activity"
                >
                    <mat-icon>
                        {{ expandedRow === activity ? 'expand_less' : 'expand_more' }}
                    </mat-icon>
                </button>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="user">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n="@@name">Name</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let activity">
                <span class="mobile-label" i18n="@@name">Name:</span>
                <div
                    (click)="onSearchFilterClick(GlobalFilterProperty.USERS, activity.user.name)"
                    fxLayoutGap="5px"
                    fxLayout="row"
                >
                    <span
                        [ngStyle]="{
                            color:
                                activity.user.role_value === 'superadmin'
                                    ? '#ff9961'
                                    : activity.user.role_value === 'user'
                                    ? '#3e5365'
                                    : '#a6c2b7'
                        }"
                        class="pointer"
                        >{{ activity.user.name }}</span
                    >
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n="@@email">Email</span>
                </div>
            </mat-header-cell>
            <mat-cell [ngClass]="{ 'rows-expanded': expandedRow === activity }" *matCellDef="let activity">
                <span class="mobile-label" i18n="@@email">Email:</span>
                <ng-container
                    [ngTemplateOutlet]="userEmail"
                    [ngTemplateOutletContext]="{ activity: activity, isMA: isMasterPlatform }"
                ></ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="customer">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n="@@academy">Academy</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let activity">
                <span class="mobile-label" i18n="@@academy">Academy:</span>
                <div
                    (click)="onSearchFilterClick(GlobalFilterProperty.CUSTOMERS, activity.customer?.name)"
                    fxLayoutGap="5px"
                    fxLayout="row"
                >
                    <span class="orange pointer">{{ activity.customer?.name }}</span>
                </div>
                <button
                    class="orange pointer view-cust-sett"
                    mat-icon-button
                    (click)="onViewAcademy(activity.customer?.id)"
                >
                    <mat-icon>visibility</mat-icon>
                </button>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="role">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n="@@role">Role</span>
                </div>
            </mat-header-cell>
            <mat-cell [ngClass]="{ 'rows-expanded': expandedRow === activity }" *matCellDef="let activity">
                <span class="mobile-label" i18n="@@role">Role:</span>
                <div
                    (click)="onItemClick(GlobalFilterProperty.USER_ROLE, activity.user.role_value)"
                    fxLayout="row"
                    fxLayoutGap="5px"
                    fxLayoutAlign="start center"
                    class="pointer"
                >
                    <mat-icon>{{ activity.user.roleValueSpecs.icon }}</mat-icon>
                    <span class="orange">{{ activity.user.roleValueSpecs.title }}</span>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="scope_id">
            <mat-header-cell mat-sort-header *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n="@@function">Function</span>
                </div>
            </mat-header-cell>
            <mat-cell [ngClass]="{ 'rows-expanded': expandedRow === activity }" *matCellDef="let activity">
                <span class="mobile-label" i18n="@@function">Function:</span>
                <div
                    (click)="onItemClick(GlobalFilterProperty.SCOPE, activity.scope_id)"
                    class="pointer"
                    fxLayout="row"
                    fxLayoutGap="5px"
                    fxLayoutAlign="start center"
                >
                    <ng-container *ngIf="AppScope.isResource(activity.scope_id)">
                        <mat-icon
                            fxFlex="0 0 auto"
                            color="primary"
                            fontSet="klickdata"
                            [fontIcon]="AppScope.fontIconType(activity.scope_id)"
                            class="kd-icon-24"
                        ></mat-icon>
                    </ng-container>
                    <ng-container *ngIf="!AppScope.isResource(activity.scope_id)">
                        <mat-icon>{{ AppScope.fontIconType(activity.scope_id) }}</mat-icon>
                    </ng-container>
                    <span class="orange">{{ AppScope.label(activity.scope_id, false) }}</span>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n="@@action">Action</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let activity">
                <span class="mobile-label" i18n="@@action">Action:</span>
                <div class="action-activity" fxLayout="row" fxLayoutGap="3px" fxLayoutAlign="start center">
                    <span>{{ activity.action }}</span>
                    <ng-container
                        [ngTemplateOutlet]="eventSubject"
                        [ngTemplateOutletContext]="{ activity: activity, isMA: isMasterPlatform }"
                    ></ng-container>
                </div>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row (click)="$event.stopPropagation()" *matRowDef="let resource; columns: columns"></mat-row>
    </mat-table>
    <mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>
</div>

<ng-template #loading>
    <div fxLayout="row" fxLayoutAlign="center center" class="spinner-container mt-2">
        <mat-spinner fxFlex="0 0 auto"></mat-spinner>
    </div>
</ng-template>
<ng-template #eventSubject let-isMA="isMA" let-activity="activity">
    <span (click)="onEventClick(activity.subject_title, activity.scope_id, isMA)" class="orange pointer"
        >"{{ activity.subject_title }}"</span
    >
</ng-template>
<ng-template #userEmail let-isMA="isMA" let-activity="activity">
    <div (click)="onUserEmailClick(activity, isMA)" fxLayoutGap="5px" fxLayout="row">
        <span class="orange pointer">{{ activity.user.email }}</span>
    </div>
</ng-template>
