import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    Optional,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { ControlContainer, FormBuilder, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { Customer, CustomerService } from '@klickdata/core/customer';
import { MediaService } from '@klickdata/core/media/src/media.service';
import { S3MediaService } from '@klickdata/core/media/src/s3-media.service';
import { MessageSavedComponent, MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { MobileService, SideNaveDataTypes } from '@klickdata/core/mobile';
import { AppScope } from '@klickdata/core/resource';
import { Resource } from '@klickdata/core/resource/src/resource.model';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';
import { BehaviorSubject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-course-manager-diploma-logos',
    templateUrl: './course-manager-diploma-logos.component.html',
    styleUrls: ['./course-manager-diploma-logos.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class CourseManagerDiplomaLogosComponent extends OnDestroyHandler implements OnInit {
    @Input() customer: Customer;
    @Input() resource: Resource;
    @ViewChildren('logoInput') logoInputs!: QueryList<ElementRef>;
    // Progress observables for each logo
    logoProgress: BehaviorSubject<number>[] = [
        new BehaviorSubject<number>(0),
        new BehaviorSubject<number>(0),
        new BehaviorSubject<number>(0),
    ];

    public diplomaLogosForm: FormGroup;

    public alignmentOptions = [
        { label: 'Left', value: 'left', disabled: false },
        { label: 'Center', value: 'center', disabled: false },
        { label: 'Right', value: 'right', disabled: false },
    ];

    constructor(
        @Optional() protected parentFormDirective: FormGroupDirective,
        protected fb: FormBuilder,
        private readonly s3MediaService: S3MediaService,
        private readonly mediaService: MediaService,
        private readonly customerService: CustomerService,
        private readonly messageService: MessageService,
        private readonly cdr: ChangeDetectorRef,
        private readonly mobileService: MobileService
    ) {
        super();
    }

    ngOnInit(): void {
        const resourceForm = this.parentFormDirective.form as FormGroup;
        this.diplomaLogosForm = resourceForm.get('collaboration.view.metadata.diploma_logos') as FormGroup;
        this.setDisabledOptions();
        this.diplomaLogosForm.valueChanges.pipe(takeUntil(this.destroy)).subscribe(() => this.setDisabledOptions());
        this.setDefaultLogosFromCustomer();
    }
    private setDefaultLogosFromCustomer() {
        if (!!this.customer.medias.diploma?.length && !this.isDiplomaLogosHasValue()) {
            this.customer.medias.diploma.forEach((diploma, index) => {
                if (!this.diplomaLogosForm.value[`logo_${index + 1}`]?.media_id) {
                    this.diplomaLogosForm.patchValue({
                        [`logo_${index + 1}`]: { media_id: diploma.id, alignment: diploma.description },
                    });
                }
            });
        }
    }
    private isDiplomaLogosHasValue() {
        if (!this.resource) return false;
        return !!this.resource.collaboration.view?.metadata?.diploma_logos;
    }

    private setDisabledOptions() {
        const alignmentValues = Object.values(this.diplomaLogosForm.value).map((logo: any) => logo.alignment);
        this.alignmentOptions.forEach((option) => (option.disabled = alignmentValues.includes(option.value)));
        this.cdr.markForCheck();
    }
    addAcademyLogo(index: number) {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.SELECT_PUBLISHER_LOGO,
            data: {
                publisherLogos: this.customer.medias.publisher,
                kd_publisher: this.customer.kd_publisher,
            },
        });
        this.mobileService
            .getSideNavResponseData()
            .pipe(filter((data) => data.type == 'selectPublisherLogo'))
            .subscribe((data) => {
                if (data.uploadLogo) {
                    const inputs = this.logoInputs.toArray();
                    if (inputs[index]) {
                        inputs[index].nativeElement.click();
                    }
                } else {
                    this.setLogoMedia(index, +data.publisherLogo);
                    this.cdr.markForCheck();
                }
            });
    }

    fileHandler(event: Event, index: number) {
        const file = (event.target as HTMLInputElement).files?.[0];
        if (!file) return;
        this.s3MediaService
            .uploadMediaToS3(file, this.logoProgress[index])
            .pipe(
                takeUntil(this.destroy),
                switchMap((media) =>
                    this.mediaService.uploadMediaByLink({
                        ...media.getData(),
                        scope_id: AppScope.CUSTOMERS,
                    })
                ),
                switchMap((media) => {
                    const customerPublishers = !!this.customer.medias?.publisher?.length
                        ? this.customer.medias?.publisher
                        : [];

                    return this.customerService
                        .update({
                            id: this.customer.id,
                            medias: { publisher: [...customerPublishers, +media.id] },
                        })
                        .pipe(map(() => +media.id));
                })
            )
            .subscribe({
                next: (mediaId) => {
                    this.setLogoMedia(index, mediaId);
                    this.messageService.openMessage(MessageSavedComponent);
                    this.cdr.markForCheck();
                },
                error: (err) => {
                    if (err?.error?.error) {
                        this.messageService.openMessage(MessageErrorComponent, err.error.error.messages.join('\n'));
                    }
                },
            });
    }

    removeLogo(index: number): void {
        this.setLogoMedia(index, null);
        this.cdr.markForCheck();
    }

    setLogoMedia(index: number, mediaId: number): void {
        const logoGroup = this.getLogoForm(index);
        logoGroup.patchValue({ media_id: mediaId });
        logoGroup.markAsDirty();
    }

    getLogoForm(index: number): FormGroup<{
        media_id: FormControl<number>;
        alignment: FormControl<string>;
    }> {
        return this.diplomaLogosForm.get(`logo_${index}`) as FormGroup;
    }
    resetAlignment() {
        this.diplomaLogosForm.patchValue({
            logo_1: { alignment: '' },
            logo_2: { alignment: '' },
            logo_3: { alignment: '' },
        });
        this.cdr.markForCheck();
        this.diplomaLogosForm.markAsDirty();
    }
}
