<div class="res-listing-wrapper action-log-res-listing-mobile" fxLayout="column" fxFlex="0 0 100%">
    <mat-accordion displayMode="flat">
        <mat-expansion-panel [expanded]="true" #expansionPanel>
            <mat-expansion-panel-header> </mat-expansion-panel-header>
            <app-table-filter
                class="action-log-res"
                [cacheScope]="AppScope.RESOURCES"
                [filtersTogglesInOnBtn]="true"
                [inGlobalFilter]="true"
                color="accent"
                #filter
            >
                <app-table-search [inGlobalFilter]="true" class="align-space" searchFilterRef></app-table-search>
                <app-select-filter
                    [inGlobalFilter]="true"
                    class="filter-space"
                    label="All Time"
                    i18n-label
                    [property]="GlobalFilterProperty.TIME_SPENT"
                    [options]="predefinedTimeSpentOptions"
                    fixedTimeSpentFilterRef
                >
                </app-select-filter>
                <app-select-filter
                    [inGlobalFilter]="true"
                    class="filter-space"
                    label="Resource Action"
                    i18n-label="@@resourceAction"
                    [property]="GlobalFilterProperty.LOG_EVENT"
                    [options]="resourceEventOptions"
                    logEventRef
                ></app-select-filter>
                <app-select-filter
                    [inGlobalFilter]="true"
                    class="filter-space"
                    label="Type"
                    i18n-label="@@type"
                    [property]="{
                        name: GlobalFilterProperty.SCOPE,
                        type: GlobalFilterPropertyType.RESOURCE_TYPE
                    }"
                    [options]="resourceTypeOptions"
                    scopesFilterRef
                >
                </app-select-filter>
                <app-language-filter
                    [inGlobalFilter]="true"
                    #langFilter
                    fxFlex="0 0 auto"
                    fxLayout="row"
                    langFilterRef
                ></app-language-filter>
                <ng-container *ngIf="isMasterPlatform$ | async; let isMasterPlatform">
                    <app-customer-filter
                        *ngIf="isMasterPlatform && !customer"
                        [inGlobalFilter]="true"
                        class="customer-select"
                        fxFlex="1 1 auto"
                        customerSearchRef
                    ></app-customer-filter>
                </ng-container>
            </app-table-filter>
        </mat-expansion-panel>
    </mat-accordion>
    <mat-progress-bar
        class="progress-table-loader"
        *ngIf="(dataSource.status | async) === 'loading'"
        mode="indeterminate"
    ></mat-progress-bar>
    <mat-table
        #table
        [dataSource]="dataSource"
        multiTemplateDataRows
        matSortDirection="desc"
        matSortActive="last_action"
        matSort
    >
        <ng-container matColumnDef="image">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n="@@image">Image</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let resource" class="pr-0-m">
                <app-image-view
                    [media_id]="resource.media$"
                    [proportional]="!isMobile"
                    [paddingBottom]="'58px'"
                    [smallSpinner]="true"
                ></app-image-view>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@title"> Title </mat-header-cell>
            <mat-cell *matCellDef="let resource" class="pr-0-m">
                <span *ngIf="!isMobile">{{ resource.title }}</span>
                <div class="mobile-title-row" fxLayoutAlign="start center" *ngIf="isMobile">
                    <app-image-view
                        [media_id]="resource.media$"
                        [paddingBottom]="'58px'"
                        [proportional]="false"
                        [smallSpinner]="true"
                    ></app-image-view>
                    <span class="res-mobile-title">{{ resource.title }}</span>
                    <button
                        class="expand-btn"
                        mat-icon-button
                        (click)="
                            $event.stopPropagation();
                            expandedRow = null;
                            expandedCell = expandedCell === resource ? null : resource
                        "
                    >
                        <mat-icon>
                            {{ expandedCell === resource ? 'expand_less' : 'expand_more' }}
                        </mat-icon>
                    </button>
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="last_action">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@lastAction"> Last action </mat-header-cell>
            <mat-cell [ngClass]="{ 'main-rows-expanded': expandedCell === resource }" *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@lastAction">Last action</span>
                <span>
                    {{ resource.last_action | kdDateFormat : 'YYYY-MM-DD HH:mm' }}
                </span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@type"> Type </mat-header-cell>
            <mat-cell [ngClass]="{ 'main-rows-expanded': expandedCell === resource }" *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@type">Type</span>
                <div
                    (click)="onItemClick(GlobalFilterProperty.SCOPE, resource.type_id)"
                    fxLayoutAlign="start center"
                    fxLayoutGap="5px"
                >
                    <mat-icon
                        fontSet="klickdata"
                        [fontIcon]="resource.type_id | typeFontIcon"
                        class="kd-icon lh-icon"
                    ></mat-icon>
                    {{ ResourceTypes.label(resource.type_id, { capitalize: true }) }}
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="entity">
            <mat-header-cell *matHeaderCellDef i18n="@@user/group"> User </mat-header-cell>
            <mat-cell [ngClass]="{ 'main-rows-expanded': expandedCell === resource }" *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@user/group">User</span>
                <div fxLayoutAlign="start center" fxLayoutGap="3px">
                    {{ resource.causer?.fname }} {{ resource.causer?.lname }}
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="event">
            <mat-header-cell mat-sort-header *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n="@@action">Action</span>
                </div>
            </mat-header-cell>
            <mat-cell
                [ngClass]="{ 'main-rows-expanded': expandedCell === resource }"
                *matCellDef="let resource"
                class="pr-0-m"
            >
                <span *ngIf="isMobile" class="mobile-label" i18n="@@action">Action</span>
                <div
                    (click)="onItemClick(GlobalFilterProperty.LOG_EVENT, resource.event)"
                    fxLayoutAlign="start center"
                    fxLayoutGap="5px"
                    class="info-wrap"
                >
                    <mat-icon> {{ (resource.event | actionLogEvent)?.icon }}</mat-icon>
                    <span> {{ (resource.event | actionLogEvent).title }}</span>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="occasionStatusOld">
            <mat-header-cell mat-sort-header *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n="@@status">Status</span>
                </div>
            </mat-header-cell>
            <mat-cell
                [ngClass]="{ 'main-rows-expanded': expandedCell === resource }"
                *matCellDef="let resource"
                class="pr-0-m"
                [ngStyle]="{ color: (resource | resourceStatus : true)?.color }"
            >
                <span *ngIf="isMobile" class="mobile-label" i18n="@@status">Status</span>
                <div
                    (click)="onItemClick(GlobalFilterProperty.STATUS, resource.occasionStatus)"
                    fxLayoutAlign="start center"
                    fxLayoutGap="5px"
                >
                    <mat-icon>
                        {{ (resource | resourceStatus : true)?.icon }}
                    </mat-icon>
                    <span> {{ (resource | resourceStatus : true)?.status }}</span>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="result">
            <mat-header-cell *matHeaderCellDef i18n="@@result"> Results </mat-header-cell>
            <mat-cell [ngClass]="{ 'main-rows-expanded': expandedCell === resource }" *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@result">Results</span>
                <span *ngIf="resource.done"> </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="language_id">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n="@@lang">Lang</span>
                </div>
            </mat-header-cell>
            <mat-cell [ngClass]="{ 'main-rows-expanded': expandedCell === resource }" *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@lang">Lang</span>
                <ng-container>
                    <app-language-selector
                        [language_id]="resource.language_id"
                        class="icon-resource-builder"
                        fxFlex="0 0 auto"
                    ></app-language-selector>
                </ng-container>
                <button
                    class="tools-btn orange"
                    mat-icon-button
                    (click)="$event.stopPropagation(); viewResResults(resource)"
                    [matTooltip]="'Check ' + resource.title + ' actions'"
                    i18n-matTooltip
                >
                    <mat-icon>touch_app</mat-icon>
                </button>
                <button
                    class="tools-btn orange"
                    mat-icon-button
                    (click)="$event.stopPropagation(); viewRes(resource)"
                    matTooltip="View"
                    i18n-matTooltip
                >
                    <mat-icon>visibility</mat-icon>
                </button>
                <button
                    *ngIf="isMobile"
                    class="expand-btn"
                    mat-icon-button
                    (click)="$event.stopPropagation(); expandedRow = expandedRow === resource ? null : resource"
                >
                    <mat-icon>
                        {{ expandedRow === resource ? 'expand_less' : 'expand_more' }}
                    </mat-icon>
                </button>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="tools">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <button
                    (click)="$event.stopPropagation(); expandedRow = expandedRow === resource ? null : resource"
                    matTooltip="View"
                    mat-icon-button
                    i18n-matTooltip="@@view"
                    *ngIf="!isMobile"
                >
                    <mat-icon>{{ expandedRow === resource ? 'expand_less' : 'expand_more' }}</mat-icon>
                </button>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="expandedDetail">
            <mat-cell *matCellDef="let resource" [attr.colspan]="columns.length">
                <div
                    class="example-element-detail"
                    [@detailExpand]="resource == expandedRow ? 'expanded' : 'collapsed'"
                >
                    <button
                        class="download-btn"
                        mat-icon-button
                        matTooltip="Download PDF"
                        (click)="$event.stopPropagation()"
                        i18n-matTooltip
                    >
                        <mat-icon>save_alt</mat-icon>
                    </button>
                    <app-shared-action-log-resources-users
                        class="expanded-user-from-res"
                        *ngIf="expandedRow === resource"
                        [resource]="resource"
                        [customer]="customer"
                    ></app-shared-action-log-resources-users>
                </div>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row
            *matRowDef="let resource; columns: columns"
            [class.example-expanded-row]="expandedRow === resource"
            class="example-element-row"
            (click)="expandedRow = expandedRow === resource ? null : resource"
        ></mat-row>
        <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></mat-row>
    </mat-table>
</div>
<mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>

<ng-template #loadingTemp>
    <div fxFlex="0 0 100%" fxLayout="row" fxLayoutAlign="center" class="spinner-container">
        <app-kd-logo-loading size="small"></app-kd-logo-loading>
    </div>
</ng-template>
