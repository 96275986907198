import { takeUntil } from 'rxjs/operators';
import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    EventEmitter,
    Input,
    Output,
    ChangeDetectorRef,
} from '@angular/core';
import { MediaService } from '@klickdata/core/media/src/media.service';
import { SideNaveActionsTypes, SideNaveData } from '@klickdata/core/mobile';
import { SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { Subject, Observable, of, EMPTY } from 'rxjs';
import { Media } from '@klickdata/core/media/src/media.model';

@Component({
    selector: 'app-menu-side-display-media',
    templateUrl: './menu-side-display-media.component.html',
    styleUrls: ['./menu-side-display-media.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideDisplayMediaComponent implements OnInit {
    public mediaItems$: Observable<any[]> = EMPTY;
    private _navData: SideNaveData;
    @Input()
    public set navData(navData: SideNaveData) {
        this._navData = navData;
        if (navData.data.mediaIds && navData.data.mediaIds?.length) {
            this.mediaItems$ = this.mediaService.getMediaByIds(navData.data.mediaIds);
        }
    }

    public get navData(): SideNaveData {
        return this._navData;
    }
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    SideNaveActionsTypes = SideNaveActionsTypes;
    constructor(protected mediaService: MediaService, protected cd: ChangeDetectorRef) {}

    ngOnInit(): void {}

    public download(mediaItem: any) {
        window.open(mediaItem.url, '_blank');
    }
}
