import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@klickdata/core/auth';
import { MobileService, SideNaveDataTypes } from '@klickdata/core/mobile';
import { Downloads } from '@klickdata/core/resource/src/download.model';
import { DownloadPdfDialogComponent } from './download-pdf-dialog.component';
import { DownloadService } from './download.service';
import { PlayerService } from '@klickdata/player/src/core/player/player.service';

@Injectable({ providedIn: 'root' })
export class DownloadHelperService {
    constructor(
        protected dialog: MatDialog,
        protected downloadService: DownloadService,
        protected mobileService: MobileService,
        protected auth: AuthService,
        private playerService: PlayerService
    ) {}

    public download(downloads: Downloads, queryParam?: { [key: string]: (number | string)[] }) {
        if (downloads.data.length > 1) {
            this.downloadWithOptions(downloads);
        } else if (queryParam) {
            this.downloadService.download(downloads.data[0].url, queryParam).subscribe((data) => {
                const blob = new Blob([data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');
            });
        } else {
            window.open(downloads.data[0].url, '_blank');
        }
    }

    public downloadWithOptions(downloads: Downloads) {
        if (!this.auth.checkPlatform('player')) {
            this.mobileService.updateSideNavSub({
                dataType: SideNaveDataTypes.DOWNLOAD,
                data: downloads,
            });
        } else {
            this.playerService.updateSliderData({
                dataType: SideNaveDataTypes.CANCEL_COURSE_PLAY,
                data: downloads,
            });
            // this.mobileService.isMobile().subscribe((isMobile) => {
            //     const dialogRef = this.dialog.open(DownloadPdfDialogComponent, {
            //         width: isMobile ? '90%' : '70%',
            //         data: downloads,
            //         disableClose: true,
            //         panelClass: ['sheet-wrapper', 'download-sheet-wrapper'],
            //     });
            // });
        }
    }
}
