<div class="course-items-switch-wrapper">
    <ng-container [ngSwitch]="display">
        <ng-container *ngSwitchCase="ResourceBuildingItems.TEST_LIBRARY">
            <mat-tab-group class="res-tabs-course-item" #tabGroup ngClass.xs="xs">
                <mat-tab>
                    <ng-template mat-tab-label i18n> My test </ng-template>
                    <ng-template matTabContent>
                        <app-resource-library-multiple-select
                            [type_scope_id]="AppScope.TEST"
                            context="author"
                            [selection]="selection"
                            (onSubmitMultiSelect)="onMultiSelect.emit({ res: $event, isParentSubmission: false })"
                            (selectChange)="selectChange.emit($event)"
                        ></app-resource-library-multiple-select>
                    </ng-template>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label i18n> Academy tests </ng-template>
                    <ng-template matTabContent>
                        <app-resource-library-multiple-select
                            [type_scope_id]="AppScope.TEST"
                            context="academy"
                            [selection]="selection"
                            (onSubmitMultiSelect)="onMultiSelect.emit({ res: $event, isParentSubmission: false })"
                            (selectChange)="selectChange.emit($event)"
                        ></app-resource-library-multiple-select>
                    </ng-template>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label i18n> Open library (KOL) </ng-template>
                    <ng-template matTabContent>
                        <app-resource-library-multiple-select
                            [type_scope_id]="AppScope.TEST"
                            context="kol"
                            [selection]="selection"
                            (onSubmitMultiSelect)="onMultiSelect.emit({ res: $event, isParentSubmission: false })"
                            (selectChange)="selectChange.emit($event)"
                        ></app-resource-library-multiple-select>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </ng-container>
        <ng-container *ngSwitchCase="ResourceBuildingItems.CREATE_NEW_TEST"
            ><app-course-manager-create-test
                #compRef
                class="create-test-in-course-manager"
                (published)="add.emit($event)"
                (onError)="onError.emit($event)"
            ></app-course-manager-create-test
        ></ng-container>
        <ng-container *ngSwitchCase="ResourceBuildingItems.CREATE_NEW_TEST_WITH_AI"
            ><app-course-manager-core-create-ai-test
                #compRef
                class="create-test-in-course-manager"
                (published)="add.emit($event)"
                (onError)="onError.emit($event)"
            ></app-course-manager-core-create-ai-test
        ></ng-container>
    </ng-container>
    <button
        matTooltip="Trash"
        mat-icon-button
        (click)="display = null; onCloseView.emit(true)"
        i18n-matTooltip
        class="add-creation-tab"
        [ngClass]="display === ResourceBuildingItems.TEST_LIBRARY ? 'lib' : 'normal'"
    >
        <mat-icon>cancel</mat-icon>
    </button>
</div>
