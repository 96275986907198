import { Cast, CastType, IDataModel, Ignore, Model } from '@klickdata/core/application';
import { Group } from '@klickdata/core/group';
import { AppScope, Resource } from '@klickdata/core/resource';
import { User, UserData } from '@klickdata/core/user';
import { Observable } from 'rxjs';
import { MsgCase } from './msg-case.model';

export interface NotificationData extends IDataModel {
    id?: number;
    scope_id?: number;
    recipient_ids?: number[];
    group_ids?: number[];
    created_at?: string;
    subject?: string;
    subject_id?: string;
    author_id?: number;
    important?: boolean;
    read_at: string;
    draft?: boolean;
    deleted_at?: string;
    updated_at?: string;
    body?: string;
    media_ids?: number[];
    badge?: number;
    parent_id?: number;
    resource_ids?: number[];
    author?: UserData;
    author$?: Observable<User>;
    recipients?: User[];
    groups?: Group[];
    resources?: Observable<Resource[]>;
    case_attached?: Observable<MsgCase>;
}
export class Notification extends Model<NotificationData> {
    id: number;
    scope_id: number;
    recipient_ids: number[];
    group_ids: number[];
    @Cast(CastType.MOMENT)
    created_at: string;
    subject: string;
    important: boolean;
    @Cast(CastType.MOMENT)
    read_at: string;
    draft: boolean;
    author_id: number;
    subject_id: string;
    @Cast(CastType.MOMENT)
    deleted_at: string;
    @Cast(CastType.MOMENT)
    updated_at: string;
    body: string;
    media_ids: number[];
    badge: number;
    resource_ids: number[];
    author: User;
    author$: Observable<User>;
    recipients: User[];
    groups?: Group[];
    resources: Observable<Resource[]>;
    case_attached: Observable<MsgCase>;
    parent_id?: number;
    @Ignore()
    downloads?: any;
    @Ignore()
    signoff?: any;
}

export enum MessageScopes {
    CASE = AppScope.RESOURCES,
    DIALOGE = AppScope.USERS,
    ALERT = AppScope.ALERT,
    NOTIFICATION = AppScope.NOTIFICATION,
    // INVITE = AppScope.INVITE,
}

export enum MessageContextTypes {
    INBOX = 'inbox',
    SENT = 'sent',
    IMPORTANT = 'important',
    DRAFT = 'draft',
    TICKET = 'ticket',
    SIGNOFF = 'signoff',
}

export namespace MessageAllScopes {
    export function getAllScopes() {
        return [
            {
                title: $localize`Message`,
                class: 'scope-msg',
                icon: 'forum',
                toolTip: $localize`Send a message to a KLMS learner`,
                value: MessageScopes.DIALOGE,
                disabled: false,
                availableForUser: true,
            },
            {
                title: $localize`Case`,
                class: 'scope-case',
                icon: 'school',
                toolTip: $localize`Send a Case to a learner in KLMS to be done`,
                value: MessageScopes.CASE,
                disabled: false,
                availableForUser: false,
            },
            // {
            //     title: $localize`Invite`,
            //     class: 'scope-invite',
            //     icon: 'contact_mail',
            //     toolTip: $localize`Send an invitation to unregistered users`,
            //     value: MessageScopes.INVITE,
            //     disabled: false,
            //     availableForUser: false,
            // },
            {
                title: $localize`Notification`,
                class: 'scope-noti',
                icon: 'manage_accounts',
                toolTip: $localize`Send a notification about KLMS as an admin`,
                value: MessageScopes.NOTIFICATION,
                disabled: true,
                availableForUser: false,
            },
        ];
    }
}
