import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '@klickdata/core/auth';
import { Customer } from '@klickdata/core/customer';
import { AppScope } from '@klickdata/core/resource';
import { map, Observable, of } from 'rxjs';

@Pipe({
    name: 'modelDynamicLabel',
    pure: false,
})
export class ModelDynamicLabelPipe implements PipeTransform {
    private cachedCustomer: Customer = null;
    constructor(private auth: AuthService) {}

    transform(scope: AppScope): Observable<string> {
        if (this.cachedCustomer) {
            return of(this.cachedCustomer.scope_names[scope] ?? AppScope.label(scope));
        }
        return this.auth.getCustomer().pipe(
            map((customer) => {
                this.cachedCustomer = customer;
                return customer.scope_names[scope] ?? AppScope.label(scope);
            })
        );
    }
}
