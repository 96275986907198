<div class="content-dashboard" fxLayout="column wrap" fxLayoutAlign="center center">
    <div fxFlex="0 1 100%" fxLayout="row wrap" fxLayoutAlign="center center">
        <p fxFlex="0 0 90%" i18n="@@uploadSCORMInstructions">
            Please select file to import your SCORM 1.2 project into KLMS.
        </p>
        <div
            [ngStyle]="dropStyle"
            fxFlex="0 0 50%"
            fxFlex.lt-md="0 0 90%"
            class="media-dropzone"
            [ngClass]="{ 'loading-mode': saving }"
            fxLayout="row wrap"
            fxLayoutAlign="center center"
        >
            <input
                class="media-upload-button"
                type="file"
                (change)="fileHandler($event)"
                [disabled]="saving"
                accept=".zip"
                #uploader
            />
        </div>
        <app-kd-logo-loading *ngIf="saving"></app-kd-logo-loading>
        <div class="progress-wrapper" *ngIf="progress$ | async; let progress">
            <mat-progress-bar class="progress" mode="determinate" [value]="progress"></mat-progress-bar>
            <span class="progress-value">{{ progress }}%</span>
        </div>
    </div>
</div>
