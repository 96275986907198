import { Injectable } from '@angular/core';
import { AuthService } from '@klickdata/core/auth';
import { ConfigService } from '@klickdata/core/config/src/config.service';
import { RequestBuilderService } from '@klickdata/core/http/src/request/request-builder.service';
import { MediaService } from '@klickdata/core/media/src/media.service';
import { Resource, TestPassingPercentage } from '@klickdata/core/resource/src/resource.model';
import { EMPTY, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { Question, QuestionData } from './question';
import { QuestionTypes } from './question-type/question-type';

@Injectable({
    providedIn: 'root',
})
export class QuestionService {
    protected resourceUrl: string;
    protected customer_id: Observable<number>;

    constructor(
        protected builder: RequestBuilderService,
        protected config: ConfigService,
        protected auth: AuthService,
        protected mediaService: MediaService
    ) {
        this.resourceUrl = `${this.config.config.apiUrl}questions`;
        this.customer_id = this.auth.getCustomer().pipe(
            first(),
            map((customer) => customer.id)
        );
    }

    public getQuestions(resource: Resource): Observable<Question[]> {
        return this.builder
            .get<QuestionData[]>(this.resourceUrl)
            .param('resource', resource.id.toString(10))
            .request()
            .pipe(map((res) => this.mapQuestions(res.data)));
    }

    public getQuestionsWithOccasion(
        opportunity_id: number
    ): Observable<{ questions: Question[]; passingPercentage: TestPassingPercentage }> {
        return this.builder
            .get<QuestionData[]>(`${this.resourceUrl}`)
            .param('result', 1)
            .param('opportunity', opportunity_id)
            .request()
            .pipe(
                map((res) => {
                    return { questions: this.mapQuestions(res.data), passingPercentage: res.more };
                })
            );
    }

    public getQuestionByItem(itemId: number): Observable<Question> {
        return this.builder
            .get<QuestionData[]>(this.resourceUrl)
            .param('item', itemId.toString(10))
            .request()
            .pipe(map((res) => this.createQuestion(res.data[0])));
    }

    public store(question: QuestionData) {
        return this.builder
            .post<QuestionData>(`${this.resourceUrl}`, question)
            .request()
            .pipe(map((res) => this.createQuestion(res.data)));
    }

    /**
     * Method for storing multiple questions at a time.
     *
     * @param questions
     */
    public storeAll(questions: QuestionData[]): Observable<Question[]> {
        return this.builder
            .post<QuestionData[]>(`${this.resourceUrl}/all`, { questions: questions })
            .request()
            .pipe(map((res) => this.mapQuestions(res.data)));
    }

    public update(question: QuestionData): Observable<Question> {
        return this.builder
            .put<QuestionData>(`${this.resourceUrl}/${question.id}`, question)
            .request()
            .pipe(map((res) => this.createQuestion(res.data)));
    }

    public destroy(question: Question) {
        return this.builder.delete<{ success: boolean }>(`${this.resourceUrl}/${question.id}`).request();
    }

    protected mapQuestions(data: QuestionData[]): Question[] {
        return data.map((item) => this.createQuestion(item));
    }

    protected createQuestion(data: QuestionData): Question {
        const question = new Question(data);

        question.media$ = question.media_id ? this.mediaService.getMedia(question.media_id) : EMPTY;
        if (question.alternatives) {
            question.alternatives.map((alt) => {
                if (alt.media_id) {
                    alt.media$ = this.mediaService.getMedia(alt.media_id);
                }
                return alt;
            });
        }
        // if (question.question_type_value == QuestionTypes.INPUT && !question.max_points) {
        //     question.max_points = 10;
        // }

        return question;
    }
}
