import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { ResourceLibraryComponent } from '../resource-library/resource-library.component';
import { AuthService } from '@klickdata/core/auth';
import { AppScope, Resource, ResourceTag, ResourceTagData, ResourceTypeService } from '@klickdata/core/resource';
import { ResourceListService } from '../resource-list.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MobileService } from '@klickdata/core/mobile';

@Component({
    selector: 'app-resource-library-multiple-select',
    templateUrl: './resource-library-multiple-select.component.html',
    styleUrls: ['./resource-library-multiple-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ResourceListService],
})
export class ResourceLibraryMultipleSelectComponent extends ResourceLibraryComponent implements OnInit {
    public selectedResources: Resource[] = [];
    constFilters = [];
    @Output() onSubmitMultiSelect: EventEmitter<Resource[]> = new EventEmitter<Resource[]>();
    @Output() selectChange: EventEmitter<{ value: Resource; selected: boolean }> = new EventEmitter();

    constructor(
        protected resourceTableService: ResourceListService,
        protected auth: AuthService,
        protected typeService: ResourceTypeService,
        protected mobileService: MobileService,
        protected cdRef: ChangeDetectorRef
    ) {
        super(resourceTableService, auth, typeService, mobileService, cdRef);
    }
    ngOnInit(): void {
        super.ngOnInit();
        if (this.type_scope_id == AppScope.MATERIAL) {
            this.columns.splice(2, 0, 'tags');
        }
    }
    public isResDefaultSelected(resId: number) {
        return this.selectionSub.value.includes(resId);
    }
    onSubmitMultiple() {
        this.onSubmitMultiSelect.emit(this.selectedResources);
    }
    onTagClick(tag: ResourceTagData) {
        this.filter.filterByModel(new ResourceTag(tag).getFilter(), true);
    }
    onRowSelected(resource: Resource) {
        resource.selected = !resource.selected;
        this.onSelect(resource);
    }
    onCheckboxClick(ev: MatCheckboxChange, resource: Resource) {
        resource.selected = ev.checked;
        this.onSelect(resource);
    }
    public onSelect(resource: Resource): void {
        resource.selected
            ? this.selectedResources.push(resource)
            : this.selectedResources.splice(this.selectedResources.indexOf(resource), 1);

        this.selectChange.emit({ value: resource, selected: resource.selected });
    }
    getRowTooltip(disabled: boolean) {
        return disabled
            ? $localize`${AppScope.label(this.type_scope_id)} is not ready to be in course`
            : $localize`Select ${AppScope.label(this.type_scope_id)}`;
    }
}
