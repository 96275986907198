import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ResourceOpportunity } from '@klickdata/core/opportunity';
import { Resource } from '@klickdata/core/resource';
import { TestPassingPercentage } from '@klickdata/core/resource/src/resource.model';
import { User } from '@klickdata/core/user';
import { Utils } from '@klickdata/core/util';
import { OccasionService } from '@klickdata/player/src/core/occasion/occasion.service';
import { PassingLevelStorage, TakeTestStatus, TestClosingData } from '@klickdata/player/src/core/player/player.service';
import { NextStepService } from '@klickdata/player/src/next-step.service';
import { filter, map, Observable, switchMap, tap } from 'rxjs';
import * as moment from 'moment';
import { LocalStorageService } from 'ngx-webstorage';
import { ResourceItem } from '@klickdata/core/resource-item';

@Component({
    selector: 'app-general-test-occasions-results',
    templateUrl: './general-test-occasions-results.component.html',
    styleUrls: ['./general-test-occasions-results.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralTestOccasionsResultsComponent implements OnInit {
    @Input() test: Resource;
    @Input() user: User;
    @Output()
    public close: EventEmitter<TestClosingData> = new EventEmitter<TestClosingData>();
    public isUserPassed: boolean;
    public isLastRepeat: boolean;
    public isCourseFailed: boolean;
    public showRepeatBtn: boolean;
    public showNextBtn: boolean;
    public userRepeats: number;
    public relatedCourseItem: ResourceItem;
    public testOccasions$: Observable<ResourceOpportunity[]>;
    public lastDoneOccasion: ResourceOpportunity;
    public testPassingLevel: TestPassingPercentage;
    private takeTestStatus: TakeTestStatus;

    constructor(
        protected occasionService: OccasionService,
        private route: ActivatedRoute,
        private nextStepService: NextStepService,
        protected storage: LocalStorageService
    ) {}

    ngOnInit(): void {
        this.testOccasions$ = Utils.getRouteParam(this.route, 'occasionId').pipe(
            switchMap((courseOccId) =>
                this.occasionService.getAllOccasionsWithParent({
                    parent_id: courseOccId,
                    resource_id: this.test.id,
                    eager: ['passed', 'downloads'],
                })
            ),
            filter((ocassions) => !!ocassions.length),
            map((ocassions) => ocassions.filter((occ) => !!occ.done)),
            map((ocassions) => ocassions.sort((a, b) => b.user_percentage - a.user_percentage)),
            tap((occasions) => this.handleOccasions(occasions))
        );
    }
    private handleOccasions(occasions: ResourceOpportunity[]): void {
        this.userRepeats = occasions.length;
        this.isUserPassed = occasions.some((occ) => occ.passed);
        this.relatedCourseItem = this.nextStepService
            .getItems()
            ?.find((item) => item?.child_resource_id == this.test.id);
        const highestScoreOcc = occasions.reduce((max, item) =>
            item.user_percentage > max.user_percentage ? item : max
        );

        this.testPassingLevel = !!this.relatedCourseItem?.passing_level?.percentage
            ? {
                  ...this.relatedCourseItem.passing_level,
                  user_repeats: this.userRepeats,
                  user_percentage: highestScoreOcc.user_percentage,
              }
            : null;
        this.isCourseFailed =
            this.testPassingLevel &&
            this.testPassingLevel?.repeats != null &&
            this.testPassingLevel?.repeats <= this.userRepeats;
        this.lastDoneOccasion = occasions.reduce((latest, current) => {
            return !latest || current.done.isAfter(latest.done) ? current : latest;
        }, null);
        this.isLastRepeat = this.testPassingLevel && this.testPassingLevel.repeats - this.userRepeats == 1;
        // When passing percentage is 0 we set test repeats to null > infinte repeats
        if (this.testPassingLevel && !this.testPassingLevel.percentage) {
            this.testPassingLevel.repeats = null;
        }
        this.showRepeatBtn = this.userCanRepeat();
        this.showNextBtn = this.userCanGoNext();
    }

    getRemainigRepeats() {
        if (!this.testPassingLevel || this.testPassingLevel?.repeats == null) {
            return $localize`Infinte retries allowed`;
        } else if (this.testPassingLevel.repeats == 0 || this.userRepeats >= this.testPassingLevel?.repeats) {
            return $localize`No retries allowed`;
        } else {
            return $localize`You still have ${this.testPassingLevel?.repeats - this.userRepeats} retries`;
        }
    }
    private updateStoredPassingLevel() {
        let testsPassing: PassingLevelStorage[] = this.storage.retrieve('testPassingLevel') ?? [];
        const index = testsPassing?.map((passing) => passing.testId)?.findIndex((testId) => testId == this.test.id);
        if (index < 0) {
            testsPassing = !!testsPassing?.length
                ? [
                      {
                          testId: this.test.id,
                          takeTestStatus: this.takeTestStatus,
                          testPassingPercentage: this.testPassingLevel,
                      },
                      ...testsPassing,
                  ]
                : [
                      {
                          testId: this.test.id,
                          takeTestStatus: this.takeTestStatus,
                          testPassingPercentage: this.testPassingLevel,
                      },
                  ];
        } else {
            testsPassing[index] = {
                testId: this.test.id,
                takeTestStatus: this.takeTestStatus,
                testPassingPercentage: this.testPassingLevel,
            };
        }
        this.storage.store('testPassingLevel', testsPassing);
    }

    public userCanRepeat(): boolean {
        /**
         * passingLevel.repeats == 0 means no repats allowed
         * passingLevel.repeats ==  means infinte repeats
         */
        const level = this.relatedCourseItem?.passing_level;
        if (!level || level?.repeats == 0) {
            return false;
        }
        return level && (level.repeats == null || level.repeats > this.userRepeats);
    }
    roundPercentage(percentage: number): number {
        return Math.round(percentage);
    }
    perfectScore(percentage: number) {
        return percentage > 99;
    }
    private userCanGoNext(): boolean {
        /**
         * Cases of showing next btn
         * 1- When failed course > Show you failed and give exit options
         * 2- when passed test > Normal
         * 3- When no passing > normal
         */
        return (
            this.isCourseFailed || this.isUserPassed || !this.testPassingLevel || this.testPassingLevel?.repeats == 0
        );
    }
    repeat() {
        /**
         * Cases of showing repeat btn
         * 1- When Not passed test and course not failed > Show you repeat directly or repeat from beginning
         * 2- when passed test > Repeat directly
         * 3- When no passing > Repeat directly
         *
         */

        this.takeTestStatus = this.isUserPassed
            ? TakeTestStatus.SHOULD_REPEAT_NOW
            : TakeTestStatus.SHOULD_REPEAT_WITH_OPTIONS;
        this.updateStoredPassingLevel();

        this.close.emit({
            status: true,
            testPassingPercentage: this.testPassingLevel,
            takeTestStatus: this.takeTestStatus,
        });
    }
    done() {
        /**
         * Cases of showing next btn
         * 1- When failed course > Show you failed and give exit options
         * 2- when passed test > Normal
         * 3- When no passing > normal
         */
        if (!this.testPassingLevel || !this.testPassingLevel.percentage) {
            this.takeTestStatus = TakeTestStatus.CONTINUE;
        } else if (this.isUserPassed) {
            this.takeTestStatus = TakeTestStatus.PASSED;
        } else if (this.isCourseFailed) {
            this.takeTestStatus = TakeTestStatus.COURSE_FAILED;
        }
        this.updateStoredPassingLevel();
        this.close.emit({
            status: true,
            testPassingPercentage: this.testPassingLevel,
            takeTestStatus: this.takeTestStatus,
        });
    }
    public getTestTooltip() {
        if (this.relatedCourseItem) {
            const item = this.relatedCourseItem;
            const percentage =
                item.passing_level?.percentage > 0
                    ? $localize`Passing percentage: ${item.passing_level?.percentage} %`
                    : $localize`No passing percentage`;
            const repeats =
                item.passing_level?.repeats == 0
                    ? $localize`No repeats allowed`
                    : item.repeats > 0
                    ? $localize`Repeats: ${item.passing_level?.repeats}`
                    : $localize`Infinte repeats`;
            return percentage + ', ' + repeats + ', ' + item.passing_level.question_count + 'Qs';
        }
    }
}
