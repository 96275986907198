<form [formGroup]="groupForm" *ngIf="navData" class="data-wrap" fxLayout="column">
    <ng-container *ngIf="customer">
        <div class="title" fxLayoutAlign="start center" fxLayoutGap="5px">
            <mat-icon *ngIf="!isTeam">groups</mat-icon>
            <mat-icon *ngIf="isTeam">diversity_2</mat-icon>
            <span *ngIf="!isTeam"> <span i18n>Create</span> {{ getScopeName(customer, AppScope.GROUPS) }}</span>
            <span *ngIf="isTeam"> <span i18n>Create</span> {{ getScopeName(customer, AppScope.TEAM) }}</span>
        </div>
        <div fxLayout="column" fxLayoutGap="20px">
            <app-user-setting-input
                type="text"
                i18n-placeholder
                placeholder="Set name"
                [control]="groupForm.get('name') | cast"
            >
                <label role="input-label" *ngIf="!isTeam"
                    >{{ getScopeName(customer, AppScope.GROUPS) }} <span i18n>Name</span></label
                >
                <label role="input-label" *ngIf="isTeam"
                    >{{ getScopeName(customer, AppScope.TEAM) }} <span i18n>Name</span></label
                >
            </app-user-setting-input>
            <div *ngIf="isTeam" fxLayoutGap="4px" class="assignee input-wrapper default-select" fxLayout="column">
                <span class="label">{{ getScopeName(customer, AppScope.TEAM) }} <span i18n>Manager</span></span>
                <div fxLayout="row" fxLayoutGap="10px" fxLayoutGap.lt-md="10px" class="input-parent">
                    <mat-form-field class="small-field users-rec-input" appearance="fill">
                        <input
                            type="text"
                            matInput
                            #assigneeRecieverInput
                            i18n-placeholder
                            placeholder="Search and select manager"
                            [formControl]="userControl"
                            [matAutocomplete]="autoUser"
                        />
                        <mat-autocomplete #autoUser="matAutocomplete" (optionSelected)="selected(autoUser, $event)">
                            <mat-option *ngFor="let user of userSearch$ | async" [value]="user">
                                <div fxLayout="row" fxLayoutAlign="start center">
                                    <span *ngIf="user.name">{{ user.name }}</span>
                                    (<span>{{ user.email }}</span
                                    >)
                                </div>
                            </mat-option>
                        </mat-autocomplete>
                        <button
                            *ngIf="!!teamManagers?.length"
                            mat-icon-button
                            class="cancel-contact"
                            matSuffix
                            (click)="onRemoveManager()"
                        >
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
            </div>

            <app-team-select-dropdown
                *ngIf="(navData.data.team || customer.division_mode) && !isTeam"
                formControlName="parent_id"
                [customer]="customer"
                [filters]="{ team: 1}"
                [label]="getSelectStationLable(customer)"
                [searchPlacholder]="getSearchStationPlaceholder(customer)"
            ></app-team-select-dropdown>

            <div fxLayoutGap="4px" fxLayout="column" class="w-100 input-field-parent deadline">
                <span i18n>Expiration Date</span>
                <mat-form-field
                    appearance="outline"
                    floatLabel="never"
                    class="w-100"
                    fxFlex="grow"
                    fxFlex="1 1 100%"
                    fxFlex.lt-md="1 1 100%"
                >
                    <mat-label i18n>Enter a valid date or leave empty</mat-label>
                    <input
                        matInput
                        [matDatepicker]="expirationDatepicker"
                        (focus)="expirationDatepicker.open()"
                        formControlName="expiration"
                    />
                    <mat-datepicker-toggle matIconSuffix [for]="expirationDatepicker"></mat-datepicker-toggle>
                    <mat-datepicker #expirationDatepicker></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
        <div class="action" fxLayout="row-reverse wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
            <ng-container *ngIf="!(loading$ | async)">
                <button
                    [disabled]="groupForm.invalid"
                    fxFlex="0 0 auto"
                    class="mobile-button custom"
                    (click)="submit()"
                    mat-raised-button
                >
                    <span i18n>Create</span>
                </button>
                <button
                    [disabled]="groupForm.invalid"
                    fxFlex="0 0 auto"
                    class="mobile-button positive"
                    (click)="submit(true)"
                    mat-raised-button
                >
                    <span i18n>Create and Edit</span>
                </button>
            </ng-container>
            <app-kd-logo-loading *ngIf="loading$ | async" size="small"></app-kd-logo-loading>
            <button
                fxFlex="0 0 auto"
                class="mobile-button negative"
                (click)="onClose.emit(); groupForm.reset()"
                mat-button
            >
                <span i18n>Cancel</span>
            </button>
        </div>
    </ng-container>
</form>
