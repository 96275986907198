import {
    Cast,
    CastType,
    IDataModel,
    Ignore,
    InitialValue,
    Model,
} from '@klickdata/core/application/src/model/model-interface';
import { Customer } from '@klickdata/core/customer/src/customer.model';
import { Prop } from '@klickdata/core/form';
import { Group } from '@klickdata/core/group/src/group.model';
import { Media } from '@klickdata/core/media/src/media.model';
import { QuoteStatus } from '@klickdata/core/quote/src/quote.model';
import { ResourceTagData } from '@klickdata/core/resource';
import { Downloads } from '@klickdata/core/resource/src/download.model';
import { Educator, Staff } from '@klickdata/core/resource/src/resource.model';
import { User } from '@klickdata/core/user/src/user.model';
import { Utils } from '@klickdata/core/util';
import * as moment from 'moment';
import { Observable } from 'rxjs';

export interface PostData extends IDataModel {
    id?: number;
    title?: string;
    body?: string;
    about_us?: string;
    short_body?: string;
    ingress?: string;
    published_at?: string;
    created_at?: string;
    updated_at?: string;
    public?: boolean;
    published?: boolean;
    author_id?: number;
    customer_id?: number;
    group_ids?: number[];
    tag_ids?: number[];
    media_id?: number;
    medias: Medias;
    language_id?: number;
    users_access_open?: boolean;
    users_attach?: number[];
    users_detach?: number[];
    sync_all_users?: boolean;
    groups_attach?: number[];
    groups_detach?: number[];
    sync_all_groups?: boolean;
    users_attached: boolean;
    groups_attached: boolean;
    tags: ResourceTagData[];
    videoMedia$: Observable<Media>;
    staff?: Staff;
    downloads?: Downloads;
}

export class Post extends Model<PostData> {
    id: number;
    title: string;
    body: string;
    short_body: string;
    ingress: string;
    @Cast(CastType.MOMENT)
    published_at: moment.Moment;
    @Cast(CastType.MOMENT)
    created_at: moment.Moment;
    @Cast(CastType.MOMENT)
    updated_at: moment.Moment;
    public: boolean;
    @Prop()
    public users_access_open: boolean;
    published: boolean;
    publisher: string;
    about_us: string;
    author_id: number;
    customer_id: number;
    group_ids: number[];
    tag_ids: number[];
    media_id: number;
    language_id: number;
    tags: ResourceTagData[];
    @Ignore()
    videoMedia$: Observable<Media>;
    author$: Observable<User>;
    customer: Observable<Customer>;
    @Ignore()
    groups: Observable<Group[]>;
    media$: Observable<Media>;
    @Cast(CastType.CLOSURE, Utils.filterUpdatedObject)
    public staff: Staff;
    @Cast(CastType.CLOSURE, (data: PostQuote) => {
        if (!data.quote) return null;
        if (!data.quotee) {
            data.quotee = null;
        }
        return data;
    })
    public quote?: PostQuote;
    public managers$: Observable<Educator[]>;
    public publishers$: Observable<Educator[]>;

    // users
    @InitialValue([])
    users_attach: number[];
    @InitialValue([])
    users_detach: number[];
    sync_all_users: boolean;

    // groups
    @InitialValue([])
    groups_attach: number[];
    @InitialValue([])
    groups_detach: number[];
    sync_all_groups: boolean;
    @Ignore()
    public users_attached: boolean;
    s;
    @Ignore()
    public downloads?: Downloads;
    @Ignore()
    public groups_attached: boolean;
    @Cast(CastType.CLOSURE, (data) => {
        data.cover = data.cover?.id ? [data?.cover] : null;
        data.video = data.video?.id ? [data?.video] : null;
        if (data.other?.length) {
            data.other = data.other.filter((other) => other.id !== null);
        }
        return data;
    })
    public medias?: Medias;

    get status(): QuoteStatus {
        if (!this.published) {
            return { label: $localize`Draft`, color: '#a7a7a7', icon: 'public_off' };
        } else if (this.public) {
            return { label: $localize`Public`, color: '#3e5365', icon: 'public' };
        } else {
            return { label: $localize`:@@academy:Academy`, color: '#93cbd1', icon: 'published_with_changes' };
        }
    }
}
export interface Medias {
    cover?: MediasItem[] | MediasItem;
    other?: MediasItem[];
    video?: MediasItem[] | MediasItem;
}
export interface MediasItem {
    id: number;
    description: string;
}
export interface PostQuote {
    quote: string;
    quotee: number;
}
