import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { AuthService } from '@klickdata/core/auth';
import { MessageService } from '@klickdata/core/message';
import { MobileService } from '@klickdata/core/mobile';
import { AppScope, Resource, ResourceTypeService } from '@klickdata/core/resource';
import { ResourceTableActions } from '@klickdata/core/resource/src/types.enum';
import { Filter, TableFilterComponent, TableSource } from '@klickdata/core/table';
import { User, UserService } from '@klickdata/core/user';
import { WelcomeMessageDialogComponent } from 'apps/klickdata/src/app/shared/email/welcome-message-dialog/welcome-message-dialog.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TestResultsOverallService } from './test-results-overall.service';

@Component({
    selector: 'app-tests-results-overall',
    templateUrl: './tests-results-overall.component.html',
    styleUrls: ['./tests-results-overall.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [TestResultsOverallService],
})
export class TestsResultsOverallComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() resource: Resource;
    public dataSource = new TableSource();
    public columns = ['name', 'percentage', 'correct', 'passing', 'grade', 'started_at', 'test_time', 'tools'];
    public date = new Date();
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(TableFilterComponent) filter: TableFilterComponent;
    private isMobile: boolean;
    public destroy: Subject<boolean> = new Subject<boolean>();
    AppScope = AppScope;

    ResourceTableActions = ResourceTableActions;
    public tableFilterItems: Filter<string | number>[];

    constructor(
        protected listService: TestResultsOverallService,
        protected changeRef: ChangeDetectorRef,
        protected userService: UserService,
        protected typeService: ResourceTypeService,
        protected dialog: MatDialog,
        protected router: Router,
        protected bottomSheet: MatBottomSheet,
        protected auth: AuthService,
        protected message: MessageService,
        protected mobileService: MobileService
    ) {}

    public ngOnInit(): void {
        this.dataSource.service = this.listService;
        this.mobileService
            .isMobile()
            .pipe(takeUntil(this.destroy))
            .subscribe((mobile) => (this.isMobile = mobile));
        this.tableFilterItems = [
            { property: 'overall', items: [this.resource.id] },
            { property: 'eager', items: ['activations'] },
        ];
    }

    public ngAfterViewInit(): void {
        // this.dataSource.sort = this.sort;
        // this.dataSource.paginator = this.paginator;
        // this.dataSource.filter = this.filter;
        // this.filter.createOrUpdateFilter([{ property: 'overall', items: [this.resource.id] }]);
    }

    public sendEmail(user: User) {
        const dialogRef = this.dialog.open(WelcomeMessageDialogComponent, {
            width: !this.isMobile ? '70%' : '100%',
            data: { users_attach: [user.id], title: user.name },
            disableClose: true,
            panelClass: 'sheet-wrapper',
        });
    }

    public viewUserResults(user: User) {
        this.router.navigateByUrl(
            `/player/occasion/${user.finalResult?.opportunity_id}/test/result/${user.id}?referrer=/admin/statistics/test-results/tests/${this.resource.id}`
        );
    }
    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
