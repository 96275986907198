import { IDataModel, Model } from '@klickdata/core/application';

export interface PaymentData extends IDataModel {
    id?: number;
    txn_id?: string; // The PayPal transaction ID.
    amount?: string; // The total amount.
    currency?: string; //
    status?: string;
    type?: 'paypal';
    resource_id?: number;
    payer_id?: number;
    tran_ref?: string;

    /**
     * Payer send when user not register check by email and register.
     */
    payer_email?: string;
    payer_fname?: string; // name.given_name: "John"
    payer_lname?: string; // name.surname: "Doe"
    payer_country?: string; //
    payer_zip?: string; //
}
export interface PaymentMethod {
    id: number;
    name: string;
    title: string;
    logo_url: string;
    disabled: boolean;
}

export interface PaymentItem {
    id: number;
    price: number;
    currency: string;
    name?: string;
    subTitle?: string;
    desc?: string;
    recurrent?: string;
    subsPlan?: SubscriptionPlan;
}
export interface SubscriptionPlan {
    plan?: string;
    users_count?: string;
}

export class Payment extends Model<PaymentData> {
    id: number;
    txn_id: string; // The PayPal transaction ID.
    amount: string; // The total amount.
    currency: string; //
    status: string;
    type: 'paypal';
    resource_id: number;
    payer_id: number;
    tran_ref: string;
}
