import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    OnInit,
    ChangeDetectorRef,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CanComponentDeactivate } from '@klickdata/core/auth';
import { Group } from '@klickdata/core/group';
import { MobileService } from '@klickdata/core/mobile';
import { Filter, GlobalFilterProperty, SelectFilterOption } from '@klickdata/core/table';
import { Utils } from '@klickdata/core/util';
import { SelectModelBaseDirective } from '../../select-model-base/select-model-base.component';
import { GroupsService } from '../../groups-select/groups.service';
import { takeUntil } from 'rxjs';
import { MessageSavedComponent, MessageService } from '@klickdata/core/message';
import { SectionService } from '@klickdata/core/section';
import { FormHelper } from '@klickdata/core/form';

@Component({
    selector: 'app-stations-select-in-group',
    templateUrl: './stations-select-in-group.component.html',
    styleUrls: ['./stations-select-in-group.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StationsSelectInGroupComponent
    extends SelectModelBaseDirective<Group>
    implements OnInit, AfterViewInit, CanComponentDeactivate, OnDestroy
{
    @Input() team: Group;
    @Input() sectionId: number;
    accessForm: FormGroup;
    @Input() referenceFilter = 'reference_section_team';
    @Input() referenceIdKey = 'id';
    @Input() customer_id: number;
    public columns = ['select', 'name', 'created_at', 'updated_at', 'team_group_users_count'];
    @Input() sync_all_key = 'sync_all';
    @Input() attachKey = 'attach_ids';
    @Input() detachKey = 'detach_ids';
    @Input() assignId: number;
    @Input() filtersToBeApplied: Filter<string | number>[];
    @Output() onSaved: EventEmitter<boolean> = new EventEmitter<boolean>();
    public itemSelectedOptions: SelectFilterOption[];
    GlobalFilterProperty = GlobalFilterProperty;

    constructor(
        protected groupsService: GroupsService,
        protected mobile: MobileService,
        private fb: FormBuilder,
        private sectionService: SectionService,
        protected messageService: MessageService,
        protected cdRef: ChangeDetectorRef
    ) {
        super(groupsService, mobile);
        this.itemSelectedOptions = Utils.getItemSelectedOptions();
        this.accessForm = this.fb.group({
            id: [],
            sync_all: [],
            attach_ids: [[]],
            detach_ids: [[]],
        });
    }
    public ngOnInit(): void {
        super.ngOnInit();
        this.accessForm.patchValue({ id: this.sectionId + ',' + this.team.id });
        this.accessForm.valueChanges.subscribe((value) => {
            FormHelper.markForm(this.accessForm);
            this.cdRef.markForCheck();
        });
    }
    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    /**
     * @override
     * @param id
     */
    protected refresh(id?: any): void {
        const filters = [];
        if (this.referenceFilter) {
            filters.push({ property: this.referenceFilter, items: [id] });
        }
        if (this.customer_id) {
            filters.push({ property: 'customer', items: [this.customer_id] });
        }
        if (!!this.filtersToBeApplied?.length) {
            this.filtersToBeApplied
                .filter((fltr) => !!fltr.property && !!fltr.items?.length)
                .forEach((fltr) => filters.push(fltr));
        }
        this.filter.createOrUpdateFilter(filters);
    }

    detectIdKeyValueChange() {
        if (this.referenceFilter) {
            super.detectIdKeyValueChange();
        } else {
            this.refresh();
        }
    }

    public submit() {
        if (
            this.accessForm.value.attach_ids?.length ||
            this.accessForm.value.detach_ids?.length ||
            this.accessForm.value.sync_all !== null
        ) {
            const data = this.accessForm.value;
            delete data.id;

            this.saving = true;
            this.sectionService
                .update({ id: this.sectionId, groups: { ...data, team_id: this.team.id } })
                .pipe(takeUntil(this.destroy))
                .subscribe((res) => {
                    this.saving = false;
                    this.messageService.openMessage(MessageSavedComponent);
                    this.onSaved.emit(true);
                });
        }
    }
}
