import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AuthService } from '@klickdata/core/auth';
import { SideNaveActionsTypes, SideNaveData } from '@klickdata/core/mobile';
import { SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { User } from '@klickdata/core/user';
import { Observable, forkJoin, of, switchMap, take, takeUntil } from 'rxjs';
import { AddFormativeEvaluationComponent } from '../formative-evaluation/add-formative-evaluation/add-formative-evaluation.component';
import { ResourceOpportunityService } from '@klickdata/core/opportunity';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';
import { EditorTemplateService } from '../text-editor/editor-template.service';
import { EditorTemplate } from '../text-editor/editor-template.model';

@Component({
    selector: 'app-menu-side-validate-signoff',
    templateUrl: './menu-side-validate-signoff.component.html',
    styleUrls: ['./menu-side-validate-signoff.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideValidateSignoffComponent extends OnDestroyHandler implements OnInit {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    SideNaveActionsTypes = SideNaveActionsTypes;
    public authUser$: Observable<User>;
    @ViewChild('addFormEval') addFormEval: AddFormativeEvaluationComponent;
    public approved: boolean;
    constructor(
        protected auth: AuthService,
        protected resourceOpportunityService: ResourceOpportunityService,
        protected templateService: EditorTemplateService
    ) {
        super();
    }

    ngOnInit(): void {
        this.authUser$ = this.auth.getUser().pipe(take(1));
    }
    submitEvaluation() {
        this.resourceOpportunityService
            .postReviews(this.navData.data.occasion.id, { approved: this.approved })
            .pipe(takeUntil(this.destroy))
            .subscribe((oppo) =>
                this.onClose.emit({
                    data: {
                        oppo: { ...this.navData.data.occasion, ...{ approved: this.approved } },
                        type: this.navData.data.type,
                    },
                })
            );
    }
}
