import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SideNaveActionsTypes, SideNaveData, SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';

@Component({
    selector: 'app-menu-side-select-publisher-logo',
    templateUrl: './menu-side-select-publisher-logo.component.html',
    styleUrls: ['./menu-side-select-publisher-logo.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideSelectPublisherLogoComponent implements OnInit {
    @Input() public navData: SideNaveData;
    SideNaveActionsTypes = SideNaveActionsTypes;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    constructor() {}
    ngOnInit(): void {}
}
