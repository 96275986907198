<div class="group-select-wrapper" fxLayout="column" fxFlex="0 0 100%">
    <app-table-filter [filtersTogglesInOnBtn]="true" [inGlobalFilter]="true" color="accent" #filter>
        <app-table-search [inGlobalFilter]="true" class="align-space" searchFilterRef></app-table-search>
        <app-team-chip-search-filter
            *ngIf="customer.division_mode && !isTeam"
            [inGlobalFilter]="true"
            teamSearchRef
        ></app-team-chip-search-filter>
    </app-table-filter>

    <mat-progress-bar
        class="progress progress-table-loader"
        *ngIf="(dataSource.status | async) === 'loading'"
        mode="indeterminate"
    ></mat-progress-bar>
    <mat-table
        #table
        [dataSource]="dataSource"
        matSortActive="name"
        matSortDirection="desc"
        matSort
        [class.mobileView]="isMobile"
        [ngClass]="{ sv: localeId == 'sv' }"
    >
        <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef> </mat-header-cell>
            <mat-cell *matCellDef="let group">
                <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="selectToggle(group, $event.checked); group.some_resource_perm_checked = false"
                    [checked]="group.all_resource_perm_checked"
                    [indeterminate]="group.some_resource_perm_checked"
                    [disabled]="group.disabled"
                >
                </mat-checkbox>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@name"> Name </mat-header-cell>
            <mat-cell *matCellDef="let group">
                <span i18n="@@name" class="mobile-label"> Name </span>
                {{ group.name }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="users_count">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@numberOfUsers">
                <mat-icon fxFlex="0 0 auto" fontSet="klickdata" fontIcon="kd-icon-user" class="kd-icon-16"></mat-icon>
            </mat-header-cell>
            <mat-cell *matCellDef="let group">
                <span i18n="@@numberOfUsers" class="mobile-label"> Number of users </span>
                {{ group.users_count }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="parent">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                <mat-icon>diversity_2</mat-icon>
            </mat-header-cell>
            <mat-cell (click)="$event.stopPropagation()" *matCellDef="let group">
                <span *ngIf="isMobile" class="mobile-label"> <mat-icon>diversity_2</mat-icon> </span>
                <div *ngIf="group.parent?.id">
                    {{ group.parent.name }}
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="permissions">
            <mat-header-cell *matHeaderCellDef
                ><div class="perm" fxLayout="row" fxLayoutAlign="space-between center">
                    <div
                        class="{{ (perm.value | resourcePermissionPipe).class }}"
                        *ngFor="let perm of resourcePermissions"
                    >
                        <span [matTooltip]="perm.tooltip">{{ perm.label }}</span>
                    </div>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let group">
                <span class="mobile-label perms">
                    <div class="perm" fxLayout="row" fxLayoutAlign="start center">
                        <div
                            class="{{ (perm.value | resourcePermissionPipe).class }}"
                            *ngFor="let perm of resourcePermissions"
                        >
                            <span [matTooltip]="perm.tooltip">{{ perm.label }}</span>
                        </div>
                    </div>
                </span>

                <app-resource-permissions-handler
                    #resourcePermHandler
                    *ngIf="groupPermissionsArray && !isTeam"
                    [permissionsFormArray]="groupPermissionsArray"
                    [group]="group"
                    [permissions]="group.resource_permissions"
                    [resourcePermissions]="resourcePermissions"
                ></app-resource-permissions-handler>
                <app-resource-permissions-handler
                    #resourcePermHandler
                    *ngIf="teamPermissionsArray && isTeam"
                    [permissionsFormArray]="teamPermissionsArray"
                    [group]="group"
                    [permissions]="group.resource_permissions"
                    [resourcePermissions]="resourcePermissions"
                ></app-resource-permissions-handler>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let group; columns: columns" [class.disable-row]="group.disabled"></mat-row>
    </mat-table>

    <mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"></mat-paginator>
</div>
