import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AuthService } from '@klickdata/core/auth';
import { GeneralCapability } from '@klickdata/core/auth/src/capabilities/capability/general-capability.enum';
import { Customer } from '@klickdata/core/customer';
import { AppScope } from '@klickdata/core/resource';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-admin-menu',
    templateUrl: './admin-menu.component.html',
    styleUrls: ['./admin-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminMenuComponent implements OnInit {
    public accessAdmin = GeneralCapability.access(GeneralCapability.VIEW_ADMIN);
    public accessMaster = GeneralCapability.access(GeneralCapability.VIEW_MASTER);
    public accessAdminOrMaster = GeneralCapability.access(
        GeneralCapability.VIEW_ADMIN || GeneralCapability.VIEW_MASTER
    );
    AppScope = AppScope;
    public customer$: Observable<Customer>;

    constructor(private auth: AuthService) {
        this.customer$ = this.auth.getCustomer();
    }

    ngOnInit() {}
}
