<ng-container *ngIf="!loading; else loadingTemp">
    <div
        *ngIf="selected && !changeMode; else selectTemp"
        fxFlex="0 0 100%"
        class="selected-wrapper"
        fxLayout="row wrap"
    >
        <app-resource-builder
            fxFlex="0 0 100%"
            [resource]="selected"
            [language_id]="selected.language_id"
            [questionTypes]="questionTypes"
            [resource_type_id]="ResourceTypes.GeneralTest"
            [resourceType]="ResourceTypes.TEST"
            [active]="true"
            class="mt-1"
        ></app-resource-builder>
    </div>
</ng-container>

<ng-template #selectTemp>
    <app-course-manager-core-add-survey
        [display]="ResourceBuildingItems.SURVEY_LIBRARY"
        (selectChange)="selectResource($event.value); onChangeMode(false)"
        (onCloseView)="onChangeMode(false)"
    ></app-course-manager-core-add-survey>
</ng-template>

<ng-template #loadingTemp>
    <div fxLayout="row" fxLayoutAlign="left" class="spinner-container">
        <app-kd-logo-loading size="small"></app-kd-logo-loading>
    </div>
</ng-template>
