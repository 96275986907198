import { MobileService, SideNaveActionsTypes, SideNaveDataTypes } from '@klickdata/core/mobile';
import {
    ChangeDetectionStrategy,
    Component,
    Directive,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { ControlContainer, FormGroup, FormGroupDirective } from '@angular/forms';
import { CanComponentDeactivate } from '@klickdata/core/auth';
import { FormHelper } from '@klickdata/core/form';
import { QuestionType, QuestionTypes } from '@klickdata/core/question';
import { AppScope, Resource, ResourceType, ResourceTypes } from '@klickdata/core/resource';
import { ResourceItem, ResourceItemTypes } from '@klickdata/core/resource-item';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, switchMap } from 'rxjs/operators';
import { ResourceBuilderToolsComponent } from './resource-builder-tools/resource-builder-tools.component';
import { ResourceBuilderService, ResourceBuilderSubmitEvent } from './resource-builder.service';
import { AlternativeService } from './shared/alternative.service';
import { QuestionService } from './shared/question.service';
import { ResourceBuilderFormComponent } from './resource-builder-form.component';

@Directive({
    selector: '[appBuilderTitle]',
})
export class ResourceBuilderTitleDirective {}

@Directive({
    selector: '[appBuilderEmpty]',
})
export class ResourceBuilderEmptyDirective {}

@Component({
    selector: 'app-resource-builder',
    templateUrl: './resource-builder.component.html',
    styleUrls: ['./resource-builder.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ResourceBuilderService, QuestionService, AlternativeService],
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class ResourceBuilderComponent implements OnInit, CanComponentDeactivate, OnDestroy {
    @Input()
    set resource(resource: Resource) {
        if (resource) {
            this.resourceBuilderService.init(resource);
        }
    }
    @Input()
    set language_id(language_id: number) {
        if (language_id) {
            this.resourceBuilderService.setLanguageId(language_id);
        }
    }

    @Input()
    set resource_type_id(resource_type_id: number) {
        this.resourceBuilderService.resource_type_id = resource_type_id;
    }

    public get resource_type_id(): number {
        return this.resourceBuilderService.resource_type_id;
    }
    @Input()
    set resourceType(resourceType: ResourceTypes) {
        this.resourceBuilderService.resourceType = resourceType;
    }

    public form: Observable<FormGroup>;

    @Input()
    public inCard: boolean;
    @Input() hideTools: boolean;

    @Input()
    public forceSubmit: boolean;

    @Input()
    public active = false;

    @Input()
    public saveTitle: string;

    @Input()
    public usePoints = true;

    @Output() saving: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() saved: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() removal: EventEmitter<number[]> = new EventEmitter<number[]>();
    @Input() hideAddItems: boolean;
    @Input() resourceForm: FormGroup;
    @Input() resourceTypes: ResourceType[] = [];
    @Input() questionTypes: QuestionTypes[] = [];
    ResourceTypes = ResourceTypes;
    public destroy = new Subject<boolean>();
    public foldAll = true;
    public isMobile$: Observable<boolean>;
    public aiMetaData = new BehaviorSubject<any>(null);

    @ViewChild(ResourceBuilderToolsComponent) tools: ResourceBuilderToolsComponent;

    constructor(
        protected resourceBuilderService: ResourceBuilderService,
        protected questionService: QuestionService,
        protected mobileService: MobileService,
        protected parentFormDirective: FormGroupDirective
    ) {}

    public getService(): ResourceBuilderService {
        return this.resourceBuilderService;
    }

    public getLoading(): BehaviorSubject<boolean> {
        return this.resourceBuilderService.getLoading();
    }

    public getQuestionService(): QuestionService {
        return this.questionService;
    }

    public getResource(): Observable<Resource> {
        return this.resourceBuilderService.getResource();
    }

    public getCurrentResource(): Resource {
        return this.resourceBuilderService.getCurrentResource();
    }

    public getCurrentForm(): FormGroup {
        return this.resourceBuilderService.getCurrentForm();
    }

    public updateResource(resource: Resource) {
        this.resourceBuilderService.updateResource(resource);
    }

    public reset() {
        this.resourceBuilderService.reset();
    }

    public getItems(): Observable<ResourceItem[]> {
        return this.resourceBuilderService.getItems();
    }

    public submit(event: ResourceBuilderSubmitEvent = { emitSaved: true, extraPayload: {} }): void {
        this.resourceBuilderService.submit(event);
    }

    public ngOnInit(): void {
        this.form = this.resourceBuilderService.getForm();
    }

    public onSaving(value: boolean): void {
        this.saving.emit(value);
    }

    public onSaved(value: boolean): void {
        this.form.pipe(takeUntil(this.destroy)).subscribe((form) => {
            FormHelper.resetForm(form);
        });

        this.saved.emit(value);
    }

    public canDeactivate(): Observable<boolean> {
        return this.form.pipe(
            map((form) => {
                return form.untouched;
            })
        );
    }

    public addQuestions() {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.ADD_RESOURCE_QUESTIONS,
            data: {
                type: AppScope.QUESTIONS,
                buildingItems: this.questionTypes,
                icon: 'help',
                title: $localize`Add Question`,
            },
        });

        this.mobileService
            .getSideNavAction()
            .pipe(
                filter((action) => action === SideNaveActionsTypes.POSITIVE),
                switchMap(() => this.mobileService.getSideNavResponseData()),
                filter((data) => data.type === AppScope.QUESTIONS),
                takeUntil(this.destroy)
                // map((data) => data.value)
            )
            .subscribe((data) => {
                this.onAddQuestion(data);
                if (data.value === QuestionTypes.WOK) {
                    setTimeout(() => {
                        window.scrollTo({ top: 330, behavior: 'smooth' });
                    }, 500);
                }
            });
    }

    public onAddQuestion(data: any) {
        if (data.value === QuestionTypes.WOK) {
            this.onAddFromWOK(QuestionTypes.RADIO);
            return;
        }
        if (data.value === QuestionTypes.AI) {
            this.aiMetaData.next(data.aiMetaData);
            this.onAddFromAI(data.aiMetaData);
            return;
        }
        const alternatives_count = QuestionType.questionTypesWhichHaveAlts().includes(<QuestionTypes>data.value)
            ? 3
            : 0;

        this.resourceBuilderService.createItem({
            item_type_value: ResourceItemTypes.QUESTION,
            questionForm: this.questionService.createEmptyFormGroup(data.value, alternatives_count, this.usePoints),
            isEmpty: true,
        });

        // hide WOK generator.
        this.resourceBuilderService.showWOKGenerator.next(false);
        this.resourceBuilderService.selectedWOKQuestionType.next(null);
    }

    public onAddFromWOK(questionType: QuestionTypes) {
        this.resourceBuilderService.selectedWOKQuestionType.next(questionType);
        this.resourceBuilderService.showWOKGenerator.next(true);
    }

    public onAddFromAI(aiMetaData: any) {
        this.submit({ emitSaved: true, prompt_status: 'PROCESSING', extraPayload: { ai_metadata: aiMetaData } });
    }

    public onItemCollapsingChange(ev: boolean) {
        this.resourceBuilderService.updateItemsFoldingState(ev);
    }
    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
