import { IDataModel, Model, InitialValue, Ignore } from '@klickdata/core/application/src/model/model-interface';
import { Customer } from '@klickdata/core/customer/src/customer.model';
import { Resource } from '@klickdata/core/resource/src/resource.model';
import { GlobalFilterProperty } from '@klickdata/core/table/src/global-filters-types.enum';
import { Filter } from '@klickdata/core/table/src/table-filter/filter';
import { FilterModel } from '@klickdata/core/table/src/table-filter/filter-model';
import { Observable, Subject } from 'rxjs';

export interface ResourceTagData extends IDataModel {
    id?: number;
    resource_ids?: number[];
    customer_id?: number;
    name?: string;
    names?: string[];
    public?: boolean;
    type_ids?: string[];
    language_id?: number;
}

/**
 * 'resource_id', 'title', 'subtitle', 'color', 'public', 'parent_id'
 */
export class ResourceTag extends Model<ResourceTagData> implements FilterModel {
    public id: number;
    public customer_id: number;
    public name: string;
    public public: boolean;
    public language_id: number;

    @InitialValue([])
    public resource_ids: number[];
    @InitialValue([])
    public type_ids: number[];

    // Properies that handle lazy loading of resources and search parent/children/child resources;
    @Ignore()
    public parentTitle: string;
    @Ignore()
    public opened: boolean;
    @Ignore()
    public page: number;
    @Ignore()
    public limit = 25;
    @Ignore()
    public loading: boolean;
    @Ignore()
    public hide: boolean;
    @Ignore()
    public query: string;

    @Ignore()
    public loadedResources: Resource[];
    public customer: Observable<Customer>;
    public resources: Observable<Resource[]>;
    public courses: Observable<Resource[]>;
    public resourcesPaginator: Observable<Resource[]>[];
    public updateSubject: Subject<ResourceTag> = new Subject<ResourceTag>();

    public getNext(): Observable<Resource[]> {
        if (this.page < this.resourcesPaginator.length) {
            return this.resourcesPaginator[this.page];
        }
    }

    public get canLoadMore(): boolean {
        return this.page < this.resourcesPaginator.length;
    }

    public getFilter(): Filter<string | number> {
        return {
            property: GlobalFilterProperty.TAGS,
            items: [this.id],
            chips: [{ id: this.id, label: this.name, language_id: this.language_id, selected: true }],
        };
    }
}
