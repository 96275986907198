import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoggerService } from '@klickdata/core/application/src/logger/logger.service';
import { CapabilityService } from '@klickdata/core/auth';
import { AuthService } from '@klickdata/core/auth/src/token/auth.service';
import { ConfigService } from '@klickdata/core/config/src/config.service';
import { Customer, CustomerService } from '@klickdata/core/customer';
import { GradeSystem } from '@klickdata/core/grade-system';
import { GradeSystemService } from '@klickdata/core/grade-system/src/grade-system.service';
import { PaginatorResponse, ResponseData } from '@klickdata/core/http';
import { RequestBuilderService } from '@klickdata/core/http/src/request/request-builder.service';
import { MediaService } from '@klickdata/core/media/src/media.service';
import { ResourceItemService } from '@klickdata/core/resource-item/src/resource-item.service';
import { Filter } from '@klickdata/core/table';
import { User } from '@klickdata/core/user';
import { UserService } from '@klickdata/core/user/src/user.service';
import { DatatableHttpService } from 'apps/klickdata/src/app/shared/datatable/datatable-http.service';
import * as moment from 'moment';
import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs';
import { catchError, first, map, share, switchMap, tap } from 'rxjs/operators';
import { Utils } from './../../util/src/utils';
import { ResourceCategory, ResourceCategoryData } from './category/resource-category.model';
import { ApprovalMessage, Educator, Resource, ResourceData, ResourceEventType } from './resource.model';
import { ResourceTag, ResourceTagData } from './tag/resource-tag.model';
import { AppScope } from './type.scope.enum';
import { ResourceTypeService } from './type/resource-type.service';
import { ResourceStaffRoles } from './types.enum';
export interface YouTubeVideoData {
    title: string;
    duration: string;
}
@Injectable({
    providedIn: 'root',
})
export class ResourceService implements DatatableHttpService {
    protected resourceUrl: string;
    protected guestResourceUrl: string;
    protected resourceCollaborationUrl: string;
    protected resourceLogUrl: string;
    protected resourceTagUrl: string;
    protected resourceCatUrl: string;
    protected fieldCheckUrl: string;
    protected youTubeVideoInfoUrl: string;
    protected folderUrl: string;
    protected educatorUrl: string;
    protected registerationUrl: string;
    protected joinEventUrl: string;
    protected user_id: Observable<number>;
    protected customer_id: Observable<number>;
    private gradeSystemsMap = new Map<number, Observable<GradeSystem>>();
    public lastPrompterValues = { tag_ids: [], prompt: '' };
    private isResSubmitDisabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public constructor(
        protected auth: AuthService,
        protected builder: RequestBuilderService,
        protected config: ConfigService,
        protected typeService: ResourceTypeService,
        protected gradeSystemService: GradeSystemService,
        protected logger: LoggerService,
        protected userService: UserService,
        protected customerService: CustomerService,
        protected itemService: ResourceItemService,
        protected capabilityService: CapabilityService,
        protected mediaService: MediaService
    ) {
        this.resourceUrl = `${this.config.config.apiUrl}resources`;
        this.guestResourceUrl = `${this.config.config.apiUrl}guest/resources`;
        this.resourceLogUrl = `${this.config.config.apiUrl}resources/stats/log`;
        this.resourceTagUrl = `${this.config.config.apiUrl}tags`;
        this.resourceCatUrl = `${this.config.config.apiUrl}categories`;
        this.folderUrl = `${this.config.config.apiUrl}folders`;
        this.educatorUrl = `${this.config.config.apiUrl}educators`;
        this.registerationUrl = `${this.config.config.apiUrl}event/registrations`;
        this.fieldCheckUrl = `${this.config.config.apiUrl}resources/field/validate`;
        this.joinEventUrl = `${this.config.config.apiUrl}resource-opportunities/join`;
        this.resourceCollaborationUrl = `${this.resourceUrl}/collaboration`;
        // this.youTubeVideoInfoUrl = `https://www.googleapis.com/youtube/v3/videos?id=r7jNERUUemQ&key=AIzaSyAk8Co-GcqulQaEasqCdNKqAaGhQZ7WwaI&part=snippet,contentDetails`
        this.youTubeVideoInfoUrl = `https://www.googleapis.com/youtube/v3/videos?part=snippet,contentDetails`;

        this.user_id = this.auth.getUser().pipe(
            first(),
            map((user) => user.id)
        );

        this.customer_id = this.auth.getCustomer().pipe(
            first(),
            map((customer) => customer.id)
        );
    }
    public setResSubmitDisabled(disabled: boolean) {
        this.isResSubmitDisabled.next(disabled);
    }
    public getResSubmitDisabled(): Observable<boolean> {
        return this.isResSubmitDisabled.asObservable();
    }
    public getResource(resourceId: number, eager?: string | string[]): Observable<Resource> {
        const request = this.builder.get<ResourceData>(`${this.resourceUrl}/${resourceId}`);
        request.putEager(eager);
        return request.request().pipe(
            map((res) => {
                return this.createResource(res.data);
            })
        );
    }
    public getResourceWithImgSize(resourceId: number, imgSize = 's', eager?: string | string[]): Observable<Resource> {
        const request = this.builder.get<ResourceData>(`${this.resourceUrl}/${resourceId}`);
        request.putEager(eager);
        request.param('size', imgSize);
        return request.request().pipe(
            map((res) => {
                return this.createResource(res.data);
            })
        );
    }

    public getResourceWithCompletionTime(
        resourceId: number,
        interval: number,
        eager?: string[] | string
    ): Observable<ResponseData<ResourceData>> {
        return this.builder
            .get<ResourceData>(`${this.resourceUrl}/${resourceId}`)
            .param('prompt_completion_time', interval)
            .putEager(eager)
            .request();
    }

    public getResourcesByType(type?: AppScope): Observable<Resource[]> {
        const request = this.builder.get<ResourceData[]>(this.resourceUrl);

        if (type) {
            request.param('type', type);
        }

        return request.request().pipe(map((res) => this.mapResource(res.data)));
    }
    public getResourceInstructors(params: {
        query?: string;
        ids?: number[];
        resourceId?: number;
        limit?: number;
    }): Observable<Educator[]> {
        const request = this.builder.get<Educator[]>(this.educatorUrl);

        request.limit(params.limit || 10);

        if (params.query) {
            request.param('query', params.query);
        }
        if (params.ids) {
            request.param('ids', params.ids.join());
        }
        if (params.resourceId) {
            request.param('resource', params.resourceId);
        }
        return request.request().pipe(map((res) => this.mapEducators(res.data)));
    }

    public getResourceEducators(params: {
        query?: string;
        ids?: number[];
        resourceId?: number;
        limit?: number;
        role?: ResourceStaffRoles;
        extraFilters?: { property: string; items: any[] }[];
    }): Observable<Educator[]> {
        const request = this.builder.get<Educator[]>(this.educatorUrl);

        request.limit(params.limit || 10);

        if (params.query) {
            request.param('query', params.query);
        }
        if (params.role) {
            request.param('role', params.role);
        }
        if (params.ids) {
            request.param('ids', params.ids.join());
        }
        if (params.resourceId) {
            request.param('resource', params.resourceId);
        }
        if (!!params.extraFilters?.length) {
            params.extraFilters.forEach((param) => {
                if (param.property && param.items && param.items.length) {
                    request.param(param.property, param.items.join());
                }
            });
        }
        return request.request().pipe(map((res) => this.mapEducators(res.data)));
    }

    public getDefaultEducatorsByRole(role: ResourceStaffRoles): Observable<Educator[]> {
        const request = this.builder.get<Educator[]>(this.educatorUrl);
        request.param('role', role);
        request.param('default', 1);
        return request.request().pipe(map((res) => this.mapEducators(res.data)));
    }

    public createNewResEducator(data: Educator, eager?: string[]): Observable<Educator> {
        return this.builder
            .post<Educator>(`${this.educatorUrl}`, data)
            .putEager(eager)
            .request()
            .pipe(
                map((res) => this.mapEducator(res.data)),
                catchError((res: HttpErrorResponse) => {
                    this.logger.error(res.error.error.messages[0]);
                    return EMPTY;
                })
            );
    }

    public getUserInvoiceInfo(userId: number): Observable<any> {
        return this.builder
            .get<any>(`${this.resourceUrl}/invoice`)
            .param('user', userId)
            .request()
            .pipe(
                map((res) => res.data),
                catchError((res: HttpErrorResponse) => {
                    this.logger.error(res.error.error.messages[0]);
                    return EMPTY;
                })
            );
    }

    public getEducatorByUserId(userId?: number): Observable<Educator> {
        return this.builder
            .get<Educator>(`${this.educatorUrl}/byUser`)
            .request()
            .pipe(
                map((res) => this.mapEducator(res.data)),
                catchError((res: HttpErrorResponse) => {
                    this.logger.error(res.error.error.messages[0]);
                    return EMPTY;
                })
            );
    }

    public updateResEducator(data: Educator, eager?: string[]): Observable<Educator> {
        return this.builder
            .put<Educator>(`${this.educatorUrl}/${data.id}`, data)
            .putEager(eager)
            .request()
            .pipe(
                map((res) => this.mapEducator(res.data)),
                catchError((res: HttpErrorResponse) => {
                    this.logger.error(res.error.error.messages[0]);
                    return EMPTY;
                })
            );
    }

    public deleteResEducator(data: Educator): Observable<{ success: boolean } | {}> {
        return this.builder
            .delete<{ success: boolean }>(`${this.educatorUrl}/${data.id}`)
            .request()
            .pipe(
                map((res) => res.data),
                catchError((res: HttpErrorResponse) => {
                    this.logger.error(res.error.error.messages[0]);
                    return EMPTY;
                })
            );
    }

    public userRequestPermission(
        resId: number,
        message?: ApprovalMessage,
        eager?: string | string[],
        permValue = 'enroll_requested'
    ): Observable<Resource> {
        let data = {
            resource_id: resId,
            permission_type: permValue,
            permission_value: true,
        };
        if (!!message && !!message.body) {
            data = { ...data, ...{ message: message } };
        }
        const req = this.builder.post<ResourceData>(`${this.resourceCollaborationUrl}`, data);
        req.putEager(eager);
        return req.request().pipe(
            map((res) => this.createResource(res.data)),
            catchError((res: HttpErrorResponse) => {
                this.logger.error(res.error.error.messages[0]);
                return EMPTY;
            })
        );
    }
    public userRequestEventPermission(params: {
        resId: number;
        eventOccasionId: number;
        message?: ApprovalMessage;
        eager?: string | string[];
    }): Observable<Resource> {
        let data = {
            resource_id: params.resId,
            permission_type: 'enroll_requested',
            permission_value: true,
        };
        if (!!params.message && !!params.message.body) {
            data = { ...data, ...{ message: params.message } };
        }
        if (!!params.eventOccasionId) {
            data = { ...data, ...{ event_occasion_id: params.eventOccasionId } };
        }
        const req = this.builder.post<ResourceData>(`${this.resourceCollaborationUrl}`, data);
        req.putEager(params.eager);
        return req.request().pipe(
            map((res) => this.createResource(res.data)),
            catchError((res: HttpErrorResponse) => {
                this.logger.error(res.error.error.messages[0]);
                return EMPTY;
            })
        );
    }
    public reviewCollRequest(
        permission_type: string,
        coll_id: number,
        approved: boolean,
        message?: ApprovalMessage
    ): Observable<{ success: boolean } | {}> {
        let data = {
            permission_type: permission_type,
            permission_value: approved ? 1 : 0,
            collaboration_ids: [coll_id],
        };
        if (!!message) {
            data = { ...data, ...{ message: message } };
        }
        return this.builder
            .post<{ success: boolean }>(`${this.resourceCollaborationUrl}`, data)
            .request()
            .pipe(
                map((res) => res.data),
                catchError((res: HttpErrorResponse) => {
                    this.logger.error(res.error.error.messages[0]);
                    return EMPTY;
                })
            );
    }
    public reviewManyCollRequests(
        permission_type: string,
        coll_ids: number[],
        approved: boolean,
        message?: ApprovalMessage
    ): Observable<{ success: boolean } | {}> {
        let data = { permission_type: permission_type, permission_value: approved, collaboration_ids: coll_ids };
        if (!!message) {
            data = { ...data, ...{ message: message } };
        }
        return this.builder
            .post<{ success: boolean }>(`${this.resourceCollaborationUrl}`, data)
            .request()
            .pipe(
                map((res) => res.data),
                catchError((res: HttpErrorResponse) => {
                    this.logger.error(res.error.error.messages[0]);
                    return EMPTY;
                })
            );
    }

    public getResourceEventType(): Observable<ResourceEventType[]> {
        const request = this.builder.get<ResourceEventType[]>(`${this.resourceUrl}/event/types`);
        return request.request().pipe(map((res) => res.data));
    }

    public registerResource(data: any): Observable<any> {
        return this.builder
            .post<any>(`${this.registerationUrl}`, data)
            .request()
            .pipe(
                map((res) => res.data),
                catchError((res: HttpErrorResponse) => {
                    this.logger.error(res.error.error.messages[0]);
                    return EMPTY;
                })
            );
    }
    public joinEvent(data: {
        userId: number;
        resourceId?: number;
        eventOccasionId?: number;
        eager?: string | string[];
    }): Observable<any> {
        let body = { user_id: data.userId, resource_id: data.resourceId };
        if (!!data.eventOccasionId) {
            body = { ...body, ...{ event_occasion_id: data.eventOccasionId } };
        }
        const req = this.builder.post<any>(`${this.joinEventUrl}`, body);
        req.putEager(data.eager);
        return req.request().pipe(
            map((res) => res.data),
            catchError((res: HttpErrorResponse) => {
                this.logger.error(res.error.error.messages[0]);
                return EMPTY;
            })
        );
    }

    public getResgieredUsersByResId(resId: number): Observable<boolean> {
        return this.builder
            .get<any>(`${this.registerationUrl}`)
            .param('resource', resId)
            .request()
            .pipe(map((res) => res.data));
    }

    public checkResBooking(resId: number, regId: number): Observable<boolean> {
        return this.builder
            .get<any>(`${this.registerationUrl}/${regId}`)
            .param('resource', resId)
            .request()
            .pipe(map((res) => Utils.isEmpty(res.data)));
    }

    public bookResource(resId: number, regId: number): Observable<any> {
        return this.builder
            .put<any>(`${this.registerationUrl}/${regId}`, { resource_id: resId })
            .request()
            .pipe(map((res) => res.data));
    }

    public getPublicResourcesByScope(scope: AppScope, query?: string): Observable<Resource[]> {
        const request = this.builder.get<ResourceData[]>(this.resourceUrl).param('public', '1');

        request.param('scope', scope);
        request.param('published', '1');
        if (query && query.length) {
            request.param('query', query);
        }

        return request.request().pipe(map((res) => this.mapResource(res.data)));
    }

    public getResourcesByUser(scope?: AppScope, query?: string): Observable<Resource[]> {
        const request = this.builder.get<ResourceData[]>(this.resourceUrl).param('user', this.user_id);
        request.param('published', '1');
        if (scope) {
            request.param('scope', scope);
        }
        if (query && query.length) {
            request.param('query', query);
        }

        return request.request().pipe(map((res) => this.mapResource(res.data)));
    }

    public getResourcesBySectionId(config: {
        sectionId?: number;
        param?: { [key: string]: any };
        sectionCustomer?: number;
        query?: string;
        page?: number;
        limit?: number;
        sort?: Filter<string>;
        dir?: Filter<string>;
        language?: number[];
    }): Observable<{ data: Resource[]; total_count: number }> {
        const request = this.builder.get<ResourceData[]>(this.resourceUrl).param('published', 1);

        if (!Utils.isEmpty(config.language)) {
            request.param('language', Array.isArray(config.language) ? config.language.join() : config.language);
        }

        if (config.sectionId) {
            request.param('section', config.sectionId);
        }

        if (config.param) {
            for (const key in config.param) {
                if (config.param.hasOwnProperty(key)) {
                    request.param(key, config.param[key]);
                }
            }
        }

        if (config.sort && config.sort.items?.length) {
            request.param('sort', Array.isArray(config.sort.items) ? config.sort.items.join() : config.sort.items);
        }

        if (config.dir && config.dir.items?.length) {
            request.param(config.dir.property, config.dir.items.join());
        }

        if (config.query?.length) {
            request.param('query', config.query);
        }

        if (config.sectionCustomer) {
            request.param('sectionCustomer', config.sectionCustomer);
        }
        request.page(config.page);
        request.limit(config.limit || 25);

        return request.request().pipe(
            map((res: PaginatorResponse<ResourceData[]>) => ({
                data: this.mapResource(res.data),
                total_count: res.paginator.total_count,
            }))
        );
    }
    public getResourcesByStatus(param: {
        status: string;
        userId?: number;
        scope?: AppScope;
        query?: string;
        page?: number;
        limit?: number;
        sort?: Filter<string>;
        dir?: Filter<string>;
        language?: number[];
    }): Observable<{ data: Resource[]; total_count: number }> {
        const request = this.builder
            .get<ResourceData[]>(this.resourceUrl)
            .param(param.status, param.userId ?? this.user_id);

        if (param.language) {
            request.param('language', Array.isArray(param.language) ? param.language.join() : param.language);
        }

        if (param.scope) {
            request.param('scope', param.scope);
        }

        if (param.status === 'recommended' || param.status === 'popular') {
            request.param('publicOrCustomer', this.customer_id);
        }

        if (param.sort && param.sort.items?.length) {
            request.param(param.sort.property, param.sort.items.join());
        }
        if (param.dir && param.dir.items?.length) {
            request.param(param.dir.property, param.dir.items.join());
        }

        if (param.query?.length) {
            request.param('query', param.query);
        }
        request.page(param.page);
        request.limit(param.limit || 25);

        return request.request().pipe(
            map((res: PaginatorResponse<ResourceData[]>) => ({
                data: this.mapResource(res.data),
                more: res.more,
                total_count: res.paginator.total_count,
            }))
        );
    }

    public getNewlyResources(scope?: AppScope, limit?: number, query?: string): Observable<Resource[]> {
        const request = this.builder.get<ResourceData[]>(this.resourceUrl).param('user', this.user_id);
        request.param('sort', 'updated_at');
        request.param('published', 1);
        request.param('dir', 'desc');
        if (scope) {
            request.param('scope', scope);
        }
        if (limit) {
            request.param('limit', limit);
        }
        if (query && query.length) {
            request.param('query', query);
        }

        return request.request().pipe(map((res) => this.mapResource(res.data)));
    }

    public getDoneResourcesByScope(scope?: AppScope, query?: string): Observable<Resource[]> {
        const request = this.builder.get<ResourceData[]>(this.resourceUrl).param('done', this.user_id);

        if (scope) {
            request.param('scope', scope);
        }
        if (query && query.length) {
            request.param('query', query);
        }

        return request.request().pipe(map((res) => this.mapResource(res.data)));
    }

    public getDoneResources(filter: Filter<number>, query?: string): Observable<Resource[]> {
        const request = this.builder.get<ResourceData[]>(this.resourceUrl).param('done', this.user_id);
        // request.param('sort', 'updated_at');
        // request.param('dir', 'desc');

        if (filter) {
            request.param(filter.property, filter.items.join());
        }

        if (query && query.length) {
            request.param('query', query);
        }
        return request.request().pipe(map((res) => this.mapResource(res.data)));
    }

    public getAssignedResources(filter: Filter<number>, query?: string): Observable<Resource[]> {
        const request = this.builder.get<ResourceData[]>(this.resourceUrl).param('assigned', this.user_id);
        request.param('sort', 'updated_at');
        request.param('dir', 'desc');

        if (filter) {
            request.param(filter.property, filter.items.join());
        }

        if (query && query.length) {
            request.param('query', query);
        }
        return request.request().pipe(map((res) => this.mapResource(res.data)));
    }

    public downlaodRes(ids: number[], type: string[]): Observable<Blob> {
        const builder = this.builder.get<Blob>(`${this.resourceUrl}/${ids.join(',')}/download`);
        builder.param('type', type);
        return builder.download();
    }

    public getAssignedCoursePlan(filter: Filter<number>, query?: string): Observable<Resource[]> {
        const request = this.builder.get<ResourceData[]>(this.resourceUrl).param('assignedCoursePlan', this.user_id);
        // request.param('sort', 'updated_at');
        // request.param('dir', 'desc');

        if (filter) {
            request.param(filter.property, filter.items.join());
        }

        if (query && query.length) {
            request.param('query', query);
        }
        return request.request().pipe(map((res) => this.mapResource(res.data)));
    }

    public getStartedResources(filter: Filter<number>, query?: string): Observable<Resource[]> {
        const request = this.builder.get<ResourceData[]>(this.resourceUrl).param('started', this.user_id);
        // request.param('sort', 'started_at');
        // request.param('dir', 'desc');
        if (filter) {
            request.param(filter.property, filter.items.join());
        }
        if (query && query.length) {
            request.param('query', query);
        }
        return request.request().pipe(map((res) => this.mapResource(res.data)));
    }

    public getFavResourcesByScope(scope?: AppScope, query?: string): Observable<Resource[]> {
        const request = this.builder.get<ResourceData[]>(this.resourceUrl).param('user', this.user_id);
        request.param('favorite', '1');
        request.param('published', '1');
        if (scope) {
            request.param('scope', scope);
        }
        if (query && query.length) {
            request.param('query', query);
        }

        return request.request().pipe(map((res) => this.mapResource(res.data)));
    }

    public getStartedResourcesByType(type?: AppScope): Observable<Resource[]> {
        const request = this.builder.get<ResourceData[]>(this.resourceUrl).param('started', this.customer_id);

        if (type) {
            request.param('type', type);
        }

        return request.request().pipe(map((res) => this.mapResource(res.data)));
    }

    public getUserStartedResourcesByScope(scope?: AppScope, query?: string): Observable<Resource[]> {
        const request = this.builder.get<ResourceData[]>(this.resourceUrl).param('started', this.user_id);

        if (scope) {
            request.param('scope', scope);
        }
        if (query && query.length) {
            request.param('query', query);
        }

        return request.request().pipe(map((res) => this.mapResource(res.data)));
    }

    public checkFieldValidaty(data: ResourceData): Observable<boolean> {
        return this.builder
            .post<ResourceData>(`${this.fieldCheckUrl}`, data)
            .request()
            .pipe(
                map(() => true),
                catchError(() => of(false))
            );
    }

    public getResources(
        ids?: number[],
        params?: {},
        limit?: number,
        query?: string,
        filters?: Filter<any>[]
    ): Observable<Resource[]> {
        const request = this.builder.get<ResourceData[]>(this.resourceUrl).limit(limit).query(query);
        if (ids) {
            request.param('ids', ids.join(','));
        }
        for (const i in params) {
            if (params.hasOwnProperty(i)) {
                if (i === 'owner') {
                    request.param('owner', this.user_id);
                } else if (i === 'customer') {
                    request.param('customer', this.customer_id);
                } else if (i === 'includeCustomer') {
                    request.param('includeCustomer', this.customer_id);
                } else if (i === 'assignedOrCustomer') {
                    request.param('assignedOrCustomer', this.customer_id);
                } else {
                    request.param(i, params[i]);
                }
            }
        }
        return request
            .filters(filters) // filters must follow params, user filters to override default.
            .request()
            .pipe(map((res) => this.mapResource(res.data)));
    }
    public getResourcesByFilters(
        filters?: Filter<any>[],
        limit?: number
    ): Observable<{ data: Resource[]; total_count: number }> {
        const request = this.builder.get<ResourceData[]>(this.resourceUrl).filters(filters);
        request.limit(limit || 25);

        return request.request().pipe(
            map((res: PaginatorResponse<ResourceData[]>) => ({
                data: this.mapResource(res.data),
                total_count: res.paginator.total_count,
            }))
        );
    }
    public getGuestResourcesByFilters(
        filters?: Filter<any>[],
        limit?: number
    ): Observable<{ data: Resource[]; total_count: number }> {
        const request = this.builder.get<ResourceData[]>(this.guestResourceUrl).filters(filters);
        request.limit(limit || 25);

        return request.request().pipe(
            map((res: PaginatorResponse<ResourceData[]>) => ({
                data: this.mapResource(res.data),
                total_count: res.paginator.total_count,
            }))
        );
    }
    public datatable(
        query: string,
        page?: number | any,
        sort?: string,
        dir?: string,
        selection?: string[],
        params?: {},
        limit?: number
    ): Observable<PaginatorResponse<Resource[]>> {
        const request = this.builder.get<ResourceData[]>(this.resourceUrl);

        if (query) {
            request.param('query', query);
        }

        request.page(page);
        request.limit(limit);

        if (sort) {
            request.param('sort', sort);
        }

        if (dir) {
            request.param('dir', dir);
        }

        if (selection) {
            request.param('ids', selection);
        }

        for (const i in params) {
            if (params.hasOwnProperty(i)) {
                if (i === 'owner') {
                    request.param('owner', this.user_id);
                } else if (i === 'customer') {
                    request.param('customer', this.customer_id);
                } else if (i === 'includeCustomer') {
                    request.param('includeCustomer', this.customer_id);
                } else if (i === 'publicOrCustomer') {
                    request.param('publicOrCustomer', this.customer_id);
                } else {
                    request.param(i, params[i]);
                }
            }
        }

        return request.request().pipe(
            map((res: PaginatorResponse<ResourceData[]>) => {
                return {
                    data: this.mapResource(res.data),
                    notify: res.notify,
                    paginator: res.paginator,
                };
            })
        );
    }

    public createResourceTemplate(data: ResourceData, params?: { [key: string]: any }): Observable<Resource> {
        return this.auth.getUser().pipe(
            first(),
            switchMap((user) =>
                this.store(
                    {
                        title: `${user.name}_${moment().format('YYYY_MM_DD_HHmmss')}`,
                        customer_id: user.customer_id,
                        author_id: user.id,
                        ...data,
                    },
                    params
                )
            )
        );
    }

    public store(data: ResourceData, params?: { [key: string]: any }): Observable<Resource> {
        const req = this.builder.post<ResourceData>(`${this.resourceUrl}`, data);

        if (params && !Utils.isEmpty(params)) {
            req.putParam(params);
        }
        return req.request().pipe(map((res) => this.createResource(res.data)));
    }

    public prompter(data: ResourceData, id: number): Observable<Resource | Resource[]> {
        return this.builder
            .post<ResourceData | ResourceData[]>(`${this.resourceUrl}/prompter/${id || ''}`, data)
            .request()
            .pipe(
                map((res) =>
                    Array.isArray(res.data)
                        ? res.data.map((item) => this.createResource(item))
                        : this.createResource(res.data)
                )
            );
    }

    public update(data: ResourceData, ignoreFetch = false, params?: { [key: string]: any }): Observable<Resource> {
        const obs = !ignoreFetch ? this.getResource(data.id) : of(null);
        return obs.pipe(
            switchMap((resource) =>
                this.builder
                    .put<ResourceData>(`${this.resourceUrl}/${data.id}`, resource ? resource.getData(data) : data)
                    .putParam(params)
                    .request()
            ),
            map((res) => this.createResource(res.data))
        );
    }

    public updateStatus(ids: number[], data: ResourceData): Observable<any> {
        return this.builder.put<ResourceData[]>(`${this.resourceUrl}/${ids}/hide`, data).request();
    }

    public destroy(resource: Resource): Observable<{ success: boolean } | {}> {
        return this.builder
            .delete<{ success: boolean }>(`${this.resourceUrl}/${resource.id}`)
            .request()
            .pipe(
                map((res) => res.data),
                catchError((res: HttpErrorResponse) => {
                    this.logger.error(res.error.error.messages[0]);

                    return EMPTY;
                })
            );
    }

    public destroyMultipleResources(ids: number[]): Observable<{ success: boolean } | {}> {
        return this.builder
            .delete<{ success: boolean }>(`${this.resourceUrl}/${ids}`)
            .request()
            .pipe(
                map((res) => res.data),
                catchError((res: HttpErrorResponse) => {
                    this.logger.error(res.error.error.messages[0]);

                    return EMPTY;
                })
            );
    }

    public restore(ids: number | number[]): Observable<Resource | Resource[]> {
        return this.builder
            .put<ResourceData | ResourceData[]>(`${this.resourceUrl}/${ids}/restore`, null)
            .request()
            .pipe(map((res) => (Array.isArray(res.data) ? this.mapResource(res.data) : this.createResource(res.data))));
    }

    public publish(ids: number | number[], eager?: string | string[]): Observable<Resource | Resource[]> {
        const req = this.builder.put<ResourceData | ResourceData[]>(`${this.resourceUrl}/${ids}/publish`, null);
        req.putEager(eager);
        return req
            .request()
            .pipe(map((res) => (Array.isArray(res.data) ? this.mapResource(res.data) : this.createResource(res.data))));
    }

    public unpublish(ids: number | number[], eager?: string | string[]): Observable<Resource | Resource[]> {
        const req = this.builder.put<ResourceData | ResourceData[]>(`${this.resourceUrl}/${ids}/unpublish`, null);
        req.putEager(eager);
        return req
            .request()
            .pipe(map((res) => (Array.isArray(res.data) ? this.mapResource(res.data) : this.createResource(res.data))));
    }

    public public(ids: number | number[], extra?: { cluster: boolean }): Observable<Resource | Resource[]> {
        return this.builder
            .put<ResourceData | ResourceData[]>(`${this.resourceUrl}/${ids}/public`, extra)
            .request()
            .pipe(map((res) => (Array.isArray(res.data) ? this.mapResource(res.data) : this.createResource(res.data))));
    }

    public unpublic(ids: number | number[]): Observable<Resource | Resource[]> {
        return this.builder
            .put<ResourceData | ResourceData[]>(`${this.resourceUrl}/${ids}/unpublic`, null)
            .request()
            .pipe(map((res) => (Array.isArray(res.data) ? this.mapResource(res.data) : this.createResource(res.data))));
    }

    public duplicate(resource: Resource, data?: ResourceData): Observable<Resource> {
        return this.builder
            .post<ResourceData>(`${this.resourceUrl}/${resource.id}/duplicate`, data ? data : null)
            .request()
            .pipe(
                map((res) => this.createResource(res.data)),
                catchError((res: HttpErrorResponse) => {
                    this.logger.error(res.error.error.messages[0]);

                    return EMPTY;
                })
            );
    }

    public getYouTubeVideoInfos(videoId: string): Observable<YouTubeVideoData> {
        const req = this.builder.get<YouTubeVideoData>(this.youTubeVideoInfoUrl).param('id', videoId);
        req.param('key', this.config.config.YOUTUBE_DATA_API_KEY);
        return req.request().pipe(
            map((res: any) => {
                return {
                    title: res.items[0].snippet.title,
                    duration: res.items[0].contentDetails.duration,
                };
            })
        );
    }
    // public getYouTubeVideoInfo(videoId: string): Observable<any> {
    //     const url = 'https://www.googleapis.com/youtube/v3/videos?id=r7jNERUUemQ&key=AIzaSyAk8Co-GcqulQaEasqCdNKqAaGhQZ7WwaI&part=snippet,contentDetails';
    //     return this.http.get<any>(url);
    // }

    /**
     * Map data to resources
     */
    protected mapResource(data: ResourceData[]): Resource[] {
        return data.map((item) => this.createResource(item));
    }

    /**
     * Map data to Educators
     */
    protected mapEducators(data: Educator[]): Educator[] {
        return data.map((item) => this.mapEducator(item));
    }

    /**
     * Create a educator from data.
     */
    public mapEducator(edu: Educator): Educator {
        edu.media$ = of(edu.media);
        return edu;
    }

    /**
     * Create a resource from data.
     */
    public createResource(data: ResourceData): Resource {
        const resource = new Resource(data);
        if (resource.id && resource.items_attached) {
            resource.items$ = this.itemService.getResourceItems(resource.id);
            resource.item$ = this.itemService.getResourceItemByResourceId(resource.id);
        }
        resource.user_permissions = data.user_permissions ?? [
            {
                permission_type: 'view',
                permission_value: {
                    value: true,
                    disabled: false,
                },
            },
            {
                permission_type: 'mandatory',
                permission_value: {
                    value: false,
                    disabled: false,
                },
            },
            {
                permission_type: 'enroll_approval_required',
                permission_value: {
                    value: false,
                    disabled: false,
                },
            },
            {
                permission_type: 'signoff_required',
                permission_value: {
                    value: false,
                    disabled: false,
                },
            },
            {
                permission_type: 'edit',
                permission_value: {
                    value: false,
                    disabled: true,
                },
            },
            {
                permission_type: 'publish',
                permission_value: {
                    value: false,
                    disabled: true,
                },
            },
            {
                permission_type: 'tutor',
                permission_value: {
                    value: false,
                    disabled: true,
                },
            },
            {
                permission_type: 'review',
                permission_value: {
                    value: false,
                    disabled: true,
                },
            },
        ];
        resource.group_permissions = data.group_permissions ?? [
            {
                permission_type: 'view',
                permission_value: {
                    value: true,
                    disabled: false,
                },
            },
            {
                permission_type: 'mandatory',
                permission_value: {
                    value: false,
                    disabled: false,
                },
            },
            {
                permission_type: 'enroll_approval_required',
                permission_value: {
                    value: false,
                    disabled: false,
                },
            },
            {
                permission_type: 'signoff_required',
                permission_value: {
                    value: false,
                    disabled: false,
                },
            },
            {
                permission_type: 'edit',
                permission_value: {
                    value: false,
                    disabled: true,
                },
            },
            {
                permission_type: 'publish',
                permission_value: {
                    value: false,
                    disabled: true,
                },
            },
            {
                permission_type: 'tutor',
                permission_value: {
                    value: false,
                    disabled: true,
                },
            },
            {
                permission_type: 'review',
                permission_value: {
                    value: false,
                    disabled: true,
                },
            },
        ];
        if (data.items?.length) {
            resource.items = this.itemService.mapItems(data.items);
        }

        if (resource.staff) {
            if (resource.staff.educator && resource.staff.educator.length) {
                resource.educators$ = this.getResourceEducators({ ids: resource.staff.educator });
                resource.educators?.map((staff) => (staff.media$ = of(staff.media)));
            }
            if (resource.staff.manager && resource.staff.manager.length) {
                resource.managers$ = this.getResourceEducators({ ids: resource.staff.manager });
                resource.managers?.map((staff) => (staff.media$ = of(staff.media)));
            }
            if (resource.staff.host && resource.staff.host.length) {
                resource.hosts$ = this.getResourceEducators({ ids: resource.staff.host });
                resource.hosts?.map((staff) => (staff.media$ = of(staff.media)));
            }
            if (resource.staff.organizer && resource.staff.organizer.length) {
                resource.organizers$ = this.getResourceEducators({ ids: resource.staff.organizer });
                resource.organizers?.map((staff) => (staff.media$ = of(staff.media)));
            }
            if (resource.staff.publisher && resource.staff.publisher.length) {
                resource.publishers$ = this.getResourceEducators({ ids: resource.staff.publisher });
                resource.publishers?.map((staff) => (staff.media$ = of(staff.media)));
            }
        }

        if (resource.categories_attached) {
            resource.categories$ = this.builder
                .get<ResourceCategoryData[]>(this.resourceCatUrl)
                .param('resource', resource.id)
                .request()
                .pipe(
                    tap((res) => (resource.number_of_categories = res.data.length)),
                    map((res) => this.mapCategories(res.data))
                );
        }

        if (resource.tags_attached) {
            resource.tags$ = data.tags
                ? of(this.mapTags(data.tags))
                : this.builder
                      .get<ResourceTagData[]>(this.resourceTagUrl)
                      .param('resource', resource.id)
                      .request()
                      .pipe(
                          tap((res) => (resource.number_of_tags = res.data.length)),
                          map((res) => this.mapTags(res.data))
                      );
        }

        /**
         * Global handle for resource observable items that has many template pipe async, to make use of rxjs share feature.
         * No need to duplicate many server requests.
         */
        // resource.author = zip(
        //     this.capabilityService.check(
        //         GeneralCapability.getName(),
        //         GeneralCapability.IMPERSONATE_ALL_USERS
        //     ),
        //     this.customer_id
        // ).pipe(
        //     switchMap(([capability, customer_id]) => {
        //         if (capability || resource.customer_id === customer_id) {
        //             return this.getAuthor(resource.author_id);
        //         } else {
        //             return of(new User({ fname: 'Anonymous' }));
        //         }
        //     })
        // );

        if (resource.managers?.length) {
            resource.author$ = of(new User(resource.managers[0]));
        } else if (resource.staff?.manager?.length) {
            resource.author$ = this.userService.getAuthor(resource.staff?.manager[0]);
        } else if (data.author) {
            resource.author$ = of(new User(data.author));
        } else if (resource.author_id) {
            resource.author$ = this.userService.getAuthor(resource.author_id);
        } else {
            resource.author$ = of(new User({ fname: 'Anonymous' }));
        }
        if (resource.occasion_dates) {
            resource.occasion_dates = resource.occasion_dates.map((occasion) => {
                return {
                    ...occasion,
                    show_application_period: occasion.show_application_period ?? false,
                    application_start_date: occasion.application_start_date ?? null,
                    application_end_date: occasion.application_end_date ?? null,
                };
            });
        }
        resource.customer = resource.customer_id
            ? this.customerService.getCustomer(resource.customer_id)
            : of(new Customer({ name: 'Anonymous' }));

        if (resource.grade_system_id) {
            resource.gradeSystem = this.getGradeSystem(resource.grade_system_id);
        }

        // resource.media$ = this.mediaService
        //     .getMedia(resource.media_id, ResourceTypes.scopeByChildType(resource.type_id), resource.article_code)
        //     .pipe(shareReplay());
        resource.media$ = of(resource.media);
        return resource;
    }
    protected mapTags(data: ResourceTagData[]): ResourceTag[] {
        return data.map((item) => new ResourceTag(item));
    }
    protected mapCategories(data: ResourceCategoryData[], query?: string, type_id?: number): ResourceCategory[] {
        return data.map((item) => new ResourceCategory(item));
    }

    protected getGradeSystem(grade_system_id: number): Observable<GradeSystem> {
        let gradeSystem = this.gradeSystemsMap.get(grade_system_id);
        if (gradeSystem) {
            return gradeSystem;
        }
        gradeSystem = this.gradeSystemService.getGradeSystem(grade_system_id).pipe(share());
        this.gradeSystemsMap.set(grade_system_id, gradeSystem);
        return gradeSystem;
    }
}
