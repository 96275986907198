import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { AuthService } from '@klickdata/core/auth';
import { MessageDeletedUndoComponent, MessageSavedComponent, MessageService } from '@klickdata/core/message';
import { MobileService, SideNaveActionsTypes, SideNaveDataTypes } from '@klickdata/core/mobile';
import { MessageContextTypes, Notification, NotificationService } from '@klickdata/core/notification';
import { AppScope } from '@klickdata/core/resource';
import { ResourceOpportunityService } from '@klickdata/core/opportunity';
import {
    Filter,
    GlobalFilterProperty,
    SelectFilterOption,
    TableFilterComponent,
    TableSource,
} from '@klickdata/core/table';
import { User } from '@klickdata/core/user';
import { Utils } from '@klickdata/core/util';
import { DownloadHelperService } from 'apps/klickdata/src/app/shared/dialog/download-pdf-dialog/download-helper.service';
import moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { filter, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { NotificationListingService } from './notification.service';
@Component({
    selector: 'app-messages-listing',
    templateUrl: './messages-listing.component.html',
    styleUrls: ['./messages-listing.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [NotificationListingService],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class MessagesListingComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() tableFilterItems: Filter<string | number>[];
    @Input() msgContextType: MessageContextTypes;
    public dataSource = new TableSource<Notification>();
    @Input() columns = ['select', 'created_at', 'author_id', 'scope_id', 'subject', 'badge', 'important'];
    @Output() onRowExpanded: EventEmitter<Notification> = new EventEmitter<Notification>();
    public isMobile$: Observable<boolean>;
    public showActionHeader: boolean;
    public authUser: Observable<User>;
    public destroy: Subject<boolean> = new Subject<boolean>();
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(TableFilterComponent) filter: TableFilterComponent;
    AppScope = AppScope;
    GlobalFilterProperty = GlobalFilterProperty;

    public expandedRow: Notification | null;
    public expandedCell: Notification | null;
    public selection = new SelectionModel<Notification>(true, []);
    MessageContextTypes = MessageContextTypes;
    moment = moment;
    public messageTypes: SelectFilterOption[];

    constructor(
        protected notificationListingService: NotificationListingService,
        protected mobile: MobileService,
        protected dialog: MatDialog,
        protected auth: AuthService,
        protected cdRef: ChangeDetectorRef,
        protected router: Router,
        protected message: MessageService,
        protected notificationService: NotificationService,
        protected downloadHelper: DownloadHelperService,
        protected resourceOpportunityService: ResourceOpportunityService
    ) {
        this.messageTypes = Utils.getMessageScopesOptions();
    }
    public ngOnInit() {
        this.isMobile$ = this.mobile.isMobile().pipe(shareReplay());
        this.authUser = this.auth.getUser();
        this.dataSource.service = this.notificationListingService;

        // Listen on new message from inbox
        if (this.msgContextType === MessageContextTypes.INBOX || this.msgContextType === MessageContextTypes.SENT) {
            this.notificationService
                .listenToNewMessage()
                .pipe(takeUntil(this.destroy))
                .subscribe((message) => this.dataSource.add(message));
        }
        this.notificationService
            .listenToMessageWithNewDialog()
            .pipe(takeUntil(this.destroy))
            .subscribe((message) => this.dataSource.replace(message));
    }

    public ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filter = this.filter;
        this.filter.setActiveFilter(GlobalFilterProperty.MESSAGE_SUBJECT);
        this.updateFilters();
    }
    private updateFilters() {
        const appliedFilters = [];
        if (this.tableFilterItems) {
            this.tableFilterItems.forEach((filterItem) => {
                appliedFilters.push(new Filter(filterItem.property, filterItem.items));
            });
        }

        this.filter.createOrUpdateFilter(appliedFilters);
    }
    expandRow(notification: Notification) {
        const expanded = this.expandedRow === notification ? null : notification;
        this.onRowExpanded.emit(expanded);
        setTimeout(() => {
            this.expandedRow = expanded;
            notification.badge = 0;
            notification.read_at = moment().format('YYYY-MM-DD HH:mm:ss');
            this.cdRef.markForCheck();
        }, 100);
    }
    onApprove(oppoId: number, approved: boolean) {
        this.resourceOpportunityService
            .postReviews([oppoId], { approved: approved })
            .pipe(takeUntil(this.destroy))
            .subscribe(() => this.dataSource.refresh());
    }
    public download(notification: Notification) {
        this.downloadHelper.download(notification.downloads);
    }

    importantNotification(notification: Notification) {
        this.notificationService
            .changeImportantStatus(notification.id, !notification.important)
            .pipe(takeUntil(this.destroy))
            .subscribe(() => {
                this.message.openMessage(MessageSavedComponent);
                this.dataSource.refresh();
                this.cdRef.markForCheck();
            });
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    masterToggle() {
        this.isAllSelected()
            ? this.selection.clear()
            : this.dataSource.data.forEach((row) => this.selection.select(row));
    }

    checkboxLabel(row?: Notification): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
    }
    public deleteMsg() {
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.GENERAL_NOTIFIER,
            data: {
                icon: 'delete',
                title: $localize`Delete message`,
                contentBody: $localize`You are about to delete this message. Please use caution and make sure that you really intend to delete before confirming delete below.`,
                positiveBtn: $localize`Delete`,
                negativeBtn: $localize`Cancel`,
            },
        });
        this.mobile
            .getSideNavAction()
            .pipe(
                filter((action) => action === SideNaveActionsTypes.POSITIVE),
                switchMap(() =>
                    this.notificationService.destroyMultipleMsgs(this.selection.selected.map((msg) => msg.id))
                )
            )
            .subscribe((success) => {
                if (success) {
                    const ref = this.message.openMessage(MessageDeletedUndoComponent);
                    ref.instance.snackBarRef = ref;
                    ref.onAction()
                        .pipe(takeUntil(this.destroy))
                        .subscribe(() => {
                            this.restoreMessage(this.selection.selected);
                        });
                    this.dataSource.removeByIds(this.selection.selected.map((msg) => msg.id));
                }
            });
    }
    /**
     * Restore a removed msg
     */
    public restoreMessage(messages: Notification[]) {
        this.notificationService
            .restoreMultipleMsgs(messages.map((msg) => msg.id))
            .pipe(takeUntil(this.destroy))
            .subscribe(() => {
                this.message.openMessage(MessageSavedComponent);
                this.dataSource.refresh();
            });
    }
    public makeMsgImportant(isImportant: boolean) {
        this.notificationService
            .importantMultipleMsgs(
                this.selection.selected.map((msg) => msg.id),
                isImportant
            )
            .pipe(takeUntil(this.destroy))
            .subscribe(() => {
                this.message.openMessage(
                    MessageSavedComponent,
                    isImportant
                        ? $localize`Messages are marked as important`
                        : $localize`Messages are marked as not important`
                );
                this.dataSource.refresh();
                this.cdRef.markForCheck();
            });
    }

    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
